import { LocationType } from 'common/enums'
import { ApiGeographyPoint } from 'common/types'
import { GeographyPoint } from 'common/types'

export const GeographyPointModel = (
  geographyPoint: ApiGeographyPoint,
): GeographyPoint => {
  const {
    code1 = '',
    code2 = '',
    direction,
    location1 = LocationType.EMPTY,
    location2 = LocationType.EMPTY,
    permitted,
  } = geographyPoint

  return {
    code1,
    code2,
    direction,
    location1,
    location2,
    permitted,
  }
}
