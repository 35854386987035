import { Typography } from '@atlas-design-system/react'
import { FormHeader } from 'common/components'
import { CarrierFormValue } from 'common/types'
import { FormikProps } from 'formik'
import React from 'react'

import styles from '../../carrier-form.module.css'
import { EmailListForm } from './email-list-form/email-list-form.component'

type EmailListProps = {
  formik: FormikProps<CarrierFormValue>
}

export const EmailList: React.FC<EmailListProps> = ({ formik }) => {
  return (
    <div className={styles.emailList}>
      <div className={styles.formHeader}>
        <FormHeader
          title="Email Notifications"
          variant="displayText7"
          className={styles.emailHeader}
        />
        <Typography variant="contentText2">
          Add email addresses that will receive alerts when new ATPCO content is
          received
        </Typography>
      </div>
      <EmailListForm formik={formik} />
    </div>
  )
}
