import { Spinner } from '@atlas-design-system/react'
import { useCarrierDetailsContext } from 'common/context'
import React from 'react'

import { SegmentBrandingFormWrapper } from './components'
import { SegmentBrandingSidebar } from './components/segment-branding-sidebar/segment-branding-sidebar.component'

export const SegmentBranding: React.FC = () => {
  const { isFetching } = useCarrierDetailsContext()

  if (isFetching) {
    return <Spinner />
  }

  return (
    <div data-testid="marketing-upsell-page">
      <div className="row">
        <div className="col-xs-8">
          <SegmentBrandingFormWrapper />
        </div>
        <div className="col-xs-4">
          <SegmentBrandingSidebar />
        </div>
      </div>
    </div>
  )
}
