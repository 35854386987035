import { CollapsibleFormBlock } from 'common/components'
import { alphabeticalSort } from 'common/helpers'
import { useAncillaries } from 'common/hooks'
import {
  BrandFormValue,
  BrandServicesFiltersValue,
  OptionalService,
  OptionalServicesByCategoryValue,
} from 'common/types'
import React, { useMemo } from 'react'

import { ServicesFilters } from '../services-filters/services-filters.component'
import { ServicesTable } from '../services-table/services-table.component'
import styles from './associated-services.module.css'

const SORT_KEYS_MAP: Record<string, keyof OptionalService> = {
  group: 'group',
  name: 'serviceName',
  associated: 'key',
}

const filterByGroupName = (
  filters: BrandServicesFiltersValue,
  services: OptionalService[],
  optionalServicesByCategory: OptionalServicesByCategoryValue,
) => {
  const {
    show: { value: groupName },
    sort: { value: sortValue },
  } = filters
  const items =
    groupName === 'all'
      ? services
      : services.filter(
          (service) => service.group.toLowerCase() === groupName.toLowerCase(),
        )

  const optionalServiceList = Object.values(optionalServicesByCategory).flat()

  const sortKey = SORT_KEYS_MAP[sortValue]

  const selectedIds = optionalServiceList.map(({ meta: { id } }) => id)

  if (sortKey === 'key') {
    return [...items].sort((a, b) => {
      if (selectedIds.includes(b.meta.id) > selectedIds.includes(a.meta.id)) {
        return 1
      } else if (
        selectedIds.includes(b.meta.id) < selectedIds.includes(a.meta.id)
      ) {
        return -1
      } else {
        return 0
      }
    })
  }
  return alphabeticalSort(items, sortKey)
}

type AssociatedServicesProps = {
  brandId?: string
  tableDisabled: boolean
  filters: BrandFormValue['meta']['filters']
  optionalServicesByCategory: BrandFormValue['optionalServicesByCategory']
  onEditChargeableClick: (
    serviceId: string,
    name: keyof OptionalServicesByCategoryValue,
  ) => void
}

export const AssociatedServices: React.FC<AssociatedServicesProps> = ({
  brandId,
  tableDisabled,
  filters,
  optionalServicesByCategory,
  onEditChargeableClick,
}) => {
  const { brandsData } = useAncillaries()

  const {
    distinctSegmentServiceList,
    distinctServiceList,
    distinctMandatoryServiceList,
  } = useMemo(() => {
    return {
      distinctSegmentServiceList: filterByGroupName(
        filters,
        brandsData.distinctSegmentServiceList,
        optionalServicesByCategory,
      ),
      distinctServiceList: filterByGroupName(
        filters,
        brandsData.distinctServiceList,
        optionalServicesByCategory,
      ),
      distinctMandatoryServiceList: filterByGroupName(
        filters,
        brandsData.distinctMandatoryServiceList,
        optionalServicesByCategory,
      ),
    }
  }, [filters, brandsData, optionalServicesByCategory])

  return (
    <>
      <ServicesFilters services={brandsData.distinctServiceList} />

      <CollapsibleFormBlock
        title="Mandatory Ancillaries"
        className={styles.tableWrapper}
      >
        <div style={{ padding: 'var(--spaceS) 0' }}>
          <ServicesTable
            name="mandatory"
            brandId={brandId}
            data={distinctMandatoryServiceList}
            onEditChargeableClick={onEditChargeableClick}
            disabled={tableDisabled}
          />
        </div>
      </CollapsibleFormBlock>

      <CollapsibleFormBlock title="Ancillaries" className={styles.tableWrapper}>
        <div style={{ padding: 'var(--spaceS) 0' }}>
          <ServicesTable
            name="standard"
            brandId={brandId}
            data={distinctServiceList}
            onEditChargeableClick={onEditChargeableClick}
            disabled={tableDisabled}
          />
        </div>
      </CollapsibleFormBlock>

      <CollapsibleFormBlock title="Segments" className={styles.tableWrapper}>
        <div style={{ padding: 'var(--spaceS) 0' }}>
          <ServicesTable
            name="segment"
            brandId={brandId}
            data={distinctSegmentServiceList}
            disabled={tableDisabled}
            isSegment
          />
        </div>
      </CollapsibleFormBlock>
    </>
  )
}
