import { Spinner } from '@atlas-design-system/react'
import { useCarrierDetailsContext } from 'common/context'
import { useMarketingUpsellContext } from 'common/context/marketing-upsell'
import React from 'react'

import { MarketingUpsellContent, MarketingUpsellSidebar } from './components'

export const MarketingUpsell: React.FC = () => {
  const { selectedFamily } = useMarketingUpsellContext()
  const { isFetching } = useCarrierDetailsContext()

  if (isFetching) {
    return <Spinner />
  }

  return (
    <div data-testid="marketing-upsell-page">
      <div className="row">
        <div className="col-xs-8">
          {selectedFamily && (
            <MarketingUpsellContent selectedFamily={selectedFamily} />
          )}
        </div>
        <div className="col-xs-4">
          <MarketingUpsellSidebar />
        </div>
      </div>
    </div>
  )
}
