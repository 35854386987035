import {
  ActionIconGroup,
  AddIcon,
  CloseIcon,
  CopyIcon,
} from '@atlas-design-system/react'
import { useCarrierDetailsContext } from 'common/context'
import { useSegmentBrandingContext } from 'common/context/segment-branding-context'
import { PendingAction } from 'common/enums'
import { useDirtyForm } from 'common/hooks'
import { useMemo } from 'react'

const disabledColor = 'var(--contrastColor40)'

type ActionButtonsProps = {
  disabled: boolean
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ disabled }) => {
  const {
    copyMode,
    selectedSegmentBrand,
    setCopyMode,
    setPendingAction,
    resetSelectedSegmentBrand,
  } = useSegmentBrandingContext()

  const { archiveMode } = useCarrierDetailsContext()
  const { dirtyForm } = useDirtyForm()

  const canDelete = useMemo(() => {
    if (disabled) {
      return false
    }

    if (copyMode || archiveMode || !selectedSegmentBrand?.key) {
      return false
    }

    return true
  }, [disabled, copyMode, archiveMode, selectedSegmentBrand?.key])

  const canCopy = useMemo(() => {
    if (disabled) {
      return false
    }

    if (copyMode) {
      return false
    }

    if (selectedSegmentBrand?.key) {
      return true
    }

    return false
  }, [copyMode, disabled, selectedSegmentBrand])

  const canCreateNew = useMemo(() => {
    if (disabled) {
      return false
    }
    if (archiveMode) {
      return false
    }

    return true
  }, [archiveMode, disabled])

  const onNew = () => {
    if (disabled) {
      return
    }
    if (!canCreateNew) {
      return
    }

    if (dirtyForm) {
      setPendingAction(PendingAction.NEW_BUTTON_CLICK)
    } else {
      resetSelectedSegmentBrand()
    }
  }

  const onCopy = () =>
    canCopy && selectedSegmentBrand && setCopyMode(selectedSegmentBrand.key)
  const onDelete = () => canDelete && setPendingAction(PendingAction.DELETE)

  const actionButtons = [
    {
      icon: (
        <AddIcon
          data-testid={`segment-branding-add${
            !canCreateNew ? '-disabled' : ''
          }`}
          fill={canCreateNew ? '' : disabledColor}
        />
      ),
      key: 'new',
      tooltip: 'New',
      action: onNew,
    },
    {
      icon: (
        <CopyIcon
          data-testid={`segment-branding-copy${!canCopy ? '-disabled' : ''}`}
          fill={canCopy ? '' : disabledColor}
        />
      ),
      key: 'copy',
      tooltip: 'Copy',
      action: onCopy,
    },
    {
      icon: (
        <CloseIcon
          data-testid={`segment-branding-delete${
            !canDelete ? '-disabled' : ''
          }`}
          fill={canDelete ? '' : disabledColor}
        />
      ),
      key: 'delete',
      tooltip: 'Delete',
      action: onDelete,
    },
  ]

  return (
    <ActionIconGroup
      actions={actionButtons}
      className="mmp-ancillary-collapsible-action-group"
    />
  )
}
