import { toast } from '@atlas-design-system/react'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { QUERY_KEYS, SERVICES } from 'common/api'
import { useCarrierDetailsContext } from 'common/context'
import { addCarrier, updateCarrier } from 'common/services'
import { CarrierRequest, CarrierResponse, ErrorMessage } from 'common/types'

export type UseAddUpdateProps = {
  requestData: CarrierRequest
  isNew: boolean
  onSuccess?: () => void
}

export const useAddUpdateCarrier = (): UseMutationResult<
  CarrierResponse,
  AxiosError<ErrorMessage>,
  UseAddUpdateProps
> => {
  const queryClient = useQueryClient()
  const { reloadCarrierDetails } = useCarrierDetailsContext()
  return useMutation<
    CarrierResponse,
    AxiosError<ErrorMessage>,
    UseAddUpdateProps
  >(
    ({ requestData, isNew }) =>
      isNew ? addCarrier(requestData) : updateCarrier(requestData),
    {
      onSuccess: async ({ confirmationId }, { isNew, onSuccess }) => {
        toast.success(
          `Carrier successfully ${
            isNew ? 'created' : 'saved'
          }. Confirmation id: ${confirmationId}`,
        )
        await queryClient.invalidateQueries([
          SERVICES.PROVISIONING_CARRIER_SERVICE,
          QUERY_KEYS.CARRIERS,
        ])
        reloadCarrierDetails()
        if (!!onSuccess) {
          onSuccess()
        }
      },
      onError: ({ response, message: baseMassage }) => {
        toast.error(`Error: ${response?.data.message || baseMassage}`)
      },
    },
  )
}
