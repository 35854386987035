import { Button } from '@atlas-design-system/react'
import { ReactElement } from 'react'

export function GetBrandCopyModalButtons(
  selectMode: boolean,
  handleDismiss: () => void,
  handleConfirm: () => void,
  handleClose: () => void,
  confirmText: string,
  dismissText: string,
): ReactElement {
  if (!selectMode) {
    return (
      <>
        <Button
          appearance="primary"
          data-testid="confirm-modal-button"
          onClick={handleConfirm}
        >
          {confirmText}
        </Button>
        <Button
          appearance="secondary"
          data-testid="dismiss-modal-button"
          onClick={handleDismiss}
        >
          {dismissText}
        </Button>
        <Button
          appearance="secondary"
          data-testid="close-modal-button"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </>
    )
  } else {
    return (
      <>
        <Button
          appearance="primary"
          data-testid="dismiss-modal-button"
          onClick={handleDismiss}
        >
          {dismissText}
        </Button>
        <Button
          appearance="secondary"
          data-testid="close-modal-button"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </>
    )
  }
}
