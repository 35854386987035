import { FormControl, InputNumber, Select } from '@atlas-design-system/react'
import { AncillaryFormValue, SelectOption } from 'common/types'
import { FormikProps } from 'formik'
import { FC } from 'react'
import styled from 'styled-components'

const StyledFormControl = styled.div`
  flex-basis: 12.5%;
  max-width: 12.5%;
`

interface AncillaryBaggageProps {
  formik: FormikProps<AncillaryFormValue>
  disabled?: boolean
}

const cabinSelectMock: SelectOption[] = [
  {
    label: 'F',
    value: 'F',
  },
  {
    label: 'J',
    value: 'J',
  },
  {
    label: 'P',
    value: 'P',
  },
  {
    label: 'Y',
    value: 'Y',
  },
  {
    label: '',
    value: '',
  },
]

const kglbSelectMock: SelectOption[] = [
  {
    label: 'KG',
    value: 'KG',
  },
  {
    label: 'LB',
    value: 'LB',
  },
  {
    label: '',
    value: '',
  },
]

const dimensionsSelectMock: SelectOption[] = [
  {
    label: 'CM',
    value: 'CM',
  },
  {
    label: 'IN',
    value: 'IN',
  },
  {
    label: '',
    value: '',
  },
]

export const AncillaryBaggage: FC<AncillaryBaggageProps> = ({
  formik,
  disabled = false,
}) => {
  const { values, errors, touched, setFieldValue, handleBlur, handleChange } =
    formik

  return (
    <div className="row">
      <StyledFormControl className="col-xs-1">
        <FormControl
          label="Cabin"
          disabled={disabled}
          error={touched.baggageDetails && errors.baggageDetails?.cabin?.value}
        >
          <Select
            next
            name="cabin"
            data-testid="ancillary-baggage-cabin-select"
            options={cabinSelectMock}
            onChange={(value) => setFieldValue('baggageDetails.cabin', value)}
            onBlur={handleBlur}
            value={values.baggageDetails.cabin}
          />
        </FormControl>
      </StyledFormControl>
      <StyledFormControl className="col-xs-1">
        <FormControl
          label="Piece"
          disabled={disabled}
          error={errors.baggageDetails?.piece}
        >
          <InputNumber
            name="baggageDetails.piece"
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid="ancillary-baggage-piece-input"
            value={values.baggageDetails.piece || ''}
            min="1"
            max="99"
          />
        </FormControl>
      </StyledFormControl>
      <StyledFormControl className="col-xs-1">
        <FormControl
          label="KG/LB"
          disabled={disabled}
          error={errors.baggageDetails?.weightUnit?.value}
        >
          <Select
            next
            name="weightUnit"
            data-testid="ancillary-baggage-weight-unit-select"
            options={kglbSelectMock}
            onChange={(value) =>
              setFieldValue('baggageDetails.weightUnit', value)
            }
            onBlur={handleBlur}
            value={values.baggageDetails.weightUnit}
          />
        </FormControl>
      </StyledFormControl>
      <StyledFormControl className="col-xs-1">
        <FormControl
          label="Weight"
          disabled={disabled || !values.baggageDetails.weightUnit.value}
          error={errors.baggageDetails?.weight}
        >
          <InputNumber
            name="baggageDetails.weight"
            data-testid="ancillary-baggage-weight-input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.baggageDetails.weight || ''}
            min="1"
            max="999"
          />
        </FormControl>
      </StyledFormControl>
      <StyledFormControl className="col-xs-1">
        <FormControl
          label="Dimensions"
          disabled={disabled}
          error={errors.baggageDetails?.dimensionUnit?.value}
        >
          <Select
            next
            name="dimensionUnit"
            data-testid="ancillary-baggage-dim-unit-select"
            options={dimensionsSelectMock}
            onChange={(value) =>
              setFieldValue('baggageDetails.dimensionUnit', value)
            }
            onBlur={handleBlur}
            value={values.baggageDetails.dimensionUnit}
          />
        </FormControl>
      </StyledFormControl>
      <StyledFormControl className="col-xs-1">
        <FormControl
          label="W"
          disabled={disabled || !values.baggageDetails.dimensionUnit.value}
          error={errors.baggageDetails?.width}
        >
          <InputNumber
            name="baggageDetails.width"
            data-testid="ancillary-baggage-width-input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.baggageDetails.width || ''}
            min="1"
            max="99"
          />
        </FormControl>
      </StyledFormControl>
      <StyledFormControl className="col-xs-1">
        <FormControl
          label="H"
          disabled={disabled || !values.baggageDetails.dimensionUnit.value}
          error={errors.baggageDetails?.height}
        >
          <InputNumber
            name="baggageDetails.height"
            data-testid="ancillary-baggage-height-input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.baggageDetails.height || ''}
            min="1"
            max="99"
          />
        </FormControl>
      </StyledFormControl>
      <StyledFormControl className="col-xs-1">
        <FormControl
          label="L"
          disabled={disabled || !values.baggageDetails.dimensionUnit.value}
          error={errors.baggageDetails?.length}
        >
          <InputNumber
            name="baggageDetails.length"
            data-testid="ancillary-baggage-length-input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.baggageDetails.length || ''}
            min="1"
            max="99"
          />
        </FormControl>
      </StyledFormControl>
    </div>
  )
}
