import { API, SERVICES } from 'common/api'
import {
  CreateSegmentBrandRequest,
  CreateSegmentBrandResponse,
  DeleteSegmentBrandRequest,
  DeleteSegmentBrandResponse,
  EditSegmentBrandRequest,
  EditSegmentBrandResponse,
} from 'common/types'

export const createSegmentBrand = async (
  data: CreateSegmentBrandRequest,
): Promise<CreateSegmentBrandResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().post<CreateSegmentBrandResponse>(
      `${SERVICES.BRANDED_FARES_SERVICE}/segmentBranding`,
      data,
    )

    return { confirmationId, entityId }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const editSegmentBrand = async (
  data: EditSegmentBrandRequest,
): Promise<EditSegmentBrandResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().put<EditSegmentBrandResponse>(
      `${SERVICES.BRANDED_FARES_SERVICE}/segmentBranding`,
      data,
    )

    return {
      confirmationId,
      entityId,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteSegmentBrand = async (
  data: DeleteSegmentBrandRequest,
): Promise<DeleteSegmentBrandResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().delete<DeleteSegmentBrandResponse>(
      `${SERVICES.BRANDED_FARES_SERVICE}/segmentBranding`,
      { data },
    )

    return {
      confirmationId,
      entityId,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}
