import { CollapsibleFormBlock } from 'common/components'
import { useCarrierDetailsContext } from 'common/context'
import { PendingAction } from 'common/enums'
import { useCarriers } from 'common/hooks'
import { CarrierFormValue } from 'common/types'
import { useSubmitCarrier } from 'modules/settings/hooks/use-submit-carrier.hook'
import React, { useCallback, useEffect, useState } from 'react'

import { CarrierForm } from './carrier-form/carrier-form.component'
import styles from './carrier-form/carrier-form.module.css'
import { CarrierFormModel } from './models/carrier-form.model'
import { CarrierFormRequestModel } from './models/carrier-form-request.model'

export const CarrierFormWrapper: React.FC = () => {
  const { selectedCarrier, pendingAction, setPendingAction } =
    useCarrierDetailsContext()
  const { isFetching } = useCarriers()
  const { submitCarrier } = useSubmitCarrier()
  const [initialValues, setInitialValues] = useState<CarrierFormValue>(() =>
    CarrierFormModel(),
  )

  const resetInitialValues = useCallback(() => {
    setInitialValues(CarrierFormModel(selectedCarrier))
  }, [selectedCarrier])

  useEffect(() => {
    if (selectedCarrier?.id !== initialValues.id) {
      resetInitialValues()
    }
  }, [initialValues.id, resetInitialValues, selectedCarrier?.id])

  useEffect(() => {
    if (
      pendingAction &&
      pendingAction === PendingAction.RESET_FORM &&
      !isFetching
    ) {
      resetInitialValues()
    }
  }, [isFetching, pendingAction, resetInitialValues, setPendingAction])

  const handleSubmit = useCallback(
    (values: CarrierFormValue) => {
      const request = CarrierFormRequestModel(values)
      submitCarrier(request)
    },
    [submitCarrier],
  )

  return (
    <div>
      <div className="row">
        <div className="col-xs-9">
          <CollapsibleFormBlock
            title="Add/Edit Carrier"
            defaultOpened={true}
            className={styles.formHeader}
          >
            <CarrierForm
              initialValues={initialValues}
              onSubmit={handleSubmit}
            />
          </CollapsibleFormBlock>
        </div>
      </div>
    </div>
  )
}
