import { CustomCollapsible } from 'common/components'
import { useAncillaryContext, useCarrierDetailsContext } from 'common/context'
import { groupServices } from 'common/helpers'
import { alphabeticalSort } from 'common/helpers/sorter-helpers'
import { ServiceGroup } from 'common/types'
import React, { useMemo } from 'react'

import { AncillarySelectRow } from '../ancillary-select-row/ancillary-select-row.component'
import styles from './ancillary-sidebar-block.module.css'

type AncillarySidebarBlockProps = {
  serviceGroup: ServiceGroup
}

export const AncillarySidebarBlock: React.FC<AncillarySidebarBlockProps> = ({
  serviceGroup,
}) => {
  const { selectedAncillary } = useAncillaryContext()
  const { archiveMode } = useCarrierDetailsContext()
  const { title, services } = serviceGroup

  const serviceGroups = groupServices(services, 'source')
  const sortedServiceGroups = alphabeticalSort(serviceGroups, 'title')

  const selectedAncillaryInGroup = useMemo(() => {
    for (const ancillaryGroup of serviceGroups) {
      const findedAncillary = ancillaryGroup.services.find(
        (ancillary) => ancillary.key === selectedAncillary?.key,
      )
      if (findedAncillary) {
        return findedAncillary
      }
    }
  }, [selectedAncillary, serviceGroups])

  return (
    <>
      <CustomCollapsible
        title={title}
        defaultOpened={!!selectedAncillaryInGroup}
      >
        <ul className={styles.list}>
          {archiveMode
            ? serviceGroup.services.map((service, index) => (
                <li
                  key={`${service.meta.id}${index}`}
                  className={styles.listItem}
                >
                  <AncillarySelectRow service={service} />
                </li>
              ))
            : sortedServiceGroups.map((group) => {
                const subGroupOpened = group.services.some(
                  (service) => service.key === selectedAncillaryInGroup?.key,
                )
                return (
                  <li className={styles.listItem} key={group.title}>
                    <CustomCollapsible
                      title={group.title}
                      defaultOpened={subGroupOpened}
                    >
                      <ul className={styles.list}>
                        {group.services.map((service, index) => (
                          <li
                            key={`${service.meta.id}${index}`}
                            className={styles.listItem}
                          >
                            <AncillarySelectRow service={service} />
                          </li>
                        ))}
                      </ul>
                    </CustomCollapsible>
                  </li>
                )
              })}
        </ul>
      </CustomCollapsible>
    </>
  )
}
