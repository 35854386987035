import { Button, ExportIcon, toast } from '@atlas-design-system/react'
import { CustomCollapsible } from 'common/components'
import { useCarrierDetailsContext } from 'common/context'
import { useFamilyContext } from 'common/context/family-context/family-context'
import { FamilyStatus } from 'common/enums'
import { isATPCOFamily } from 'common/helpers'
import { GlobeIcon } from 'common/icons'
import { Family } from 'common/types'
import React, { useEffect, useMemo, useState } from 'react'

import { FamilySelectRow } from './components'
import { MESSAGE_PROMOTE_SELECT_ERROR } from './family-sidebar-block.constants'
import styles from './family-sidebar-block.module.css'

type FamilySidebarBlockProps = {
  title: string
  familyList: Family[]
  hasWritePermission: boolean
}

// atpcoSequenceNumber
export const FamilySidebarBlock: React.FC<FamilySidebarBlockProps> = ({
  title,
  familyList,
  hasWritePermission,
}) => {
  const [lastSelectedLive, setLastSelectedLive] = useState(false)
  const { selectedFamily, openPromoteModal } = useFamilyContext()
  const { archiveMode } = useCarrierDetailsContext()

  const familyExists = useMemo(
    () => familyList.some((family) => family.id === selectedFamily?.id),
    [selectedFamily, familyList],
  )

  const isFamilyFromATPCO = useMemo(
    () => familyList.some((family) => isATPCOFamily(family)),
    [familyList],
  )

  const selectedFamilyInGroup = useMemo(() => {
    if (!selectedFamily) {
      return false
    }

    return familyList.find(({ id }) => id === selectedFamily.id)
  }, [familyList, selectedFamily])

  useEffect(() => {
    if (selectedFamilyInGroup) {
      setLastSelectedLive(selectedFamilyInGroup.status === FamilyStatus.LIVE)
    }
  }, [selectedFamilyInGroup])

  const promoteDisabled = useMemo(() => {
    if (!selectedFamily) {
      return false
    }

    if (selectedFamilyInGroup) {
      return selectedFamilyInGroup.status === FamilyStatus.LIVE
    }

    return lastSelectedLive
  }, [selectedFamily, selectedFamilyInGroup, lastSelectedLive])

  const handlePromoteClick = () => {
    if (
      selectedFamily?.id &&
      familyList.some(({ id }) => id === selectedFamily.id)
    ) {
      openPromoteModal()
    } else {
      toast.error(MESSAGE_PROMOTE_SELECT_ERROR)
    }
  }

  return (
    <>
      <CustomCollapsible
        title={title}
        icon={isFamilyFromATPCO ? <GlobeIcon width="16" /> : <></>}
        defaultOpened={familyExists}
      >
        <ul className={styles.list}>
          {familyList.map((family) => (
            <li
              data-testid="family-select-list-item"
              key={family.id}
              className={styles.listItem}
            >
              <FamilySelectRow family={family} />
            </li>
          ))}
        </ul>
        {hasWritePermission && !archiveMode && (
          <div className={styles.footer}>
            <div className="row end-xs">
              <div className="col-xs">
                <Button
                  disabled={promoteDisabled}
                  data-testid="promote-family-button"
                  onClick={handlePromoteClick}
                  icon={<ExportIcon width="16" />}
                >
                  Promote
                </Button>
              </div>
            </div>
          </div>
        )}
      </CustomCollapsible>
    </>
  )
}
