import { CollapsibleFormBlock } from 'common/components'
import { CarrierFormValue } from 'common/types'
import { FormikProps } from 'formik'
import React from 'react'

import styles from '../../carrier-form.module.css'
import { TestDataList } from '../test-data-list/test-data-list.component'

type CountryListProps = {
  formik: FormikProps<CarrierFormValue>
}

export const CountryList: React.FC<CountryListProps> = ({ formik }) => {
  return (
    <div>
      <CollapsibleFormBlock
        title="Test Countries"
        defaultOpened={false}
        className={styles.formHeader}
      >
        <TestDataList formik={formik} listName="countries" />
      </CollapsibleFormBlock>
    </div>
  )
}
