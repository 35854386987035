import React, { ReactNode } from 'react'

import styles from './sidebar.module.css'

type AppSidebarProps = {
  children?: ReactNode
}

export const AppSidebar: React.FC<AppSidebarProps> = ({ children }) => {
  return <div className={styles.sidebar}>{children}</div>
}
