import { FareRule } from 'common/types'
import { FieldArray, useField } from 'formik'
import React, { useMemo } from 'react'

import { FareRuleRow } from './components'

type FareRulesFieldsListProps = {
  name: string
  disabled: boolean
}

export const FareRulesFieldsList: React.FC<FareRulesFieldsListProps> = ({
  name,
  disabled,
}) => {
  const [field] = useField<FareRule[]>(name)

  const { value: items } = field

  const canDelete = useMemo(() => items.length > 1, [items])
  return (
    <>
      <p>Enter a code value for 2 or 3 of the following:</p>
      <FieldArray name={name}>
        {(fieldArrayProps) => (
          <>
            {items.map((item, index) => (
              <FareRuleRow
                key={index}
                index={index}
                canDelete={canDelete}
                fieldArrayProps={fieldArrayProps}
                namePath={name}
                disabled={disabled}
              />
            ))}
          </>
        )}
      </FieldArray>
    </>
  )
}
