import {
  ActionIconGroup,
  AddIcon,
  CloseIcon,
  FormControl,
  Select,
} from '@atlas-design-system/react'
import { LocationCodeSelect, LocationTypeSelect } from 'common/components'
import { PermissionSelect } from 'common/components/form-components/permission-select/permission-select.component'
import {
  DIRECTION_SELECT_OPTIONS,
  EMPTY_SELECT_OPTION,
  GEOGRAPHY_POINT_INITIAL_VALUE,
} from 'common/constants'
import { GeographyPointValue, SelectOption } from 'common/types'
import { FieldArrayRenderProps, FormikProps } from 'formik'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components'

const StyledSelect = styled.div`
  flex-basis: 10%;
  max-width: 10%;
  .atls-select-next__indicators {
    display: none;
  }
`

const StyledActionsWrapper = styled.div`
  flex-basis: 13%;
  max-width: 13%;
`

type GeographyRowProps<T> = {
  item: GeographyPointValue
  index: number
  canDelete: boolean
  formik: FormikProps<T>
  fieldArrayProps: FieldArrayRenderProps
  namePath: string
  disabled?: boolean
  onGetErrosMessage?: (fieldName: string, index: number) => string | undefined
}

export const GeographyRow = <T,>({
  item,
  index,
  formik,
  namePath,
  fieldArrayProps,
  canDelete,
  disabled = false,
  onGetErrosMessage,
}: GeographyRowProps<T>): JSX.Element => {
  const { insert, remove } = fieldArrayProps
  const { setFieldValue, handleBlur } = formik

  const fieldPrefix = useMemo(() => `${namePath}.${index}`, [namePath, index])

  const handleCodeSelect = useCallback(
    (value: SelectOption<string>, name: string) => {
      setFieldValue(name, value)
    },
    [setFieldValue],
  )

  const errorCode1 = onGetErrosMessage && onGetErrosMessage('code1', index)
  const errorCode2 = onGetErrosMessage && onGetErrosMessage('code2', index)

  return (
    <div className="row">
      <div className="col-xs-2">
        <FormControl label="From/To/Within" disabled={disabled}>
          <Select
            next
            data-testid="geo-direction"
            name={`${fieldPrefix}.direction`}
            options={DIRECTION_SELECT_OPTIONS}
            onChange={(selectedOption) => {
              setFieldValue(`${fieldPrefix}.direction`, selectedOption)
            }}
            onBlur={handleBlur}
            value={item.direction}
            isDisabled={disabled}
          />
        </FormControl>
      </div>
      <div className="col-xs-2">
        <LocationTypeSelect
          label="Loc1"
          name={`${fieldPrefix}.location1`}
          disabled={disabled}
          onChange={() => {
            setFieldValue(`${fieldPrefix}.code1`, EMPTY_SELECT_OPTION)
          }}
        />
      </div>
      <StyledSelect className="col-xs-1 location-code-label">
        <LocationCodeSelect
          value={item.code1}
          name={`${fieldPrefix}.code1`}
          disabled={disabled || !item.location1?.value}
          locationValue={item.location1.value}
          onChange={handleCodeSelect}
          onBlur={handleBlur}
          error={errorCode1}
        />
      </StyledSelect>
      <div className="col-xs-2">
        <LocationTypeSelect
          label="Loc2"
          name={`${fieldPrefix}.location2`}
          onChange={() => {
            setFieldValue(`${fieldPrefix}.code2`, EMPTY_SELECT_OPTION)
          }}
          disabled={disabled}
        />
      </div>
      <StyledSelect className="col-xs-1 location-code-label">
        <LocationCodeSelect
          value={item.code2}
          name={`${fieldPrefix}.code2`}
          disabled={disabled || !item.location2?.value}
          locationValue={item.location2.value}
          onChange={handleCodeSelect}
          onBlur={handleBlur}
          error={errorCode2}
        />
      </StyledSelect>
      <div className="col-xs-2">
        <PermissionSelect
          disabled={disabled}
          name={`${fieldPrefix}.permitted`}
        />
      </div>
      <StyledActionsWrapper className="col-xs-1">
        {!disabled && (
          <ActionIconGroup
            actions={[
              {
                icon: <AddIcon />,
                key: 'add',
                tooltip: 'Add Geography',
                action: () => insert(index + 1, GEOGRAPHY_POINT_INITIAL_VALUE),
              },
              {
                icon: canDelete && index > 0 && (
                  <CloseIcon fill="var('--primaryA300')" />
                ),
                key: 'remove',
                tooltip: 'Remove Geography',
                action: () => canDelete && remove(index),
              },
            ]}
            style={{
              height: '100%',
              alignItems: 'center',
            }}
          />
        )}
      </StyledActionsWrapper>
    </div>
  )
}
