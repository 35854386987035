import { Checkbox } from '@atlas-design-system/react'
import { HeaderContext } from '@tanstack/react-table'
import { OptionalService } from 'common/types'
import React from 'react'

type RowSelectionHeaderProps = HeaderContext<OptionalService, any> & {
  disabled: boolean
}

export const RowSelectionHeader: React.FC<RowSelectionHeaderProps> = ({
  table,
  disabled,
}) => (
  <Checkbox
    disabled={disabled}
    checked={table.getIsAllRowsSelected()}
    onChange={table.getToggleAllRowsSelectedHandler()}
    label=""
  />
)
