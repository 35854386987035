import { ServiceSource } from 'common/enums'
import { GeographyListRequestModel } from 'common/models'
import {
  SegmentBrandingFormValue,
  SegmentBrandRequestShape,
} from 'common/types'

export const SegmentBrandingRequestModel = (
  values: SegmentBrandingFormValue,
  copyMode: boolean,
): SegmentBrandRequestShape => {
  const {
    key,
    serviceName,
    discontinueDate,
    effectiveDate,
    imageDetailsList,
    matchingData,
    textByCategory,
    titleByType,
    additionalDetailList,
  } = values

  const { equipmentType, cabinType, geographyList, marketingPartners } =
    matchingData

  const requestShape = {
    serviceName,
    group: 'EQ',
    serviceTypeCode: 'F',
    source: ServiceSource.TRAVELPORT,
    discontinueDate,
    effectiveDate,
    imageDetailsList,
    textByCategory,
    titleByType,
    matchingData: {
      equipmentType: equipmentType.value || null,
      cabinType: cabinType.label || null,
      geographyList: GeographyListRequestModel(geographyList),
      marketingPartners: marketingPartners
        ? marketingPartners.replace(/\s/g, '')
        : null,
    },
    additionalDetailList,
  }

  if (!key || copyMode) {
    return requestShape
  }

  return {
    ...requestShape,
    key,
  }
}
