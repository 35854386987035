import { CustomFormControl } from 'common/components'
import React from 'react'

type FormCustomErrorProps = {
  error?: string | boolean
}

export const FormCustomError: React.FC<FormCustomErrorProps> = ({ error }) => (
  <CustomFormControl label="" error={error}>
    <></>
  </CustomFormControl>
)
