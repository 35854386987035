import { Banner } from '@atlas-design-system/react'
import {
  CabinTypeSelect,
  EquipmentTypeSelect,
  GeographyFieldsList,
  TextareaField,
} from 'common/components'
import { findArrayFieldsError } from 'common/helpers'
import { SelectErrors, ServiceMatchingDataValue } from 'common/types'
import { FormikErrors, FormikProps, FormikTouched } from 'formik'
import { ChangeEvent, useCallback, useMemo } from 'react'
import styled from 'styled-components'

const StyledBanner = styled.div`
  margin-bottom: var(--spaceM);
`

type MatchingDataProps<T> = {
  formik: FormikProps<T & { matchingData: ServiceMatchingDataValue }>
  fieldsDisabled?: boolean

  marketingFieldDisabled?: boolean
  equipmentFieldDisabled?: boolean
  cabinFieldDisabled?: boolean
  marketingPartnersfield?: boolean
}

export const MatchingData = <T,>({
  formik,
  fieldsDisabled = false,

  marketingFieldDisabled,
  equipmentFieldDisabled,
  cabinFieldDisabled = false,
  marketingPartnersfield = false,
}: MatchingDataProps<T>): JSX.Element => {
  const { errors, values, touched, setFieldValue, handleBlur } = formik
  const { geographyList, marketingPartners } = values.matchingData

  const touchedMatchingData =
    touched?.matchingData as FormikTouched<ServiceMatchingDataValue>
  const errorsMatchingData =
    errors?.matchingData as FormikErrors<ServiceMatchingDataValue>

  const geographyErrors = errorsMatchingData?.geographyList

  const formatMarketingPartnersDisplayValue = (value: string): string => {
    const valueWithoutWhitespaces = value.replace(/\s/g, '')

    return valueWithoutWhitespaces.replace(/•/g, ' • ')
  }

  const geographyListError = useMemo(
    () => findArrayFieldsError(geographyErrors),
    [geographyErrors],
  )

  const getGeographyErrorMessage = useCallback(
    (fieldName: string, index: number) => {
      if (geographyErrors) {
        const pointOfSalesListError = geographyErrors[index] as SelectErrors
        if (pointOfSalesListError?.[fieldName]?.value) {
          return 'Required'
        }
      }
    },
    [geographyErrors],
  )

  const handleChangeTextareaField = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const marketingCode = event.target.value.replaceAll(/\n/gm, ' • ')

      setFieldValue('matchingData.marketingPartners', marketingCode, true)
    },
    [setFieldValue],
  )

  return (
    <div data-testid="matching-data-form">
      {touchedMatchingData?.geographyList && geographyListError && (
        <StyledBanner>
          <Banner
            data-testid="matching-data-error"
            appearance="error"
            size="small"
            heading={geographyListError}
          />
        </StyledBanner>
      )}
      <GeographyFieldsList
        formik={formik}
        namePath="matchingData.geographyList"
        items={geographyList}
        onGetErrosMessage={getGeographyErrorMessage}
        disabled={fieldsDisabled}
      />

      <div className="row">
        <div className="col-xs-4">
          <EquipmentTypeSelect
            name={'matchingData.equipmentType'}
            disabled={fieldsDisabled || equipmentFieldDisabled}
          />
        </div>
        <div className="col-xs-4">
          <CabinTypeSelect
            name="matchingData.cabinType"
            disabled={fieldsDisabled || cabinFieldDisabled}
          />
        </div>
      </div>
      {marketingPartnersfield && (
        <div className="row">
          <div className="col-xs">
            <TextareaField
              name="matchingData.marketingPartners"
              label="Marketing Partners"
              value={
                marketingPartners
                  ? formatMarketingPartnersDisplayValue(marketingPartners)
                  : undefined
              }
              onBlur={handleBlur}
              onChange={handleChangeTextareaField}
              disabled={fieldsDisabled || marketingFieldDisabled}
              errors={
                touchedMatchingData?.marketingPartners &&
                errorsMatchingData?.marketingPartners
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}
