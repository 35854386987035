import { FormControl, Select } from '@atlas-design-system/react'
import {
  FieldArrayActions,
  FormCustomError,
  FormDatePicker,
  FormInput,
  LocationCodeSelect,
} from 'common/components'
import {
  COUNTRY_FORM_INITIAL_VALUE,
  GDS_SELECT_OPTIONS,
  PSEUDO_CITY_FORM_INITIAL_VALUE,
} from 'common/constants'
import { LocationType } from 'common/enums'
import { disabledDateBefore, isDateStringPresentOrFuture } from 'common/helpers'
import { useCarriers } from 'common/hooks/use-carriers.hook'
import {
  CarrierFormValue,
  CountryFormValue,
  PseudoCityFormValue,
} from 'common/types'
import { Field, FieldArray, FormikErrors, FormikProps } from 'formik'
import React, { useCallback, useMemo } from 'react'

import styles from '../../carrier-form.module.css'

type TestDataListProps = {
  formik: FormikProps<CarrierFormValue>
  listName: string
}

export const TestDataList: React.FC<TestDataListProps> = ({
  formik,
  listName,
}) => {
  const { carrierList } = useCarriers()
  const { values, errors, handleBlur, setFieldValue } = formik
  const countriesErrors = errors.countries
  const pseudoCitiesErrors = errors.pseudoCities

  const numberOfExisting = useMemo(() => {
    const originalValue = carrierList.find(
      (carrier) => carrier.id === values.id,
    )

    if (listName === 'countries') {
      return originalValue?.countries.length || 0
    } else {
      return (
        originalValue?.pseudoCities.filter(isDateStringPresentOrFuture)
          .length || 0
      )
    }
  }, [carrierList, listName, values.id])

  const labelName = useMemo(() => {
    if (listName === 'countries') {
      return 'Country'
    } else {
      return 'PCC'
    }
  }, [listName])

  const initialRowValue = useMemo(() => {
    if (listName === 'countries') {
      return COUNTRY_FORM_INITIAL_VALUE
    } else {
      return PSEUDO_CITY_FORM_INITIAL_VALUE
    }
  }, [listName])

  const itemList: any[] = useMemo(() => {
    if (listName === 'countries') {
      return values.countries
    } else {
      return values.pseudoCities
    }
  }, [listName, values.countries, values.pseudoCities])

  const getRowError = useCallback(
    <T,>(
      errorsArray: string | string[] | FormikErrors<T>[] | undefined,
      index: number,
    ) => {
      return (
        errorsArray &&
        typeof errorsArray[index] === 'string' &&
        errorsArray[index].toString()
      )
    },
    [],
  )

  return (
    <FieldArray name={listName}>
      {(fieldArrayProps) => (
        <>
          {itemList.map((item, index) => {
            const countriesError =
              listName === 'countries' &&
              getRowError<CountryFormValue>(countriesErrors, index)

            const pseudoCitiesError =
              listName === 'pseudoCities' &&
              getRowError<PseudoCityFormValue>(pseudoCitiesErrors, index)

            return (
              <>
                <div className="row" key={index}>
                  <div className="col-xs-2">
                    {listName === 'countries' && (
                      <LocationCodeSelect
                        value={item.code}
                        name={`${listName}[${index}].code1`}
                        locationValue={LocationType.COUNTRY}
                        label="Country Code"
                        onBlur={handleBlur}
                        disabled={index <= numberOfExisting - 1}
                        onChange={(value) =>
                          setFieldValue(`${listName}[${index}].code`, value)
                        }
                        error={Boolean(countriesError)}
                      />
                    )}
                    {listName === 'pseudoCities' && (
                      <FormControl
                        className={styles.carrierFormControl}
                        label="GDS"
                        disabled={index <= numberOfExisting - 1}
                        error={Boolean(pseudoCitiesError)}
                      >
                        <Select
                          next
                          data-testid="pseudo-city-gds-select"
                          options={GDS_SELECT_OPTIONS}
                          value={item.gdsCode}
                          name={`${listName}[${index}].gdsCode`}
                          onChange={(value) =>
                            setFieldValue(
                              `${listName}[${index}].gdsCode`,
                              value,
                            )
                          }
                        />
                      </FormControl>
                    )}
                  </div>
                  <div className="col-xs-2">
                    <Field
                      component={FormInput}
                      data-testid={
                        listName === 'countries'
                          ? 'country-name-input'
                          : 'pseudo-city-list-pcc-input'
                      }
                      label={labelName}
                      disabled={index <= numberOfExisting - 1}
                      minLength="1"
                      value={
                        listName === 'countries' ? item.name : item.cityCode
                      }
                      name={
                        listName === 'countries'
                          ? `${listName}[${index}].name`
                          : `${listName}[${index}].cityCode`
                      }
                      error={Boolean(pseudoCitiesError)}
                    />
                  </div>
                  <div className="col-xs-3">
                    <Field
                      component={FormDatePicker}
                      hint="Format: (dd-mmm-yyyy)"
                      label="Effective Date"
                      name={`${listName}[${index}].effectiveDate`}
                      disabledDate={disabledDateBefore}
                    />
                  </div>
                  <div className="col-xs-3">
                    <Field
                      component={FormDatePicker}
                      hint="Format: (dd-mmm-yyyy)"
                      label="Discontinue Date"
                      name={`${listName}[${index}].discontinueDate`}
                      disabledDate={disabledDateBefore}
                    />
                  </div>
                  {index === itemList.length - 1 && (
                    <div className="col-xs-2">
                      <FieldArrayActions
                        addTooltip={`Add ${labelName}`}
                        canDelete={false}
                        index={index}
                        insert={fieldArrayProps.insert}
                        initialValueShape={initialRowValue}
                        remove={fieldArrayProps.remove}
                        replace={fieldArrayProps.replace}
                        removeTooltip={`Remove ${labelName}`}
                      />
                    </div>
                  )}
                </div>
                {(countriesError || pseudoCitiesError) && (
                  <div className="row">
                    <div className={`col-xs-12 ${styles.formRowError}`}>
                      <FormCustomError
                        error={countriesError || pseudoCitiesError}
                      />
                    </div>
                  </div>
                )}
              </>
            )
          })}
        </>
      )}
    </FieldArray>
  )
}
