import {
  Button,
  ButtonGroup,
  Dialog,
  Modal,
  Spinner,
  toast,
  Typography,
} from '@atlas-design-system/react'
import { useCarrierDetailsContext } from 'common/context'
import {
  getImageDetails,
  validateImage,
} from 'common/helpers/file-upload-helpers'
import { useGallery } from 'common/hooks/use-gallery.hook'
import { GalleryItem, ImageRestrictions } from 'common/types'
import { FC, MutableRefObject, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  StyledGallery,
  StyledGalleryItem,
  StyledInput,
} from '../../image-loader.styled'
import { useDeleteImage } from './use-delete-image'
import { useUploadImage } from './use-upload-image'

interface ImageLoaderGalleryModalProps {
  showGalleryModal: boolean
  restrictions?: ImageRestrictions
  onSelectImage: (selectedImageDetails: GalleryItem) => void
  setImageModal: (state: boolean) => void
  setGalleryModal: (state: boolean) => void
}

export const ImageLoaderGalleryModal: FC<ImageLoaderGalleryModalProps> = (
  props,
) => {
  const {
    showGalleryModal,
    restrictions,
    setImageModal,
    setGalleryModal,
    onSelectImage,
  } = props

  const { selectedCarrier } = useCarrierDetailsContext()
  const [selectedImage, setSelectedImage] = useState<null | GalleryItem>(null)
  const [isRemoveDialog, setRemoveDialog] = useState(false)
  const { gallery, isLoading: isGalleryLoading } = useGallery(
    selectedCarrier?.CDNTravelSupplierId || '',
  )
  const { pathname } = useLocation()

  const filteredGallery = restrictions
    ? gallery.filter((image) => {
        const { width, height, attribute } = image.versions[0]

        const widthAllowed = !restrictions.width || width <= restrictions.width
        const heightAllowed =
          !restrictions.height || height <= restrictions.height
        const attributeAllowed =
          !restrictions.attribute || attribute === restrictions.attribute

        return widthAllowed && heightAllowed && attributeAllowed
      })
    : gallery

  const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>
  const updateImageMutation = useUploadImage(
    selectedCarrier?.CDNTravelSupplierId || '',
  )
  const deleteImageMutation = useDeleteImage(
    selectedCarrier?.CDNTravelSupplierId || '',
  )

  const isLoading =
    isGalleryLoading ||
    deleteImageMutation.isLoading ||
    updateImageMutation.isLoading

  const handleSelect = (): void => {
    if (!selectedImage) {
      toast.error('Select an image first!')
      return
    }

    onSelectImage(selectedImage)
    setGalleryModal(false)
  }

  const handleUpload = () => {
    const file = fileInputRef?.current?.files?.[0]

    if (!file) {
      toast.error('Error! Invalid file, select a valid one.')
      return
    }

    getImageDetails(file, selectedCarrier?.code || '', pathname.slice(1))
      .then((imageDetails) => {
        const isValid = validateImage(
          imageDetails,
          gallery,
          file.size,
          restrictions,
        )

        if (isValid) {
          updateImageMutation.mutate({ imageDetails })
        }
      })
      .catch(() => {
        toast.error('Failed to read file, please check your file and try again')
      })
  }

  const handleRemoveClick = () => {
    if (!selectedImage) {
      toast.error('Select an image first!')
    } else {
      setRemoveDialog(true)
    }
  }

  const handleDelete = () => {
    const imageId = selectedImage?.imageId.toString() || ''
    deleteImageMutation.mutate(imageId)
    setSelectedImage(null)
    setRemoveDialog(false)
  }

  return (
    <Modal
      title="Image Upload"
      hidden={!showGalleryModal}
      size="small"
      onDismiss={() => setGalleryModal(false)}
      id="image-loader-gallery-modal"
      footer={
        <>
          <div className="row end-xs">
            <div className="col-xs">
              <Typography
                data-testid="image-restrictions"
                variant="contentText2"
              >
                {!!restrictions &&
                (!!restrictions.height ||
                  !!restrictions.width ||
                  !!restrictions.size)
                  ? `Image specification(Dimension:${restrictions.width}X${restrictions.height} pixels, Size:${restrictions.size} KB).`
                  : 'No image restriction.'}
              </Typography>
            </div>
          </div>
          <div className="row">
            <div className="col-xs">
              <StyledInput type="file" accept="image/*" ref={fileInputRef} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs">
              <Button size="small" appearance="primary" onClick={handleUpload}>
                Upload
              </Button>
            </div>
          </div>
          <ButtonGroup size="small" appearance="primary" align="right">
            <Button
              onClick={() => {
                setImageModal(true)
                setGalleryModal(false)
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleRemoveClick}>Remove</Button>
            <Button onClick={handleSelect}>Select</Button>
          </ButtonGroup>

          <Dialog
            id="image-loader-delete-dialog"
            appearance="warning"
            hidden={!isRemoveDialog}
            title="Delete Image"
            onDismiss={() => setRemoveDialog(false)}
            onConfirm={handleDelete}
            dismissText="Cancel"
          >
            Warning! Are you sure you want to delete the image?
          </Dialog>
        </>
      }
    >
      <StyledGallery>
        {isLoading ? (
          <Spinner primaryLabel="We are processing your request..." />
        ) : (
          filteredGallery.map((item) => {
            const { versions } = item
            return (
              <StyledGalleryItem key={item.imageId}>
                <div className="image">
                  <input
                    type="radio"
                    name="gallery-select"
                    onClick={() => setSelectedImage(item)}
                  />
                  <img src={versions[0].url} alt={versions[0].fileName} />
                </div>
                <Typography variant="contentText2" className="link">
                  {versions[0].fileName}
                </Typography>
                <Typography variant="contentText2">
                  {`${versions[0].height}x${versions[0].width} pixels`}
                </Typography>
              </StyledGalleryItem>
            )
          })
        )}
      </StyledGallery>
    </Modal>
  )
}
