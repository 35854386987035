import { Button, EmptyState } from '@atlas-design-system/react'
import { useAuth } from 'common/context'
import { Permission } from 'common/enums'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './not-found-page.module.css'

export const NotFoundPage: React.FC = () => {
  const { hasPermissionTo } = useAuth()
  const navigate = useNavigate()

  const canViewDashboard = hasPermissionTo(Permission.READ_DASHBOARD)

  const handleClick = useCallback(() => {
    navigate('/dashboard')
  }, [navigate])

  return (
    <EmptyState
      className={styles.wrapper}
      title="Not found"
      actionButtons={
        canViewDashboard && (
          <div className={styles.buttons}>
            <Button appearance="primary" onClick={handleClick}>
              Go to Dashboard
            </Button>
          </div>
        )
      }
    >
      The page you are looking for is not found
    </EmptyState>
  )
}
