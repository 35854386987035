import { PendingAction } from 'common/enums'

export enum AncillaryActionType {
  SET_SELECTED = 'SET_SELECTED',
  SET_PENDING_SELECTED = 'SET_PENDING_SELECTED',
  CREATE_SUCCESS = 'CREATE_SUCCESS',
  UPDATE_SUCCESS = 'UPDATE_SUCCESS',
  DELETE_SUCCESS = 'DELETE_SUCCESS',
  SET_COPY_MODE = 'SET_COPY_MODE',
  SET_PENDING_ACTION = 'SET_PENDING_ACTION',
  SWITCH_SELECTED_ANCILLARY = 'SWITCH_SELECTED_ANCILLARY',
}

export type SetSelectedAncillaryAction = {
  type: AncillaryActionType.SET_SELECTED
  payload: {
    id?: string
  }
}

export type AncillaryCreateSuccessAction = {
  type: AncillaryActionType.CREATE_SUCCESS
  payload: {
    id: string
  }
}

export type AncillaryUpdateSuccessAction = {
  type: AncillaryActionType.UPDATE_SUCCESS
  payload: {
    id: string
  }
}

export type AncillaryDeleteSuccessAction = {
  type: AncillaryActionType.DELETE_SUCCESS
  payload: {
    id: string
  }
}

export type SetCopyModeAction = {
  type: AncillaryActionType.SET_COPY_MODE
  payload: {
    active: boolean
  }
}

export type SetPendingAction = {
  type: AncillaryActionType.SET_PENDING_ACTION
  payload: {
    pendingAction?: PendingAction
  }
}

export type SetPendingSelectedAncillaryAction = {
  type: AncillaryActionType.SET_PENDING_SELECTED
  payload: {
    id?: string
  }
}

export type SwitchSelectedAncillaryAction = {
  type: AncillaryActionType.SWITCH_SELECTED_ANCILLARY
  payload: {
    id: string
  }
}

export type AncillaryAction =
  | SetSelectedAncillaryAction
  | AncillaryCreateSuccessAction
  | AncillaryUpdateSuccessAction
  | SetCopyModeAction
  | SetPendingAction
  | AncillaryDeleteSuccessAction
  | SetPendingSelectedAncillaryAction
  | SwitchSelectedAncillaryAction

export type AncillaryState = {
  selectedAncillaryId?: string
  pendingSelectedAncillaryId?: string
  copyMode: boolean
  pendingAction?: PendingAction
  showCancelDialog: boolean
}

export const INITIAL_STATE: AncillaryState = {
  selectedAncillaryId: undefined,
  copyMode: false,
  pendingAction: undefined,
  pendingSelectedAncillaryId: undefined,
  showCancelDialog: false,
}

export const ancillaryReducer = (
  state: AncillaryState,
  action: AncillaryAction,
): AncillaryState => {
  const { type, payload } = action

  switch (type) {
    case AncillaryActionType.SET_SELECTED:
      return {
        ...state,
        selectedAncillaryId: payload.id,
        copyMode: false,
      }
    case AncillaryActionType.CREATE_SUCCESS:
      return {
        ...state,
        copyMode: false,
        selectedAncillaryId: payload.id,
      }
    case AncillaryActionType.UPDATE_SUCCESS:
      return {
        ...state,
        selectedAncillaryId: payload.id,
      }

    case AncillaryActionType.SET_COPY_MODE:
      return {
        ...state,
        copyMode: payload.active,
      }

    case AncillaryActionType.SET_PENDING_ACTION:
      return {
        ...state,
        pendingAction: payload.pendingAction,
      }

    case AncillaryActionType.DELETE_SUCCESS:
      return {
        ...state,
        pendingAction: PendingAction.REFRESH_FORM_AFTER_FETCH,
        pendingSelectedAncillaryId: payload.id,
        selectedAncillaryId: undefined,
      }

    case AncillaryActionType.SET_PENDING_SELECTED:
      return {
        ...state,
        pendingSelectedAncillaryId: payload.id,
      }

    case AncillaryActionType.SWITCH_SELECTED_ANCILLARY: {
      return {
        ...state,
        pendingSelectedAncillaryId: undefined,
        selectedAncillaryId: payload.id,
        copyMode: false,
        pendingAction: undefined,
      }
    }

    default:
      return state
  }
}
