import { FamilyStatusIcon, FormInput } from 'common/components'
import { Family, FamilyFormValue } from 'common/types'
import { Field, useField } from 'formik'
import React, { useCallback, useMemo } from 'react'

import { FAMILY_NAME_UNIQUE_ERROR } from '../../family-form.schema'

type FamilyNameFieldProps = {
  copyMode: boolean
  disabled: boolean
  selectedFamily?: Family
}

export const FamilyNameField: React.FC<FamilyNameFieldProps> = ({
  copyMode,
  disabled,
  selectedFamily,
}) => {
  const [, meta, helpers] = useField<FamilyFormValue['name']>('name')
  const { error = '', touched } = meta
  const { setTouched } = helpers

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (error === FAMILY_NAME_UNIQUE_ERROR) {
        if (!touched) {
          setTouched(true)
        }
        event.target.focus()
        event.target.select()
        event.preventDefault()
      } else {
        setTouched(true)
      }
    },
    [error, setTouched, touched],
  )

  const familyStatusIcon = useMemo(
    () =>
      selectedFamily && (
        <div
          className="col-xs-6"
          style={{
            display: 'flex',
            paddingBottom: '15px',
          }}
        >
          {!copyMode && <FamilyStatusIcon status={selectedFamily.status} />}
        </div>
      ),
    [selectedFamily, copyMode],
  )

  return (
    <>
      <div className="row">
        <div className="col-xs-6">
          <Field
            component={FormInput}
            disabled={disabled}
            data-testid="family-name-field"
            hint="The Title must be unique"
            label="Family Title"
            maxLength="50"
            minLength="1"
            name="name"
            onBlur={handleBlur}
            required
          />
        </div>
        {familyStatusIcon}
      </div>
    </>
  )
}
