import {
  ActionIconGroup,
  AddIcon,
  CloseIcon,
  CopyIcon,
  SearchIcon,
} from '@atlas-design-system/react'
import { useBrandContext, useCarrierDetailsContext } from 'common/context'
import { PendingAction, ServiceSource } from 'common/enums'
import { useDirtyForm } from 'common/hooks'
import { useMemo } from 'react'

const DISABLED_COLOR = 'var(--contrastColor40)'

type ActionButtonsProps = {
  disabled: boolean
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ disabled }) => {
  const {
    selectedBrand,
    setPendingAction,
    copyMode,
    selectedFamily,
    resetSelectedBrand,
  } = useBrandContext()
  const { archiveMode } = useCarrierDetailsContext()
  const { dirtyForm } = useDirtyForm()

  const isATPCO = useMemo(
    () => selectedFamily?.source === ServiceSource.ATPCO,
    [selectedFamily],
  )
  const canDelete = useMemo(() => {
    if (disabled) {
      return false
    }
    if (isATPCO) {
      return false
    }
    return !!selectedBrand && !archiveMode
  }, [selectedBrand, archiveMode, disabled, isATPCO])

  const canCreateNew = useMemo(() => {
    if (disabled) {
      return false
    }
    if (isATPCO) {
      return false
    }

    return !!selectedFamily && !archiveMode
  }, [archiveMode, disabled, selectedFamily, isATPCO])

  const onNew = () => {
    if (!canCreateNew) {
      return
    }

    if (dirtyForm) {
      setPendingAction(PendingAction.NEW_BUTTON_CLICK)
    } else {
      resetSelectedBrand()
    }
  }

  const canCopy = useMemo(() => {
    if (disabled) {
      return false
    }
    if (isATPCO) {
      return false
    }
    if (copyMode || archiveMode) {
      return false
    }
    return !!selectedBrand?.brandId
  }, [disabled, isATPCO, copyMode, archiveMode, selectedBrand])

  const onCopy = () => {
    if (!canCopy) {
      return false
    }
    if (disabled) {
      return false
    }
    if (selectedBrand) {
      setPendingAction(PendingAction.SET_BRAND_COPY_MODE)
    }
  }

  const onDelete = () => {
    if (disabled) {
      return false
    }
    setPendingAction(PendingAction.DELETE_BRAND)
  }

  const onSearch = () => console.log('search')
  const actionButtons = [
    {
      icon: (
        <AddIcon
          data-testid="new-icon"
          fill={canCreateNew ? '' : DISABLED_COLOR}
        />
      ),
      key: 'new',
      tooltip: 'New',
      action: onNew,
    },
    {
      icon: (
        <CopyIcon
          data-testid="copy-icon"
          fill={canCopy ? '' : DISABLED_COLOR}
        />
      ),
      key: 'copy',
      tooltip: 'Copy',
      action: onCopy,
    },
    {
      icon: (
        <CloseIcon
          data-testid="delete-icon"
          fill={canDelete ? '' : DISABLED_COLOR}
        />
      ),
      key: 'delete',
      tooltip: 'Delete',
      action: () => canDelete && onDelete(),
    },
    {
      icon: <SearchIcon data-testid="search-icon" />,
      key: 'search',
      tooltip: 'Search',
      action: onSearch,
    },
  ]

  return (
    <ActionIconGroup
      actions={actionButtons}
      className="mmp-ancillary-collapsible-action-group"
    />
  )
}
