import {
  Button,
  ButtonGroup,
  Card,
  Dialog,
  Typography,
} from '@atlas-design-system/react'
import { FC, useState } from 'react'

interface ImageLoaderViewProps {
  image?: string
  label: string
  disabled: boolean
  setImageModal: (state: boolean) => void
  onRemoveImage: () => void
}

export const ImageLoaderView: FC<ImageLoaderViewProps> = (props) => {
  const { image, label, disabled, setImageModal, onRemoveImage } = props
  const [showRemoveDialog, setRemoveDialog] = useState(false)

  const confirmRemoveImage = () => {
    onRemoveImage()
    setRemoveDialog(false)
  }

  return (
    <>
      <Typography variant="contentText2">{label}</Typography>
      <Card
        data-testid={`image-card-${label.replace(' ', '')}`}
        image={image}
        imageAlt="Image"
        className="image-loader-preview"
      >
        {' '}
      </Card>
      <ButtonGroup align="expand" appearance="tertiary" size="small">
        <Button
          data-testid={`image-loader-button-${label.replace(' ', '')}`}
          className={`image-loader-button ${disabled && 'disabled'}`}
          onClick={() => !disabled && setImageModal(true)}
        >
          load image
        </Button>
        <Button
          data-testid={`image-loader-remove-button-${label.replace(' ', '')}`}
          onClick={() => !disabled && setRemoveDialog(true)}
          className={`${!image ? 'hidden' : 'image-loader-button'} ${
            disabled && 'disabled'
          }`}
        >
          remove
        </Button>
      </ButtonGroup>

      <Dialog
        id="image-loader-remove-dialog"
        hidden={!showRemoveDialog}
        appearance="warning"
        title="Image Remove"
        onConfirm={confirmRemoveImage}
        onDismiss={() => setRemoveDialog(false)}
      >
        Do you want to remove the image?
      </Dialog>
    </>
  )
}
