import { CustomCollapsible } from 'common/components'
import { BrandDetails } from 'common/types'
import React from 'react'
import { useDrag, useDrop } from 'react-dnd'

import styles from './brand-collapsible-card.module.css'

export const DRAGGABLE_ITEM_TYPE = 'brand-radio-card'

type DragItem = {
  id: string
  originalIndex: number
}

type BrandCollapsibleCardProps = {
  brand: BrandDetails
  moveBrand: (id: string, to: number) => void
  findBrand: (id: string) => { index: number }
}

export const BrandCollapsibleCard: React.FC<BrandCollapsibleCardProps> = ({
  brand,
  findBrand,
  moveBrand,
}) => {
  const {
    brandId,
    brandDescription: { name },
    optionalServiceList = [],
  } = brand
  const originalIndex = findBrand(brandId).index

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DRAGGABLE_ITEM_TYPE,
      item: { id: brandId, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId } = item
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          moveBrand(droppedId, item.originalIndex)
        }
      },
    }),
    [brandId, originalIndex, moveBrand],
  )

  const [, drop] = useDrop(
    () => ({
      accept: DRAGGABLE_ITEM_TYPE,
      hover({ id: draggedId }: DragItem) {
        if (draggedId !== brandId) {
          const { index: overIndex } = findBrand(brandId)
          moveBrand(draggedId, overIndex)
        }
      },
    }),
    [findBrand, moveBrand],
  )

  const opacity = isDragging ? 0 : 1

  return (
    <div
      data-testid="brand-card"
      ref={(node) => drag(drop(node))}
      style={{ opacity }}
      title={`Brand id: ${brandId}`}
      className={styles.card}
    >
      <CustomCollapsible title={name} defaultOpened={false}>
        <ul className={styles.list}>
          {optionalServiceList.map((option) => (
            <li
              key={option.key}
              className={`contentText2 ${styles.listItem}`}
              data-testid="brand-card-details-item"
            >
              {option.serviceName}
            </li>
          ))}
        </ul>
      </CustomCollapsible>
    </div>
  )
}
