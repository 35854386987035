import { Dialog } from '@atlas-design-system/react'
import { DiscontinueDatePickerInput } from 'common/components'
import {
  MESSAGE_ARCHIVE_NOT_ASSOCIATED_ANCILLARY,
  MESSAGE_CONFIRM_FORM_RESET,
  MESSAGE_DISCONTINUE_ASSOCIATED_ANCILLARY,
  MESSAGE_UPDATE_ANCILLARY,
} from 'common/constants'
import { useAncillaryContext, useCarrierDetailsContext } from 'common/context'
import { PendingAction } from 'common/enums'
import { useDeleteAncillary } from 'modules/ancillaries/hooks'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

type AncillaryConfirmModalProps = {
  submitForm: () => void
  resetForm: () => void
}

export const AncillaryConfirmModal: FC<AncillaryConfirmModalProps> = ({
  resetForm,
  submitForm,
}) => {
  const { pendingAction, setPendingAction, switchSelectedAncillary } =
    useAncillaryContext()

  const { handleDeleteAncillary } = useDeleteAncillary()

  const { isFetching } = useCarrierDetailsContext()

  const modalMessage = useMemo(() => {
    switch (pendingAction) {
      case PendingAction.EDIT: {
        return MESSAGE_UPDATE_ANCILLARY
      }
      case PendingAction.DELETE: {
        return MESSAGE_ARCHIVE_NOT_ASSOCIATED_ANCILLARY
      }
      case PendingAction.ARCHIVE: {
        return MESSAGE_DISCONTINUE_ASSOCIATED_ANCILLARY
      }
      case PendingAction.NEW_BUTTON_CLICK:
      case PendingAction.SELECT_ANOTHER_ANCILLARY: {
        return MESSAGE_CONFIRM_FORM_RESET
      }
      default: {
        return ''
      }
    }
  }, [pendingAction])

  const [discontinueDate, setDiscontinueDate] = useState<string>()

  const handleDeleteConfirm = useCallback(() => {
    if (discontinueDate) {
      handleDeleteAncillary(discontinueDate)
    }
  }, [discontinueDate, handleDeleteAncillary])

  const confirmEdit = useCallback(() => {
    submitForm()
    setPendingAction()
  }, [submitForm, setPendingAction])

  const isActionDelete = useMemo(
    () =>
      pendingAction === PendingAction.ARCHIVE ||
      pendingAction === PendingAction.DELETE,
    [pendingAction],
  )

  useEffect(() => {
    if (
      pendingAction === PendingAction.REFRESH_FORM_AFTER_FETCH &&
      !isFetching
    ) {
      switchSelectedAncillary()
    }
  })

  const confirmAncillarySwitch = useCallback(() => {
    switchSelectedAncillary()
  }, [switchSelectedAncillary])

  const confirmFormReset = useCallback(() => {
    resetForm()

    setPendingAction()
  }, [resetForm, setPendingAction])

  const title = useMemo(() => {
    switch (pendingAction) {
      case PendingAction.EDIT:
        return 'Submit Data'
      case PendingAction.DELETE:
      case PendingAction.ARCHIVE:
        return 'Archive Ancillary'

      default:
        return 'Warning'
    }
  }, [pendingAction])

  const modalHidden = useMemo(() => {
    const hasPendingAction = !!pendingAction

    return (
      !hasPendingAction ||
      pendingAction === PendingAction.REFRESH_FORM_AFTER_FETCH
    )
  }, [pendingAction])

  const handleConfirm = useCallback(() => {
    switch (pendingAction) {
      case PendingAction.EDIT:
        return confirmEdit()
      case PendingAction.NEW_BUTTON_CLICK:
        return confirmFormReset()
      case PendingAction.DELETE:
      case PendingAction.ARCHIVE:
        return handleDeleteConfirm()
      case PendingAction.SELECT_ANOTHER_ANCILLARY:
        return confirmAncillarySwitch()
      default:
        return setPendingAction()
    }
  }, [
    pendingAction,
    confirmEdit,
    confirmFormReset,
    handleDeleteConfirm,
    confirmAncillarySwitch,
    setPendingAction,
  ])

  const handleDismiss = useCallback(() => {
    setPendingAction()
  }, [setPendingAction])

  return (
    <Dialog
      title={title}
      hidden={modalHidden}
      onDismiss={handleDismiss}
      onConfirm={handleConfirm}
      appearance="warning"
      id="ancillary-confirm-dialog"
      dismissText="Cancel"
    >
      <>
        {modalMessage}
        {isActionDelete && (
          <DiscontinueDatePickerInput onChange={setDiscontinueDate} />
        )}
      </>
    </Dialog>
  )
}
