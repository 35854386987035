import { useCarrierDetailsContext } from 'common/context'
import { checkIsSegmentBrand, splitOptionalServices } from 'common/helpers'
import { OptionalService } from 'common/types'
import { useMemo } from 'react'

import { useMandatoryAncillaries } from './use-mandatory-ancillaries.hook'

// @todo - Add correct filtering
type UseAncillariesReturn = {
  defaultAncillaries: OptionalService[]
  allAncillaries: OptionalService[]
  segmentBrandAncillaries: OptionalService[]
  brandsData: {
    distinctMandatoryServiceList: OptionalService[]
    distinctSegmentServiceList: OptionalService[]
    distinctServiceList: OptionalService[]
    all: OptionalService[]
  }
}

export const useAncillaries = (): UseAncillariesReturn => {
  const { data } = useCarrierDetailsContext()
  const { mandatoryAncillaryList } = useMandatoryAncillaries()

  const { optionalServiceList = [] } = data || {}

  const defaultAncillaries = useMemo(
    () => optionalServiceList.filter(({ group }) => group === 'BF'),
    [optionalServiceList],
  )

  const allAncillaries = useMemo(
    () =>
      optionalServiceList.filter((service) => !checkIsSegmentBrand(service)),
    [optionalServiceList],
  )

  const segmentBrandAncillaries = useMemo(
    () => optionalServiceList.filter(checkIsSegmentBrand),
    [optionalServiceList],
  )

  const brandsData = useMemo(
    () => splitOptionalServices(optionalServiceList, mandatoryAncillaryList),
    [optionalServiceList, mandatoryAncillaryList],
  )

  return {
    defaultAncillaries,
    allAncillaries,
    segmentBrandAncillaries,
    brandsData,
  }
}
