import { API, SERVICES } from 'common/api'
import {
  ApiShelf,
  DeleteShelvesRequest,
  DeleteShelvesResponse,
  EditShelvesRequest,
  EditShelvesResponse,
  Shelf,
} from 'common/types'

export const fetchShelfs = async (): Promise<Shelf[]> => {
  try {
    const { data } = await API().get<ApiShelf[]>(
      `${SERVICES.AGENT_STORE_FRONT_SERVICE}/shelf`,
    )

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const editShelfs = async (
  data: EditShelvesRequest,
): Promise<EditShelvesResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().post<EditShelvesResponse>(
      `${SERVICES.AGENT_STORE_FRONT_SERVICE}/shelf`,
      data,
    )

    return { confirmationId, entityId }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteShelfs = async (
  data: DeleteShelvesRequest,
): Promise<DeleteShelvesResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().delete<DeleteShelvesResponse>(
      `${SERVICES.AGENT_STORE_FRONT_SERVICE}/shelf`,
      { data },
    )

    return {
      confirmationId,
      entityId,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}
