import { ApiAdditionalDetail } from 'common/types'
import { AdditionalDetail } from 'common/types'

import { TextTypeModel } from './text-type.model'

export const AdditionalDetailModel = (
  additionalDetail: ApiAdditionalDetail,
): AdditionalDetail => {
  const { langCode = '', textTypeList = [] } = additionalDetail

  return {
    langCode,
    textTypeList: textTypeList.map(TextTypeModel),
  }
}
