import { DATE_FORMAT, EMPTY_SELECT_OPTION } from 'common/constants'
import {
  AncillaryFormValue,
  PseudoCity,
  SegmentBrandingFormValue,
  SelectOption,
} from 'common/types'
import { areIntervalsOverlapping, isBefore, isSameDay, parse } from 'date-fns'
import { FormikErrors, FormikValues, getIn } from 'formik'
import * as Yup from 'yup'
import { SchemaDescription } from 'yup/lib/schema'

type ToOption = (value?: string | null) => SelectOption<string>

export const removeSpaces = (value: string): string =>
  value.replace(/\s/g, '').replace(/[^a-zA-Z0-9]/g, '')

export const generateId = (): string =>
  'id' + Math.random().toString(16).slice(2)

export const toOption: ToOption = (value) => {
  if (!value) {
    return EMPTY_SELECT_OPTION
  }

  return { value, label: value }
}

const today = new Date()

export const disabledDateBefore = (
  value: Date,
  startDate?: string,
): boolean => {
  if (!!startDate) {
    return isBefore(value, parse(startDate, DATE_FORMAT, today))
  }
  if (isSameDay(value, today)) {
    return false
  }
  return isBefore(value, today)
}
/**
 * Recursively searches through all nested objects for a Required error
 * @param error
 * @returns
 */
const errorContainsRequired = (error: FormikValues): boolean => {
  if (typeof error === 'string' && error === 'Required') {
    return true
  } else if (typeof error === 'object' && error !== null) {
    return Object.keys(error).some((key) => {
      return errorContainsRequired(error[key])
    })
  } else {
    return false
  }
}

/**
 * Searches the formik errors object for any required fields errors
 * @param errors
 * @returns
 */
export const errorsContainRequiredField = <T>(
  errors: FormikErrors<T>,
): boolean => {
  const errorsKeys = Object.keys(errors)
  return errorsKeys.some((fieldName) => {
    const fieldError = getIn(errors, fieldName)
    return errorContainsRequired(fieldError)
  })
}

export const findArrayFieldsError = <T>(
  errors?: string | Array<string | FormikErrors<T> | undefined>,
): string | undefined => {
  if (!errors) {
    return undefined
  }

  if (typeof errors === 'string') {
    return errors
  }

  const errorMsg = errors.find((error) => typeof error === 'string')

  if (errorMsg) {
    return errorMsg as string
  }

  const pointOfSalesListError = errors
    .filter((error) => {
      if (typeof error !== 'string') {
        return !!error
      }
    })
    .reduce<string | undefined>((_msg, error: any) => {
      for (const key in error) {
        if (error[key]?.value) {
          return error[key].value
        }
      }
    }, '')

  return pointOfSalesListError
}

type DatesOverlappingProps = {
  initialValues: AncillaryFormValue | SegmentBrandingFormValue
  values: AncillaryFormValue | SegmentBrandingFormValue
  copyMode: boolean
  selectedOptionalServiceKey?: string
}

export const isDatesOverlapping = ({
  initialValues,
  copyMode,
  selectedOptionalServiceKey,
  values,
}: DatesOverlappingProps): boolean => {
  const { effectiveDate, discontinueDate } = initialValues

  if (!copyMode && selectedOptionalServiceKey) {
    if (effectiveDate === '' || discontinueDate === '') {
      return false
    }
    if (
      effectiveDate !== values.effectiveDate &&
      discontinueDate !== values.discontinueDate
    ) {
      try {
        return areIntervalsOverlapping(
          {
            start: new Date(effectiveDate),
            end: new Date(discontinueDate),
          },
          {
            start: new Date(values.effectiveDate),
            end: new Date(values.discontinueDate),
          },
        )
      } catch (e) {}
    }
  }

  return false
}

export function noop(): void {}

export function isDateStringPresentOrFuture(city: PseudoCity): boolean {
  if (!!!city.discontinueDate) {
    return true
  }
  const discDate = new Date(city.discontinueDate)
  if (discDate >= today) {
    return true
  } else {
    return false
  }
}

export const matchFromStart = (
  candidate: SelectOption,
  input: string,
): boolean => candidate.label.toLowerCase().startsWith(input.toLowerCase())

export const isFieldRequired = (
  validationSchema: Yup.AnyObjectSchema,
  id: string,
): boolean => {
  const schemaDescription = validationSchema.describe()
  const fieldDescription = schemaDescription.fields[id] as SchemaDescription

  return fieldDescription.tests.some(({ name }) => name === 'required')
}
