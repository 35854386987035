import styled from 'styled-components'

export const StyledImageLoader = styled.div`
  .image-loader-preview {
    width: 200px;
    height: 153px;
    padding: 0 24px 1px;

    .contentWrap {
      display: none;
    }
  }

  #image-loader-gallery-modal footer {
    height: min-content;
  }

  div.atls-buttonGroup {
    margin-top: 0;
    margin-bottom: var(--spaceM);

    .atls-button.image-loader-button {
      font-weight: 400;
      color: var(--accentA600);

      &.disabled {
        color: var(--contrastColor70);
      }

      &:hover {
        background: transparent;
        border-color: transparent;
      }
    }

    .hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
`

export const StyledImageView = styled.div`
  display: flex;

  img {
    margin: auto;
  }
`

export const StyledButtonGroup = styled.div`
  .atls-buttonGroup {
    justify-content: space-between;
  }
`

export const StyledInput = styled.input`
  margin-bottom: var(--spaceXS);
`

export const StyledGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 100px;
`

export const StyledGalleryItem = styled.div`
  width: 165px;
  height: 180px;

  input {
    height: 13px;
    margin: 4px;
    cursor: pointer;
  }

  .image {
    display: flex;
    width: 100px;
    height: 60px;
  }

  img {
    margin: 0 10px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .link {
    color: var(--accentA600);
    cursor: pointer;
    line-height: 17px;
    max-width: 160px;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }
`
