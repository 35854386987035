import { FormControl, TextArea } from '@atlas-design-system/react'
import React, { DetailedHTMLProps, TextareaHTMLAttributes } from 'react'

const TEXTAREA_PROPS = { rows: 7 }

type TextareaFieldProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  name: string
  label: string
  textareaProps?: object
  errors?: string | boolean
}

export const TextareaField: React.FC<TextareaFieldProps> = ({
  label,
  name,
  value = '',
  onChange,
  textareaProps = TEXTAREA_PROPS,
  disabled,
  errors,
  onBlur,
}) => {
  return (
    <FormControl label={label} disabled={disabled} error={errors}>
      <TextArea
        name={name}
        data-testid={`text-area-${name}`}
        textareaProps={textareaProps}
        onChange={onChange}
        value={value}
        disabled={disabled}
        error={errors}
        onBlur={onBlur}
      />
    </FormControl>
  )
}
