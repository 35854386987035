export enum RouteTitle {
  DASHBOARD = 'Dashboard',
  ANCILLARIES = 'Ancillaries',
  FAMILIES = 'Families',
  BRANDS = 'Brands',
  MARKETING_UPSELL = 'Marketing Upsell',
  SEGMENT_BRANDING = 'Segment Branding',
  MANDATORY_ANCILLARIES = 'Mandatory Ancillaries',
  AGENT_STORE_FRONT = 'Agent Store Front',
}

export enum UserRole {
  MMP_USER = 'MMP User',
  MMP_MANAGER = 'MMP Manager',
  MMP_CONTRIBUTOR = 'MMP Contributor',
  MMP_ADMIN = 'MMP Admin',
}

export enum RoutePath {
  DASHBOARD = 'dashboard',
  ANCILLARIES = 'ancillaries',
  FAMILIES = 'families',
  BRANDS = 'brands',
  MARKETING_UPSELL = 'marketing-upsell',
  SEGMENT_BRANDING = 'segment-branding',
  MANDATORY_ANCILLARIES = 'mandatory-ancillaries',
  AGENT_STORE_FRONT = 'agent-store-front',
  SETTINGS = 'settings',
}

export enum FamilyStatus {
  LIVE = 'LIVE',
  TEST = 'TEST',
  DRAFT = 'DRAFT',
  SUPPRESSED = 'SUPPRESSED',
}

export enum ServiceSource {
  ATPCO = 'ATPCO',
  TRAVELPORT = 'TRAVELPORT',
}

export enum UpsellIndicator {
  A = 'All',
  B = 'Basic',
  O = 'Ancillary Only',
  U = 'Upsell',
}

export enum Direction {
  FROM = 'FROM',
  TO = 'TO',
  WITHIN = 'WITHIN',
  BETWEEN = 'BETWEEN',
}

export enum FareRuleInputType {
  EMPTY = 'EMPTY',
  MULTIPLE = 'MULTIPLE',
  SINGLE = 'SINGLE',
}

export enum BrandRuleFieldInputType {
  EMPTY = 'EMPTY',
  RBD = 'RBD',
  FBC = 'FBC',
  FTC = 'FTC',
}

export enum PriceRangeDirection {
  FROM = 'FROM',
  UP_TO = 'UP_TO',
  BETWEEN = 'BETWEEN',
}

export enum PendingAction {
  DELETE = 'DELETE',
  ARCHIVE = 'ARCHIVE',
  EDIT = 'EDIT',
  RESET_FORM = 'RESET_FORM',
  NEW_BUTTON_CLICK = 'NEW_BUTTON_CLICK',
  SELECT_ANOTHER_FAMILY = 'SELECT_ANOTHER_FAMILY',
  SELECT_ANOTHER_ANCILLARY = 'SELECT_ANOTHER_ANCILLARY',
  SAVE_FAMILY_TO_DRAFT = 'SAVE_FAMILY_TO_DRAFT',
  EDIT_BRAND = 'EDIT_BRAND',
  DELETE_BRAND = 'DELETE_BRAND',
  SET_BRAND_COPY_MODE = 'SET_BRAND_COPY_MODE',
  SELECT_ANOTHER_BRAND = 'SELECT_ANOTHER_BRAND',
  SELECT_ANOTHER_SEGMENT_BRAND = 'SELECT_ANOTHER_SEGMENT_BRAND',
  SELECT_ANOTHER_CARRIER = 'SELECT_ANOTHER_CARRIER',
  REFRESH_FORM_AFTER_FETCH = 'REFRESH_FORM_AFTER_FETCH',
}

export enum LocationType {
  EMPTY = '',
  AREA = 'Area',
  CITY = 'City',
  COUNTRY = 'Country',
  AIRPORT = 'Airport',
  ZONE = 'Zone',
}

export enum ServiceAvailability {
  FREE = 'FREE',
  NOT_OFFERED = 'NOT_OFFERED',
  CHARGEABLE = 'CHARGEABLE',
}

export enum PermissionLabel {
  PERMITTED = 'Permitted',
  NOT_PERMITTED = 'Not-Permitted',
}

export enum ServiceCategory {
  AGENT = 'AGENT',
  CONSUMER = 'CONSUMER',
}

export enum BrandsCategory {
  AGENT = 'AGENT',
  CONSUMER = 'CONSUMER',
  UPSELL = 'UPSELL',
}

export enum CabinType {
  EMPTY = '',
  PREMIUM_FIRST = 'R',
  FIRST = 'F',
  PREMIUM_BUSINESS = 'J',
  BUSINESS = 'C',
  PREMIUM_ECONOMY = 'P',
  ECONOMY = 'Y',
}
