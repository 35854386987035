import { Radio } from '@atlas-design-system/react'
import { BrandDetails } from 'common/types'
import React, { useMemo } from 'react'
import { useDrag, useDrop } from 'react-dnd'

const RADIO_STYLE = {
  border: '1px solid #7b7f84',
  padding: '7px',
  background: '#fff',
  marginBottom: 'var(--spaceM)',
}

export const DRAGGABLE_ITEM_TYPE = 'brand-radio-card'

type DragItem = {
  id: string
  originalIndex: number
}

type BrandRadioCardProps = {
  brand: BrandDetails
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  selectedBrand?: BrandDetails
  moveBrand: (id: string, to: number) => void
  findBrand: (id: string) => { index: number }
  copyMode: boolean
  canDrag: boolean
}

export const BrandRadioCard: React.FC<BrandRadioCardProps> = ({
  brand,
  onChange,
  selectedBrand,
  findBrand,
  moveBrand,
  copyMode,
  canDrag,
}) => {
  const {
    brandId,
    brandDescription: { name },
  } = brand
  const originalIndex = findBrand(brandId).index

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DRAGGABLE_ITEM_TYPE,
      item: { id: brandId, originalIndex },
      canDrag,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId } = item
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          moveBrand(droppedId, item.originalIndex)
        }
      },
    }),
    [brandId, originalIndex, moveBrand],
  )

  const [, drop] = useDrop(
    () => ({
      accept: DRAGGABLE_ITEM_TYPE,
      hover({ id: draggedId }: DragItem) {
        if (draggedId !== brandId) {
          const { index: overIndex } = findBrand(brandId)
          moveBrand(draggedId, overIndex)
        }
      },
    }),
    [findBrand, moveBrand],
  )

  const opacity = isDragging ? 0 : 1

  const checked = useMemo(() => {
    if (copyMode) {
      return false
    } else {
      return brandId === selectedBrand?.brandId
    }
  }, [copyMode, brandId, selectedBrand?.brandId])

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{ opacity }}
      title={`Brand id: ${brandId}`}
      data-testid="brand-list-item"
    >
      <Radio
        label={name}
        name="brandsList"
        value={brandId}
        id={brandId}
        key={brandId}
        onChange={onChange}
        style={RADIO_STYLE}
        checked={checked}
      />
    </div>
  )
}
