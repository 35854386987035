import {
  Button,
  ButtonGroup,
  Card,
  StepTracker,
} from '@atlas-design-system/react'
import { useAuth } from 'common/context'
import { Permission } from 'common/enums'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './dashboard-informationarea.module.css'

const mock = {
  title: 'Easy as 1, 2, 3!',
  list: [
    'Set up your fare families and brands',
    'Layout your comparison table',
    'Test your page, then launch',
  ],
  btnText: 'Start',
}

export const InformationArea: React.FC = () => {
  const navigate = useNavigate()
  const { hasPermissionTo } = useAuth()

  const actionButtons = hasPermissionTo(Permission.READ_ANCILLARIES) && (
    <ButtonGroup align="right">
      <Button
        data-testid="dashboard-start-button"
        appearance="primary"
        onClick={() => navigate('/ancillaries')}
      >
        {mock.btnText}
      </Button>
    </ButtonGroup>
  )

  return (
    <>
      <Card
        className={styles.card}
        title={mock.title}
        actionButtons={actionButtons}
      >
        <StepTracker
          className={`sb-step-tracker ${styles.stepTracker}`}
          active={mock?.list.length}
        >
          {mock?.list.map((subtitle: string, index: number): JSX.Element => {
            return <div key={index} title={subtitle} />
          })}
        </StepTracker>
      </Card>
    </>
  )
}
