import { Spinner } from '@atlas-design-system/react'
import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'common/api'
import { FormHeader } from 'common/components'
import { useAuth, useCarrierDetailsContext } from 'common/context'
import { Permission } from 'common/enums'
import { fetchShelfs } from 'common/services'
import { Shelf, ShelfFormValue, ShelfValue } from 'common/types'
import React, { useCallback, useMemo } from 'react'

import { useEditShelf } from './hooks'
import { ShelfFormModel } from './models/shelf-form.model'
import { ShelfRequestModel } from './models/shelf-request.model'
import { ShelfForm } from './shelf-form/shelf-form.component'

const checkShouldBeDeleted = (item: ShelfValue) => item.shouldDelete

export const AgentStoreFront: React.FC = () => {
  const { isFetching } = useCarrierDetailsContext()
  const { hasPermissionTo } = useAuth()
  const { data = [] } = useQuery<Shelf[]>(
    [QUERY_KEYS.AGENT_STORE_FRONT],
    () => fetchShelfs(),
    { refetchOnMount: true, staleTime: 1000 },
  )

  const { editShelves, submitInProgress } = useEditShelf()

  const hasWritePermission = hasPermissionTo(Permission.WRITE_AGENT_STOREFRONT)

  const initialValues = useMemo(() => {
    return ShelfFormModel(data)
  }, [data])

  const handleSubmit = useCallback(
    (values: ShelfFormValue) => {
      if (!hasWritePermission) {
        return
      }
      const toDelete = values.items.filter(checkShouldBeDeleted)

      const { itemsToUpdate, itemsToDelete } = ShelfRequestModel(
        values.items,
        toDelete,
      )

      editShelves(itemsToUpdate, itemsToDelete)
    },
    [editShelves, hasWritePermission],
  )

  if (isFetching) {
    return <Spinner />
  }

  return (
    <div data-testid="agent-store-front-page">
      <FormHeader title="Agent Store Front" variant="displayText6" />
      <ShelfForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        hasWritePermission={hasWritePermission}
        submitInProgress={submitInProgress}
      />
    </div>
  )
}
