import {
  EMPTY_SELECT_OPTION,
  GEOGRAPHY_POINT_INITIAL_VALUE,
  INITIAL_TEXT_BY_CATEGORY,
  INITIAL_TITLE_BY_TYPE,
} from 'common/constants'
import { toOption } from 'common/helpers/form-helpers'
import { GeographyListFormModel } from 'common/models'
import { OptionalService, SegmentBrandingFormValue } from 'common/types'

const EMPTY_SEGMENT_BRANDING_FORM_VALUES: SegmentBrandingFormValue = {
  serviceName: '',
  group: 'EQ',
  textByCategory: INITIAL_TEXT_BY_CATEGORY,
  titleByType: INITIAL_TITLE_BY_TYPE,
  effectiveDate: '',
  discontinueDate: '',
  matchingData: {
    geographyList: [GEOGRAPHY_POINT_INITIAL_VALUE],
    equipmentType: EMPTY_SELECT_OPTION,
    cabinType: EMPTY_SELECT_OPTION,
    marketingPartners: '',
  },
  imageDetailsList: [],
  serviceTypeCode: 'F',
  copyMode: false,
  additionalDetailList: [],
}

type SegmentBrandingFormModelConfig = {
  selectedSegmentBrand?: OptionalService
  copyMode?: boolean
}

export const SegmentBrandingFormModel = (
  config?: SegmentBrandingFormModelConfig,
): SegmentBrandingFormValue => {
  if (!config?.selectedSegmentBrand) {
    return EMPTY_SEGMENT_BRANDING_FORM_VALUES
  }

  const { selectedSegmentBrand, copyMode = false } = config

  const {
    key,
    serviceName = '',
    textByCategory,
    titleByType = INITIAL_TITLE_BY_TYPE,
    effectiveDate = '',
    discontinueDate = '',
    matchingData,
    serviceAvailability,
    imageDetailsList = [],
    serviceTypeCode = 'F',
    group,
    additionalDetailList = [],
  } = selectedSegmentBrand

  const { SHORT = '', EXTERNAL = '' } = titleByType
  const {
    STRAPLINE = '',
    MARKETING_AGENT = '',
    MARKETING_CONSUMER = '',
  } = textByCategory

  const formValues = {
    key,
    group,
    serviceName,
    textByCategory: {
      STRAPLINE,
      MARKETING_AGENT,
      MARKETING_CONSUMER,
    },
    titleByType: {
      SHORT: SHORT ? SHORT.trim() : SHORT,
      EXTERNAL,
    },
    effectiveDate,
    discontinueDate,
    matchingData: {
      geographyList: matchingData?.geographyList
        ? GeographyListFormModel(matchingData.geographyList)
        : [GEOGRAPHY_POINT_INITIAL_VALUE],
      equipmentType: toOption(matchingData?.equipmentType),
      cabinType: toOption(matchingData?.cabinType),
      marketingPartners: matchingData?.marketingPartners || '',
    },
    serviceAvailability,
    imageDetailsList,
    serviceTypeCode,
    copyMode: copyMode,
    additionalDetailList,
  }

  return formValues
}
