import { FormControl, Input } from '@atlas-design-system/react'
import { noop } from 'common/helpers'
import { FieldProps } from 'formik'
import React from 'react'

import { CustomFormControl } from '../custom-form-control/custom-form-control.component'

type FormInputProps = {
  label?: string
  disabled?: boolean
  required?: boolean
  error?: string | boolean
} & FieldProps &
  Pick<React.ComponentProps<typeof FormControl>, 'hint'>

export const FormInput: React.FC<FormInputProps> = ({
  label = '',
  field: { name, onBlur, onChange, value },
  form: { getFieldMeta },
  hint,
  disabled = false,
  required = false,
  error: customError,
  ...props
}) => {
  const { touched, error } = getFieldMeta(name)
  const fieldError = touched && error ? error : ''

  return (
    <CustomFormControl
      label={label}
      error={customError || fieldError}
      hint={hint}
      disabled={disabled}
      required={required}
    >
      <Input
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        disabled={disabled}
        wrapperProps={{
          onMouseUp: noop,
        }}
        {...props}
      />
    </CustomFormControl>
  )
}
