export const FBC_REGEX = /^.{1,8}$/
export const FTC_REGEX = /^.{1,3}$/
export const RBD_REGEX = /^.{1,2}$/
export const RULE_REGEX = /^.{1,4}$/
export const TARIFF_REGEX = /^.{1,3}$/
export const FLIGHT_NUMBER_REGEX = /^(?!^0)\d{1,4}$/
export const FLIGHT_RANGE_REGEX = /^\d{1,4}(\-)\d{1,4}$/
export const SBN_REGEX = /^[\sa-zA-Z\d\/\-.]*$/

export const FBC_REGEX_ERROR = 'Enter 8 digit Valid Code'
export const RBD_REGEX_ERROR = 'Enter 2 digit Valid Code'
export const FTC_REGEX_ERROR = 'Enter 3 digit Valid Code'
export const FLIGHT_RANGE_ERROR = 'Enter Valid Flight Range'
export const FLIGHT_NUMBER_ERROR = 'Enter Valid Flight Number'
export const SBN_REGEX_ERROR =
  "Accepts alphanumeric characters, '/', '-' and '.' only."
