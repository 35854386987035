import './header.component.css'

import {
  ArrowDownIcon,
  Avatar,
  Button,
  Floating,
  Header,
  ProfileMenu,
  Typography,
} from '@atlas-design-system/react'
import {
  useAuth,
  useCarrierDetailsContext,
  useFamilyContext,
} from 'common/context'
import { Permission, RoutePath } from 'common/enums'
import { MmpLogo } from 'common/icons'
import React, { useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import styles from './header-component.module.css'

type HelpPagesUrlMap = {
  [key: string]: string | undefined
}

const HELP_PAGES_URL_MAP: HelpPagesUrlMap = {
  [RoutePath.DASHBOARD]: 'Dashboard.htm',
  [RoutePath.ANCILLARIES]: 'Ancillaries/Ancillaries.htm',
  [RoutePath.FAMILIES]: 'Families/Families.htm',
  [RoutePath.BRANDS]: 'Brands/Brands.htm',
  [RoutePath.MARKETING_UPSELL]: 'MarketingUpsell.htm',
  [RoutePath.SEGMENT_BRANDING]: 'Segment_Branding/Segment_Branding.htm',
  [RoutePath.MANDATORY_ANCILLARIES]: 'Ancillaries/MandatoryAncillaries.htm',
}

const HELP_PAGE_URL_BASE = 'https://support.travelport.com/webhelp/mmp/Content/'

export const AppHeader: React.FC = () => {
  const { user, logOut, isTravelportUser, userSupplierCodes, hasPermissionTo } =
    useAuth()
  const { resetCarrier, selectedCarrier } = useCarrierDetailsContext()
  const { resetSelectedFamily } = useFamilyContext()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const location = useLocation()
  const navigate = useNavigate()
  const locationPathname = location.pathname.split('/')[1]
  const helpPageUrl = HELP_PAGE_URL_BASE + HELP_PAGES_URL_MAP[locationPathname]

  const settingsFlag = useMemo(() => {
    return hasPermissionTo(Permission.UPDATE_CARRIERS)
  }, [hasPermissionTo])

  const handleCarrierChangeClick = useCallback(() => {
    resetCarrier()
    navigate('/dashboard')
    resetSelectedFamily()
    setIsOpen(false)
  }, [resetCarrier, navigate, resetSelectedFamily])

  const userName = `${user?.firstName} ${user?.lastName}`

  const currentCarrierText = selectedCarrier
    ? `Current Carrier: ${selectedCarrier?.name}`
    : ''

  const allowChangeCarrier = useMemo(
    () => isTravelportUser || userSupplierCodes.length > 1,
    [isTravelportUser, userSupplierCodes],
  )

  const handleSettingsClick = useCallback(() => {
    navigate(`/${RoutePath.SETTINGS}`)
    setIsOpen(false)
  }, [navigate])

  const rightNavigation = (
    <>
      <Floating
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        render={() => (
          <>
            <ProfileMenu>
              <Typography
                variant="contentText2"
                bold
                className="current-carrier"
                data-testid="current-carrier"
              >
                {currentCarrierText}
              </Typography>
              <ProfileMenu.Header>
                <Avatar name={userName} size="large" />
                <ProfileMenu.Description>
                  <Typography variant="contentText1" bold>
                    {userName}
                  </Typography>
                  <Typography variant="contentText2">{user?.email}</Typography>
                </ProfileMenu.Description>
              </ProfileMenu.Header>
              <ProfileMenu.Main>
                {allowChangeCarrier && (
                  <ProfileMenu.Option onClick={handleCarrierChangeClick}>
                    Change carrier
                  </ProfileMenu.Option>
                )}

                {settingsFlag && (
                  <ProfileMenu.Option onClick={handleSettingsClick}>
                    Settings
                  </ProfileMenu.Option>
                )}

                {location.pathname !== '/agent-store-front' && (
                  <ProfileMenu.Option
                    onClick={() => window.open(helpPageUrl, '_blank')}
                  >
                    Help
                  </ProfileMenu.Option>
                )}
              </ProfileMenu.Main>
            </ProfileMenu>
          </>
        )}
      >
        <Button data-icon-align="right" data-testid="profile-avatar">
          <Avatar name={userName} />
          <ArrowDownIcon />
        </Button>
      </Floating>
      <div className="mmp-ui-header-logout-wrapper">
        <Button
          appearance="secondaryReverse"
          data-testid="logout-button"
          onClick={() => {
            logOut()
          }}
        >
          Sign out
        </Button>
      </div>
    </>
  )

  const isActive = true

  const leftNavigation = (
    <>
      <Button data-active={isActive}>Manage Rich Content & Branding</Button>
    </>
  )

  return (
    <Header
      data-testid="app-header"
      logo={<MmpLogo />}
      children={undefined}
      leftNavigation={leftNavigation}
      rightNavigation={rightNavigation}
      className={`mmp-ui-header ${styles.activeBtn}`}
    />
  )
}
