import { ApiTitleByType } from 'common/types'
import { TitleByType } from 'common/types'

export const TitleByTypeModel = (titleByType: ApiTitleByType): TitleByType => {
  const { EXTERNAL = '', SHORT = '' } = titleByType

  return {
    EXTERNAL,
    SHORT,
  }
}
