import { DATE_FORMAT } from 'common/constants'
import {
  BrandRuleFieldInputType,
  FareRuleInputType,
  ServiceAvailability,
  ServiceSource,
} from 'common/enums'
import {
  ApiFareRule,
  ApiFlightRange,
  BrandFormRequestShape,
  BrandFormValue,
  BrandMatchingDataRequestShape,
  BrandOptionalServiceRequestShape,
  FareRule,
  MandatoryMap,
  MatchingDataMetaValue,
  MatchingDataValue,
  OptionalService,
  OptionalServiceValue,
  PriceRange,
  PriceRangeValue,
  UncreatedMandatoryOptionalServiceRequestShape,
} from 'common/types'
import { format } from 'date-fns'

// Makes a map of all mandatory ancillaries that are not selected and/or created in the brand form
export const getMissingMandatoryMap = (
  values: BrandFormValue,
  defaultMandatoryMap: MandatoryMap,
  distinctMandatoryServiceList: OptionalService[],
): MandatoryMap => {
  const mandatoryMap: MandatoryMap = {}
  for (const mandatoryKey of Object.keys(defaultMandatoryMap)) {
    const mandatoryGroupSubGroup = defaultMandatoryMap[mandatoryKey][0]

    const someSelected = Object.values(values.optionalServicesByCategory)
      .flat()
      .filter((selectedService) => {
        const parentService = distinctMandatoryServiceList.find(
          (service) => service.meta.id === selectedService.meta.id,
        )

        if (parentService) {
          return (
            `${parentService.group}${parentService.subgroup || ''}` ===
            mandatoryGroupSubGroup
          )
        }

        return false
      })

    if (someSelected.length === 0) {
      mandatoryMap[mandatoryKey] = [mandatoryGroupSubGroup]
    }
  }

  return mandatoryMap
}

// Adds all unselected mandatory ancillaries that are available and not read only to a list of new optional services and returns that list
export const addUnselectedMandatoryServices = (
  optionalServiceList: OptionalServiceValue[],
  missingMandatoryMap: MandatoryMap,
  distinctMandatoryServiceList: OptionalService[],
): BrandOptionalServiceRequestShape[] => {
  const unselected: BrandOptionalServiceRequestShape[] = []
  for (const mandatoryService of distinctMandatoryServiceList) {
    const isSelected = !!optionalServiceList.find(
      (selected) => selected.meta.id === mandatoryService.meta.id,
    )

    let addFlag = false

    const groupSubgroup = `${mandatoryService.group}${
      mandatoryService.subgroup || ''
    }`

    if (!isSelected) {
      for (const key in missingMandatoryMap) {
        if (groupSubgroup === missingMandatoryMap[key][0]) {
          addFlag = true
          break
        }
      }
    }

    if (addFlag) {
      const added = !!unselected.find(({ group, subgroup = '' }) => {
        return groupSubgroup === `${group}${subgroup}`
      })
      if (added) {
        addFlag = false
      }
    }

    if (addFlag) {
      const {
        discontinueDate,
        effectiveDate,
        group,
        key,
        serviceName,
        serviceTypeCode,
        subcode,
        subgroup,
        textByCategory,
        titleByType,
        source,
      } = mandatoryService

      const keyValue = key !== subcode ? key : undefined

      unselected.push({
        discontinueDate: !!discontinueDate ? discontinueDate : null,
        effectiveDate: !!effectiveDate ? effectiveDate : null,
        group: group,
        key: keyValue,
        priceRangeList: [],
        serviceAvailability: ServiceAvailability.NOT_OFFERED,
        serviceName: serviceName,
        serviceTypeCode,
        source: source,
        subcode: !!subcode ? subcode : undefined,
        subgroup: !!subgroup ? subgroup : undefined,
        textByCategory,
        titleByType,
      })
    }
  }
  return unselected
}

const FlightRangesBrandsRequestModel = ({
  flightNumbers: flightNumbersData,
  flightRanges: flightRangesData,
}: MatchingDataValue): ApiFlightRange[] => {
  if (!flightNumbersData.trim() && !flightRangesData.trim()) {
    return []
  }

  const flightNumbers = !!flightNumbersData.trim()
    ? flightNumbersData
        .split('•')
        .filter((flightCode) => !!flightCode.trim())
        .map((flightCode) => ({
          flightNumberStart: flightCode.trim(),
          flightNumberEnd: flightCode.trim(),
        }))
    : []

  const flightRanges = !!flightRangesData.trim()
    ? flightRangesData
        .split('•')
        .filter((flightCode) => !!flightCode.trim())
        .map((flightCode) => {
          const flightNumber = flightCode.split('-')
          return {
            flightNumberStart: flightNumber[0].trim(),
            flightNumberEnd: flightNumber[1].trim(),
          }
        })
    : []

  return [...flightNumbers, ...flightRanges]
}

const getDynamicFieldName = (
  metaMatchingData: MatchingDataMetaValue,
): keyof Pick<FareRule, 'rbd' | 'fareBasisCode' | 'fareTypeCode'> => {
  switch (metaMatchingData.brandRuleFieldInputType.value) {
    case BrandRuleFieldInputType.RBD: {
      return 'rbd'
    }
    case BrandRuleFieldInputType.FTC: {
      return 'fareTypeCode'
    }
    default:
    case BrandRuleFieldInputType.FBC: {
      return 'fareBasisCode'
    }
  }
}

const getStringOrUndefined = (value: string) =>
  !!value.trim() ? value.trim() : undefined

const FareRuleRequestModel = ({
  fareBasisCode,
  fareTypeCode,
  rbd,
  rule,
  tariff,
}: FareRule): ApiFareRule => ({
  fareBasisCode: getStringOrUndefined(fareBasisCode),
  fareTypeCode: getStringOrUndefined(fareTypeCode),
  rbd: getStringOrUndefined(rbd),
  rule: getStringOrUndefined(rule),
  tariff: getStringOrUndefined(tariff),
})

const FareRulesRequestModel = (
  metaMatchingData: MatchingDataMetaValue,
  matchingData: MatchingDataValue,
): ApiFareRule[] => {
  if (metaMatchingData.fareRuleInputType.value === FareRuleInputType.EMPTY) {
    return []
  }

  if (metaMatchingData.fareRuleInputType.value === FareRuleInputType.SINGLE) {
    const result = matchingData.fareRules
      .map(FareRuleRequestModel)
      .filter((rule) => Object.values(rule).some((field) => !!field))

    return result
  }

  if (metaMatchingData.fareRuleInputType.value === FareRuleInputType.MULTIPLE) {
    const { rule, tariff } = metaMatchingData.fareRule

    const fareRule: ApiFareRule = {
      rule: getStringOrUndefined(rule),
      tariff: getStringOrUndefined(tariff),
    }

    switch (metaMatchingData.brandRuleFieldInputType.value) {
      case BrandRuleFieldInputType.EMPTY: {
        return !rule.trim() && !tariff.trim() ? [] : [fareRule]
      }

      default: {
        return metaMatchingData.fareRule.dynamicField
          .split('•')
          .filter((property) => !!property.trim())
          .map((property) => {
            const dynamicFieldName = getDynamicFieldName(metaMatchingData)

            return {
              ...fareRule,
              [dynamicFieldName]: getStringOrUndefined(property),
            }
          })
      }
    }
  }

  return []
}

const BrandMatchingDataRequestModel = (
  matchingData: MatchingDataValue,
  metaMatchingData: MatchingDataMetaValue,
): BrandMatchingDataRequestShape | undefined => {
  const requestMatchingData = {
    fareRules: FareRulesRequestModel(metaMatchingData, matchingData),
    cabin: matchingData.cabin.value || undefined,
    equipment: matchingData.equipment.value || undefined,
    flightRanges: FlightRangesBrandsRequestModel(matchingData),
  }

  const allEmpty = Object.keys(requestMatchingData).every((fieldKey) => {
    switch (fieldKey) {
      case 'fareRules':
        return !requestMatchingData[fieldKey].length
      case 'flightRanges':
        return !requestMatchingData[fieldKey].length
      case 'cabin':
        return typeof requestMatchingData[fieldKey] === 'undefined'
      case 'equipment':
        return typeof requestMatchingData[fieldKey] === 'undefined'
    }
  })

  return allEmpty ? undefined : requestMatchingData
}

const PriceRangeRequestModel = ({
  defaultIndicator,
  start,
  end,
  currencyCode,
}: PriceRangeValue): PriceRange => ({
  defaultIndicator: defaultIndicator,
  start: !!start ? start : undefined,
  end: !!end ? end : undefined,
  currencyCode: currencyCode.value,
})

const BrandOptionalServiceRequestModel = (
  optionalService: OptionalServiceValue,
  allServices: OptionalService[],
): BrandOptionalServiceRequestShape => {
  const {
    id,
    priceRangeList: priceRangeListValue = [],
    serviceAvailability,
    discontinueDate,
    effectiveDate,
  } = optionalService

  const {
    group = '',
    serviceName = '',
    serviceTypeCode = 'F',
    source = ServiceSource.TRAVELPORT,
    subcode = '',
    subgroup = '',
    textByCategory,
    titleByType,
  } = allServices.find((s) => {
    if (s.key === s.subcode) {
      return s.key === optionalService.subcode
    }
    return s.key === optionalService.id
  }) || {}

  const priceRangeList =
    serviceAvailability === ServiceAvailability.CHARGEABLE
      ? priceRangeListValue.map(PriceRangeRequestModel)
      : []

  const keyValue = id !== subcode ? id : undefined

  return {
    discontinueDate: !!discontinueDate ? discontinueDate : null,
    effectiveDate: !!effectiveDate ? effectiveDate : null,
    group,
    key: keyValue,
    priceRangeList,
    serviceAvailability,
    serviceName,
    serviceTypeCode,
    source,
    subcode: !!subcode ? subcode : undefined,
    subgroup: !!subgroup ? subgroup : undefined,
    status: 'LIVE',
    textByCategory,
    titleByType,
  }
}

const BrandTextByCategoryRequestModel = (
  textByCategory: BrandFormValue['brandDescription']['textByCategory'],
): BrandFormRequestShape['brandDescription']['textByCategory'] => {
  const allEmpty = Object.values(textByCategory).every((value) => !value.trim())

  if (allEmpty) {
    return undefined
  }

  const { STRAPLINE, UPSELL, MARKETING_AGENT, MARKETING_CONSUMER } =
    textByCategory

  return {
    STRAPLINE: getStringOrUndefined(STRAPLINE),
    UPSELL: getStringOrUndefined(UPSELL),
    MARKETING_AGENT: getStringOrUndefined(MARKETING_AGENT),
    MARKETING_CONSUMER: getStringOrUndefined(MARKETING_CONSUMER),
  }
}

// Finds all mandatory ancillaries that are still missing after unselected available ones have been added to the list
// Then it returns a list of uncreated ancillary services marked to be created by the addBrand request
const addUncreatedMandatoryServices = (
  selectedServices: BrandOptionalServiceRequestShape[],
  missingMandatoryMap: MandatoryMap,
): UncreatedMandatoryOptionalServiceRequestShape[] => {
  const uncreatedMap = Object.keys(missingMandatoryMap).map((serviceName) => {
    const groupSubgroupKeyList = missingMandatoryMap[serviceName]

    const uncreated = groupSubgroupKeyList.filter((groupSubgroup) => {
      return !selectedServices.find(
        (service) =>
          groupSubgroup === `${service.group}${service.subgroup || ''}`,
      )
    })

    return {
      tagName: serviceName,
      groupSubgroupKeyList: uncreated,
    }
  })

  const uncreated: UncreatedMandatoryOptionalServiceRequestShape[] = []

  uncreatedMap.forEach((tagNameGroup) => {
    const services = tagNameGroup.groupSubgroupKeyList.map((groupSubgroup) => {
      const group = groupSubgroup.slice(0, 2)
      const subGroup = groupSubgroup.slice(2, 4)

      const today = new Date()
      const future = new Date(2099, 11, 31)

      return {
        key: 'Uncreated Mandatory Ancillary',
        group,
        subgroup: subGroup,
        serviceName: tagNameGroup.tagName,
        effectiveDate: format(today, DATE_FORMAT),
        discontinueDate: format(future, DATE_FORMAT),
      }
    })

    uncreated.push(...services)
  })
  return uncreated
}

export const BrandRequestModel = (
  values: BrandFormValue,
  allServices: OptionalService[],
  copyMode: boolean,
  missingMandatoryMap: MandatoryMap,
  distinctMandatoryServiceList: OptionalService[],
): BrandFormRequestShape => {
  const { brandId, brandDescription, matchingData, excludingData, meta } =
    values

  const optionalServiceList = Object.values(
    values.optionalServicesByCategory,
  ).flat()

  const {
    name,
    textByCategory,
    brandRule,
    brandCode,
    brandCodeExcluding,
    titleByType,
    additionalDetailList,
    imageDetailsList,
  } = brandDescription

  const { EXTERNAL, SHORT } = titleByType

  const optionalServices = optionalServiceList.map((service) =>
    BrandOptionalServiceRequestModel(service, allServices),
  )

  const unselected = addUnselectedMandatoryServices(
    optionalServiceList,
    missingMandatoryMap,
    distinctMandatoryServiceList,
  )

  const selectedServices = [...optionalServices, ...unselected]

  const uncreated = addUncreatedMandatoryServices(
    selectedServices,
    missingMandatoryMap,
  )

  const requestShape = {
    brandDescription: {
      name,
      brandCode: getStringOrUndefined(brandCode),
      brandCodeExcluding: getStringOrUndefined(brandCodeExcluding),
      titleByType: {
        EXTERNAL,
        SHORT,
      },
      textByCategory: BrandTextByCategoryRequestModel(textByCategory),
      brandRule: getStringOrUndefined(brandRule),
      imageDetailsList,
      additionalDetailList,
    },
    matchingData: BrandMatchingDataRequestModel(
      matchingData,
      meta.matchingData,
    ),
    excludingData: BrandMatchingDataRequestModel(
      excludingData,
      meta.excludingData,
    ),
    optionalServiceList: [...selectedServices, ...uncreated],
  }

  if (!brandId || brandId === 'NEW' || copyMode) {
    return requestShape
  }

  return {
    ...requestShape,
    brandId,
  }
}
