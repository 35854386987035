import {
  ActionIconGroup,
  AddIcon,
  CloseIcon,
  FormControl,
  Input,
  Select,
} from '@atlas-design-system/react'
import { LocationCodeSelect, LocationTypeSelect } from 'common/components'
import { PermissionSelect } from 'common/components/form-components/permission-select/permission-select.component'
import {
  EMPTY_SELECT_OPTION,
  GDS_SELECT_OPTIONS,
  POINT_OF_SALE_INITIAL_VALUE,
} from 'common/constants'
import { noop } from 'common/helpers'
import {
  FamilyFormValue,
  PointOfSaleValue,
  SelectErrors,
  SelectOption,
} from 'common/types'
import { FieldArray, FormikProps } from 'formik'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

const StyledPointOfSaleRow = styled.div`
  .col-xs-1 {
    flex-basis: 12%;
    max-width: 12%;
  }
  .col-xs-2 {
    flex-basis: 15%;
    max-width: 15%;
  }
  .col-xs-1.gds-col {
    flex-basis: 11%;
    max-width: 11%;
  }
  .col-xs-2.permission-col {
    flex-basis: 16%;
    max-width: 16%;
  }
`

type PointOfSaleFieldsListProps = {
  formik: FormikProps<FamilyFormValue>
  items: PointOfSaleValue[]
  disabled?: boolean
}

export const PointOfSaleFieldsList: React.FC<PointOfSaleFieldsListProps> = ({
  formik,
  items,
  disabled = false,
}) => {
  const { setFieldValue, handleBlur, handleChange, touched, errors } = formik

  const canDelete = useMemo(() => items.length > 1, [items])

  const handleCodeSelect = useCallback(
    (value: SelectOption<string>, name: string) => {
      setFieldValue(name, value)
    },
    [setFieldValue],
  )

  const getErrorMessage = useCallback(
    (fieldName: string, index: number) => {
      if (errors.pointOfSalesList) {
        const pointOfSalesListError = errors.pointOfSalesList[index]
        const pointOfSalesError = pointOfSalesListError as SelectErrors
        return pointOfSalesError?.[fieldName].value ? 'Required' : undefined
      }
    },
    [errors.pointOfSalesList],
  )

  return (
    <FieldArray name="pointOfSalesList">
      {({ insert, remove }) => (
        <>
          {items.map((item, index) => {
            const errorLocationCode = getErrorMessage('locationCode', index)
            return (
              <div key={index}>
                <StyledPointOfSaleRow className="row">
                  <div className="col-xs-2">
                    <FormControl label="IATA" disabled={disabled}>
                      <Input
                        name={`pointOfSalesList.${index}.iataNumber`}
                        data-testid={`point-of-sales-list.${index}.iataNumber`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={item.iataNumber}
                        disabled={disabled}
                        maxLength="8"
                        wrapperProps={{
                          onMouseUp: noop,
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className="col-xs-1 gds-col">
                    <FormControl label="GDS" disabled={disabled}>
                      <Select
                        next
                        name={`pointOfSalesList.${index}.gds`}
                        data-testid={`point-of-sales-list.${index}.gds`}
                        options={[EMPTY_SELECT_OPTION, ...GDS_SELECT_OPTIONS]}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            `pointOfSalesList.${index}.gds`,
                            selectedOption,
                          )
                          setFieldValue(`pointOfSalesList.${index}.pcc`, '')
                        }}
                        onBlur={handleBlur}
                        value={item.gds}
                        isDisabled={disabled}
                      />
                    </FormControl>
                  </div>
                  <div className="col-xs-2">
                    <FormControl
                      label="PCC"
                      disabled={disabled || item.gds.value === ''}
                    >
                      <Input
                        name={`pointOfSalesList.${index}.pcc`}
                        data-testid={`point-of-sales-list.${index}.pcc`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={item.pcc}
                        disabled={disabled || item.gds.value === ''}
                        maxLength="6"
                        wrapperProps={{
                          onMouseUp: noop,
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className="col-xs-2">
                    <LocationTypeSelect
                      label="Location Type"
                      name={`pointOfSalesList.${index}.locationType`}
                      data-testid={`point-of-sales-list.${index}.locationType`}
                      disabled={disabled}
                      onChange={() => {
                        setFieldValue(
                          `pointOfSalesList.${index}.locationCode`,
                          EMPTY_SELECT_OPTION,
                        )
                      }}
                    />
                  </div>
                  <div className="col-xs-2">
                    <LocationCodeSelect
                      label="Location Code"
                      value={item.locationCode}
                      name={`pointOfSalesList.${index}.locationCode`}
                      disabled={disabled || !item.locationType?.value}
                      locationValue={item.locationType.value}
                      onChange={handleCodeSelect}
                      onBlur={handleBlur}
                      error={
                        touched.pointOfSalesList?.[index] && errorLocationCode
                      }
                    />
                  </div>
                  <div className="col-xs-2 permission-col">
                    <PermissionSelect
                      name={`pointOfSalesList.${index}.permitted`}
                      data-testid={`point-of-sales-list.${index}.permitted`}
                      disabled={disabled}
                    />
                  </div>
                  <div className="col-xs-1">
                    {!disabled && (
                      <ActionIconGroup
                        actions={[
                          {
                            icon: <AddIcon />,
                            key: 'add',
                            tooltip: 'Add POS GEO',
                            action: () =>
                              insert(index + 1, POINT_OF_SALE_INITIAL_VALUE),
                          },
                          {
                            icon: canDelete && index > 0 && (
                              <CloseIcon fill="var('--primaryA300')" />
                            ),
                            key: 'remove',
                            tooltip: 'Remove POS GEO',
                            action: () => canDelete && remove(index),
                          },
                        ]}
                        style={{
                          height: '100%',
                          alignItems: 'center',
                          flexWrap: 'nowrap',
                        }}
                      />
                    )}
                  </div>
                </StyledPointOfSaleRow>
              </div>
            )
          })}
        </>
      )}
    </FieldArray>
  )
}
