import {
  discontinueDateSchema,
  effectiveDateSchema,
  geographyPointSchema,
  pointOfSaleSchema,
} from 'common/helpers'
import { FamilyFormValue } from 'common/types'
import { FormikConfig } from 'formik'
import * as Yup from 'yup'

export const FAMILY_NAME_UNIQUE_ERROR = 'Title must be unique'

const familyNameSchema = (familiesNameList: string[]) =>
  Yup.string()
    .required('Required')
    .test(
      'is-unique-family',
      FAMILY_NAME_UNIQUE_ERROR,
      function checkUnique(value = '') {
        const { meta, id } = this.parent

        if (!meta.copyMode && !!id) {
          return true
        }
        return !familiesNameList.some(
          (name) => name.toLowerCase().trim() === value.toLowerCase().trim(),
        )
      },
    )

export const validationSchema: FormikConfig<FamilyFormValue>['validationSchema'] =
  (familiesNameList: string[]) =>
    Yup.object({
      name: familyNameSchema(familiesNameList),
      effectiveTravelDate: effectiveDateSchema,
      discontinueTravelDate: discontinueDateSchema('effectiveTravelDate'),
      effectiveSearchDate: effectiveDateSchema,
      discontinueSearchDate: discontinueDateSchema('effectiveSearchDate'),
      geographyList: Yup.array().of(geographyPointSchema),
      passengerTypeCode: Yup.string().max(3),
      accountTypeCodeList: Yup.array().of(Yup.string().max(20)),
      pointOfSalesList: Yup.array().of(pointOfSaleSchema),
    })
