import { useAncillaryContext } from 'common/context'
import { AncillaryFormValue } from 'common/types'
import { FormikProps } from 'formik'
import { useCallback, useState } from 'react'

type UseAncillaryFormReturns = {
  onFormReset: () => void
  cancelDialogVisible: boolean
  hideCancelDialog: () => void
  showCancelDialog: () => void
}

export const useAncillaryForm = (
  formik: FormikProps<AncillaryFormValue>,
): UseAncillaryFormReturns => {
  const { selectedAncillary, resetSelectedAncillary } = useAncillaryContext()
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false)

  const showCancelDialog = useCallback(() => {
    setCancelDialogVisible(true)
  }, [])

  const hideCancelDialog = useCallback(() => {
    setCancelDialogVisible(false)
  }, [])

  const handleFormReset = useCallback(() => {
    if (selectedAncillary) {
      resetSelectedAncillary()
    } else {
      formik.resetForm()
    }

    if (cancelDialogVisible) {
      hideCancelDialog()
    }
  }, [
    cancelDialogVisible,
    formik,
    hideCancelDialog,
    resetSelectedAncillary,
    selectedAncillary,
  ])

  return {
    cancelDialogVisible,
    hideCancelDialog,
    showCancelDialog,
    onFormReset: handleFormReset,
  }
}
