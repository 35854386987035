import { toast } from '@atlas-design-system/react'
import { useMutation } from '@tanstack/react-query'
import { useCarrierDetailsContext } from 'common/context'
import { reorderBrands } from 'common/services/brand.service'
import {
  BrandDetails,
  ReorderBrandsRequest,
  ReorderBrandsResponse,
} from 'common/types'
import { useCallback } from 'react'

type ReorderBrandsMutationParams = {
  request: ReorderBrandsRequest
  notifyOnSuccess: boolean
}

export const useReorderBrands = (): {
  saveReorderedBrands: (
    brandsList: BrandDetails[],
    familyId: string,
    notifyOnSuccess: boolean,
    onSuccesUpdate?: (id: string) => void,
  ) => void
} => {
  const { reloadCarrierDetails } = useCarrierDetailsContext()

  const reorderMutation = useMutation<
    ReorderBrandsResponse,
    Error,
    ReorderBrandsMutationParams
  >(({ request }) => reorderBrands(request), {
    onSuccess: ({ confirmationId }, { notifyOnSuccess }) => {
      if (notifyOnSuccess) {
        toast.success(
          `Success! Brands have been reordered. Confirmation id: (${confirmationId})`,
        )
      }
      reloadCarrierDetails()
    },
    onError: ({ message }) => {
      toast.error(`Error: Something went wrong. ${message}`)
    },
  })

  const saveReorderedBrands = useCallback(
    (
      brandsList: BrandDetails[],
      familyId: string,
      notifyOnSuccess: boolean,
      onSuccesUpdate?: (id: string) => void,
    ) => {
      const items = brandsList.map(({ brandId }, index) => ({
        key: brandId,
        sequenceNumber: index + 1,
      }))

      reorderMutation.mutate(
        {
          request: {
            key: familyId,
            reorderedBrandDetailsDTOList: items,
          },
          notifyOnSuccess,
        },
        {
          onSuccess({ entityId: recievedFamilyID }) {
            if (onSuccesUpdate) {
              onSuccesUpdate(recievedFamilyID)
            }
          },
        },
      )
    },
    [reorderMutation],
  )

  return { saveReorderedBrands }
}
