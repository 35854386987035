import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS, SERVICES } from 'common/api'
import {
  useCarrierDetailsContext,
  useMarketingUpsellContext,
} from 'common/context'
import { fetchMarketingUpsell } from 'common/services'
import { MarketingUpsellDetails } from 'common/types'

export const useMarketingUpsell = (): {
  data?: MarketingUpsellDetails
  isLoading: boolean
} => {
  const { selectedFamily } = useMarketingUpsellContext()
  const { selectedCarrier } = useCarrierDetailsContext()

  const { data, isLoading } = useQuery(
    [
      SERVICES.BRANDED_FARES_SERVICE,
      QUERY_KEYS.MARKETING_UPSELL,
      selectedFamily,
      selectedCarrier,
    ],
    () =>
      fetchMarketingUpsell({
        familyId: selectedFamily?.id || '',
        carrierCode: selectedCarrier?.code || '',
      }),
  )

  return { data, isLoading }
}
