import { Spinner } from '@atlas-design-system/react'
import {
  AncillaryDisplayOrder,
  Family,
  MarketingUpsellTableColumn,
} from 'common/types'
import { useMarketingUpsell } from 'modules/marketing-upsell/hooks'
import { FC, useEffect, useMemo, useState } from 'react'

import { MarketingUpsellHeader, MarketingUpsellTable } from './components'
import { MarketingUpsellTableModel } from './marketing-upsell-table.model'

type MarketingUpsellContentProps = {
  selectedFamily: Family
}

export const MarketingUpsellContent: FC<MarketingUpsellContentProps> = ({
  selectedFamily,
}) => {
  const { data, isLoading } = useMarketingUpsell()

  const [displayOrderList, setDisplayOrderList] = useState<
    AncillaryDisplayOrder[]
  >([])

  const { mainRowGroup, sortableRowGroup } = useMemo(
    () => MarketingUpsellTableModel(data),
    [data],
  )

  const sortedBrandsList = useMemo((): MarketingUpsellTableColumn[] => {
    const brandsList = selectedFamily.brandDetailsList.map((brand) => ({
      id: brand.brandId,
      sequenceNumber: brand.sequenceNumber,
      name: brand.brandDescription.name,
    }))

    const sortedList = [...brandsList].sort((a, b) =>
      a.sequenceNumber < b.sequenceNumber ? 1 : -1,
    )
    return sortedList
  }, [selectedFamily])

  useEffect(() => {
    if (sortableRowGroup) {
      setDisplayOrderList(
        sortableRowGroup.rows.map((ancillary, index) => {
          const tableDetail: AncillaryDisplayOrder = {
            ancillaryId: ancillary.ancillaryId,
            displayOrder: index + 1, // 06/05/2023(TPRT-23613): Defect report requested that display order index always starts with 1 instead of 0
          }
          if (ancillary.displayOrderKey) {
            tableDetail.displayOrderKey = ancillary.displayOrderKey
          }
          return tableDetail
        }),
      )
    }
  }, [sortableRowGroup])

  if (isLoading || !selectedFamily) {
    return <Spinner />
  }

  return (
    <>
      <MarketingUpsellHeader selectedFamily={selectedFamily} />

      <MarketingUpsellTable data={mainRowGroup} columns={sortedBrandsList} />

      {!!sortableRowGroup && (
        <MarketingUpsellTable
          data={[sortableRowGroup]}
          columns={sortedBrandsList}
          displayOrderList={displayOrderList}
          setDisplayOrderList={setDisplayOrderList}
        />
      )}
    </>
  )
}
