import { toast } from '@atlas-design-system/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'common/api'
import { deleteShelfs, editShelfs } from 'common/services'
import {
  DeleteShelvesRequest,
  DeleteShelvesResponse,
  EditShelvesRequest,
  EditShelvesResponse,
  Shelf,
} from 'common/types'
import { useCallback, useMemo } from 'react'

export const useEditShelf = (): {
  editShelves: (shelfList: Shelf[], itemsToDelete: string[]) => void
  submitInProgress: boolean
} => {
  const queryClient = useQueryClient()

  const editMutation = useMutation<
    EditShelvesResponse,
    Error,
    EditShelvesRequest
  >((data: EditShelvesRequest) => editShelfs(data), {
    onError: ({ message }) => {
      toast.error(`Error: Something went wrong. ${message}`)
    },
  })

  const deleteMutation = useMutation<
    DeleteShelvesResponse,
    Error,
    DeleteShelvesRequest
  >((data: DeleteShelvesRequest) => deleteShelfs(data), {
    onError: ({ message }) => {
      toast.error(`Error: Something went wrong. ${message}`)
    },
  })

  const submitInProgress = useMemo(
    () => editMutation.isLoading || deleteMutation.isLoading,
    [editMutation, deleteMutation],
  )

  const editShelves = useCallback(
    (shelfList: Shelf[], itemsToDelete: string[]) => {
      if (itemsToDelete.length > 0) {
        editMutation.mutate(shelfList, {
          onSuccess: () => {
            deleteMutation.mutate(itemsToDelete, {
              onSuccess: ({ confirmationId }) => {
                toast.success(
                  `Success: Shelf deleted. Transaction ID is (${confirmationId})`,
                )
                queryClient.invalidateQueries([QUERY_KEYS.AGENT_STORE_FRONT])
              },
            })
          },
        })
      } else {
        if (shelfList.length > 0) {
          editMutation.mutate(shelfList, {
            onSuccess: ({ confirmationId }) => {
              toast.success(
                `Success: Shelf Updated. Transaction ID is (${confirmationId})`,
              )
              queryClient.invalidateQueries([QUERY_KEYS.AGENT_STORE_FRONT])
            },
          })
        }
      }
    },
    [editMutation, deleteMutation, queryClient],
  )

  return { editShelves, submitInProgress }
}
