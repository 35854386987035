import { toast } from '@atlas-design-system/react'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { QUERY_KEYS, SERVICES } from 'common/api'
import { postSupplierImage } from 'common/services/cdn.service'
import { ImageRequestShape, PostImageResponse } from 'common/types'

export const useUploadImage = (
  supplierId: string,
): UseMutationResult<
  PostImageResponse,
  Error,
  { imageDetails: ImageRequestShape }
> => {
  const queryClient = useQueryClient()

  return useMutation<
    PostImageResponse,
    Error,
    { imageDetails: ImageRequestShape }
  >(({ imageDetails }) => postSupplierImage(imageDetails), {
    onSuccess: async () => {
      // We refresh carriers in order to assure that any changes to CDN image ID is used in the refresh of the gallery page
      await queryClient.invalidateQueries([
        SERVICES.PROVISIONING_CARRIER_SERVICE,
        QUERY_KEYS.CARRIERS,
      ])
      await queryClient.invalidateQueries([
        SERVICES.CDN_TOKEN_SERVICE,
        QUERY_KEYS.SUPPLIERS,
        supplierId,
        QUERY_KEYS.IMAGES,
      ])

      toast.success('Image uploaded')
    },
    onError: (err) => {
      toast.error(`Failed to upload image: ${err.message}`)
    },
  })
}
