import { toast } from '@atlas-design-system/react'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { QUERY_KEYS, SERVICES } from 'common/api'
import { updateMandatoryAncillaries } from 'common/services/mandatory-ancillaries.service'
import {
  ErrorMessage,
  MandatoryAncillariesResponse,
  UpdateMandatoryAncillariesRequest,
} from 'common/types'

export const useUpdateMandatoryAncillary = (): UseMutationResult<
  MandatoryAncillariesResponse,
  AxiosError<ErrorMessage>,
  UpdateMandatoryAncillariesRequest
> => {
  const queryClient = useQueryClient()

  return useMutation<
    MandatoryAncillariesResponse,
    AxiosError<ErrorMessage>,
    UpdateMandatoryAncillariesRequest
  >((data) => updateMandatoryAncillaries(data), {
    onSuccess: ({ confirmationId }) => {
      toast.success(
        `Success! Mandatory Ancillaries Updated. Transaction ID ${confirmationId}`,
      )
      queryClient.invalidateQueries([
        SERVICES.MANDATORY_ANCILLARY_SERVICE,
        QUERY_KEYS.ANCILLARY,
        QUERY_KEYS.MANDATORY,
      ])
    },
    onError: ({ response, message: baseMassage }) => {
      toast.error(`Error: ${response?.data.message || baseMassage}`)
    },
  })
}
