import { FormHeader } from 'common/components'
import { useBrandContext } from 'common/context'
import { ServiceSource } from 'common/enums'
import { MatchingExcludingDataNamePath } from 'common/types'
import React, { useMemo } from 'react'

import { AdditionalInfoFields } from '../additional-info-fields/additional-info-fields.component'
import { FareRulesFields } from '../fare-rules-fields/fare-rules-fields.component'

type MatchingExcludingFieldsProps = {
  namePath: MatchingExcludingDataNamePath
  hasWritePermission: boolean
}
export const MatchingExcludingFields: React.FC<
  MatchingExcludingFieldsProps
> = ({ namePath, hasWritePermission }) => {
  const { selectedFamily } = useBrandContext()

  const fieldsDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }

    if (selectedFamily?.source === ServiceSource.ATPCO) {
      return true
    }
    return !selectedFamily
  }, [hasWritePermission, selectedFamily])

  const title = useMemo(
    () => (namePath === 'matchingData' ? 'Matching Data' : 'Excluding Data'),
    [namePath],
  )

  return (
    <>
      <FormHeader title={title} />

      <FareRulesFields namePath={namePath} disabled={fieldsDisabled} />
      <AdditionalInfoFields namePath={namePath} disabled={fieldsDisabled} />
    </>
  )
}
