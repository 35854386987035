import {
  ArrowDownIcon,
  ArrowUpIcon,
  Collapsible,
  Typography,
} from '@atlas-design-system/react'
import { ReactNode } from 'react'

import styles from './custom-collapsible.module.css'

type CustomCollapsibleProps = {
  title: string
  icon?: ReactNode
  children: ReactNode
  defaultOpened?: boolean
  'data-testid'?: string
}

type CustomHeaderProps = {
  onClick: () => void
  tabIndex: number
  onKeyDown: () => void
  isExpanded: boolean
}

export const CustomCollapsible: React.FC<CustomCollapsibleProps> = ({
  title,
  icon,
  children,
  defaultOpened = false,
  ...rest
}) => {
  return (
    <Collapsible
      className={styles.wrapper}
      defaultOpened={defaultOpened}
      header={({
        onClick,
        tabIndex,
        onKeyDown,
        isExpanded,
      }: CustomHeaderProps) => {
        return (
          <div
            data-testid={rest['data-testid']}
            onClick={onClick}
            onKeyDown={onKeyDown}
            role="button"
            tabIndex={tabIndex}
            className={`mmp-custom-collapsible-header ${styles.header}`}
          >
            <div className={styles.headerLeft}>
              {isExpanded ? (
                <ArrowUpIcon width="16" />
              ) : (
                <ArrowDownIcon width="16" />
              )}
              <div className={styles.headerTitle}>
                <Typography variant="contentText2" bold>
                  {title}
                </Typography>
              </div>
            </div>
            {icon && <div className={styles.headerRight}>{icon}</div>}
          </div>
        )
      }}
    >
      <div className={`mmp-custom-collapsible-content ${styles.content}`}>
        {children}
      </div>
    </Collapsible>
  )
}
