import { Dialog } from '@atlas-design-system/react'
import { DiscontinueDatePickerInput } from 'common/components'
import {
  MESSAGE_CONFIRM_ARCHIVE_SEGMENT_BRAND,
  MESSAGE_CONFIRM_EDIT_SEGMENT_BRAND,
  MESSAGE_CONFIRM_FORM_RESET,
} from 'common/constants'
import { useCarrierDetailsContext } from 'common/context'
import { useSegmentBrandingContext } from 'common/context/segment-branding-context'
import { PendingAction } from 'common/enums'
import { useDeleteSegmentBranding } from 'modules/segment-branding/hooks/use-delete-segment-branding.hook'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

type SegmentBrandConfirmModalProps = {
  submitForm: () => void
  resetForm: () => void
}

export const SegmentBrandConfirmModal: React.FC<
  SegmentBrandConfirmModalProps
> = ({ submitForm, resetForm }) => {
  const {
    selectedSegmentBrand,
    pendingAction,
    setPendingAction,
    switchSelectedSegmentBrand,
    pendingSelectedBrandKey,
  } = useSegmentBrandingContext()
  const [discontinueDate, setDiscontinueDate] = useState<string>()
  const { isFetching } = useCarrierDetailsContext()

  const modalHidden = useMemo(() => {
    if (pendingAction) {
      return (
        pendingAction === PendingAction.RESET_FORM ||
        pendingAction === PendingAction.REFRESH_FORM_AFTER_FETCH
      )
    }
    return true
  }, [pendingAction])
  const deleteMutation = useDeleteSegmentBranding()

  const title = useMemo(() => {
    switch (pendingAction) {
      default:
        return 'Warning'
    }
  }, [pendingAction])

  const isActionDelete = useMemo(
    () => pendingAction === PendingAction.DELETE,
    [pendingAction],
  )

  useEffect(() => {
    if (
      pendingAction === PendingAction.REFRESH_FORM_AFTER_FETCH &&
      !isFetching
    ) {
      switchSelectedSegmentBrand()
    }
  })

  const handleDismiss = useCallback(() => {
    setPendingAction()
  }, [setPendingAction])

  const confirmEdit = useCallback(() => {
    submitForm()
    setPendingAction()
  }, [submitForm, setPendingAction])

  const confirmSegmentBrandSwitch = useCallback(() => {
    const pendingActionType =
      selectedSegmentBrand?.key === pendingSelectedBrandKey
        ? PendingAction.RESET_FORM
        : undefined

    switchSelectedSegmentBrand(pendingActionType)
  }, [
    selectedSegmentBrand?.key,
    pendingSelectedBrandKey,
    switchSelectedSegmentBrand,
  ])

  const confirmDelete = useCallback(() => {
    if (discontinueDate) {
      deleteMutation(discontinueDate)
    }
  }, [discontinueDate, deleteMutation])

  const confirmFormReset = useCallback(() => {
    resetForm()
    setPendingAction()
  }, [resetForm, setPendingAction])

  const handleConfirm = useCallback(() => {
    switch (pendingAction) {
      case PendingAction.DELETE:
        return confirmDelete()
      case PendingAction.NEW_BUTTON_CLICK:
        return confirmFormReset()
      case PendingAction.EDIT:
        return confirmEdit()
      case PendingAction.SELECT_ANOTHER_SEGMENT_BRAND:
        return confirmSegmentBrandSwitch()

      default:
        return setPendingAction()
    }
  }, [
    pendingAction,
    confirmDelete,
    confirmFormReset,
    confirmEdit,
    confirmSegmentBrandSwitch,
    setPendingAction,
  ])

  const modalMessage = useMemo(() => {
    switch (pendingAction) {
      case PendingAction.EDIT: {
        return MESSAGE_CONFIRM_EDIT_SEGMENT_BRAND
      }
      case PendingAction.NEW_BUTTON_CLICK:
      case PendingAction.SELECT_ANOTHER_SEGMENT_BRAND: {
        return MESSAGE_CONFIRM_FORM_RESET
      }
      case PendingAction.DELETE: {
        return MESSAGE_CONFIRM_ARCHIVE_SEGMENT_BRAND
      }
      default: {
        return ''
      }
    }
  }, [pendingAction])

  useEffect(() => () => setDiscontinueDate(undefined), [])

  return (
    <Dialog
      title={title}
      hidden={modalHidden}
      onDismiss={handleDismiss}
      onConfirm={handleConfirm}
      appearance="warning"
      id="ancillary-confirm-dialog"
      dismissText="Cancel"
    >
      {modalMessage}
      {isActionDelete && (
        <DiscontinueDatePickerInput onChange={setDiscontinueDate} />
      )}
    </Dialog>
  )
}
