import { useBrandContext } from 'common/context'
import { BrandFormValue } from 'common/types'
import { FormikProps } from 'formik'
import { useCallback, useState } from 'react'

type UseBrandFormProps = {
  formik: FormikProps<BrandFormValue>
}

export const useBrandForm = ({
  formik,
}: UseBrandFormProps): {
  cancelDialogVisible: boolean
  hideCancelDialog: () => void
  showCancelDialog: () => void
  onFormReset: () => void
} => {
  const { resetSelectedBrand, selectedBrand } = useBrandContext()
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false)

  const showCancelDialog = useCallback(() => {
    setCancelDialogVisible(true)
  }, [])

  const hideCancelDialog = useCallback(() => {
    setCancelDialogVisible(false)
  }, [])

  const handleFormReset = useCallback(() => {
    if (selectedBrand) {
      resetSelectedBrand()
    } else {
      formik.resetForm()
    }
    if (cancelDialogVisible) {
      hideCancelDialog()
    }
  }, [
    formik,
    hideCancelDialog,
    resetSelectedBrand,
    cancelDialogVisible,
    selectedBrand,
  ])

  return {
    cancelDialogVisible,
    hideCancelDialog,
    showCancelDialog,
    onFormReset: handleFormReset,
  }
}
