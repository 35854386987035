import { Spinner, Typography } from '@atlas-design-system/react'
import { AppSidebar } from 'common/components'
import { ArchiveButton } from 'common/components/archive-button/archive-button'
import { useCarrierDetailsContext } from 'common/context'
import { groupSegmentBrandAncillaries } from 'common/helpers'
import { useAncillaries } from 'common/hooks'
import React, { useMemo } from 'react'

import { SegmentBrandingSidebarBlock } from './components'
import styles from './segment-branding-sidebar.module.css'

export const SegmentBrandingSidebar: React.FC = () => {
  const { isLoading, archiveMode } = useCarrierDetailsContext()
  const { segmentBrandAncillaries } = useAncillaries()

  const serviceGroups = groupSegmentBrandAncillaries(segmentBrandAncillaries)

  const sidebarSubtitle = useMemo(() => {
    if (archiveMode) {
      return 'All archived Segment Brands are displaying here'
    }
    return 'All current Segment Brands will be saved here'
  }, [archiveMode])

  return (
    <>
      <AppSidebar>
        <div className={styles.header}>
          <div className="row middle-xs between-xs">
            <div className="col-xs">
              <Typography variant="displayText6" bold>
                Segment Brands
              </Typography>
            </div>
            <ArchiveButton />
          </div>
          <Typography variant="contentText2">{sidebarSubtitle}</Typography>
        </div>

        {isLoading && (
          <Spinner
            data-testid="segment-branding-sidebar-spinner"
            className="mmp-ui-spinner"
          />
        )}

        <ul className={styles.list}>
          {serviceGroups.map((serviceGroup, index) => (
            <li key={index} className={styles.listItem}>
              <SegmentBrandingSidebarBlock serviceGroup={serviceGroup} />
            </li>
          ))}
        </ul>
      </AppSidebar>
    </>
  )
}
