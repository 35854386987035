import { FormControl, Radio, RadioGroup } from '@atlas-design-system/react'
import { useAuth } from 'common/context'
import { Permission, UpsellIndicator } from 'common/enums'
import { useCarriers } from 'common/hooks'
import { CarrierFormValue, SelectOption } from 'common/types'
import { FormikProps } from 'formik'
import React, { ChangeEvent, useMemo } from 'react'

import styles from '../../../carrier-form.module.css'

type UpsellListFormProps = {
  formik: FormikProps<CarrierFormValue>
}

export const UPSELL_INDICATOR_SELECT_OPTIONS: SelectOption<string>[] = [
  { value: UpsellIndicator.O, label: 'Ancillary Only' },
  { value: UpsellIndicator.B, label: 'Basic' },
  { value: UpsellIndicator.A, label: 'All' },
  { value: UpsellIndicator.U, label: 'Upsell' },
]

const getIndicatorIndex = (indicator: UpsellIndicator): number => {
  const indicatorOption =
    UPSELL_INDICATOR_SELECT_OPTIONS.find(({ value }) => indicator === value) ||
    UPSELL_INDICATOR_SELECT_OPTIONS[0]

  return UPSELL_INDICATOR_SELECT_OPTIONS.indexOf(indicatorOption)
}

export const UpsellListForm: React.FC<UpsellListFormProps> = ({ formik }) => {
  const { values, setFieldValue } = formik
  const { carrierList } = useCarriers()
  const { hasPermissionTo } = useAuth()

  const highestTierUpsellIndex = useMemo(() => {
    const originalValue = carrierList.find(
      (carrier) => carrier.id === values.id,
    )

    let highestIndex = 0
    let highestIndicator = 0
    if (!!originalValue) {
      originalValue.upsells.forEach((upsell) => {
        if (getIndicatorIndex(upsell.indicator) > highestIndicator) {
          highestIndicator = getIndicatorIndex(upsell.indicator)
          highestIndex = originalValue.upsells.indexOf(upsell)
        }
      })
    }

    return highestIndex
  }, [carrierList, values.id])

  const canCreate = useMemo(() => {
    return hasPermissionTo(Permission.CREATE_CARRIERS)
  }, [hasPermissionTo])

  const indicatorRadioOnChange = (change: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      `upsells[${highestTierUpsellIndex}].indicator`,
      UPSELL_INDICATOR_SELECT_OPTIONS.find(
        ({ value }) => value === change.target.value,
      ) || UPSELL_INDICATOR_SELECT_OPTIONS[3],
    )
  }

  const indicatorIndex = useMemo(() => {
    const originalValue = carrierList.find(
      (carrier) => carrier.id === values.id,
    )

    if (!!originalValue) {
      return getIndicatorIndex(
        originalValue.upsells[highestTierUpsellIndex].indicator,
      )
    } else {
      return 0
    }
  }, [carrierList, highestTierUpsellIndex, values.id])

  return (
    <>
      <div className="row">
        <div className="col-xs-6">
          <FormControl
            className={styles.carrierFormControl}
            label="Provisioning Tier"
            disabled={!canCreate}
          >
            <RadioGroup
              inline
              value={values.upsells[highestTierUpsellIndex].indicator.value}
              onChange={indicatorRadioOnChange}
              className={styles.radioButtons}
            >
              <Radio
                value={UPSELL_INDICATOR_SELECT_OPTIONS[0].value}
                label={UPSELL_INDICATOR_SELECT_OPTIONS[0].label}
                name="AncillaryOnlyRadio"
                data-testid="ancillary-only-radio"
                disabled={indicatorIndex > 0 || !canCreate}
              />
              <Radio
                value={UPSELL_INDICATOR_SELECT_OPTIONS[1].value}
                label={UPSELL_INDICATOR_SELECT_OPTIONS[1].label}
                name="BasicRadio"
                data-testid="basic-radio"
                disabled={indicatorIndex > 1 || !canCreate}
              />
              <Radio
                value={UPSELL_INDICATOR_SELECT_OPTIONS[2].value}
                label={UPSELL_INDICATOR_SELECT_OPTIONS[2].label}
                name="AllRadio"
                data-testid="all-radio"
                disabled={indicatorIndex > 2 || !canCreate}
              />
              <Radio
                value={UPSELL_INDICATOR_SELECT_OPTIONS[3].value}
                label={UPSELL_INDICATOR_SELECT_OPTIONS[3].label}
                name="UpsellRadio"
                data-testid="upsell-radio"
                disabled={!canCreate}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </>
  )
}
