import { useAuth } from 'common/context/auth-context'
import { Permission } from 'common/enums'
import React, { useMemo } from 'react'

import { InformationArea } from '../dashboard-informationarea/dashboard-informationarea.component'
import { LogoArea } from '../dashboard-logo/dashboard-logo.component'
import { PseudoCityCodesArea } from '../dashboard-pseudo-city-codes/dashboard-pseudo-city-codes.component'
import styles from './dashboard-gridarea.module.css'

export const GridArea: React.FC = () => {
  const { hasPermissionTo } = useAuth()
  const hasWritePermission = useMemo(
    () => hasPermissionTo(Permission.WRITE_DASHBOARD),
    [hasPermissionTo],
  )

  return (
    <div className="">
      <div className={`row ${styles.gridRow}`}>
        <div className="col-xs-6">
          <InformationArea />
        </div>
        <div className="col-xs-6">
          <PseudoCityCodesArea />
        </div>
      </div>
      <div className={`row ${styles.gridRow}`}>
        <div className="col-xs-6">
          <LogoArea disabled={!hasWritePermission} />
        </div>
        <div className="col-xs-6"></div>
      </div>
    </div>
  )
}
