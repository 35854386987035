import { PendingAction } from 'common/enums'

export enum BrandActionType {
  SET_SELECTED_FAMILY = 'SET_SELECTED_FAMILY',
  SET_SELECTED_BRAND = 'SET_SELECTED_BRAND',
  SET_PENDING_ACTION = 'SET_PENDING_ACTION',
  SET_COPY_MODE = 'SET_COPY_MODE',
  SWITCH_SELECTED_BRAND = 'SWITCH_SELECTED_BRAND',
  SET_PENDING_SELECTED = 'SET_PENDING_SELECTED',
}

export type SetSelectedFamilyAction = {
  type: BrandActionType.SET_SELECTED_FAMILY
  payload: {
    id: string
  }
}

export type SetSelectedBrandAction = {
  type: BrandActionType.SET_SELECTED_BRAND
  payload: {
    id?: string
    pendingAction?: PendingAction
  }
}

export type SetPendingAction = {
  type: BrandActionType.SET_PENDING_ACTION
  payload: {
    pendingAction?: PendingAction
  }
}

export type SetCopyModeAction = {
  type: BrandActionType.SET_COPY_MODE
  payload: {
    familyId?: string
  }
}

export type SetPendingSelectedBrandAction = {
  type: BrandActionType.SET_PENDING_SELECTED
  payload: {
    id?: string
  }
}

export type SwitchSelectedBrandAction = {
  type: BrandActionType.SWITCH_SELECTED_BRAND
  payload: {
    id: string
  }
}

export type BrandAction =
  | SetSelectedFamilyAction
  | SetSelectedBrandAction
  | SetPendingAction
  | SetCopyModeAction
  | SetPendingSelectedBrandAction
  | SwitchSelectedBrandAction

export type BrandState = {
  copyMode: boolean
  parentFareFamilyRef?: string
  pendingAction?: PendingAction
  pendingSelectedBrandId?: string
  selectedBrandId?: string
  selectedFamilyId?: string
}

export const INITIAL_STATE = {
  copyMode: false,
  parentFareFamilyRef: undefined,
  pendingAction: undefined,
  pendingSelectedBrandId: undefined,
  selectedBrandId: undefined,
  selectedFamilyId: undefined,
}

export const brandReducer = (
  state: BrandState,
  action: BrandAction,
): BrandState => {
  const { type, payload } = action

  switch (type) {
    case BrandActionType.SET_SELECTED_FAMILY:
      return {
        ...state,
        selectedFamilyId: payload.id,
        pendingAction: PendingAction.RESET_FORM,
        selectedBrandId: undefined,
      }
    case BrandActionType.SET_SELECTED_BRAND:
      return {
        ...state,
        selectedBrandId: payload.id,
        copyMode: false,
        pendingAction: payload.pendingAction,
      }
    case BrandActionType.SET_PENDING_ACTION:
      return {
        ...state,
        pendingAction: payload.pendingAction,
      }
    case BrandActionType.SET_COPY_MODE:
      return {
        ...state,
        parentFareFamilyRef: payload.familyId,
        pendingAction: undefined,
        selectedFamilyId: payload.familyId,
        copyMode: true,
      }
    case BrandActionType.SET_PENDING_SELECTED:
      return {
        ...state,
        pendingSelectedBrandId: payload.id,
      }

    case BrandActionType.SWITCH_SELECTED_BRAND: {
      return {
        ...state,
        pendingSelectedBrandId: undefined,
        selectedBrandId: payload.id,
        copyMode: false,
        pendingAction: undefined,
      }
    }
    default:
      return state
  }
}
