import { Checkbox } from '@atlas-design-system/react'
import { createColumnHelper, RowSelectionState } from '@tanstack/react-table'
import { TableSelection } from 'common/components'
import { ShelfValue, TagValue } from 'common/types'
import React, { useMemo, useState } from 'react'

type ShelfTableProps = {
  item: ShelfValue
  index: number
  onRowSelectionChange: (
    newRowSelection: RowSelectionState,
    index: number,
  ) => void
}

const getRowId = (original: TagValue) => original.name

const mapValuesToRowSelection = (values: TagValue[]): RowSelectionState =>
  values.reduce<RowSelectionState>((rowSelection, value) => {
    rowSelection[value.name] = value.isActive
    return rowSelection
  }, {})

export const ShelfTable: React.FC<ShelfTableProps> = ({
  item,
  index,
  onRowSelectionChange,
}) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    mapValuesToRowSelection(item.tags),
  )

  const rows = useMemo(() => item.tags, [item])

  const columnHelper = createColumnHelper<TagValue>()

  const columns = [
    columnHelper.display({
      id: `select-all-tags-${item.name}${index}`,
      size: 70,
      header: ({ table }) => (
        <Checkbox
          name={`select-all-tags-${index}`}
          id={`select-all-tags-${index}`}
          checked={table.getIsAllRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
          label=""
          value={`select-all-tags-${index}`}
          disabled={item.shouldDelete}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          name={`checked-${row.original.name}-${index}`}
          id={`checked-${row.original.name}-${index}`}
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          label=""
          disabled={item.shouldDelete}
        />
      ),
    }),
    columnHelper.accessor((row) => row.name, {
      cell: (info) => info.getValue(),
      header: 'Name',
      id: 'name',
      enableResizing: true,
    }),
  ]

  const handleRowSelectionChange = (updater: any) => {
    if (item.shouldDelete) {
      return
    }
    setRowSelection(updater)
    const newRowSelection = updater(rowSelection)
    onRowSelectionChange(newRowSelection, index)
  }

  return (
    <TableSelection<TagValue>
      data={rows}
      columns={columns}
      rowSelection={rowSelection}
      onRowSelectionChange={handleRowSelectionChange}
      getRowId={getRowId}
    />
  )
}
