import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS, SERVICES } from 'common/api'
import { fetchMandatoryAncillaries } from 'common/services'
import { MandatoryAncillary, MandatoryMap } from 'common/types'
import { useMemo } from 'react'

const createDefaultMandatoryMap = (items: MandatoryAncillary[]) => {
  const mandatoryList = items
    .filter(({ mandatory }) => mandatory)
    .map(({ tagName }) => tagName)

  const mandatoryMap = mandatoryList.reduce<MandatoryMap>((acc, item) => {
    const list: string[] = []
    for (const service of items) {
      if (service.tagName === item) {
        const { group, subgroup } = service
        const tagName = group + subgroup
        if (service.mandatory) {
          if (list.length > 0) {
            list.unshift(tagName)
          } else {
            list.push(tagName)
          }
        } else {
          list.push(tagName)
        }
      }
    }

    acc[item] = list
    return acc
  }, {})

  return mandatoryMap
}

export const useMandatoryAncillaries = (): {
  mandatoryAncillaryList: MandatoryAncillary[]
  isLoading: boolean
  defaultMandatoryMap: MandatoryMap
} => {
  const { data: mandatoryAncillaryList = [], isLoading } = useQuery<
    MandatoryAncillary[]
  >(
    [
      SERVICES.MANDATORY_ANCILLARY_SERVICE,
      QUERY_KEYS.ANCILLARY,
      QUERY_KEYS.MANDATORY,
    ],
    () => fetchMandatoryAncillaries(),
  )

  const defaultMandatoryMap = createDefaultMandatoryMap(mandatoryAncillaryList)

  return { mandatoryAncillaryList, isLoading, defaultMandatoryMap }
}
