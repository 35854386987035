import {
  CollapsibleFormBlock,
  FormHeader,
  FormInput,
  ImageLoader,
  MultiLanguage,
  TextareaField,
  TextareaInput,
} from 'common/components'
import { useBrandContext } from 'common/context'
import { BrandsCategory, ServiceSource } from 'common/enums'
import { MultiLanguageBrandsSchema } from 'common/helpers'
import { parseImageDetails } from 'common/helpers/file-upload-helpers'
import { BrandDetails, BrandFormValue, Family, GalleryItem } from 'common/types'
import { Field, FormikProps } from 'formik'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { ActionButtons } from '../../../../action-buttons/action-buttons.component'

const StyledCol = styled.div`
  min-width: 205px;
`

type SiblingBrand = { id: string; name: string }

const pickSiblingBrands = (selectedFamily?: Family): SiblingBrand[] => {
  const brandDetailsList = selectedFamily?.brandDetailsList || []

  if (brandDetailsList.length === 0) {
    return []
  }

  return brandDetailsList.map(
    ({ brandId: id, brandDescription: { name } }: BrandDetails) => ({
      id,
      name,
    }),
  )
}

const checkIsBrandNameUnique = (
  brands: SiblingBrand[],
  nameValue: string,
  copyMode: boolean,
  selectedBrand?: BrandDetails,
) =>
  brands.some((brand) => {
    if (brand.id && selectedBrand?.brandId && !copyMode) {
      return false
    }
    return brand.name.toLowerCase().trim() === nameValue.toLowerCase().trim()
  })

type BrandDescriptionProps = {
  formik: FormikProps<BrandFormValue>
  hasWritePermission: boolean
}

export const BrandDescription: React.FC<BrandDescriptionProps> = ({
  formik,
  hasWritePermission,
}) => {
  const { handleChange, values, setFieldValue } = formik
  const {
    brandDescription: {
      name,
      brandRule,
      imageDetailsList,
      textByCategory: { MARKETING_AGENT, MARKETING_CONSUMER },
    },
  } = values

  const { selectedBrand, selectedFamily, copyMode } = useBrandContext()
  const editMode = !!selectedBrand

  const siblingBrands = useMemo(
    () => pickSiblingBrands(selectedFamily),
    [selectedFamily],
  )

  const isATPCO = useMemo(
    () => selectedFamily?.source === ServiceSource.ATPCO,
    [selectedFamily],
  )

  const fieldsDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }
    if (copyMode) {
      const isNotUnique = checkIsBrandNameUnique(
        siblingBrands,
        values.brandDescription.name,
        copyMode,
        selectedBrand,
      )

      if (values.brandDescription.name.trim() === '') {
        return true
      }

      return isNotUnique
    }
    return !selectedFamily
  }, [
    selectedFamily,
    hasWritePermission,
    copyMode,
    values,
    siblingBrands,
    selectedBrand,
  ])

  const brandTitleDisabled = useMemo(() => {
    if (fieldsDisabled) {
      return !copyMode
    } else if (!!selectedBrand) {
      return !copyMode
    }
    return false
  }, [fieldsDisabled, selectedBrand, copyMode])

  const onRemoveImage = useCallback(
    (category: BrandsCategory) => {
      if (!hasWritePermission) {
        return true
      }
      const filteredImageDetails = imageDetailsList.filter(
        (image) => image.category !== category,
      )

      setFieldValue('brandDescription.imageDetailsList', filteredImageDetails)
    },
    [imageDetailsList, setFieldValue, hasWritePermission],
  )

  const onSelectImage = (
    category: BrandsCategory,
    selectedGalleryItem: GalleryItem,
  ) => {
    if (!hasWritePermission) {
      return true
    }
    const imageDetailsListCopy = [...imageDetailsList]
    const categoryIndex = imageDetailsListCopy.findIndex(
      (image) => image.category === category,
    )
    const selectedImageDetails = parseImageDetails(
      selectedGalleryItem,
      category,
    )

    if (categoryIndex === -1) imageDetailsListCopy.push(selectedImageDetails)
    else imageDetailsListCopy[categoryIndex] = selectedImageDetails

    setFieldValue('brandDescription.imageDetailsList', imageDetailsListCopy)
  }

  const onSelectImageConsumer = (selectedImageDetails: GalleryItem) =>
    onSelectImage(BrandsCategory.CONSUMER, selectedImageDetails)

  const onSelectImageAgent = (selectedImageDetails: GalleryItem) =>
    onSelectImage(BrandsCategory.AGENT, selectedImageDetails)

  const onSelectImageUpsell = (selectedImageDetails: GalleryItem) =>
    onSelectImage(BrandsCategory.UPSELL, selectedImageDetails)

  const multiLanguageDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }
    return !editMode
  }, [editMode, hasWritePermission])

  const brandTitleValidate = (nameValue: string) => {
    const isNotUnique = checkIsBrandNameUnique(
      siblingBrands,
      nameValue,
      copyMode,
      selectedBrand,
    )

    if (!nameValue || nameValue.trim() === '') {
      return 'Brand Title is required'
    }

    if (isNotUnique) {
      return 'Error! Brand already exists. Please enter a new Title or edit the current Brand for updating.'
    }
  }

  return (
    <>
      <CollapsibleFormBlock title="Brand Description" defaultOpened={true}>
        <FormHeader
          title=""
          actionButtons={<ActionButtons disabled={!hasWritePermission} />}
        />
        <MultiLanguage
          label="Complete the fields below to create a brand"
          modalTitle={`${selectedFamily?.name} v${selectedFamily?.version} / ${name}`}
          type="brand"
          formik={formik}
          disabled={!editMode}
          fieldsDisabled={multiLanguageDisabled}
          validationSchema={MultiLanguageBrandsSchema}
        />

        <div className="row">
          <div className="col-xs-8">
            <Field
              component={FormInput}
              disabled={brandTitleDisabled}
              data-testid="brand-title-input"
              label="Brand Title"
              maxLength="50"
              minLength="1"
              name="brandDescription.name"
              required
              validate={brandTitleValidate}
            />

            <Field
              component={FormInput}
              data-testid="brand-external-title-input"
              disabled={fieldsDisabled}
              hint="This is the name displayed to the public"
              label="External Commercial Brand Name"
              maxLength="45"
              minLength="1"
              name="brandDescription.titleByType.EXTERNAL"
              required
            />

            <Field
              component={FormInput}
              disabled={fieldsDisabled || isATPCO}
              data-testid="brand-external-short-name-input"
              hint="This is the name displayed to the public when there is limited space"
              label="External Short Brand Name"
              maxLength="10"
              minLength="1"
              name="brandDescription.titleByType.SHORT"
              required
            />

            <Field
              component={FormInput}
              data-testid="brand-strap-line-input"
              disabled={fieldsDisabled}
              hint="Brief Description"
              label="Strap Line"
              maxLength="100"
              minLength="1"
              name="brandDescription.textByCategory.STRAPLINE"
            />
          </div>
        </div>

        <div className="row">
          <StyledCol className="col-xs-3">
            <ImageLoader
              image={
                imageDetailsList.find(
                  ({ category }) => category === BrandsCategory.UPSELL,
                )?.cdnSourcePath
              }
              label="Upsell Image"
              onSelectImage={onSelectImageUpsell}
              onRemoveImage={() => onRemoveImage(BrandsCategory.UPSELL)}
              disabled={fieldsDisabled}
              restrictions={{ attribute: 'brand' }}
            />
          </StyledCol>
          <div className="col-xs">
            <Field
              component={TextareaInput}
              disabled={fieldsDisabled}
              label="Upsell Marketing Text"
              name="brandDescription.textByCategory.UPSELL"
            />
          </div>
        </div>

        <div className="row">
          <StyledCol className="col-xs-3">
            <ImageLoader
              image={
                imageDetailsList.find(
                  ({ category }) => category === BrandsCategory.AGENT,
                )?.cdnSourcePath
              }
              label="Agent Image"
              onSelectImage={onSelectImageAgent}
              onRemoveImage={() => onRemoveImage(BrandsCategory.AGENT)}
              restrictions={{
                size: 100,
                width: 150,
                height: 150,
                attribute: 'brand',
              }}
              disabled={fieldsDisabled}
            />
          </StyledCol>
          <div className="col-xs">
            <TextareaField
              label="Agent Marketing Text"
              name="brandDescription.textByCategory.MARKETING_AGENT"
              onChange={handleChange}
              value={MARKETING_AGENT}
              disabled={fieldsDisabled}
            />
          </div>
        </div>

        <div className="row">
          <StyledCol className="col-xs-3">
            <ImageLoader
              image={
                imageDetailsList.find(
                  ({ category }) => category === BrandsCategory.CONSUMER,
                )?.cdnSourcePath
              }
              label="Consumer Image"
              onSelectImage={onSelectImageConsumer}
              onRemoveImage={() => onRemoveImage(BrandsCategory.CONSUMER)}
              disabled={fieldsDisabled}
              restrictions={{ attribute: 'brand' }}
            />
          </StyledCol>
          <div className="col-xs">
            <TextareaField
              label="Consumer Marketing Text"
              name="brandDescription.textByCategory.MARKETING_CONSUMER"
              onChange={handleChange}
              value={MARKETING_CONSUMER}
              disabled={fieldsDisabled}
            />
          </div>
        </div>

        <TextareaField
          label="Brand Rules"
          name="brandDescription.brandRule"
          onChange={handleChange}
          value={brandRule}
          disabled={fieldsDisabled}
        />
      </CollapsibleFormBlock>
    </>
  )
}
