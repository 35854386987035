import { ApiCarrierDetails } from 'common/types'
import { CarrierDetails } from 'common/types'

import { FamilyGroupModel } from './family-group.model'
import { GdsDetailsModel } from './gds-details.model'
import { OptionalServiceModel } from './optional-service-model'

export const CarrierDetailsModel = (
  carrierDetails: ApiCarrierDetails,
): CarrierDetails => {
  const {
    allDetails,
    basicDetails,
    cabinDetailsList = [],
    familyGroupList = [],
    gdsDetailsList = [],
    groupCodeList = [],
    nationList = [],
    optionalServiceList = [],
    serviceBrandMap,
  } = carrierDetails

  return {
    allDetails,
    basicDetails,
    cabinDetailsList,
    familyGroupList: familyGroupList.map(FamilyGroupModel),
    gdsDetailsList: gdsDetailsList.map(GdsDetailsModel),
    groupCodeList,
    nationList,
    optionalServiceList: optionalServiceList.map(OptionalServiceModel),
    serviceBrandMap,
  }
}
