import {
  Banner,
  Button,
  ButtonGroup,
  Form,
  FormControl,
  Input,
} from '@atlas-design-system/react'
import {
  FormCancelDialog,
  FormDatePicker,
  FormHeader,
  GeographyFieldsList,
} from 'common/components'
import {
  useAuth,
  useCarrierDetailsContext,
  useFamilyContext,
} from 'common/context'
import {
  FamilyStatus,
  PendingAction,
  Permission,
  ServiceSource,
} from 'common/enums'
import {
  disabledDateBefore,
  findArrayFieldsError,
  isATPCOFamily,
  noop,
} from 'common/helpers'
import { useFamily, useWatchForm } from 'common/hooks'
import { FamilyFormValue, SelectErrors } from 'common/types'
import { Field, FormikProvider, useFormik } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ActionButtons } from '../../action-buttons/action-buttons.component'
import { FamilyConfirmModal } from './../../family-form-modal/family-form-modal.component'
import {
  AccountTypeCodeFieldsList,
  FamilyNameField,
  PointOfSaleFieldsList,
} from './components'
import {
  FAMILY_NAME_UNIQUE_ERROR,
  validationSchema,
} from './family-form.schema'
import { useFamilyForm } from './use-family-form.hook'

type FamilyFormProps = {
  initialValues: FamilyFormValue
  onSubmit: (values: FamilyFormValue) => void
}

export const FamilyForm: React.FC<FamilyFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const { hasPermissionTo } = useAuth()
  const { selectedFamily, copyMode, setPendingAction } = useFamilyContext()
  const { archiveMode } = useCarrierDetailsContext()
  const { familyList } = useFamily()

  const [errorBannerVisible, setErrorBannerVisible] = useState(true)

  const hasWritePermission = useMemo(
    () => hasPermissionTo(Permission.WRITE_FAREFAMILIES),
    [hasPermissionTo],
  )

  const isFromATPCO = useMemo(
    () => !!selectedFamily && isATPCOFamily(selectedFamily),
    [selectedFamily],
  )

  const isTitleDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }
    if (copyMode) {
      return isFromATPCO ? true : false
    }
    return isFromATPCO || selectedFamily ? true : false
  }, [copyMode, isFromATPCO, selectedFamily, hasWritePermission])

  const shouldConfirmSavetoDraft = useMemo(() => {
    return (
      selectedFamily?.source === ServiceSource.TRAVELPORT &&
      selectedFamily.status === FamilyStatus.LIVE
    )
  }, [selectedFamily])

  const handleSubmit = (formValues: FamilyFormValue) => {
    if (shouldConfirmSavetoDraft && !copyMode) {
      setPendingAction(PendingAction.SAVE_FAMILY_TO_DRAFT)
      return
    }

    onSubmit(formValues)
  }

  const familiesNameList = useMemo(
    () => familyList.map((family) => family.name),
    [familyList],
  )

  const formik = useFormik<FamilyFormValue>({
    initialValues,
    validationSchema: validationSchema(familiesNameList),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  })

  const {
    onFormReset,
    cancelDialogVisible,
    hideCancelDialog,
    showCancelDialog,
  } = useFamilyForm({ formik })

  useEffect(() => {
    if (formik.values.id !== initialValues.id) {
      formik.resetForm({ values: initialValues, errors: {}, touched: {} })
    }
  }, [initialValues, formik])

  const {
    values,
    touched,
    errors,
    handleChange,
    isValid,
    submitCount,
    setFieldValue,
  } = formik

  const submitForm = () => {
    onSubmit(values)
  }

  useWatchForm<FamilyFormValue>(formik)

  const fieldsDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }

    const nameUniqueError =
      !!errors.name && errors.name === FAMILY_NAME_UNIQUE_ERROR

    if (!selectedFamily?.id) {
      return nameUniqueError
    }

    if (copyMode) {
      if (!values.name.trim()) {
        return true
      }
      return nameUniqueError
    }
    return isFromATPCO
  }, [
    copyMode,
    isFromATPCO,
    selectedFamily?.id,
    values.name,
    hasWritePermission,
    errors,
  ])

  const saveButtonDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }
    if (fieldsDisabled) {
      return true
    }
    if (isFromATPCO && !copyMode) {
      return true
    }
    return archiveMode && !copyMode
  }, [copyMode, fieldsDisabled, isFromATPCO, hasWritePermission, archiveMode])

  const cancelButtonDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }
    if (fieldsDisabled) {
      return true
    }
    return archiveMode && !copyMode
  }, [fieldsDisabled, hasWritePermission, archiveMode, copyMode])

  const geographyListError = useMemo(
    () => findArrayFieldsError(errors.geographyList),
    [errors.geographyList],
  )

  const pointOfSaleError = useMemo(
    () => findArrayFieldsError(errors.pointOfSalesList),
    [errors.pointOfSalesList],
  )

  const setDisableDiscontinueSearchDates = useCallback(
    (value: Date) => disabledDateBefore(value, values.effectiveSearchDate),
    [values.effectiveSearchDate],
  )

  const setDisableDiscontinueTravelDates = useCallback(
    (value: Date) => disabledDateBefore(value, values.effectiveSearchDate),
    [values.effectiveSearchDate],
  )

  const getGeographyErrorMessage = useCallback(
    (fieldName: string, index: number) => {
      if (errors.geographyList) {
        const pointOfSalesListError = errors.geographyList[
          index
        ] as SelectErrors
        if (pointOfSalesListError?.[fieldName]?.value) {
          return 'Required'
        }
      }
    },
    [errors.geographyList],
  )

  useEffect(() => {
    if (!!values.effectiveSearchDate && !values.discontinueSearchDate) {
      setFieldValue('discontinueSearchDate', values.effectiveSearchDate)
    }
    if (!!values.effectiveTravelDate && !values.discontinueTravelDate) {
      setFieldValue('discontinueTravelDate', values.effectiveTravelDate)
    }
  }, [
    setFieldValue,
    values.effectiveSearchDate,
    values.discontinueSearchDate,
    values.effectiveTravelDate,
    values.discontinueTravelDate,
  ])

  return (
    <>
      {!isValid && submitCount > 0 && errorBannerVisible && (
        <Banner
          data-testid="family-error-banner"
          appearance="error"
          dismissable
          heading="Error! Please fill all the mandatory fields."
          onClose={() => {
            setErrorBannerVisible(false)
          }}
          style={{ marginBottom: 'var(--spaceL)' }}
        />
      )}

      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} autoComplete="off">
          <FormHeader
            title="Family Builder"
            actionButtons={<ActionButtons />}
            variant="displayText6"
          />

          <FamilyNameField
            selectedFamily={selectedFamily}
            copyMode={copyMode}
            disabled={isTitleDisabled}
          />

          <div className="row">
            <div className="col-xs-6">
              <FormHeader title="Display Dates" variant="displayText7" />
              <Field
                component={FormDatePicker}
                disabled={fieldsDisabled}
                hint="When the data is active (dd-mmm-yyyy)"
                label="Effective Date"
                name="effectiveSearchDate"
                disabledDate={disabledDateBefore}
                required
              />
              <Field
                component={FormDatePicker}
                disabled={fieldsDisabled}
                hint="When the data is no longer active (dd-mmm-yyyy)"
                label="Discontinue Date"
                name="discontinueSearchDate"
                disabledDate={setDisableDiscontinueSearchDates}
                required
              />
            </div>
            <div className="col-xs-6">
              <FormHeader title="Travel Dates" variant="displayText7" />
              <Field
                component={FormDatePicker}
                disabled={fieldsDisabled}
                hint="When the data is active (dd-mmm-yyyy)"
                label="Effective Date"
                name="effectiveTravelDate"
                disabledDate={disabledDateBefore}
                required
              />
              <Field
                component={FormDatePicker}
                disabled={fieldsDisabled}
                hint="When the data is no longer active (dd-mmm-yyyy)"
                label="Discontinue Date"
                name="discontinueTravelDate"
                disabledDate={setDisableDiscontinueTravelDates}
                required
              />
            </div>
          </div>

          <FormHeader title="Geography" />

          {touched.geographyList && geographyListError && (
            <Banner
              appearance="error"
              data-testid="family-form-geo-error-banner"
              size="small"
              heading={geographyListError}
              style={{ marginBottom: 'var(--spaceL)' }}
            />
          )}

          <GeographyFieldsList<FamilyFormValue>
            formik={formik}
            namePath="geographyList"
            items={values.geographyList}
            disabled={fieldsDisabled}
            onGetErrosMessage={getGeographyErrorMessage}
          />

          <FormHeader title="Passenger Type Code" />

          <FormControl
            label="PTC value"
            hint="Applicable 3 letter PTC"
            disabled={fieldsDisabled}
          >
            <div className="row">
              <div className="col-xs-2">
                <Input
                  name="passengerTypeCode"
                  data-testid="family-form-ptc-code-input"
                  onChange={handleChange}
                  value={values.passengerTypeCode}
                  maxLength="3"
                  disabled={fieldsDisabled}
                  wrapperProps={{
                    onMouseUp: noop,
                  }}
                />
              </div>
            </div>
          </FormControl>

          <FormHeader title="Account Type Code" />

          <AccountTypeCodeFieldsList
            formik={formik}
            items={values.accountTypeCodeList}
            disabled={fieldsDisabled}
          />

          <FormHeader title="Point Of Sale" />

          {touched.pointOfSalesList && pointOfSaleError && (
            <Banner
              appearance="error"
              data-testid="family-form-pos-error-banner"
              size="small"
              heading={pointOfSaleError}
              style={{ marginBottom: 'var(--spaceL)' }}
            />
          )}

          <PointOfSaleFieldsList
            formik={formik}
            items={values.pointOfSalesList}
            disabled={fieldsDisabled}
          />

          <ButtonGroup align="right">
            <Button
              appearance="primary"
              data-testid="family-form-submit-button"
              type="submit"
              disabled={saveButtonDisabled}
            >
              Save
            </Button>
            <Button
              data-testid="family-form-cancel-button"
              onClick={showCancelDialog}
              disabled={cancelButtonDisabled}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Form>
      </FormikProvider>

      <FormCancelDialog
        hidden={!cancelDialogVisible}
        onConfirm={onFormReset}
        onDismiss={hideCancelDialog}
      />

      <FamilyConfirmModal submitForm={submitForm} resetForm={onFormReset} />
    </>
  )
}
