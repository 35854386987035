import { toast } from '@atlas-design-system/react'
import { useMutation } from '@tanstack/react-query'
import { DATE_FORMAT } from 'common/constants'
import { useBrandContext, useCarrierDetailsContext } from 'common/context'
import { useAuthUser } from 'common/hooks'
import { deleteBrand } from 'common/services/brand.service'
import { DeleteBrandRequest, DeleteBrandResponse } from 'common/types'
import { format } from 'date-fns'
import { useCallback } from 'react'

type DeleteBrandFn = (id: string) => void
export const useDeleteBrand = (): DeleteBrandFn => {
  const { reloadCarrierDetails } = useCarrierDetailsContext()
  const { selectFamily } = useBrandContext()
  const user = useAuthUser()

  const deleteMutation = useMutation<
    DeleteBrandResponse,
    Error,
    DeleteBrandRequest
  >((data: DeleteBrandRequest) => deleteBrand(data), {
    onSuccess: ({ confirmationId, entityId }) => {
      toast.success(
        `Success! Brand has been Deleted. Confirmation id: (${confirmationId})`,
      )
      if (!!entityId) {
        selectFamily(entityId)
      }
      reloadCarrierDetails()
    },
    onError: ({ message }) => {
      toast.error(`Error: Failed to delete brand, ${message}`)
    },
  })

  const handleDeleteBrand = useCallback(
    (id: string) => {
      if (!user) {
        return
      }

      deleteMutation.mutate({
        id,
        discontinueDate: format(new Date(), DATE_FORMAT),
      })
    },
    [user, deleteMutation],
  )

  return handleDeleteBrand
}
