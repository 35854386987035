import { BreakingErrorPage } from 'common/components/layout/breaking-error-page/breaking-error-page.component'
import React from 'react'

export type ErrorBoundaryState = { hasError: boolean; errorMessage?: string }

export class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  ErrorBoundaryState
> {
  private readonly children: React.ReactNode

  constructor(
    props:
      | { children: React.ReactNode }
      | Readonly<{ children: React.ReactNode }>,
  ) {
    super(props)
    this.children = props.children
    this.state = {
      hasError: false,
      errorMessage: undefined,
    }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, errorMessage: error.message }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      const errorMessage = this.state.errorMessage
      return <BreakingErrorPage message={errorMessage} />
    } else {
      return <div>{this.children}</div>
    }
  }
}
