import { Table, Tbody, Td, Th, Thead, Tr } from '@atlas-design-system/react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table'
import { ServiceAvailability } from 'common/enums'
import {
  OptionalService,
  OptionalServicesByCategoryValue,
  OptionalServiceValue,
  TableColumn,
} from 'common/types'
import { useField } from 'formik'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { OptionalServiceFormModel } from '../../../models/brand-form.model'
import { AvailabilityRadio } from './column-components/availability-radio/availability-radio.component'
import { ChargeableButton } from './column-components/chargeable-button.component'
import { RowSelectionCell } from './column-components/row-selection-cell.component'
import { RowSelectionHeader } from './column-components/row-selection-header.component'

const baseColumns: TableColumn<OptionalService>[] = [
  {
    accessorKey: 'group',
    header: 'Group',
    size: 100,
    component: ({ row }) => {
      const { group, subgroup = '', subcode = '' } = row.original

      const groupSubgroup = `${group}:${subgroup || ''}`
      const subCode = !!subcode ? ` Sub Code:${subcode}` : ''
      return <>{groupSubgroup + subCode}</>
    },
  },
  {
    accessorKey: 'serviceName',
    header: 'Name',
    size: 170,
  },
]

const serviceColumns: TableColumn<OptionalService>[] = [
  {
    header: 'Free',
    size: 70,
    component: (cellProps) =>
      AvailabilityRadio({
        ...cellProps,
        serviceAvailabilityType: ServiceAvailability.FREE,
      }),
  },
  {
    header: 'Not Offered',
    size: 120,
    component: (cellProps) =>
      AvailabilityRadio({
        ...cellProps,
        serviceAvailabilityType: ServiceAvailability.NOT_OFFERED,
      }),
  },
  {
    header: 'Chargeable',
    size: 100,
    component: (cellProps) =>
      AvailabilityRadio({
        ...cellProps,
        serviceAvailabilityType: ServiceAvailability.CHARGEABLE,
      }),
  },
  {
    header: '',
    id: 'edit-chargeable',
    size: 170,
    component: ChargeableButton,
  },
]

const getRowId = (original: OptionalService) => original.meta.id

type ServicesTableProps = {
  data: OptionalService[]
  brandId?: string
  onEditChargeableClick?: (
    serviceId: string,
    name: keyof OptionalServicesByCategoryValue,
  ) => void
  isSegment?: boolean
  disabled: boolean
  name: keyof OptionalServicesByCategoryValue
}

export const ServicesTable: React.FC<ServicesTableProps> = ({
  brandId,
  data,
  onEditChargeableClick,
  isSegment = false,
  disabled,
  name,
}) => {
  const currentBrandId = useRef<string | undefined>(undefined)

  const [field, , helpers] = useField<OptionalServiceValue[]>(
    `optionalServicesByCategory.${name}`,
  )

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const tableColumns: TableColumn<OptionalService>[] = useMemo(
    () => (isSegment ? baseColumns : [...baseColumns, ...serviceColumns]),
    [isSegment],
  )

  const enrichedColumns: ColumnDef<OptionalService>[] = tableColumns.map(
    (column) => ({
      ...column,
      cell: (cellProps) => {
        if (column.id === 'edit-chargeable' && column.component) {
          return column.component({
            ...cellProps,
            onEditChargeableClick,
            disabled,
            name,
          })
        } else {
          return column.component
            ? column.component({ ...cellProps, disabled, name })
            : cellProps.getValue()
        }
      },
    }),
  )

  const columns: ColumnDef<OptionalService>[] = [
    {
      id: 'select-all',
      header: (cellProps) => RowSelectionHeader({ ...cellProps, disabled }),
      cell: (cellProps) => RowSelectionCell({ ...cellProps, disabled }),
      size: 70,
    },
    ...enrichedColumns,
  ]

  useEffect(() => {
    if (brandId !== currentBrandId.current) {
      currentBrandId.current = brandId

      const newRowSelection: RowSelectionState =
        field.value.reduce<RowSelectionState>((selection, service) => {
          selection[service.meta.id] = true
          return selection
        }, {})

      setRowSelection(newRowSelection)
    }
  }, [brandId, field])

  const handleRowSelectionChange = (updater: any) => {
    setRowSelection(updater)
    const newRowSelection = updater(rowSelection)

    const existingItems = field.value

    const newIds = Object.keys(newRowSelection)
    const existingIds = existingItems.map(({ meta: { id } }) => id)

    const newIdsCount = Object.keys(newRowSelection).length
    const existingIdsCount = existingIds.length

    if (existingIdsCount < newIdsCount) {
      const newItems = newIds.filter((id) => !existingIds.includes(id))

      const newServices = data.filter(({ meta: { id } }) =>
        newItems.includes(id),
      )

      helpers.setValue([
        ...existingItems,
        ...newServices.map(OptionalServiceFormModel),
      ])
    } else {
      helpers.setValue(
        existingItems.filter(({ meta: { id } }) => newIds.includes(id)),
      )
    }
  }

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    onRowSelectionChange: handleRowSelectionChange,
    getCoreRowModel: getCoreRowModel(),
    getRowId,
  })

  return (
    <Table
      next
      {...{
        style: {
          width: '100%',
        },
      }}
    >
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <Th
                  key={header.id}
                  {...{
                    colSpan: header.colSpan,
                    style: {
                      width:
                        header.getSize() === 150 ? 'auto' : header.getSize(),
                    },
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Th>
              )
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Tr key={row.id} className={row.getIsSelected() ? 'checked' : ''}>
            {row.getVisibleCells().map((cell) => (
              <Td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
