import { FormControl } from '@atlas-design-system/react'
import React from 'react'

const REQUIRED_CLASSNAME = 'mmp-ui-form-control-required'

type CustomFormControlProps = {
  required?: boolean
} & React.ComponentProps<typeof FormControl>

export const CustomFormControl: React.FC<CustomFormControlProps> = ({
  required = false,
  label,
  children,
  error,
  hint,
  disabled = false,
}) => {
  return (
    <FormControl
      label={label}
      className={required ? REQUIRED_CLASSNAME : ''}
      error={error}
      hint={hint}
      disabled={disabled}
    >
      {children}
    </FormControl>
  )
}
