import { API, SERVICES } from 'common/api'
import { ApiLanguage, ApiReferenceAncillarydData } from 'common/types'

export const fetchAncillarydData =
  async (): Promise<ApiReferenceAncillarydData> => {
    try {
      const { data } = await API().get<ApiReferenceAncillarydData>(
        SERVICES.REFERENCE_DATA_SERVICE + '/ancillarydata',
      )
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }

export const fetchAreaCodes = async (): Promise<string[]> => {
  try {
    const { data } = await API().get<string[]>(
      SERVICES.REFERENCE_DATA_SERVICE + '/areacodes',
    )
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchZoneCodes = async (): Promise<string[]> => {
  try {
    const { data } = await API().get<string[]>(
      SERVICES.REFERENCE_DATA_SERVICE + '/zonecodes',
    )
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchCountryCodes = async (): Promise<string[]> => {
  try {
    const { data } = await API().get<string[]>(
      SERVICES.REFERENCE_DATA_SERVICE + '/countrycodes',
    )
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchStateCodes = async (): Promise<string[]> => {
  try {
    const { data } = await API().get<string[]>(
      SERVICES.BRANDED_FARES_SERVICE + '/statecodes',
    )
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchCityCodes = async (): Promise<string[]> => {
  try {
    const { data } = await API().get<string[]>(
      SERVICES.REFERENCE_DATA_SERVICE + '/citycodes',
    )
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchAirportCodes = async (): Promise<string[]> => {
  try {
    const { data } = await API().get<string[]>(
      SERVICES.REFERENCE_DATA_SERVICE + '/airportcodes',
    )
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchLanguages = async (): Promise<ApiLanguage[]> => {
  try {
    const { data } = await API().get<ApiLanguage[]>(
      SERVICES.REFERENCE_DATA_SERVICE + '/languages',
    )
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchEqupmentTypeCodes = async (): Promise<string[]> => {
  try {
    const { data } = await API().get<string[]>(
      SERVICES.REFERENCE_DATA_SERVICE + '/equpmenttypecodes',
    )
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchAdjustmentSubCodes = async (): Promise<string[]> => {
  try {
    const { data } = await API().get<string[]>(
      SERVICES.BRANDED_FARES_SERVICE + '/adjustmentsubcodes',
    )
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchCurrencies = async (): Promise<string[]> => {
  try {
    const { data } = await API().get<string[]>(
      SERVICES.REFERENCE_DATA_SERVICE + '/currencies',
    )
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
