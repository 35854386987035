import { Button, Modal, Typography } from '@atlas-design-system/react'
import { MultiLanguageTypes } from 'common/types'
import { FormikProps } from 'formik'
import { FC, useState } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'

import { MultiLanguageModalContent } from './components/multi-language-modal-content.component'

const StyledDiv = styled.div`
  text-align: right;
`

type MultiLanguageProps = {
  label?: string
  disabled?: boolean
  modalTitle: string
  type: MultiLanguageTypes
  formik: FormikProps<any>
  fieldsDisabled?: boolean
  validationSchema?: Yup.AnyObjectSchema
}

export const MultiLanguage: FC<MultiLanguageProps> = (props) => {
  const {
    label,
    disabled = false,
    modalTitle,
    type,
    formik,
    fieldsDisabled = false,
    validationSchema,
  } = props

  const [showModal, setModal] = useState(false)

  return (
    <>
      <div className="row between-xs">
        <div className="col-xs-9">
          <Typography data-testid="multi-language-label" variant="contentText1">
            {label}
          </Typography>
        </div>
        <StyledDiv className="col-xs">
          <Typography variant="contentText1">English</Typography>
          <div className="box">
            <Typography variant="contentText2">
              <Button
                data-testid="multi-language-button"
                appearance="tertiary"
                size="small"
                disabled={disabled}
                className={`link-button ${disabled && 'disabled'}`}
                onClick={() => !disabled && setModal(true)}
              >
                multi-language
              </Button>
            </Typography>
          </div>
        </StyledDiv>
      </div>
      <Modal
        title={modalTitle}
        hidden={!showModal}
        size="small"
        onDismiss={() => setModal(false)}
        id="multi-language-modal"
      >
        <MultiLanguageModalContent
          type={type}
          formik={formik}
          fieldsDisabled={fieldsDisabled}
          validationSchema={validationSchema}
        />
      </Modal>
    </>
  )
}
