import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS, SERVICES } from 'common/api'
import { fetchCarriers } from 'common/services'
import { Carrier } from 'common/types'

export const useCarriers = (): {
  carrierList: Carrier[]
  isLoading: boolean
  isFetching: boolean
} => {
  const { data, isLoading, isFetching } = useQuery(
    [SERVICES.PROVISIONING_CARRIER_SERVICE, QUERY_KEYS.CARRIERS],
    () => fetchCarriers(),
  )

  const carrierList = data ? data : []

  return { carrierList, isLoading, isFetching }
}
