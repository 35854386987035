import { Table, Tbody, Td, Th, Thead, Tr } from '@atlas-design-system/react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  OnChangeFn,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table'

type TableSelectionProps<T> = {
  data: T[]
  columns: ColumnDef<T, any>[]
  rowSelection: Record<string, boolean>
  onRowSelectionChange: OnChangeFn<RowSelectionState>
  getRowId: (originalRow: T) => string
}

export const TableSelection = <T,>({
  data,
  columns,
  rowSelection,
  onRowSelectionChange,
  getRowId,
}: TableSelectionProps<T>): JSX.Element => {
  const table = useReactTable<T>({
    state: {
      rowSelection,
    },
    onRowSelectionChange,
    data,
    columns,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Table
      next
      {...{
        style: {
          width: '100%',
        },
      }}
    >
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <Th
                  {...{
                    key: header.id,
                    colSpan: header.colSpan,
                    style: {
                      width:
                        header.getSize() === 150 ? 'auto' : header.getSize(),
                    },
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Th>
              )
            })}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Tr key={row.id} className={row.getIsSelected() ? 'checked' : ''}>
            {row.getVisibleCells().map((cell) => (
              <Td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
