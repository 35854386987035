import { toast } from '@atlas-design-system/react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useBrandContext, useCarrierDetailsContext } from 'common/context'
import { createBrand } from 'common/services/brand.service'
import { CreateBrandRequest, CreateBrandResponse } from 'common/types'

export const useCreateBrand = (): UseMutationResult<
  CreateBrandResponse,
  Error,
  CreateBrandRequest
> => {
  const { selectFamily } = useBrandContext()
  const { reloadCarrierDetails } = useCarrierDetailsContext()

  return useMutation<CreateBrandResponse, Error, CreateBrandRequest>(
    (data: CreateBrandRequest) => createBrand(data),
    {
      onSuccess: ({ confirmationId, entityId: familyId }) => {
        toast.success(
          `Success! Brand has been saved. Confirmation id: (${confirmationId})`,
        )
        reloadCarrierDetails()
        selectFamily(familyId)
      },
      onError: ({ message }) => {
        toast.error(`Error: Something went wrong. ${message}`)
      },
    },
  )
}
