import { ApiFareRule } from 'common/types'
import { FareRule } from 'common/types'

export const FareRuleModel = (fareRule: ApiFareRule): FareRule => {
  const {
    fareBasisCode = '',
    fareTypeCode = '',
    rbd = '',
    rule = '',
    tariff = '',
  } = fareRule

  return {
    fareBasisCode,
    fareTypeCode,
    rbd,
    rule,
    tariff,
  }
}
