import { CollapsibleFormBlock } from 'common/components'
import { CarrierFormValue } from 'common/types'
import { FormikProps } from 'formik'
import React from 'react'

import styles from '../../carrier-form.module.css'
import { TestDataList } from '../test-data-list/test-data-list.component'

type PseudoCityListProps = {
  formik: FormikProps<CarrierFormValue>
}

export const PseudoCityList: React.FC<PseudoCityListProps> = ({ formik }) => {
  return (
    <div>
      <CollapsibleFormBlock
        title="Test PCCs"
        defaultOpened={false}
        className={styles.formHeader}
      >
        <TestDataList formik={formik} listName="pseudoCities" />
      </CollapsibleFormBlock>
    </div>
  )
}
