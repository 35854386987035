import { API, QUERY_KEYS, SERVICES } from 'common/api'
import {
  GalleryItem,
  ImageRequestShape,
  PostImageResponse,
  Supplier,
} from 'common/types'

export const fetchCdnToken = async (): Promise<string> => {
  try {
    const { data } = await API().get(
      `${SERVICES.CDN_TOKEN_SERVICE}/${QUERY_KEYS.TOKEN}`,
    )

    return data.token
  } catch (error) {
    return Promise.reject(error)
  }
}

// UNUSED CODE: CDN-TOKEN-SERVICE API has no endpoint for this yet (Azure supports this)
export const fetchSuppliers = async (): Promise<[supplier: Supplier]> => {
  try {
    const { data } = await API().get(
      `${SERVICES.CDN_TOKEN_SERVICE}/${QUERY_KEYS.SUPPLIERS}`,
    )

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchSupplierImages = async (
  supplierId: string,
): Promise<GalleryItem[]> => {
  try {
    const { data } = await API().get(
      `${SERVICES.CDN_TOKEN_SERVICE}/${QUERY_KEYS.SUPPLIERS}/${supplierId}/${QUERY_KEYS.IMAGES}?archived=false`,
    )

    return data || []
  } catch (error) {
    return []
  }
}

export const postSupplierImage = async (
  imageData: ImageRequestShape,
): Promise<PostImageResponse> => {
  try {
    const { data } = await API().post(
      `${SERVICES.CDN_TOKEN_SERVICE}/${QUERY_KEYS.IMAGES}/upload`,
      imageData,
    )

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteSupplierImage = async (
  supplierId: string,
  imageId: string,
): Promise<GalleryItem> => {
  try {
    const { data } = await API().delete(
      `${SERVICES.CDN_TOKEN_SERVICE}/${QUERY_KEYS.SUPPLIERS}/${supplierId}/${QUERY_KEYS.IMAGES}/${imageId}`,
    )

    return data || []
  } catch (error) {
    return Promise.reject(error)
  }
}
