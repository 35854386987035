import { Spinner, Typography } from '@atlas-design-system/react'
import { useCarrierDetailsContext } from 'common/context'
import { FC } from 'react'
import styled from 'styled-components'

import { MandatoryAncillariesForm } from './components'

const StyledTypography = styled.div`
  margin-bottom: var(--spaceM);
`

export const MandatoryAncillaries: FC = () => {
  const { isFetching } = useCarrierDetailsContext()

  if (isFetching) {
    return <Spinner />
  }

  return (
    <div data-testid="mandatory-ancillaries-page">
      <StyledTypography>
        <Typography variant="displayText6">
          Set Mandatory Ancillaries
        </Typography>
      </StyledTypography>
      <MandatoryAncillariesForm />
    </div>
  )
}
