import { API, SERVICES } from 'common/api'
import {
  CreateFamilyRequest,
  CreateFamilyResponse,
  DeleteFamilyRequest,
  DeleteFamilyResponse,
  EditFamilyRequest,
  EditFamilyResponse,
  UpdateFamilyStatusRequest,
  UpdateFamilyStatusResponse,
} from 'common/types'

export const editFamily = async (
  data: EditFamilyRequest,
): Promise<EditFamilyResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().put<EditFamilyResponse>(
      `${SERVICES.BRANDED_FARES_SERVICE}/fareFamily`,
      data,
    )

    return {
      confirmationId,
      entityId,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateFamilyStatus = async (
  data: UpdateFamilyStatusRequest,
): Promise<UpdateFamilyStatusResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().patch<UpdateFamilyStatusResponse>(
      `${SERVICES.BRANDED_FARES_SERVICE}/fareFamily`,
      data,
    )

    return { confirmationId, entityId }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createFamily = async (
  data: CreateFamilyRequest,
): Promise<CreateFamilyResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().post<CreateFamilyResponse>(
      `${SERVICES.BRANDED_FARES_SERVICE}/fareFamily`,
      data,
    )

    return { confirmationId, entityId }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteFamily = async (
  data: DeleteFamilyRequest,
): Promise<DeleteFamilyResponse> => {
  try {
    const {
      data: { confirmationId },
    } = await API().delete<DeleteFamilyResponse>(
      `${SERVICES.BRANDED_FARES_SERVICE}/fareFamily`,
      { data },
    )

    return {
      confirmationId,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}
