import { toast } from '@atlas-design-system/react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useBrandContext, useCarrierDetailsContext } from 'common/context'
import { editBrand } from 'common/services/brand.service'
import { EditBrandRequest, EditBrandResponse } from 'common/types'

export const useEditBrand = (): UseMutationResult<
  EditBrandResponse,
  Error,
  EditBrandRequest
> => {
  const { selectFamily } = useBrandContext()
  const { reloadCarrierDetails } = useCarrierDetailsContext()

  return useMutation<EditBrandResponse, Error, EditBrandRequest>(
    (data: EditBrandRequest) => editBrand(data),
    {
      onSuccess: ({ confirmationId, entityId: familyId }) => {
        toast.success(
          `Well done! you have successfully completed the brand update. Confirmation id:(${confirmationId})`,
        )
        reloadCarrierDetails()
        selectFamily(familyId)
      },
      onError: ({ message }) => {
        toast.error(`Error: Something went wrong. ${message}`)
      },
    },
  )
}
