import { FormControl, Select } from '@atlas-design-system/react'
import { CABIN_TYPE_SELECT_OPTIONS } from 'common/constants'
import { SelectOption } from 'common/types'
import { useField } from 'formik'
import React, { useCallback } from 'react'

type CabinTypeSelectProps = {
  name: string
  disabled: boolean
}
export const CabinTypeSelect: React.FC<CabinTypeSelectProps> = ({
  disabled,
  ...props
}) => {
  const [field, , helpers] = useField(props)
  const { setValue } = helpers

  const handleChange = useCallback(
    (selectedOption: SelectOption) => {
      setValue(selectedOption)
    },
    [setValue],
  )

  return (
    <>
      <FormControl label="Cabin Type" disabled={disabled}>
        <Select
          next
          {...field}
          {...props}
          data-testid="cabin-type-select"
          className="atls-select-height-360"
          onChange={handleChange}
          options={CABIN_TYPE_SELECT_OPTIONS}
          isDisabled={disabled}
        />
      </FormControl>
    </>
  )
}
