import {
  MandatoryAncillariesFormValue,
  UpdateMandatoryAncillariesRequest,
} from 'common/types'

export const MandatoryAncillaryRequestModel = (
  values: MandatoryAncillariesFormValue,
): UpdateMandatoryAncillariesRequest => {
  const data = values.mandatoryAncillaryList.map((mandatoryAncillary) => {
    const {
      id,
      group,
      subgroup,
      subcode,
      displayOrder,
      exclude,
      mandatory,
      tagName,
    } = mandatoryAncillary

    return {
      id,
      group,
      subgroup: subgroup || null,
      subcode,
      displayOrder,
      exclude,
      mandatory,
      tagName: tagName.trim(),
    }
  })

  return data
}
