import { Modal, Typography } from '@atlas-design-system/react'
import { useBrandContext } from 'common/context'
import React, { useCallback, useMemo } from 'react'

type BrandViewFilingmModalProps = {
  onDismiss: (show: boolean) => void
  hidden: boolean
}

export const BrandViewFilingModal: React.FC<BrandViewFilingmModalProps> = ({
  onDismiss,
  hidden,
}) => {
  const { selectedBrand } = useBrandContext()

  const handleDismiss = useCallback(() => {
    onDismiss(true)
  }, [onDismiss])

  const modalMessage = useMemo(() => {
    return selectedBrand?.brandDescription.textByCategory.ATPCO
  }, [selectedBrand])

  return (
    <Modal
      title={'View Filing'}
      hidden={hidden}
      onDismiss={handleDismiss}
      id="view-filing-modal"
      size="medium"
    >
      <Typography bold> ATPCO Text </Typography>
      <br />
      <br />
      {modalMessage}
    </Modal>
  )
}
