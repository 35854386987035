import { useQuery } from '@tanstack/react-query'
import { EMPTY_SELECT_OPTION } from 'common/constants'
import { toOption } from 'common/helpers'
import { alphabeticalSort } from 'common/helpers/sorter-helpers'
import {
  fetchAirportCodes,
  fetchAncillarydData,
  fetchAreaCodes,
  fetchCityCodes,
  fetchCountryCodes,
  fetchCurrencies,
  fetchEqupmentTypeCodes,
  fetchLanguages,
  fetchZoneCodes,
} from 'common/services/reference-data.service'
import { ApiSubCode, SelectOption } from 'common/types'
import { useMemo } from 'react'

type SubCodeGroup = {
  group: SelectOption<string>
  subgroup: SelectOption<string>
}

export const useReferenceData = (): {
  groupSelectOptions: SelectOption<string>[]
  subGroupSelectOptions: SelectOption<string>[]
  groupSubGroupMap: { [key: string]: string[] }
  groupSubCodeMap: { [key: string]: ApiSubCode[] }
  subCodeGroupMap: { [key: string]: SubCodeGroup }
  ancillaryNameSubcodeMap: { [key: string]: string }
  airportCodesSelectOptions: SelectOption<string>[]
  cityCodesSelectOptions: SelectOption<string>[]
  countryCodesSelectOptions: SelectOption<string>[]
  zoneCodesSelectOptions: SelectOption<string>[]
  areaCodesSelectOptions: SelectOption<string>[]
  languagesSelectOptions: SelectOption<string>[]
  equpmentTypeCodesSelectOptions: SelectOption<string>[]
  currenciesSelectOptions: SelectOption<string>[]
} => {
  const { data, isFetched: isFetchedAncillaryData } = useQuery(
    ['reference-service', 'ancillarydata'],
    () => fetchAncillarydData(),
  )

  const { data: airportCodesData, isFetched: isFetchedAirportCodes } = useQuery(
    ['reference-service', 'airportcodes'],
    () => fetchAirportCodes(),
    {
      enabled: isFetchedAncillaryData,
    },
  )

  const { data: cityCodesData, isFetched: isFetchedCityCodes } = useQuery(
    ['reference-service', 'citycodes'],
    () => fetchCityCodes(),
    {
      enabled: isFetchedAirportCodes,
    },
  )

  const { data: countryCodesData, isFetched: isFetchedCountryCodes } = useQuery(
    ['reference-service', 'countrycodes'],
    () => fetchCountryCodes(),
    {
      enabled: isFetchedCityCodes,
    },
  )

  const { data: zoneCodesData, isFetched: isFetchedZoneCodes } = useQuery(
    ['reference-service', 'zonecodes'],
    () => fetchZoneCodes(),
    {
      enabled: isFetchedCountryCodes,
    },
  )

  const { data: areaCodesData, isFetched: isFetchedAreaCodes } = useQuery(
    ['reference-service', 'areacodes'],
    () => fetchAreaCodes(),
    {
      enabled: isFetchedZoneCodes,
    },
  )

  const { data: languagesData, isFetched: isFetchedLanguages } = useQuery(
    ['reference-service', 'languages'],
    () => fetchLanguages(),
    {
      enabled: isFetchedAreaCodes,
    },
  )

  const { data: equpmentTypeCodesData, isFetched: isFetchedEqupmentTypeCodes } =
    useQuery(
      ['reference-service', 'equpmenttypecodes'],
      () => fetchEqupmentTypeCodes(),
      {
        enabled: isFetchedLanguages,
      },
    )

  const { data: currenciesData } = useQuery(
    ['reference-service', 'currencies'],
    () => fetchCurrencies(),
    {
      enabled: isFetchedEqupmentTypeCodes,
    },
  )

  const {
    groupNameMap,
    subGroupNameMap,
    groupSubGroupMap,
    groupSubCodeMap,
    ancillaryNameSubcodeMap,
  } = useMemo(
    () => ({
      groupNameMap: data?.groupNameMap || {},
      subGroupNameMap: data?.subGroupNameMap || {},
      groupSubGroupMap: data?.groupSubGroupMap || {},
      groupSubCodeMap: data?.groupSubCodeMap || {},
      ancillaryNameSubcodeMap: data?.ancillaryNameSubcodeMap || {},
    }),
    [data],
  )

  const airportCodesSelectOptions = useMemo(() => {
    if (!airportCodesData) {
      return []
    }

    return airportCodesData.map((value) => toOption(value))
  }, [airportCodesData])

  const cityCodesSelectOptions = useMemo(() => {
    if (!cityCodesData) {
      return []
    }

    return cityCodesData.map((value) => toOption(value))
  }, [cityCodesData])

  const countryCodesSelectOptions = useMemo(() => {
    if (!countryCodesData) {
      return []
    }

    return countryCodesData.map((value) => toOption(value))
  }, [countryCodesData])

  const zoneCodesSelectOptions = useMemo(() => {
    if (!zoneCodesData) {
      return []
    }

    return zoneCodesData.map((value) => toOption(value))
  }, [zoneCodesData])

  const areaCodesSelectOptions = useMemo(() => {
    if (!areaCodesData) {
      return []
    }

    return areaCodesData.map((value) => toOption(value))
  }, [areaCodesData])

  const languagesSelectOptions = useMemo(() => {
    if (!languagesData) {
      return []
    }

    return languagesData.map((language) => ({
      value: language.languageCode,
      label: language.language,
    }))
  }, [languagesData])

  const equpmentTypeCodesSelectOptions = useMemo(() => {
    if (!equpmentTypeCodesData) {
      return []
    }

    return equpmentTypeCodesData.map((value) => toOption(value))
  }, [equpmentTypeCodesData])

  const currenciesSelectOptions = useMemo(() => {
    if (!currenciesData) {
      return []
    }

    return currenciesData.map((value) => toOption(value))
  }, [currenciesData])

  const groupSelectOptions = useMemo(() => {
    const items = Object.keys(groupNameMap).map((value) => ({
      value,
      label: `${value}-${groupNameMap[value]}`,
    }))

    const sortedItems = alphabeticalSort(items, 'label')
    sortedItems.push(EMPTY_SELECT_OPTION)

    return sortedItems
  }, [groupNameMap])

  const subGroupSelectOptions = useMemo(() => {
    const items = Object.keys(subGroupNameMap).map((value) => ({
      value,
      label: `${subGroupNameMap[value]}`,
    }))

    return alphabeticalSort(items, 'label')
  }, [subGroupNameMap])

  const subCodeGroupMap = useMemo(() => {
    const asArray = Object.entries(groupSubCodeMap)
    return asArray.reduce<{ [key: string]: SubCodeGroup }>(
      (map, [key, subCodeList]) => {
        const group =
          groupSelectOptions.find(({ value }) => value === key.slice(0, 2)) ||
          EMPTY_SELECT_OPTION
        const subgroup =
          subGroupSelectOptions.find(({ label }) => label === key.slice(2)) ||
          EMPTY_SELECT_OPTION
        subCodeList.forEach((subCode) => {
          map[subCode.subCodevalue] = { group, subgroup }
        })
        return map
      },
      {},
    )
  }, [groupSubCodeMap, groupSelectOptions, subGroupSelectOptions])

  return {
    groupSelectOptions,
    subGroupSelectOptions,
    groupSubGroupMap: {
      ...groupSubGroupMap,
      ['BG-Baggage']: [
        ...(groupSubGroupMap['BG-Baggage'] || []),
        'Checked Baggage',
      ],
    },
    groupSubCodeMap,
    subCodeGroupMap,
    ancillaryNameSubcodeMap,
    airportCodesSelectOptions,
    cityCodesSelectOptions,
    countryCodesSelectOptions,
    zoneCodesSelectOptions,
    areaCodesSelectOptions,
    languagesSelectOptions,
    equpmentTypeCodesSelectOptions,
    currenciesSelectOptions,
  }
}
