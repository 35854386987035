import { AppSidebar } from 'common/components'
import { FamilySelect } from 'common/components/family-select/family-select.component'
import { useMarketingUpsellContext } from 'common/context/marketing-upsell'
import React, { useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { BrandsList } from './components'
import styles from './marketing-upsell-sidebar.module.css'

export const MarketingUpsellSidebar: React.FC = () => {
  const { selectFamily, selectedFamily } = useMarketingUpsellContext()

  const handleFamilySelect = useCallback(
    (id: string) => {
      selectFamily(id)
    },
    [selectFamily],
  )

  return (
    <AppSidebar>
      <div className={styles.familySelect}>
        <FamilySelect
          onFamilySelect={handleFamilySelect}
          selectedFamilyId={selectedFamily?.id}
          label={'Families'}
          placeholder={'Select a family'}
          className={'mmp-ui-form-control'}
          hideAtpcoNumber={true}
        />
      </div>
      {selectedFamily && (
        <DndProvider backend={HTML5Backend}>
          <BrandsList />
        </DndProvider>
      )}
    </AppSidebar>
  )
}
