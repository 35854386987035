import { Dialog } from '@atlas-design-system/react'
import { FamilySelect } from 'common/components'
import { MESSAGE_BRAND_COPY_CONFIRM } from 'common/constants'
import { useBrandContext } from 'common/context'
import { PendingAction } from 'common/enums'
import React, { useCallback, useMemo, useState } from 'react'

import { GetBrandCopyModalButtons } from './components/brand-copy-modal-buttons'

export const BrandCopyModal: React.FC = () => {
  const {
    pendingAction,
    selectedFamily,
    selectedBrand,
    setCopyMode,
    selectFamily,
    selectBrand,
  } = useBrandContext()

  const [selectMode, setSelectMode] = useState(false)
  const [selectedFamilyId, setSelectedFamilyId] = useState<string | undefined>(
    undefined,
  )

  const modalHidden = useMemo(() => {
    if (!pendingAction) {
      return true
    }
    const pendingCopyMode = pendingAction === PendingAction.SET_BRAND_COPY_MODE
    return !pendingCopyMode
  }, [pendingAction])

  const handleDismiss = useCallback(() => {
    if (!selectMode) {
      setSelectMode(true)
    } else if (selectedFamilyId) {
      setCopyMode(selectedFamilyId)
    }
  }, [selectMode, selectedFamilyId, setCopyMode])

  const handleConfirm = useCallback(() => {
    if (!selectedFamily) {
      return
    }
    setCopyMode(selectedFamily.id)
  }, [selectedFamily, setCopyMode])

  const handleClose = useCallback(() => {
    setCopyMode(undefined)
    selectFamily(selectedFamily?.id || '')
    selectBrand(selectedBrand?.brandId || '')
  }, [selectBrand, selectedBrand, setCopyMode, selectFamily, selectedFamily])

  const modalMessage = useMemo(
    () => !selectMode && MESSAGE_BRAND_COPY_CONFIRM,
    [selectMode],
  )

  const dismissText = useMemo(() => (selectMode ? 'Save' : 'No'), [selectMode])

  const confirmText = useMemo(() => (selectMode ? 'Save' : 'Yes'), [selectMode])

  const handleFamilySelect = useCallback((familyId: string) => {
    setSelectedFamilyId(familyId)
  }, [])

  const afterClose = useCallback(() => {
    setSelectMode(false)
    setSelectedFamilyId(undefined)
  }, [])

  return (
    <Dialog
      title="Copy Brand"
      hidden={modalHidden}
      onDismiss={handleDismiss}
      onConfirm={!selectMode ? handleConfirm : undefined}
      id="brand-copy-dialog"
      dismissText={dismissText}
      confirmText={confirmText}
      afterClose={afterClose}
      buttons={GetBrandCopyModalButtons(
        selectMode,
        handleDismiss,
        handleConfirm,
        handleClose,
        confirmText,
        dismissText,
      )}
    >
      {modalMessage}
      {selectMode && (
        <FamilySelect
          label="Select Family for new Brand:"
          excludeId={selectedFamily?.id}
          onFamilySelect={handleFamilySelect}
          selectedFamilyId={selectedFamilyId}
        />
      )}
    </Dialog>
  )
}
