import { MandatoryAncillariesFormValue } from 'common/types'
import { FormikConfig } from 'formik'
import * as Yup from 'yup'

const regexp = /^[a-zA-Z0-9- ]+$/
const SubGroupRegexp = /^$|^[a-zA-Z0-9]{2}$/
const SubCodeRegexp = /^$|^[a-zA-Z0-9]{3}$/

const validGroupCodeSchema = (
  value: string,
  groupSubGroupMap: { [key: string]: string[] },
): boolean =>
  Object.keys(groupSubGroupMap).some(
    (key: string) => key.substring(0, 2) === value,
  )

const mandatoryAncillarySchema = (groupSubGroupMap: {
  [key: string]: string[]
}) =>
  Yup.object({
    group: Yup.string()
      .required('Required')
      .test('active-group-code', 'Invalid Group Code', (value = '') =>
        validGroupCodeSchema(value, groupSubGroupMap),
      ),
    subgroup: Yup.string()
      .trim()
      .matches(SubGroupRegexp, 'Invalid Sub Group')
      .when('group', (group, yup) => {
        if (!!group && validGroupCodeSchema(group, groupSubGroupMap)) {
          return yup.test(
            'valid-sub-group',
            'Invalid Sub Group for Group',
            (value = '') => {
              if (!value) {
                return true
              }
              const keyValue =
                Object.keys(groupSubGroupMap).find(
                  (key) => key.substring(0, 2) === group,
                ) || ''
              return groupSubGroupMap[keyValue].some(
                (key: string) => key.substring(0, 2) === value,
              )
            },
          )
        } else {
          return yup
        }
      }),
    displayOrder: Yup.number()
      .required('Required')
      .min(1, 'Minimum value 1')
      .max(999, 'Maximum value 999'),
    exclude: Yup.bool(),
    mandatory: Yup.bool(),
    subcode: Yup.string().trim().matches(SubCodeRegexp, 'Invalid Sub Code'),
    tagName: Yup.string()
      .trim()
      .required('Required')
      .matches(regexp, 'Invalid Tag'),
  })

export const validationSchema: FormikConfig<MandatoryAncillariesFormValue>['validationSchema'] =
  (groupSubGroupMap: { [key: string]: string[] }) =>
    Yup.object({
      mandatoryAncillaryList: Yup.array(
        mandatoryAncillarySchema(groupSubGroupMap),
      ),
    })
