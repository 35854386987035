import {
  DIRECTION_TITLE_MAP,
  GEOGRAPHY_POINT_INITIAL_VALUE,
} from 'common/constants'
import { LocationType, PermissionLabel } from 'common/enums'
import { toOption } from 'common/helpers'
import { GeographyPoint, GeographyPointValue } from 'common/types'

export const GeographyListFormModel = (
  geographyList: GeographyPoint[],
): GeographyPointValue[] => {
  if (geographyList.length === 0) {
    return [GEOGRAPHY_POINT_INITIAL_VALUE]
  }

  return geographyList.map((geographyPoint) => {
    const {
      code1 = '',
      code2 = '',
      direction,
      location1 = LocationType.EMPTY,
      location2 = LocationType.EMPTY,
      permitted,
    } = geographyPoint

    return {
      code1: toOption(code1.trim()),
      code2: toOption(code2.trim()),
      direction: {
        label: DIRECTION_TITLE_MAP[direction],
        value: direction,
      },
      location1: {
        label: location1,
        value: location1,
      },
      location2: {
        label: location2,
        value: location2,
      },
      permitted: {
        label: permitted
          ? PermissionLabel.PERMITTED
          : PermissionLabel.NOT_PERMITTED,
        value: permitted,
      },
    }
  })
}
