import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS, SERVICES } from 'common/api'
import { fetchSupplierImages } from 'common/services/cdn.service'
import { GalleryItem } from 'common/types'

export const useGallery = (
  CDNTravelSupplierId: string,
): { gallery: GalleryItem[]; isLoading: boolean; isError: boolean } => {
  const {
    data: gallery,
    isLoading,
    isError,
  } = useQuery(
    [
      SERVICES.CDN_TOKEN_SERVICE,
      QUERY_KEYS.SUPPLIERS,
      CDNTravelSupplierId,
      QUERY_KEYS.IMAGES,
    ],
    () => fetchSupplierImages(CDNTravelSupplierId),
  )

  return { gallery: gallery || [], isLoading, isError }
}
