import { useFamilyContext } from 'common/context'
import { FamilyFormValue } from 'common/types'
import { FormikProps } from 'formik'
import { useCallback, useState } from 'react'

type UseFamilyFormProps = {
  formik: FormikProps<FamilyFormValue>
}
export const useFamilyForm = ({
  formik,
}: UseFamilyFormProps): {
  cancelDialogVisible: boolean
  hideCancelDialog: () => void
  showCancelDialog: () => void
  onFormReset: () => void
} => {
  const { selectedFamily, resetSelectedFamily } = useFamilyContext()

  const [cancelDialogVisible, setCancelDialogVisible] = useState(false)

  const showCancelDialog = useCallback(() => {
    setCancelDialogVisible(true)
  }, [])

  const hideCancelDialog = useCallback(() => {
    setCancelDialogVisible(false)
  }, [])

  const handleFormReset = useCallback(() => {
    if (selectedFamily) {
      resetSelectedFamily()
    } else {
      formik.resetForm()
    }

    if (cancelDialogVisible) {
      hideCancelDialog()
    }
  }, [
    resetSelectedFamily,
    selectedFamily,
    hideCancelDialog,
    formik,
    cancelDialogVisible,
  ])

  return {
    cancelDialogVisible,
    hideCancelDialog,
    showCancelDialog,
    onFormReset: handleFormReset,
  }
}
