import { API, SERVICES } from 'common/api'
import {
  CreateAncillaryRequest,
  CreateAncillaryResponse,
  DeleteAncillaryRequest,
  DeleteAncillaryResponse,
  EditAncillaryRequest,
  EditAncillaryResponse,
} from 'common/types'

export const editAncillary = async (
  data: Partial<EditAncillaryRequest>,
): Promise<EditAncillaryResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().put<EditAncillaryResponse>(
      `${SERVICES.BRANDED_FARES_SERVICE}/ancillary`,
      data,
    )

    return { confirmationId, entityId }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createAncillary = async (
  data: CreateAncillaryRequest,
): Promise<CreateAncillaryResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().post<CreateAncillaryResponse>(
      `${SERVICES.BRANDED_FARES_SERVICE}/ancillary`,
      data,
    )

    return { confirmationId, entityId }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteAncillary = async (
  data: DeleteAncillaryRequest,
): Promise<DeleteAncillaryResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().delete<DeleteAncillaryResponse>(
      `${SERVICES.BRANDED_FARES_SERVICE}/ancillary`,
      { data },
    )

    return {
      confirmationId,
      entityId,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}
