import {
  ActionIconGroup,
  AddIcon,
  Banner,
  Button,
  ButtonGroup,
  CloseIcon,
  Form,
  Spinner,
} from '@atlas-design-system/react'
import { RowSelectionState } from '@tanstack/react-table'
import { CollapsibleFormBlock } from 'common/components'
import { generateId } from 'common/helpers'
import { ShelfFormValue, ShelfValue, TagValue } from 'common/types'
import { FieldArray, FormikErrors, FormikProvider, useFormik } from 'formik'
import React, { useCallback, useMemo } from 'react'

import { ShelfTable } from './shelf-table/shelf-table.component'

const createShelfInitialValue = (items: ShelfValue[]): ShelfValue => {
  if (items.length === 0) {
    return {
      id: '',
      name: '',
      tags: [],
      shouldDelete: false,
    }
  }

  const source = items[0]

  return {
    id: generateId(),
    name: `${items.length + 1}`,
    tags: source.tags,
    shouldDelete: false,
  }
}

const isNotActive = (tag: TagValue) => tag.isActive === false

const checkTagsEqual = (tags1: TagValue[], tags2: TagValue[]) => {
  return tags1.every((tag1) => {
    return tags2.some((tag2) => {
      return tag1.name === tag2.name && tag1.isActive === tag2.isActive
    })
  })
}

const checkAllTagsUnique = (items: ShelfValue[]) => {
  return items.every((shelf, index, otherItems) => {
    const tags = shelf.tags

    const otherShelves = otherItems.filter(({ id }) => id !== shelf.id)

    return otherShelves.every((shelf2) => {
      return !checkTagsEqual(tags, shelf2.tags)
    })
  })
}

const validate = (values: ShelfFormValue): FormikErrors<ShelfFormValue> => {
  const errors: FormikErrors<ShelfFormValue> = {}

  const shelvesWithoutTags = values.items.filter(({ tags }) =>
    tags.every(isNotActive),
  )

  const allTagsUnique = checkAllTagsUnique(values.items)

  if (shelvesWithoutTags.length > 0) {
    errors.items = 'Warning: Atleast One Tag should be Selected in Each Shelf'
    return errors
  }

  if (!allTagsUnique) {
    errors.items = 'Warning: Items should not have same tags selected'
    console.log('errors', errors)
    return errors
  }

  return errors
}

type ShelfFormProps = {
  initialValues: ShelfFormValue
  onSubmit: (values: ShelfFormValue) => void
  hasWritePermission: boolean
  submitInProgress: boolean
}

export const ShelfForm: React.FC<ShelfFormProps> = ({
  initialValues,
  onSubmit,
  hasWritePermission,
  submitInProgress,
}) => {
  const handleSubmit = useCallback(
    (formValues: ShelfFormValue) => {
      onSubmit(formValues)
    },
    [onSubmit],
  )

  const formik = useFormik<ShelfFormValue>({
    initialValues,
    validate,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  })

  const { values, setFieldValue, errors } = formik

  const { items } = values

  const canDelete = useMemo(() => items.length > 1, [items])

  const defaultValue = useMemo(
    (): ShelfValue => createShelfInitialValue(items),
    [items],
  )

  const handleRowSelectionChange = useCallback(
    (newRowSelection: RowSelectionState, index: number) => {
      const shelf = values.items[index]
      const currentSelectedTags = shelf.tags

      if (shelf.shouldDelete) {
        return
      }

      setFieldValue(
        `items.${index}.tags`,
        currentSelectedTags.map(({ id, name }) => ({
          id,
          name,
          isActive: !!newRowSelection[name],
        })),
      )
    },
    [setFieldValue, values],
  )

  const handleDeleteClick = useCallback(
    (index: number) => {
      setFieldValue(`items.${index}.shouldDelete`, true)
    },
    [setFieldValue],
  )

  if (submitInProgress) {
    return <Spinner />
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} autoComplete="off">
          {errors.items && (
            <Banner
              appearance="error"
              heading={errors.items as string}
              style={{ marginBottom: 'var(--spaceL)' }}
            />
          )}

          <FieldArray name="items">
            {({ push }) => (
              <>
                {items.map((item, index) => (
                  <div
                    className="row"
                    key={index}
                    style={{
                      marginBottom: 'var(--spaceM)',
                      padding: 'var(--spaceM)',
                      backgroundColor: item.shouldDelete
                        ? 'var(--contrastColor30)'
                        : '',
                    }}
                  >
                    <div className="col-xs-10">
                      <CollapsibleFormBlock title={`SHELF ${item.name}`}>
                        <ShelfTable
                          item={item}
                          index={index}
                          onRowSelectionChange={handleRowSelectionChange}
                        />
                      </CollapsibleFormBlock>
                    </div>
                    <div className="col-xs-2">
                      <ActionIconGroup
                        actions={[
                          {
                            icon: <AddIcon />,
                            key: 'add',
                            tooltip: 'Add Shelf',
                            action: () =>
                              !item.shouldDelete && push(defaultValue),
                          },
                          {
                            icon: canDelete && (
                              <CloseIcon fill="var('--primaryA300')" />
                            ),
                            key: 'remove',
                            tooltip: 'Delete Shelf',
                            action: () =>
                              canDelete &&
                              !item.shouldDelete &&
                              handleDeleteClick(index),
                          },
                        ]}
                        data-testid={`shelf-action-buttons-${index}`}
                        style={{
                          height: '100%',
                          alignItems: 'center',
                        }}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </FieldArray>

          <ButtonGroup align="right">
            <Button
              appearance="primary"
              type="submit"
              disabled={!hasWritePermission}
            >
              Save
            </Button>
          </ButtonGroup>
        </Form>
      </FormikProvider>
    </>
  )
}
