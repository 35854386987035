import { Spinner } from '@atlas-design-system/react'
import { AppSidebar } from 'common/components/layout/sidebar/sidebar.component'
import { useCarrierDetailsContext } from 'common/context'
import React from 'react'

import { AncillariesList } from './components'
import { AncillaryFormWrapper } from './components/ancillary-form-wrapper/ancillary-form-wrapper.component'

export const Ancillaries: React.FC = () => {
  const { isFetching } = useCarrierDetailsContext()

  if (isFetching) {
    return <Spinner />
  }

  return (
    <div data-testid="ancillaries-page">
      <div className="row">
        <div className="col-xs-8">
          <AncillaryFormWrapper />
        </div>
        <div className="col-xs-4">
          <AppSidebar>
            <AncillariesList />
          </AppSidebar>
        </div>
      </div>
    </div>
  )
}
