import { FormControl } from '@atlas-design-system/react'
import { DatePicker } from 'common/components/date-picker/date-picker.component'
import { DATE_FORMAT } from 'common/constants'
import { format } from 'date-fns'
import { FieldProps } from 'formik'
import React from 'react'

import { CustomFormControl } from '../custom-form-control/custom-form-control.component'

type FormInputProps = React.ComponentProps<typeof DatePicker> &
  Pick<React.ComponentProps<typeof FormControl>, 'hint'> &
  FieldProps

export const FormDatePicker: React.FC<FormInputProps> = ({
  label = '',
  field: { name, value },
  form: { getFieldMeta, setFieldValue },
  hint,
  disabled = false,
  required = false,
  error: customError,
  disabledDate = () => true,
}) => {
  const { touched, error } = getFieldMeta(name)

  const fieldError = touched && error ? error : ''

  const handleDateSelected = (dateValue?: Date) => {
    setFieldValue(name, dateValue ? format(dateValue, DATE_FORMAT) : '')
  }

  return (
    <CustomFormControl
      label={label}
      error={customError || fieldError}
      hint={hint}
      disabled={disabled}
      required={required}
    >
      <DatePicker
        label={label}
        disabled={disabled}
        required={required}
        error={customError || fieldError}
        value={value}
        disabledDate={disabledDate}
        onDateSelected={handleDateSelected}
      />
    </CustomFormControl>
  )
}
