import { FieldArrayActions, FormInput } from 'common/components'
import { FARE_RULE_INITIAL_VALUE } from 'common/constants'
import { Field, FieldArrayRenderProps } from 'formik'
import React, { useMemo } from 'react'

type FareRuleRowProps = {
  index: number
  canDelete: boolean
  fieldArrayProps: FieldArrayRenderProps
  namePath: string
  disabled: boolean
}
export const FareRuleRow: React.FC<FareRuleRowProps> = ({
  index,
  namePath,
  fieldArrayProps,
  canDelete,
  disabled,
}) => {
  const { insert, remove } = fieldArrayProps

  const fieldPrefix = useMemo(() => `${namePath}.${index}`, [namePath, index])

  return (
    <div className="row">
      <div className="col-xs-2">
        <Field
          component={FormInput}
          label="Tariff"
          data-testid="fare-rule-list-tariff"
          maxLength="3"
          minLength="1"
          name={`${fieldPrefix}.tariff`}
          disabled={disabled}
        />
      </div>
      <div className="col-xs-2">
        <Field
          component={FormInput}
          data-testid="fare-rule-list-rule"
          disabled={disabled}
          label="Rule"
          maxLength="4"
          minLength="1"
          name={`${fieldPrefix}.rule`}
        />
      </div>
      <div className="col-xs-2">
        <Field
          component={FormInput}
          data-testid="fare-rule-list-fbc"
          disabled={disabled}
          label="FBC"
          maxLength="8"
          minLength="1"
          name={`${fieldPrefix}.fareBasisCode`}
        />
      </div>
      <div className="col-xs-2">
        <Field
          component={FormInput}
          data-testid="fare-rule-list-rbd"
          disabled={disabled}
          label="RBD"
          maxLength="2"
          minLength="1"
          name={`${fieldPrefix}.rbd`}
        />
      </div>
      <div className="col-xs-2">
        <Field
          component={FormInput}
          data-testid="fare-rule-list-ftc"
          disabled={disabled}
          label="FTC"
          maxLength="3"
          minLength="1"
          name={`${fieldPrefix}.fareTypeCode`}
        />
      </div>
      <div className="col-xs-2">
        {!disabled && (
          <FieldArrayActions
            addTooltip="Add Matching Data"
            canDelete={canDelete}
            index={index}
            insert={insert}
            remove={remove}
            removeTooltip="Remove Matching Data"
            initialValueShape={FARE_RULE_INITIAL_VALUE}
          />
        )}
      </div>
    </div>
  )
}
