import { toast } from '@atlas-design-system/react'
import { TextareaField } from 'common/components'
import { BrandRuleFieldInputType } from 'common/enums'
import { MetaFareRuleValue } from 'common/types'
import { useField, useFormikContext } from 'formik'
import React, { ChangeEvent, KeyboardEvent, useCallback, useMemo } from 'react'

import {
  pickBrandRuleError,
  pickBrandRuleRegEx,
  validateCodes,
} from '../../../../validators'

const LABEL_MAP: Record<BrandRuleFieldInputType, string> = {
  [BrandRuleFieldInputType.RBD]: 'Current Record Booked Data',
  [BrandRuleFieldInputType.FTC]: 'Current Fare Type Code',
  [BrandRuleFieldInputType.FBC]: 'Current Fare Basis Code',
  [BrandRuleFieldInputType.EMPTY]: '`',
}

type FareRuleDynamicFieldProps = {
  name: string
  brandRuleFieldInputType: BrandRuleFieldInputType
}

export const FareRuleDynamicField: React.FC<FareRuleDynamicFieldProps> = ({
  name,
  brandRuleFieldInputType,
}) => {
  const [field, meta, helpers] =
    useField<MetaFareRuleValue['dynamicField']>(name)

  const { value } = field
  const { error } = meta
  const { setValue } = helpers

  const { submitCount } = useFormikContext()

  const disabled = useMemo(
    () => brandRuleFieldInputType === BrandRuleFieldInputType.EMPTY,
    [brandRuleFieldInputType],
  )

  const dynamicFieldError = submitCount > 0 && error ? error : ''

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const dynamicFieldValue = event.target.value
      setValue(dynamicFieldValue, submitCount > 0)
    },
    [setValue, submitCount],
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        const items = value.split('•')
        const testRegExError = pickBrandRuleError(brandRuleFieldInputType)

        if (items.length === 1 && items[0] === '') {
          toast.error(testRegExError)
        } else {
          const testRegEx = pickBrandRuleRegEx(brandRuleFieldInputType)
          const allValid = validateCodes(value, testRegEx)

          if (allValid) {
            const newValue = items.join('•') + ' • '
            setValue(newValue, false)
          } else {
            toast.error(testRegExError)
          }
        }

        event.preventDefault()
      }
    },
    [value, setValue, brandRuleFieldInputType],
  )

  return (
    <TextareaField
      label={LABEL_MAP[brandRuleFieldInputType]}
      name={name}
      onChange={handleChange}
      value={value}
      disabled={disabled}
      textareaProps={{ rows: 3, onKeyDown: handleKeyDown }}
      // onBlur={onBlur}
      errors={dynamicFieldError}
    />
  )
}
