import { PendingAction } from 'common/enums'

export enum CarrierDetailsActionType {
  SET_DIRTY_FORM = 'SET_DIRTY_FORM',
  SET_ARCHIVE_MODE = 'SET_ARCHIVE_MODE',
  SET_SELECTED_CARRIER_CODE = 'SET_SELECTED_CARRIER_CODE',
  RESET_SELECTED_CARRIER = 'RESET_SELECTED_CARRIER',
  SET_PENDING_ACTION = 'SET_PENDING_ACTION',
  SET_PENDING_SELECT = 'SET_PENDING_SELECT',
  SWITCH_SELECTED_CARRIER = 'SWITCH_SELECTED_CARRIER',
}

export type SetPendingSelectAction = {
  type: CarrierDetailsActionType.SET_PENDING_SELECT
  payload: {
    id?: string
  }
}

export type SwitchSelectedCarrierAction = {
  type: CarrierDetailsActionType.SWITCH_SELECTED_CARRIER
  payload: {
    id: string
    pendingAction?: PendingAction
  }
}

export type SetPendingAction = {
  type: CarrierDetailsActionType.SET_PENDING_ACTION
  payload: {
    pendingAction?: PendingAction
  }
}

export type SetDirtyFormAction = {
  type: CarrierDetailsActionType.SET_DIRTY_FORM
  payload: {
    isDirty: boolean
  }
}

export type SetArchiveModeAction = {
  type: CarrierDetailsActionType.SET_ARCHIVE_MODE
  payload: {
    archiveMode: boolean
  }
}

export type SetSelectedCarrierCodeAction = {
  type: CarrierDetailsActionType.SET_SELECTED_CARRIER_CODE
  payload: {
    selectedCarrierCode?: string
  }
}

export type ResetSelectedCarrierAction = {
  type: CarrierDetailsActionType.RESET_SELECTED_CARRIER
  payload?: object
}

export type CarrierDetailsAction =
  | SetDirtyFormAction
  | SetArchiveModeAction
  | SetSelectedCarrierCodeAction
  | ResetSelectedCarrierAction
  | SetPendingAction
  | SetPendingSelectAction
  | SwitchSelectedCarrierAction

export type CarrierDetailsState = {
  dirtyForm: boolean
  archiveMode: boolean
  selectedCarrierCode?: string
  pendingAction?: PendingAction
  pendingSelectedCarrierId?: string
}

export const INITIAL_STATE: CarrierDetailsState = {
  dirtyForm: false,
  archiveMode: false,
  selectedCarrierCode: undefined,
  pendingAction: undefined,
  pendingSelectedCarrierId: undefined,
}

export const carrierDetailsReducer = (
  state: CarrierDetailsState,
  action: CarrierDetailsAction,
): CarrierDetailsState => {
  const { type, payload } = action

  switch (type) {
    case CarrierDetailsActionType.SET_DIRTY_FORM:
      return {
        ...state,
        dirtyForm: payload.isDirty,
      }

    case CarrierDetailsActionType.SET_ARCHIVE_MODE:
      return {
        ...state,
        archiveMode: payload.archiveMode,
      }
    case CarrierDetailsActionType.SET_SELECTED_CARRIER_CODE:
      return {
        ...state,
        selectedCarrierCode: payload.selectedCarrierCode,
      }

    case CarrierDetailsActionType.RESET_SELECTED_CARRIER:
      return INITIAL_STATE

    case CarrierDetailsActionType.SET_PENDING_ACTION:
      return {
        ...state,
        pendingAction: payload.pendingAction,
      }

    case CarrierDetailsActionType.SET_PENDING_SELECT:
      return {
        ...state,
        pendingSelectedCarrierId: payload.id,
      }

    case CarrierDetailsActionType.SWITCH_SELECTED_CARRIER:
      return {
        ...state,
        pendingSelectedCarrierId: undefined,
        selectedCarrierCode: payload.id,
        pendingAction: payload.pendingAction,
      }

    default:
      return state
  }
}
