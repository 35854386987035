import { Tooltip } from '@atlas-design-system/react'
import { GlobeIcon } from 'common/icons'

export const AtpcoIcon: React.FC = () => {
  return (
    <>
      {
        <Tooltip content="ATPCO">
          <GlobeIcon
            data-testid="atpco-icon"
            width="16"
            style={{ fill: '#368CF5', outline: 'none' }}
          />
        </Tooltip>
      }
    </>
  )
}
