import { toast } from '@atlas-design-system/react'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { QUERY_KEYS, SERVICES } from 'common/api'
import { deleteSupplierImage } from 'common/services/cdn.service'
import { GalleryItem } from 'common/types'

export const useDeleteImage = (
  supplierId: string,
): UseMutationResult<GalleryItem, unknown, string> => {
  const queryClient = useQueryClient()

  return useMutation<GalleryItem, unknown, string>(
    (imageId) => deleteSupplierImage(supplierId, imageId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          SERVICES.CDN_TOKEN_SERVICE,
          QUERY_KEYS.SUPPLIERS,
          supplierId,
          QUERY_KEYS.IMAGES,
        ])
        toast.success('Image Deleted')
      },
    },
  )
}
