import { PRICE_RANGE_INITIAL_VALUE } from 'common/constants'
import {
  OptionalServicesByCategoryValue,
  PriceRangeFormValue,
  PriceRangeValue,
} from 'common/types'
import { useCallback, useState } from 'react'

type UseChargeableModalReturnType = {
  hidden: boolean
  closeModal: () => void
  openModal: (
    serviceId: string,
    priceRangeList: PriceRangeValue[],
    name: keyof OptionalServicesByCategoryValue,
  ) => void
  initialValues: PriceRangeFormValue
  selectedServiceId?: string
  namePath?: keyof OptionalServicesByCategoryValue
}

export const useChargeableModal = (): UseChargeableModalReturnType => {
  const [hidden, setHidden] = useState(true)
  const [selectedServiceId, setSelectedServiceId] = useState<
    string | undefined
  >(undefined)
  const [namePath, setNamePath] = useState<
    keyof OptionalServicesByCategoryValue | undefined
  >(undefined)
  const [initialValues, setInitialValues] = useState<PriceRangeFormValue>({
    priceRangeList: [PRICE_RANGE_INITIAL_VALUE],
  })

  const openModal = useCallback(
    (
      serviceId: string,
      priceRangeList: PriceRangeValue[],
      name: keyof OptionalServicesByCategoryValue,
    ) => {
      setHidden(false)
      setSelectedServiceId(serviceId)
      setNamePath(name)
      setInitialValues({
        priceRangeList:
          priceRangeList.length > 0
            ? priceRangeList
            : [PRICE_RANGE_INITIAL_VALUE],
      })
    },
    [],
  )

  const closeModal = useCallback(() => {
    setHidden(true)
    setSelectedServiceId(undefined)
    setNamePath(undefined)
    setInitialValues({
      priceRangeList: [PRICE_RANGE_INITIAL_VALUE],
    })
  }, [])

  return {
    hidden,
    closeModal,
    openModal,
    initialValues,
    selectedServiceId,
    namePath,
  }
}
