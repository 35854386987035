import { API, SERVICES } from 'common/api'
import { CarrierModel } from 'common/models/ui/carrier.model'
import { CarrierDetailsModel } from 'common/models/ui/carrier-details.model'
import {
  ApiCarrier,
  ApiCarrierDetails,
  Carrier,
  CarrierDetails,
  CarrierResponse,
  FetchCarrierDetailsRequest,
} from 'common/types'

export const fetchCarrierDetails = async (
  carrierData: FetchCarrierDetailsRequest,
): Promise<CarrierDetails> => {
  try {
    const { data } = await API().post<ApiCarrierDetails>(
      `${SERVICES.BRANDED_FARES_SERVICE}/carrierDetails`,
      carrierData,
    )

    return CarrierDetailsModel(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const fetchCarriers = async (): Promise<Carrier[]> => {
  try {
    const { data = [] } = await API().get<ApiCarrier[]>(
      `${SERVICES.PROVISIONING_CARRIER_SERVICE}/carriers`,
    )
    return data.map(CarrierModel)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateCarrier = async (
  carrierDetails: ApiCarrier,
): Promise<CarrierResponse> => {
  try {
    const { data } = await API().put<CarrierResponse>(
      `${SERVICES.PROVISIONING_CARRIER_SERVICE}/carriers`,
      carrierDetails,
    )

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const addCarrier = async (
  carrierDetails: ApiCarrier,
): Promise<CarrierResponse> => {
  try {
    const { data } = await API().post<CarrierResponse>(
      `${SERVICES.PROVISIONING_CARRIER_SERVICE}/carriers`,
      carrierDetails,
    )

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
