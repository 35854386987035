import { ApiMandatoryAncillary } from 'common/types'
import { MandatoryAncillary } from 'common/types'

export const MandatoryAncillaryModel = (
  mandatoryAncillary: ApiMandatoryAncillary,
): MandatoryAncillary => {
  const {
    displayOrder,
    exclude,
    group,
    id,
    mandatory,
    subcode = '',
    subgroup = '',
    tagName = '',
  } = mandatoryAncillary

  return {
    displayOrder,
    exclude,
    group,
    id,
    mandatory,
    subcode: (subcode && subcode.trim()) || '',
    subgroup: (subgroup && subgroup.trim()) || '',
    tagName,
  }
}
