import { CabinType, ServiceSource } from 'common/enums'
import { isValidBrandOptionalService } from 'common/helpers'
import { GeographyListRequestModel } from 'common/models'
import {
  ApiPriceRange,
  BrandDetails,
  BrandFormRequestShape,
  BrandOptionalServiceRequestShape,
  FamilyFormRequestShape,
  FamilyFormValue,
  PointOfSaleRequestShape,
  PointOfSaleValue,
  PriceRange,
  UncreatedMandatoryOptionalServiceRequestShape,
} from 'common/types'

const AccountTypeCodeListRequestModel = (
  accountTypeCodeList: string[],
): string[] =>
  accountTypeCodeList
    .map((item) => item.trim())
    .filter((item) => item.length > 0)

export const PointOfSaleListRequestModel = (
  pointOfSaleList: PointOfSaleValue[],
): PointOfSaleRequestShape[] => {
  return pointOfSaleList.map(
    ({ iataNumber, gds, pcc, locationType, locationCode, permitted }) => {
      return {
        iataNumber: iataNumber || null,
        gds: gds.value || null,
        pcc: pcc || null,
        locationType: locationType.value || null,
        locationCode: locationCode.value || null,
        permitted: permitted.value ? 'Y' : 'N',
      }
    },
  )
}
type FamilyFormModelConfid = {
  copyMode?: boolean
  source?: ServiceSource
  brandList?: BrandDetails[]
}

const PriceRangeRequestModel = ({
  defaultIndicator,
  start,
  end,
  currencyCode,
}: ApiPriceRange): PriceRange => ({
  defaultIndicator: defaultIndicator,
  start: !!start ? start : undefined,
  end: !!end ? end : undefined,
  currencyCode: currencyCode,
})

const FamilyBrandOptionalServiceListModel = (
  serviceList: (
    | BrandOptionalServiceRequestShape
    | UncreatedMandatoryOptionalServiceRequestShape
  )[],
): BrandOptionalServiceRequestShape[] => {
  if (!serviceList || serviceList.length === 0) {
    return []
  }

  const normalServices: BrandOptionalServiceRequestShape[] = []

  serviceList.forEach((service) => {
    if (isValidBrandOptionalService(service)) {
      normalServices.push({
        ...service,
        priceRangeList: service.priceRangeList?.map((range) =>
          PriceRangeRequestModel(range),
        ),
      })
    }
  })
  return normalServices
}

const BrandListRequestModel = (
  brandList: BrandDetails[] | undefined,
): BrandFormRequestShape[] => {
  const list: BrandFormRequestShape[] = []

  if (!brandList) {
    return list
  }

  brandList.forEach((brand) => {
    const {
      brandDescription,
      excludingData,
      matchingData,
      optionalServiceList,
      sequenceNumber,
    } = brand

    const reqBrand: BrandFormRequestShape = {
      brandDescription: brandDescription,
      matchingData: matchingData,
      excludingData: excludingData,
      optionalServiceList:
        FamilyBrandOptionalServiceListModel(optionalServiceList),
      sequenceNumber,
    }

    if (reqBrand.excludingData?.cabin === CabinType.EMPTY) {
      reqBrand.excludingData.cabin = undefined
    }
    if (reqBrand.matchingData?.cabin === CabinType.EMPTY) {
      reqBrand.matchingData.cabin = undefined
    }
    reqBrand.brandId = undefined
    list.push(reqBrand)
  })
  return list
}

export const FamilyRequestModel = (
  values: FamilyFormValue,
  config?: FamilyFormModelConfid,
): FamilyFormRequestShape => {
  const {
    id,
    name,
    effectiveTravelDate,
    discontinueTravelDate,
    effectiveSearchDate,
    discontinueSearchDate,
    passengerTypeCode,
    geographyList,
    accountTypeCodeList,
    pointOfSalesList,
  } = values

  const familyRequestModel = {
    id,
    name,
    effectiveTravelDate,
    discontinueTravelDate,
    effectiveSearchDate,
    discontinueSearchDate,
    passengerTypeCode,
    geographyList: GeographyListRequestModel(geographyList),
    brandDetailsList: BrandListRequestModel(config?.brandList),
    accountTypeCodeList: AccountTypeCodeListRequestModel(accountTypeCodeList),
    pointOfSaleList: PointOfSaleListRequestModel(pointOfSalesList),
  }
  if (config?.copyMode) {
    if (config.source === ServiceSource.ATPCO) {
      return {
        ...familyRequestModel,
        id: undefined,
        linkedFareFamilyId: Number(id),
      }
    }
    return {
      ...familyRequestModel,
      id: undefined,
    }
  }

  return familyRequestModel
}
