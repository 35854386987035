import { Table, Tbody, Td, Th, Thead, Tr } from '@atlas-design-system/react'
import { ServiceAvailability } from 'common/enums'
import {
  AncillaryDisplayOrder,
  MarketingUpsellTableColumn,
  MarketingUpsellTableRowGroup,
} from 'common/types'
import React, { Fragment, useMemo } from 'react'
import styled from 'styled-components'

import { AvailabilityStatus } from './column-components'

const StyledTable = styled.div`
  margin-top: var(--spaceXL);
  overflow-x: auto;

  .atls-table-next__td,
  .atls-table-next__th {
    white-space: nowrap;
  }

  .atls-action-icon-group {
    flex-wrap: nowrap;
  }
`

const mapByDisplayOrder = (
  rowGroup: MarketingUpsellTableRowGroup,
  displayOrderList: AncillaryDisplayOrder[],
): MarketingUpsellTableRowGroup[] => [
  {
    name: rowGroup.name,
    rows: displayOrderList.map(
      (order) =>
        rowGroup.rows.find(
          ({ ancillaryId }) => ancillaryId === order.ancillaryId,
        ) || rowGroup.rows[0],
    ),
  },
]

type MarketingUpsellTableProps = {
  data: MarketingUpsellTableRowGroup[]
  columns: MarketingUpsellTableColumn[]
  sortable?: boolean
  displayOrderList?: AncillaryDisplayOrder[]
  setDisplayOrderList?: (displayOrderList: AncillaryDisplayOrder[]) => void
}

export const MarketingUpsellTable: React.FC<MarketingUpsellTableProps> = ({
  data,
  columns,
  sortable = false,
  displayOrderList = [],
}) => {
  const rowGroups: MarketingUpsellTableRowGroup[] = useMemo(
    () => (sortable ? mapByDisplayOrder(data[0], displayOrderList) : data),
    [sortable, displayOrderList, data],
  )

  return (
    <StyledTable>
      <Table data-testid="marketing-upsell-table" next>
        <Thead />
        <Tbody>
          {rowGroups.map(({ name: rowGroupName, rows }, index) => (
            <Fragment key={index}>
              <Tr>
                <Th>{rowGroupName}</Th>
                {columns.map((brand) => (
                  <Th key={brand.id}>{brand.name}</Th>
                ))}
              </Tr>
              {rows.map(({ name, brandDetails }, rowIndex) => {
                const sortedStatusList = columns.map(
                  (brand) =>
                    brandDetails.find((detail) => detail.brandId === brand.id)
                      ?.serviceAvailability,
                )

                return (
                  <Tr key={rowIndex}>
                    <Td>{name}</Td>
                    {sortedStatusList.map((status, i) => (
                      <Td key={i}>
                        <AvailabilityStatus
                          status={status || ServiceAvailability.NOT_OFFERED}
                        />
                      </Td>
                    ))}
                  </Tr>
                )
              })}
            </Fragment>
          ))}
        </Tbody>
      </Table>
    </StyledTable>
  )
}
