import { ApiFamilyGroup } from 'common/types'
import { FamilyGroup } from 'common/types'

import { FamilyModel } from './family.model'

export const FamilyGroupModel = (familyGroup: ApiFamilyGroup): FamilyGroup => {
  const { familyGroupName = '', familyList = [] } = familyGroup

  return {
    familyGroupName,
    familyList: familyList.map(FamilyModel),
  }
}
