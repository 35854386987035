import { PendingAction } from 'common/enums'
import { OptionalService } from 'common/types'
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'

import { useCarrierDetailsContext } from '../carrier-details/carrier-details.context'
import {
  AncillaryActionType,
  ancillaryReducer,
  INITIAL_STATE,
} from './ancillary.reducer'

export type AncillaryContextType = {
  copyMode: boolean
  onCreateAncillarySuccess: (id: string) => void
  onDeleteAncillarySuccess: (id: string) => void
  onUpdateAncillarySuccess: (id: string) => void
  pendingAction?: PendingAction
  resetSelectedAncillary: () => void
  selectAncillary: (id: string) => void
  selectPendingAncillary: (id?: string) => void
  selectedAncillary?: OptionalService
  setCopyMode: (active: boolean) => void
  setPendingAction: (pendingAction?: PendingAction) => void
  switchSelectedAncillary: () => void
}

type AncillaryContextProviderProps = {
  children?: ReactNode
}

export const AncillaryContext = createContext<AncillaryContextType>({
  copyMode: false,
  onCreateAncillarySuccess: () => ({}),
  onDeleteAncillarySuccess: () => ({}),
  onUpdateAncillarySuccess: () => ({}),
  pendingAction: undefined,
  resetSelectedAncillary: () => {},
  selectAncillary: () => {},
  selectPendingAncillary: () => ({}),
  selectedAncillary: undefined,
  setCopyMode: () => ({}),
  setPendingAction: () => ({}),
  switchSelectedAncillary: () => ({}),
})

export const useAncillaryContext = (): AncillaryContextType =>
  useContext(AncillaryContext)

export const AncillaryContextProvider: FC<AncillaryContextProviderProps> = ({
  children,
}) => {
  const { data } = useCarrierDetailsContext()
  const [state, dispatch] = useReducer(ancillaryReducer, INITIAL_STATE)

  const { optionalServiceList = [] } = data || {}

  const {
    selectedAncillaryId,
    pendingAction,
    copyMode,
    pendingSelectedAncillaryId,
  } = state

  const selectedAncillary = useMemo(() => {
    if (!selectedAncillaryId || optionalServiceList.length === 0)
      return undefined

    return optionalServiceList.find(({ key }) => {
      return key === selectedAncillaryId
    })
  }, [selectedAncillaryId, optionalServiceList])

  const resetSelectedAncillary = useCallback(() => {
    dispatch({
      type: AncillaryActionType.SET_SELECTED,
      payload: {
        id: undefined,
      },
    })
  }, [])

  const selectAncillary = (id: string) => {
    dispatch({
      type: AncillaryActionType.SET_SELECTED,
      payload: {
        id,
      },
    })
  }

  const onCreateAncillarySuccess = (id: string) => {
    dispatch({
      type: AncillaryActionType.CREATE_SUCCESS,
      payload: {
        id,
      },
    })
  }

  const onUpdateAncillarySuccess = (id: string) => {
    dispatch({
      type: AncillaryActionType.UPDATE_SUCCESS,
      payload: {
        id,
      },
    })
  }

  const onDeleteAncillarySuccess = (id: string) => {
    dispatch({
      type: AncillaryActionType.DELETE_SUCCESS,
      payload: {
        id,
      },
    })
  }

  const setCopyMode = (active: boolean) => {
    dispatch({
      type: AncillaryActionType.SET_COPY_MODE,
      payload: {
        active,
      },
    })
  }

  const setPendingAction = (pendingActionType?: PendingAction) => {
    dispatch({
      type: AncillaryActionType.SET_PENDING_ACTION,
      payload: {
        pendingAction: pendingActionType,
      },
    })
  }

  const selectPendingAncillary = (id?: string) => {
    dispatch({
      type: AncillaryActionType.SET_PENDING_SELECTED,
      payload: {
        id,
      },
    })
  }

  const switchSelectedAncillary = () => {
    if (!pendingSelectedAncillaryId) {
      return
    }

    dispatch({
      type: AncillaryActionType.SWITCH_SELECTED_ANCILLARY,
      payload: {
        id: pendingSelectedAncillaryId,
      },
    })
  }

  return (
    <AncillaryContext.Provider
      value={{
        copyMode,
        onCreateAncillarySuccess,
        onDeleteAncillarySuccess,
        onUpdateAncillarySuccess,
        pendingAction,
        resetSelectedAncillary,
        selectAncillary,
        selectPendingAncillary,
        selectedAncillary,
        setCopyMode,
        setPendingAction,
        switchSelectedAncillary,
      }}
    >
      {children}
    </AncillaryContext.Provider>
  )
}
