import { CarrierFormValue } from 'common/types'
import { FormikProps } from 'formik'
import { useCallback, useState } from 'react'

type UseCarrierFormProps = {
  formik: FormikProps<CarrierFormValue>
  hasWritePermissions: boolean
}

export const useCarrierForm = ({
  formik,
}: UseCarrierFormProps): {
  cancelDialogVisible: boolean
  hideCancelDialog: () => void
  showCancelDialog: () => void
  onFormReset: () => void
} => {
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false)

  const showCancelDialog = useCallback(() => {
    setCancelDialogVisible(true)
  }, [])

  const hideCancelDialog = useCallback(() => {
    setCancelDialogVisible(false)
  }, [])

  const handleFormReset = useCallback(() => {
    formik.resetForm()

    if (cancelDialogVisible) {
      hideCancelDialog()
    }
  }, [cancelDialogVisible, formik, hideCancelDialog])

  return {
    cancelDialogVisible,
    hideCancelDialog,
    showCancelDialog,
    onFormReset: handleFormReset,
  }
}
