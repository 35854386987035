import {
  Checkbox,
  Dialog,
  FormControl,
  Input,
  InputNumber,
} from '@atlas-design-system/react'
import { noop } from 'common/helpers'
import {
  MandatoryAncillariesFormValue,
  MandatoryAncillaryFormValue,
} from 'common/types'
import { FieldArrayRenderProps, FormikErrors, FormikProps } from 'formik'
import { useDeleteMandatoryAncillary } from 'modules/mandatory-ancillaries/hooks'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { ActionButtons } from '../action-buttons/action-buttons.component'
import { EMPTY_MANDATORY_ANCILLARY } from '../mandatory-ancillaries-form/models'

const StyledCol = styled.div`
  flex-basis: 15%;
  max-width: 15%;
`

type MandatoryAncillariesProps = {
  fieldArrayProps: FieldArrayRenderProps
  formik: FormikProps<MandatoryAncillariesFormValue>
  index: number
  item: MandatoryAncillaryFormValue
  disabled: boolean
}

export const MandatoryAncillary: FC<MandatoryAncillariesProps> = (props) => {
  const [showDialog, setDialog] = useState(false)
  const { fieldArrayProps, formik, index, item, disabled } = props
  const { insert, remove } = fieldArrayProps
  const { handleChange, setFieldValue, errors, touched } = formik
  const {
    id,
    group,
    subgroup,
    subcode,
    tagName,
    displayOrder,
    exclude,
    mandatory,
  } = item

  const length = formik.values.mandatoryAncillaryList.length
  const fieldPrefix = `mandatoryAncillaryList.${index}`

  const deleteMutation = useDeleteMandatoryAncillary()

  const handleConfirm = async () => {
    if (id) {
      deleteMutation.mutate(
        { id },
        {
          onSuccess() {
            remove(index)
          },
        },
      )
    } else {
      remove(index)
    }
    setDialog(false)
  }

  const isEmptySubcode = !!subcode

  useEffect(() => {
    const fieldName = isEmptySubcode ? 'mandatory' : 'exclude'
    setFieldValue(`${fieldPrefix}.${fieldName}`, false)
  }, [isEmptySubcode, fieldPrefix, setFieldValue])

  const errorMessage = (fieldName: keyof MandatoryAncillaryFormValue) => {
    const isFieldTouched = touched.mandatoryAncillaryList?.[index]?.[fieldName]
    const mandatoryErrors = errors.mandatoryAncillaryList?.[
      index
    ] as FormikErrors<MandatoryAncillaryFormValue>

    return isFieldTouched && mandatoryErrors?.[fieldName]
  }

  return (
    <>
      <div className="row" data-testid="mandatory-ancillary-row">
        <StyledCol className="col-xs-2">
          <FormControl
            label="Group"
            error={errorMessage('group')}
            disabled={disabled}
          >
            <Input
              name={`${fieldPrefix}.group`}
              value={group}
              onChange={handleChange}
              disabled={disabled}
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </StyledCol>
        <StyledCol className="col-xs-2">
          <FormControl
            label="Subgroup"
            error={errorMessage('subgroup')}
            disabled={disabled}
          >
            <Input
              name={`${fieldPrefix}.subgroup`}
              value={subgroup}
              onChange={handleChange}
              disabled={disabled}
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </StyledCol>
        <StyledCol className="col-xs-2">
          <FormControl
            label="Subcode"
            error={errorMessage('subcode')}
            disabled={disabled}
          >
            <Input
              name={`${fieldPrefix}.subcode`}
              value={subcode}
              onChange={handleChange}
              disabled={disabled}
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </StyledCol>
        <StyledCol className="col-xs-2">
          <FormControl
            label="Tag"
            error={errorMessage('tagName')}
            disabled={disabled}
          >
            <Input
              name={`${fieldPrefix}.tagName`}
              value={tagName}
              onChange={handleChange}
              disabled={disabled}
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </StyledCol>
        <StyledCol className="col-xs-2">
          <FormControl
            label="Display Order"
            error={errorMessage('displayOrder')}
            disabled={disabled}
          >
            <InputNumber
              name={`${fieldPrefix}.displayOrder`}
              value={displayOrder}
              onChange={handleChange}
              min="1"
              max="999"
              disabled={disabled}
            />
          </FormControl>
        </StyledCol>
        <div className="col-xs-1">
          <FormControl label="Default" disabled={disabled || isEmptySubcode}>
            <Checkbox
              name={`${fieldPrefix}.mandatory`}
              label=""
              checked={mandatory}
              onChange={handleChange}
              disabled={disabled || isEmptySubcode}
            />
          </FormControl>
        </div>
        <div className="col-xs-1">
          <FormControl label="Exclude" disabled={disabled || !isEmptySubcode}>
            <Checkbox
              name={`${fieldPrefix}.exclude`}
              label=""
              checked={exclude}
              onChange={handleChange}
              disabled={disabled || !isEmptySubcode}
            />
          </FormControl>
        </div>
        <div className="col-xs-1 end-xs">
          <ActionButtons
            onAdd={() => insert(length, EMPTY_MANDATORY_ANCILLARY)}
            onDelete={() => setDialog(true)}
            disabled={disabled}
          />
        </div>
      </div>

      <Dialog
        appearance="warning"
        id="mandatory-ancillary-delete-dialog"
        onDismiss={() => setDialog(false)}
        onConfirm={handleConfirm}
        hidden={!showDialog}
        title="Delete"
      >
        Warning! Shelves with this selected mandatory ancillary will be deleted
        and have to be manually redefined. Do you want to confirm?
      </Dialog>
    </>
  )
}
