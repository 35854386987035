import {
  MarketingUpsellDetails,
  MarketingUpsellTableDetail,
  MarketingUpsellTableRowGroup,
} from 'common/types'

const OTHER_KEY = 'Other'

const RowGroupModel = ([name, rows]: [
  string,
  MarketingUpsellTableDetail[],
]): MarketingUpsellTableRowGroup => {
  const sortedRows = [...rows].sort((a, b) => (a.name > b.name ? 1 : -1))

  return {
    name,
    rows: sortedRows,
  }
}

const sortByDisplayOrder = ({ name, rows }: MarketingUpsellTableRowGroup) => ({
  name,
  rows: [...rows].sort((a, b) => {
    if (a.displayOrder === undefined || b.displayOrder === undefined) {
      return 0
    }

    return a.displayOrder > b.displayOrder ? 1 : -1
  }),
})

export const MarketingUpsellTableModel = (
  data?: MarketingUpsellDetails,
): {
  mainRowGroup: MarketingUpsellTableRowGroup[]
  sortableRowGroup?: MarketingUpsellTableRowGroup
} => {
  if (!data) {
    return { mainRowGroup: [] }
  }
  const tableList = Object.entries(data.tableDetails)
    .map(RowGroupModel)
    .filter(({ rows }) => rows.length > 0)

  const mainRowGroup = tableList.filter(({ name }) => name !== OTHER_KEY)

  const otherRows = tableList.find(({ name }) => name === OTHER_KEY)
  const sortableRowGroup = otherRows ? sortByDisplayOrder(otherRows) : undefined

  return {
    mainRowGroup,
    sortableRowGroup,
  }
}
