//@ts-nocheck

import { defaultEnvConfig, EnvConfig } from './envConfig'

class EnvConfigWrapper {
  config: EnvConfig = defaultEnvConfig
}

const envConfigUrl = '/config/config.json'

export const envConfig = new EnvConfigWrapper()

export async function fetchEnvConfig<EnvConfig>(): Promise<EnvConfig> {
  const response = await fetch(envConfigUrl)
  return response.json()
}
