import { useAuth, useCarrierDetailsContext } from 'common/context'
import { PendingAction, Permission } from 'common/enums'
import { CarrierRequest } from 'common/types'
import { useCallback } from 'react'

import { useAddUpdateCarrier } from './use-add-update-carrier'

export const useSubmitCarrier = (): {
  submitCarrier: (requestData: CarrierRequest) => void
} => {
  const addOrUpdateMutation = useAddUpdateCarrier()
  const { setPendingAction } = useCarrierDetailsContext()
  const { user, hasPermissionTo } = useAuth()

  const submitCarrier = useCallback(
    (requestData: CarrierRequest) => {
      const { id } = requestData
      if (
        !user ||
        !hasPermissionTo(Permission.UPDATE_CARRIERS) ||
        (!id && !hasPermissionTo(Permission.CREATE_CARRIERS))
      ) {
        return
      }

      addOrUpdateMutation.mutate({
        requestData,
        isNew: !id,
        onSuccess: () => {
          setPendingAction(PendingAction.RESET_FORM)
        },
      })
    },
    [addOrUpdateMutation, hasPermissionTo, setPendingAction, user],
  )

  return { submitCarrier }
}
