import {
  COUNTRY_FORM_INITIAL_VALUE,
  PSEUDO_CITY_FORM_INITIAL_VALUE,
  UPSELL_FORM_INITIAL_VALUE,
} from 'common/constants'
import { isDateStringPresentOrFuture, toOption } from 'common/helpers'
import {
  Carrier,
  CarrierFormValue,
  Country,
  CountryFormValue,
  PseudoCity,
  PseudoCityFormValue,
  SelectOption,
  Upsell,
  UpsellFormValue,
} from 'common/types'

import { DIST_CHANNEL_SELECT_OPTIONS } from '../carrier-form/components/distribution-channel-select/distribution-channel-select.component'

export const EMPTY_CARRIER_FORM_VALUES: CarrierFormValue = {
  code: '',
  countries: [COUNTRY_FORM_INITIAL_VALUE],
  description: '',
  distChannel: { value: '', label: 'All' },
  name: '',
  emailList: [''],
  pseudoCities: [PSEUDO_CITY_FORM_INITIAL_VALUE],
  upsells: [UPSELL_FORM_INITIAL_VALUE],
  url: '',
}

const CountriesFormModel = (countries: Country[]): CountryFormValue[] => {
  if (countries.length === 0) {
    return [COUNTRY_FORM_INITIAL_VALUE]
  }

  return countries.map((country) => {
    return {
      ...country,
      code: toOption(country.code),
    }
  })
}

const PseudoCitiesFormModel = (cities: PseudoCity[]): PseudoCityFormValue[] => {
  // All discontinued PCCs should be filtered out of the list
  const filteredList = cities.filter(isDateStringPresentOrFuture)
  if (filteredList.length === 0) {
    return [PSEUDO_CITY_FORM_INITIAL_VALUE]
  }

  return filteredList.map((city) => {
    return {
      ...city,
      gdsCode: toOption(city.gdsCode),
    }
  })
}

const UpsellFormModel = (upsells: Upsell[]): UpsellFormValue[] => {
  if (upsells.length === 0) {
    return [UPSELL_FORM_INITIAL_VALUE]
  }

  return upsells.map((upsell) => {
    return {
      ...upsell,
      groupCode: toOption(upsell.groupCode),
      indicator: toOption(upsell.indicator),
    }
  })
}

const DistChannelModel = (distChannel?: string): SelectOption<string> => {
  if (!distChannel) {
    return { value: '', label: 'All' }
  }

  return (
    DIST_CHANNEL_SELECT_OPTIONS.find(
      (option) => distChannel === option.value,
    ) || { value: '', label: 'All' }
  )
}

export const CarrierFormModel = (carrier?: Carrier): CarrierFormValue => {
  if (!carrier) {
    return EMPTY_CARRIER_FORM_VALUES
  }

  const {
    code = '',
    countries,
    description = '',
    distChannel = '',
    id,
    name = '',
    pseudoCities,
    upsells,
    url = '',
    notificationMails = [],
    fareFamilyPrecedence,
    typeCode,
  } = carrier

  const emailList = notificationMails.length > 0 ? notificationMails : ['']

  return {
    code,
    countries: CountriesFormModel(countries),
    description,
    distChannel: DistChannelModel(distChannel),
    fareFamilyPrecedence: fareFamilyPrecedence,
    typeCode: typeCode,
    id,
    name,
    pseudoCities: PseudoCitiesFormModel(pseudoCities),
    upsells: UpsellFormModel(upsells),
    url,
    emailList,
  }
}
