import { NotFoundPage, PageLayout } from 'common/components'
import SomethingWentWrongPage from 'common/components/something-went-wrong-page/something-went-wrong-page'
import { Permission, RoutePath } from 'common/enums'
import {
  AgentStoreFront,
  Ancillaries,
  Brands,
  Dashboard,
  Families,
  MandatoryAncillaries,
  MarketingUpsell,
  SegmentBranding,
  Settings,
} from 'modules'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { RequireAccess } from './components'

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/error" element={<SomethingWentWrongPage />} />
      <Route element={<PageLayout />}>
        <Route path="/">
          <Route index element={<Navigate to={RoutePath.DASHBOARD} />} />

          <Route
            path={RoutePath.DASHBOARD}
            element={<RequireAccess permission={Permission.READ_DASHBOARD} />}
          >
            <Route index element={<Dashboard />} />
          </Route>

          <Route
            path={RoutePath.SETTINGS}
            element={<RequireAccess permission={Permission.UPDATE_CARRIERS} />}
          >
            <Route index element={<Settings />} />
          </Route>

          <Route
            path={RoutePath.ANCILLARIES}
            element={<RequireAccess permission={Permission.READ_ANCILLARIES} />}
          >
            <Route index element={<Ancillaries />} />
          </Route>

          <Route
            path={RoutePath.FAMILIES}
            element={
              <RequireAccess permission={Permission.READ_FAREFAMILIES} />
            }
          >
            <Route index element={<Families />} />
          </Route>

          <Route
            path={RoutePath.BRANDS}
            element={<RequireAccess permission={Permission.READ_BRANDS} />}
          >
            <Route index element={<Brands />} />
          </Route>

          <Route
            path={RoutePath.MARKETING_UPSELL}
            element={
              <RequireAccess permission={Permission.READ_MARKETING_UPSELL} />
            }
          >
            <Route index element={<MarketingUpsell />} />
          </Route>

          <Route
            path={RoutePath.SEGMENT_BRANDING}
            element={
              <RequireAccess permission={Permission.READ_SEGMENT_BRANDING} />
            }
          >
            <Route index element={<SegmentBranding />} />
          </Route>

          <Route
            path={RoutePath.MANDATORY_ANCILLARIES}
            element={<RequireAccess />}
          >
            <Route index element={<MandatoryAncillaries />} />
          </Route>

          <Route path={RoutePath.AGENT_STORE_FRONT} element={<RequireAccess />}>
            <Route index element={<AgentStoreFront />} />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
    </Routes>
  )
}
