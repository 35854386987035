import { PendingAction } from 'common/enums'

export enum SegmentBrandActionType {
  SET_SELECTED_SEGMENT_BRAND = 'SET_SELECTED_SEGMENT_BRAND',
  SET_PENDING_ACTION = 'SET_PENDING_ACTION',
  SET_COPY_MODE = 'SET_COPY_MODE',
  SWITCH_SELECTED_SEGMENT_BRAND = 'SWITCH_SELECTED_SEGMENT_BRAND',
  SET_PENDING_SELECTED = 'SET_PENDING_SELECTED',
  CREATE_SUCCESS = 'CREATE_SUCCESS',
  DELETE_SUCCESS = 'DELETE_SUCCESS',
}

export type SetSelectedSegmentBrandAction = {
  type: SegmentBrandActionType.SET_SELECTED_SEGMENT_BRAND
  payload: {
    key?: string
    pendingAction?: PendingAction
  }
}

export type SetPendingAction = {
  type: SegmentBrandActionType.SET_PENDING_ACTION
  payload: {
    pendingAction?: PendingAction
  }
}

export type SetCopyModeAction = {
  type: SegmentBrandActionType.SET_COPY_MODE
  payload: {
    segmentBrandKey?: string
  }
}

export type SetPendingSelectedSegmentBrandAction = {
  type: SegmentBrandActionType.SET_PENDING_SELECTED
  payload: {
    key?: string
  }
}

export type SwitchSelectedSegmentBrandAction = {
  type: SegmentBrandActionType.SWITCH_SELECTED_SEGMENT_BRAND
  payload: {
    key: string
    pendingAction?: PendingAction
  }
}

export type SegmentBrandCreateSuccessAction = {
  type: SegmentBrandActionType.CREATE_SUCCESS
  payload: {
    key: string
  }
}

export type SegmentBrandDeleteSuccessAction = {
  type: SegmentBrandActionType.DELETE_SUCCESS
  payload: {
    key: string
  }
}

export type SegmentBrandingAction =
  | SetSelectedSegmentBrandAction
  | SetPendingAction
  | SetCopyModeAction
  | SetPendingSelectedSegmentBrandAction
  | SwitchSelectedSegmentBrandAction
  | SegmentBrandCreateSuccessAction
  | SegmentBrandDeleteSuccessAction

export type SegmentBrandingState = {
  copyMode: boolean
  parentSelectedBrandRefKey?: string
  pendingAction?: PendingAction
  pendingSelectedBrandKey?: string
  selectedSegmentBrandKey?: string
}

export const INITIAL_SEGMENT_BRAND_STATE: SegmentBrandingState = {
  copyMode: false,
  parentSelectedBrandRefKey: undefined,
  pendingAction: undefined,
  pendingSelectedBrandKey: undefined,
  selectedSegmentBrandKey: undefined,
}

export const segmentBrandReducer = (
  state: SegmentBrandingState,
  action: SegmentBrandingAction,
): SegmentBrandingState => {
  const { type, payload } = action

  switch (type) {
    case SegmentBrandActionType.SET_SELECTED_SEGMENT_BRAND:
      return {
        ...state,
        selectedSegmentBrandKey: payload.key,
        copyMode: false,
        pendingAction: payload.pendingAction,
      }

    case SegmentBrandActionType.SET_PENDING_ACTION:
      return {
        ...state,
        pendingAction: payload.pendingAction,
      }

    case SegmentBrandActionType.SET_COPY_MODE:
      return {
        ...state,
        parentSelectedBrandRefKey: payload.segmentBrandKey,
        pendingAction: undefined,
        selectedSegmentBrandKey: payload.segmentBrandKey,
        copyMode: true,
      }

    case SegmentBrandActionType.SET_PENDING_SELECTED:
      return {
        ...state,
        pendingSelectedBrandKey: payload.key,
      }

    case SegmentBrandActionType.SWITCH_SELECTED_SEGMENT_BRAND: {
      return {
        ...state,
        pendingSelectedBrandKey: undefined,
        selectedSegmentBrandKey: payload.key,
        copyMode: false,
        pendingAction: payload.pendingAction,
      }
    }

    case SegmentBrandActionType.CREATE_SUCCESS:
      return {
        ...state,
        copyMode: false,
        selectedSegmentBrandKey: payload.key,
      }

    case SegmentBrandActionType.DELETE_SUCCESS: {
      return {
        ...state,
        pendingSelectedBrandKey: payload.key,
        pendingAction: PendingAction.REFRESH_FORM_AFTER_FETCH,
        selectedSegmentBrandKey: undefined,
      }
    }

    default:
      return state
  }
}
