import { FormControl, Input } from '@atlas-design-system/react'
import {
  FormDatePicker,
  FormInput,
  ImageLoader,
  MultiLanguage,
  TextareaField,
} from 'common/components'
import { useSegmentBrandingContext } from 'common/context/segment-branding-context'
import { ServiceCategory } from 'common/enums'
import { disabledDateBefore, noop } from 'common/helpers'
import { parseImageDetails } from 'common/helpers/file-upload-helpers'
import { GalleryItem, SegmentBrandingFormValue } from 'common/types'
import { Field, FormikProps } from 'formik'
import { FC, useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'

import { useSegmenBrandingForm } from '../../use-segment-brand-form'

type SegmentBrandingBuilderProps = {
  formik: FormikProps<SegmentBrandingFormValue>
  titleFieldDisabled: boolean
  fieldsDisabled: boolean
}

const textareaProps = { rows: 7 }
const StyledCol = styled.div`
  min-width: 205px;
`

export const SegmentBrandingBuilder: FC<SegmentBrandingBuilderProps> = ({
  formik,
  titleFieldDisabled,
  fieldsDisabled,
}) => {
  const { selectedSegmentBrand } = useSegmentBrandingContext()

  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    formik

  const { isNonUniqueSegmentBrand } = useSegmenBrandingForm({
    formik,
    hasWritePermission: fieldsDisabled,
  })

  const uniqueServiceNameError = useMemo(() => {
    if (isNonUniqueSegmentBrand) {
      return 'Unique commercial name required'
    }
  }, [isNonUniqueSegmentBrand])

  const onRemoveImage = (category: ServiceCategory) => {
    const filteredImageDetails = values.imageDetailsList.filter(
      (image) => image.category !== category,
    )
    setFieldValue('imageDetailsList', filteredImageDetails)
  }

  const onSelectImage = (
    category: ServiceCategory,
    selectedGalleryItem: GalleryItem,
  ) => {
    const imageDetailsList = [...values.imageDetailsList]
    const categoryIndex = imageDetailsList.findIndex(
      (image) => image.category === category,
    )
    const selectedImageDetails = parseImageDetails(
      selectedGalleryItem,
      category,
    )

    if (categoryIndex === -1) imageDetailsList.push(selectedImageDetails)
    else imageDetailsList[categoryIndex] = selectedImageDetails

    setFieldValue('imageDetailsList', imageDetailsList)
  }

  const onSelectImageConsumer = (selectedImageDetails: GalleryItem) =>
    onSelectImage(ServiceCategory.CONSUMER, selectedImageDetails)

  const onSelectImageAgent = (selectedImageDetails: GalleryItem) =>
    onSelectImage(ServiceCategory.AGENT, selectedImageDetails)

  const disabledDateBeforeEffectdiveDate = useCallback(
    (value: Date) => disabledDateBefore(value, values.effectiveDate),
    [values.effectiveDate],
  )

  useEffect(() => {
    if (!!values.effectiveDate && !values.discontinueDate) {
      setFieldValue('discontinueDate', values.effectiveDate)
    }
  }, [setFieldValue, values.effectiveDate, values.discontinueDate])

  return (
    <div>
      <MultiLanguage
        disabled={!selectedSegmentBrand}
        label="Create a new ancillary that is not currently listed in ATPCO."
        modalTitle={`${values.group} / ${values.serviceName}`}
        type="segmentBranding"
        formik={formik}
        fieldsDisabled={fieldsDisabled}
      />
      <div className="row">
        <div className="col-xs-9">
          <FormControl
            label="Commercial Name"
            className="mmp-ui-form-control-required"
            error={
              touched.serviceName &&
              (errors.serviceName || uniqueServiceNameError)
            }
            disabled={titleFieldDisabled}
          >
            <Input
              name="serviceName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.serviceName}
              disabled={titleFieldDisabled}
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-9">
          <Field
            component={FormInput}
            disabled={fieldsDisabled}
            hint="This is the name displayed to the public"
            label="External Name"
            maxLength="45"
            minLength="1"
            name="titleByType.EXTERNAL"
            required
            wrapperProps={{
              onMouseUp: noop,
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-9">
          <Field
            component={FormInput}
            disabled={fieldsDisabled}
            hint="This is the name displayed to the public when there is limited space"
            label="Short External Name"
            maxLength="10"
            minLength="1"
            name="titleByType.SHORT"
            required
            wrapperProps={{
              onMouseUp: noop,
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-9">
          <Field
            component={FormInput}
            disabled={fieldsDisabled}
            hint="Brief Description"
            label="Strap Line"
            maxLength="100"
            minLength="1"
            name="textByCategory.STRAPLINE"
            required
            wrapperProps={{
              onMouseUp: noop,
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <Field
            component={FormDatePicker}
            disabled={fieldsDisabled}
            hint="When the data is active (dd-mmm-yyyy)"
            label="Effective Date"
            name="effectiveDate"
            disabledDate={disabledDateBefore}
            required
          />
        </div>
        <div className="col-xs-6">
          <Field
            component={FormDatePicker}
            disabled={fieldsDisabled}
            hint="When the data is no longer active (dd-mmm-yyyy)"
            label="Discontinue Date"
            name="discontinueDate"
            disabledDate={disabledDateBeforeEffectdiveDate}
            required
          />
        </div>
      </div>
      <div className="row">
        <StyledCol className="col-xs-3">
          <ImageLoader
            image={
              values.imageDetailsList.find(
                ({ category }) => category === ServiceCategory.AGENT,
              )?.cdnSourcePath
            }
            label="Agent Image"
            disabled={fieldsDisabled}
            onRemoveImage={() => onRemoveImage(ServiceCategory.AGENT)}
            onSelectImage={onSelectImageAgent}
            restrictions={{
              width: 60,
              height: 60,
              size: 100,
              attribute: 'ancillary',
            }}
          />
        </StyledCol>
        <div className="col-xs">
          <TextareaField
            label="Marketing Text Agent"
            name="textByCategory.MARKETING_AGENT"
            textareaProps={textareaProps}
            onChange={handleChange}
            value={values.textByCategory.MARKETING_AGENT}
            disabled={fieldsDisabled}
          />
        </div>
      </div>
      <div className="row">
        <StyledCol className="col-xs-3">
          <ImageLoader
            image={
              values.imageDetailsList.find(
                ({ category }) => category === ServiceCategory.CONSUMER,
              )?.cdnSourcePath
            }
            label="Consumer Image"
            disabled={fieldsDisabled}
            onRemoveImage={() => onRemoveImage(ServiceCategory.CONSUMER)}
            onSelectImage={onSelectImageConsumer}
            restrictions={{ attribute: 'ancillary' }}
          />
        </StyledCol>
        <div className="col-xs">
          <TextareaField
            label="Marketing Text Consumer"
            name="textByCategory.MARKETING_CONSUMER"
            onChange={handleChange}
            value={values.textByCategory.MARKETING_CONSUMER}
            disabled={fieldsDisabled}
          />
        </div>
      </div>
    </div>
  )
}
