import { PriceRangeDirection } from 'common/enums'
import { SelectOption } from 'common/types'
import * as Yup from 'yup'

const priceRegex = /^\d{0,8}(\.\d{1,2})?$/

const INVALID_RANGE_MESSAGE = 'Invalid range'
const INVALID_NUMBER_MESSAGE = 'Invalid number'

const priceValueSchema = Yup.number()
  .typeError(INVALID_NUMBER_MESSAGE)
  .min(0, INVALID_NUMBER_MESSAGE)
  .test('is-decimal', INVALID_NUMBER_MESSAGE, (value) => {
    if (!value) {
      return true
    }
    const testValue = value + ''
    return priceRegex.test(testValue)
  })

const startSchema = priceValueSchema
  .when('priceRangeDirection', {
    is: ({ value }: SelectOption<PriceRangeDirection>) =>
      value === PriceRangeDirection.BETWEEN ||
      value === PriceRangeDirection.FROM,
    then: (schema) => schema.required(INVALID_RANGE_MESSAGE),
  })
  .when(['priceRangeDirection', 'end'], {
    is: ({ value }: SelectOption<PriceRangeDirection>) =>
      value === PriceRangeDirection.BETWEEN,
    then: (schema) =>
      schema.test(
        'start-less-or-equal-than-end',
        INVALID_RANGE_MESSAGE,
        function () {
          return this.parent.start <= this.parent.end
        },
      ),
  })

const endSchema = priceValueSchema.when('priceRangeDirection', {
  is: ({ value }: SelectOption<PriceRangeDirection>) =>
    value === PriceRangeDirection.BETWEEN ||
    value === PriceRangeDirection.UP_TO,
  then: (schema) => schema.required(INVALID_RANGE_MESSAGE),
})

const priceRangeSchema = Yup.object().shape({
  start: startSchema,
  end: endSchema,
})

export const priceRangeFormSchema = Yup.object().shape({
  priceRangeList: Yup.array().of(priceRangeSchema),
})
