import { AxiosResponse } from 'axios'
import { API, SERVICES } from 'common/api'
import { AuthUser } from 'common/types'
import { envConfig } from 'config/config'

const isValidResponse = (response: AxiosResponse<AuthUser>) => {
  if (
    !response ||
    !response.data ||
    !response.data.permissions ||
    !response.data.roles
  )
    return false

  return true
}

export const fetchUserDetails = async (): Promise<AuthUser | null> => {
  try {
    // Okta error paths should land on the error handling page
    if (window.location.pathname.includes('/error')) {
      return null
    }
    const response = await API().get<AuthUser>(
      `${SERVICES.AUTH_SERVICE}/user-details`,
    )

    if (!isValidResponse(response)) {
      window.location.assign(
        `${envConfig.config.API_BASE_URL}/${SERVICES.AUTH_SERVICE}/`,
      )
      return null
    }

    return response.data
  } catch (error) {
    window.location.assign(
      `${envConfig.config.API_BASE_URL}/${SERVICES.AUTH_SERVICE}/`,
    )
    return null
  }
}
