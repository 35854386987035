import { ancillariesGroupCodes } from 'common/constants'
import { ServiceSource } from 'common/enums'
import { AncillaryFormValue, ServiceGroup } from 'common/types'
import { OptionalService } from 'common/types'

const SEGMENT_BRAND_GROUP_CODE = 'EQ'

export const checkIsSegmentBrand = (service: OptionalService): boolean =>
  service.group === SEGMENT_BRAND_GROUP_CODE

export const groupServices = (
  services: OptionalService[],
  keyProp: 'source' | 'group',
): ServiceGroup[] => {
  const groups = services.reduce(
    (acc: { [key: string]: OptionalService[] }, item) => {
      if (!acc[item[keyProp]]) {
        acc[item[keyProp]] = [item]
      } else {
        acc[item[keyProp]].push(item)
      }
      return acc
    },
    {},
  )

  return Object.keys(groups).map((groupName) => ({
    title: `${ancillariesGroupCodes[groupName] || groupName} (${
      groups[groupName].length
    })`,
    services: groups[groupName],
  }))
}

//Segment-branding

export const groupSegmentBrandAncillaries = (
  services: OptionalService[],
): ServiceGroup[] => {
  const codeShareGroup = services.filter(
    ({ ancillaryDescription }) => !!ancillaryDescription,
  )
  const cabinGroup = services.filter(
    ({ ancillaryDescription }) => !ancillaryDescription,
  )

  const groups = []

  if (cabinGroup.length > 0) {
    groups.push({
      title: `Cabin and Equipment (${cabinGroup.length})`,
      services: cabinGroup,
    })
  }

  if (codeShareGroup.length > 0) {
    groups.push({
      title: `Codeshare and Interline (${codeShareGroup.length})`,
      services: codeShareGroup,
    })
  }

  return groups
}

//Ancillary-builder helpers

export const isTravelportSource = ({
  source,
}: AncillaryFormValue | OptionalService): boolean => {
  return source === ServiceSource.TRAVELPORT
}

export const isSubCodeOrServiceTypeCode = ({
  subcode,
  serviceTypeCode,
}: AncillaryFormValue): boolean => {
  return subcode || serviceTypeCode ? true : false
}
