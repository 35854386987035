import { Card, Spinner, Typography } from '@atlas-design-system/react'
import { useFamily } from 'common/hooks'
import React from 'react'

import styles from './dashboard-pseudo-city-codes.module.css'

export const PseudoCityCodesArea: React.FC = () => {
  const { gdsDetailsList, nationList, isLoading } = useFamily()

  return (
    <Card className="atls-card dashboard">
      <div className={styles.pseudoCityCodes}>
        <Typography>Testing Pseudo City Codes</Typography>
        <div className={styles.pseudoCityCodesContent}>
          {isLoading ? (
            <Spinner className="pseudo-city-codes" percent={25} size="small" />
          ) : (
            gdsDetailsList.map(function (e, i) {
              return (
                <li
                  key={i}
                  style={{ marginRight: '15px' }}
                >{` ${e.code} - ${e.pcc}  `}</li>
              )
            })
          )}
        </div>
      </div>
      <div className={styles.geographyList}>
        <Typography>Geography List</Typography>
        {nationList.map(function (e, i) {
          return (
            <li key={i} style={{ marginRight: '15px' }}>
              {e}
            </li>
          )
        })}
      </div>
    </Card>
  )
}
