import { FormHeader } from 'common/components/form-header/form-header.component'
import React from 'react'

import { CarrierFormWrapper } from './components/carrier-form-wrapper/carrier-form-wrapper.component'

export const Settings: React.FC = () => {
  return (
    <div data-testid="settings-page">
      <FormHeader title="Administration" variant="displayText4" />
      <CarrierFormWrapper />
    </div>
  )
}
