import { PendingAction } from 'common/enums'

export enum FamilyActionType {
  SET_SELECTED = 'SET_SELECTED',
  SET_PENDING_SELECTED = 'SET_PENDING_SELECTED',
  CREATE_SUCCESS = 'CREATE_SUCCESS',
  UPDATE_SUCCESS = 'UPDATE_SUCCESS',
  DELETE_SUCCESS = 'DELETE_SUCCESS',
  OPEN_PROMOTE_MODAL = 'OPEN_PROMOTE_MODAL',
  CLOSE_PROMOTE_MODAL = 'CLOSE_PROMOTE_MODAL',
  SET_COPY_MODE = 'SET_COPY_MODE',
  SET_PENDING_ACTION = 'SET_PENDING_ACTION',
  SWITCH_SELECTED_FAMILY = 'SWITCH_SELECTED_FAMILY',
}

export type SetSelectedFamilyAction = {
  type: FamilyActionType.SET_SELECTED
  payload: {
    id?: string
    pendingAction?: PendingAction
  }
}

export type FamilyCreateSuccessAction = {
  type: FamilyActionType.CREATE_SUCCESS
  payload: {
    id: string
  }
}

export type FamilyUpdateSuccessAction = {
  type: FamilyActionType.UPDATE_SUCCESS
  payload: {
    id: string
  }
}

export type FamilyDeleteSuccessAction = {
  type: FamilyActionType.DELETE_SUCCESS
  payload?: object
}

export type OpenPromoteModalAction = {
  type: FamilyActionType.OPEN_PROMOTE_MODAL
  payload?: object
}

export type ClosePromoteModalAction = {
  type: FamilyActionType.CLOSE_PROMOTE_MODAL
  payload?: object
}

export type SetCopyModeAction = {
  type: FamilyActionType.SET_COPY_MODE
  payload: {
    active: boolean
  }
}

export type SetPendingAction = {
  type: FamilyActionType.SET_PENDING_ACTION
  payload: {
    pendingAction?: PendingAction
  }
}

export type SetPendingSelectedFamilyAction = {
  type: FamilyActionType.SET_PENDING_SELECTED
  payload: {
    id?: string
  }
}

export type SwitchSelectedFamilyAction = {
  type: FamilyActionType.SWITCH_SELECTED_FAMILY
  payload: {
    id: string
    pendingAction?: PendingAction
  }
}

export type FamilyAction =
  | SetSelectedFamilyAction
  | FamilyCreateSuccessAction
  | OpenPromoteModalAction
  | ClosePromoteModalAction
  | FamilyUpdateSuccessAction
  | SetCopyModeAction
  | SetPendingAction
  | FamilyDeleteSuccessAction
  | SetPendingSelectedFamilyAction
  | SwitchSelectedFamilyAction

export type FamilyState = {
  selectedFamilyId?: string
  pendingSelectedFamilyId?: string
  copyMode: boolean
  isPromoteModalHidden: boolean
  pendingAction?: PendingAction
}

export const familyReducer = (
  state: FamilyState,
  action: FamilyAction,
): FamilyState => {
  const { type, payload } = action

  switch (type) {
    case FamilyActionType.SET_SELECTED:
      return {
        ...state,
        selectedFamilyId: payload.id,
        copyMode: false,
        pendingAction: payload.pendingAction,
      }
    case FamilyActionType.CREATE_SUCCESS:
      return {
        ...state,
        copyMode: false,
        selectedFamilyId: payload.id,
      }
    case FamilyActionType.UPDATE_SUCCESS:
      return {
        ...state,
        pendingAction: undefined,
        selectedFamilyId: payload.id,
      }
    case FamilyActionType.OPEN_PROMOTE_MODAL:
      return {
        ...state,
        isPromoteModalHidden: false,
      }
    case FamilyActionType.CLOSE_PROMOTE_MODAL:
      return {
        ...state,
        isPromoteModalHidden: true,
      }
    case FamilyActionType.SET_COPY_MODE:
      return {
        ...state,
        copyMode: payload.active,
      }

    case FamilyActionType.SET_PENDING_ACTION:
      return {
        ...state,
        pendingAction: payload.pendingAction,
      }

    case FamilyActionType.DELETE_SUCCESS:
      return {
        ...state,
        pendingAction: undefined,
        selectedFamilyId: undefined,
      }

    case FamilyActionType.SET_PENDING_SELECTED:
      return {
        ...state,
        pendingSelectedFamilyId: payload.id,
      }

    case FamilyActionType.SWITCH_SELECTED_FAMILY: {
      return {
        ...state,
        pendingSelectedFamilyId: undefined,
        selectedFamilyId: payload.id,
        copyMode: false,
        pendingAction: payload.pendingAction,
      }
    }

    default:
      return state
  }
}
