import { Dialog } from '@atlas-design-system/react'
import {
  DATE_FORMAT,
  MESSAGE_CONFIRM_FORM_RESET,
  MESSAGE_DELETE_DRAFT_FAMILY,
  MESSAGE_DELETE_LIVE_FAMILY,
  MESSAGE_SAVE_FAMILY_TO_DRAFT,
} from 'common/constants'
import { useFamilyContext } from 'common/context/family-context/family-context'
import { FamilyStatus, PendingAction } from 'common/enums'
import { useDeleteFamily } from 'common/hooks'
import { useAuthUser } from 'common/hooks/use-auth-user'
import { format } from 'date-fns'
import { FC, useCallback, useMemo } from 'react'

// submitForm

type FamilyConfirmModalProps = {
  submitForm: () => void
  resetForm: () => void
}

export const FamilyConfirmModal: FC<FamilyConfirmModalProps> = ({
  submitForm,
  resetForm,
}) => {
  const {
    selectedFamily,
    pendingAction,
    setPendingAction,
    switchSelectedFamily,
    pendingSelectedFamilyId,
  } = useFamilyContext()
  const user = useAuthUser()

  const deleteMutation = useDeleteFamily()

  const modalMessage = useMemo(() => {
    switch (pendingAction) {
      case PendingAction.DELETE: {
        if (selectedFamily?.status === FamilyStatus.DRAFT) {
          return MESSAGE_DELETE_DRAFT_FAMILY
        } else {
          return MESSAGE_DELETE_LIVE_FAMILY
        }
      }
      case PendingAction.NEW_BUTTON_CLICK:
      case PendingAction.SELECT_ANOTHER_FAMILY: {
        return MESSAGE_CONFIRM_FORM_RESET
      }
      case PendingAction.SAVE_FAMILY_TO_DRAFT: {
        return MESSAGE_SAVE_FAMILY_TO_DRAFT
      }
      default: {
        return ''
      }
    }
  }, [pendingAction, selectedFamily])

  const handleDeleteFamily = useCallback(() => {
    if (selectedFamily && user) {
      const { status, id } = selectedFamily
      deleteMutation.mutate({
        id,
        discontinueDate:
          status !== FamilyStatus.DRAFT
            ? format(new Date(), DATE_FORMAT)
            : undefined,
      })
    }
  }, [deleteMutation, selectedFamily, user])

  const confirmFamilySwitch = useCallback(() => {
    const pendingActionType =
      selectedFamily?.id === pendingSelectedFamilyId
        ? PendingAction.RESET_FORM
        : undefined

    switchSelectedFamily(pendingActionType)
  }, [switchSelectedFamily, pendingSelectedFamilyId, selectedFamily])

  const title = useMemo(() => {
    switch (pendingAction) {
      case PendingAction.DELETE:
        return 'Delete Family'
      case PendingAction.SAVE_FAMILY_TO_DRAFT:
        return 'Family Update'

      default:
        return 'Warning'
    }
  }, [pendingAction])

  const modalHidden = useMemo(() => {
    if (pendingAction) {
      return pendingAction === PendingAction.RESET_FORM
    }

    return true
  }, [pendingAction])

  const confirmFormReset = useCallback(() => {
    resetForm()
    setPendingAction()
  }, [resetForm, setPendingAction])

  const handleConfirm = useCallback(() => {
    switch (pendingAction) {
      case PendingAction.DELETE:
        return handleDeleteFamily()

      case PendingAction.SELECT_ANOTHER_FAMILY:
        return confirmFamilySwitch()

      case PendingAction.SAVE_FAMILY_TO_DRAFT:
        return submitForm()
      case PendingAction.NEW_BUTTON_CLICK:
        return confirmFormReset()

      default:
        return setPendingAction()
    }
  }, [
    pendingAction,
    handleDeleteFamily,
    confirmFamilySwitch,
    submitForm,
    setPendingAction,
    confirmFormReset,
  ])

  const handleDismiss = useCallback(() => {
    setPendingAction()
  }, [setPendingAction])

  return (
    <Dialog
      title={title}
      hidden={modalHidden}
      onDismiss={handleDismiss}
      onConfirm={handleConfirm}
      appearance="warning"
      id="family-confirm-dialog"
      dismissText="Cancel"
    >
      {modalMessage}
    </Dialog>
  )
}
