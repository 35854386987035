import { FamilyStatusIcon } from 'common/components'
import { AtpcoIcon } from 'common/components/icons/atpco-icon/atpco.component'
import { isATPCOFamily } from 'common/helpers'
import { FamilySelectOption } from 'common/types'
import React, { useMemo } from 'react'

import styles from './custom-option-label.module.css'

type CustomOptionLabelProps = {
  family: FamilySelectOption
  hideAtpcoNumber: boolean
}

export const CustomOptionLabel: React.FC<CustomOptionLabelProps> = ({
  family,
  hideAtpcoNumber,
}) => {
  const { name, version, status, atpcoSequenceNumber } = family

  const isATPCO = useMemo(() => isATPCOFamily(family), [family])

  const optionName = useMemo(() => {
    const isAtpcoNumber = atpcoSequenceNumber !== undefined && !hideAtpcoNumber
    const atpcoNumber = isATPCO && isAtpcoNumber ? atpcoSequenceNumber : ''
    return `${name} ${atpcoNumber} v${version}`
  }, [name, version, isATPCO, atpcoSequenceNumber, hideAtpcoNumber])

  return (
    <div className={styles.block} title={optionName}>
      <div className={styles.name}>{optionName}</div>
      <div className={styles.icons}>
        {isATPCO && <AtpcoIcon />}
        <FamilyStatusIcon status={status} />
      </div>
    </div>
  )
}
