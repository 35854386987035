import { DATE_FORMAT } from 'common/constants'
import { selectSchema } from 'common/helpers'
import {
  Carrier,
  CarrierFormValue,
  CountryFormValue,
  PseudoCityFormValue,
} from 'common/types'
import { parse } from 'date-fns'
import { FormikConfig } from 'formik'
import * as Yup from 'yup'

export const CARRIER_CODE_UNIQUE_ERROR = 'Code Unavailable'

const carrierCodeSchema = (carrierList: Carrier[]) =>
  Yup.string()
    .matches(/^[A-Z0-9]{2}$/, 'Must be 2 uppercase letters or numbers')
    .required('Required')
    .test(
      'is-unique-carrier-code',
      CARRIER_CODE_UNIQUE_ERROR,
      function checkUnique(value = '') {
        const { id } = this.parent

        if (!!id) {
          return true
        }
        return !carrierList.some(
          ({ code }) =>
            code.toLowerCase().trim() === value.toLowerCase().trim(),
        )
      },
    )

const emailSchema = Yup.string().matches(
  /^\w+([.-]{1}\w+)*[@]{1}\w+([.-]{1}\w+)*([.]{1}\w{2,3}){1}$/,
  'Invalid Email Address',
)

const today = new Date()
today.setHours(0, 0, 0, 0)

const optionalEffectiveDateSchema = Yup.mixed().transform((dateString) =>
  !!dateString || dateString.length === 0
    ? parse(dateString, DATE_FORMAT, new Date())
    : undefined,
)

const optionalDiscontinueDateSchema = optionalEffectiveDateSchema.when(
  'effectiveDate',
  (effectiveDate, yup) =>
    effectiveDate
      ? yup.test(
          'compare-with-effective',
          'Error! The Discontinue Date cannot be before the Effective Date.',
          (date: Date) => (!!date ? date >= effectiveDate : true),
        )
      : yup,
)

const pseudoCitySchema = Yup.object({
  cityCode: Yup.string()
    .matches(/^[0-9A-Za-z]{1,6}$/, 'Enter a valid code')
    .when('gdsCode', (gdsCode, yup) => {
      if (!!gdsCode.value) {
        return yup.required('Required')
      } else {
        return yup
      }
    }),
  effectiveDate: optionalEffectiveDateSchema,
  discontinueDate: optionalDiscontinueDateSchema,
  gdsCode: selectSchema,
}).test(
  'duplicates',
  'Duplicated PCC detected.',
  (value, testContext: Yup.TestContext) => {
    const allPccs: PseudoCityFormValue[] = testContext.parent
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const contextOptions = testContext.options as any
    const indexOfCurrentRow = contextOptions.index
    const allPccsWithoutCurrentRow = allPccs.filter(
      (pcc, index) => index !== indexOfCurrentRow,
    )

    const isDuplicated = allPccsWithoutCurrentRow.some((pcc) => {
      const hasValue = Boolean(pcc.gdsCode.value) && Boolean(pcc.cityCode)

      if (hasValue) {
        return (
          pcc.gdsCode.value === value.gdsCode.value &&
          pcc.cityCode.toUpperCase() === value.cityCode?.toUpperCase()
        )
      }

      return false
    })

    return !isDuplicated
  },
)

const coutrySchema = Yup.object({
  code: selectSchema,
  name: Yup.string().when('code', (code, yup) => {
    if (!!code.value) {
      return yup.required('Required')
    } else {
      return yup
    }
  }),
  effectiveDate: optionalEffectiveDateSchema,
  discontinueDate: optionalDiscontinueDateSchema,
}).test(
  'duplicates',
  'Duplicated country detected.',
  (value, testContext: Yup.TestContext) => {
    const allCounries: CountryFormValue[] = testContext.parent
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const contextOptions = testContext.options as any
    const indexOfCurrentRow = contextOptions.index
    const allCounriesWithoutCurrentRow = allCounries.filter(
      (country, index) => index !== indexOfCurrentRow,
    )

    const isDuplicated = allCounriesWithoutCurrentRow.some((country) => {
      const hasValue = Boolean(country.code.value) && Boolean(country.name)

      if (hasValue) {
        return country.code.value === value.code.value
      }

      return false
    })

    return !isDuplicated
  },
)

const nameSchema = Yup.string()
  .test('empty-check', 'Required', (name) => (name || '').trim().length > 0)
  .required('Required')

export const validationSchema: FormikConfig<CarrierFormValue>['validationSchema'] =
  (carrierList: Carrier[]) =>
    Yup.object({
      code: carrierCodeSchema(carrierList),
      countries: Yup.array().of(coutrySchema),
      description: Yup.string().required('Required'),
      distChannel: selectSchema,
      name: nameSchema,
      emailList: Yup.array().of(emailSchema),
      pseudoCities: Yup.array().of(pseudoCitySchema),
      upsells: Yup.array(),
      url: Yup.string(),
    })
