import { toast } from '@atlas-design-system/react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useCarrierDetailsContext } from 'common/context'
import { useSegmentBrandingContext } from 'common/context/segment-branding-context'
import { createSegmentBrand } from 'common/services/segment-branding.service'
import {
  CreateSegmentBrandRequest,
  CreateSegmentBrandResponse,
  ErrorMessage,
} from 'common/types'

export const useCreateSegmentBranding = (): UseMutationResult<
  CreateSegmentBrandResponse,
  AxiosError<ErrorMessage>,
  CreateSegmentBrandRequest
> => {
  const { reloadCarrierDetails, setArchiveMode } = useCarrierDetailsContext()
  const { onCreateSegmentBrandSuccess } = useSegmentBrandingContext()

  return useMutation<
    CreateSegmentBrandResponse,
    AxiosError<ErrorMessage>,
    CreateSegmentBrandRequest
  >((data: CreateSegmentBrandRequest) => createSegmentBrand(data), {
    onSuccess: ({ confirmationId, entityId: segmentBrandingKey }) => {
      toast.success(
        'Success! Segment has been saved. Confirmation id: ' + confirmationId,
      )

      reloadCarrierDetails()
      setArchiveMode(false)
      onCreateSegmentBrandSuccess(segmentBrandingKey)
    },
    onError: ({ response, message: baseMassage }) => {
      toast.error(`Error: ${response?.data.message || baseMassage}`)
    },
  })
}
