import { useFamily } from 'common/hooks'
import { Family } from 'common/types'
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react'

import {
  INITIAL_STATE,
  MarketingUpsellActionType,
  marketingUpsellReducer,
} from './marketing-upsell-reducer'

export type MarketingUpsellContextType = {
  selectFamily: (id: string) => void
  selectedFamily?: Family
}

export const MarketingUpsellContext = createContext<MarketingUpsellContextType>(
  {
    selectFamily: () => ({}),
    selectedFamily: undefined,
  },
)

export const useMarketingUpsellContext = (): MarketingUpsellContextType =>
  useContext(MarketingUpsellContext)

export const MarketingUpsellContextProvider: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  const [state, dispatch] = useReducer(marketingUpsellReducer, INITIAL_STATE)

  const { familyList } = useFamily()

  const { selectedFamilyId } = state

  const selectedFamily = useMemo(() => {
    if (!selectedFamilyId || familyList.length === 0) {
      return undefined
    }

    return familyList.find(({ id }) => id === selectedFamilyId)
  }, [selectedFamilyId, familyList])

  const selectFamily = (id: string) => {
    dispatch({
      type: MarketingUpsellActionType.SET_SELECTED_FAMILY,
      payload: {
        id,
      },
    })
  }

  return (
    <MarketingUpsellContext.Provider
      value={{
        selectFamily,
        selectedFamily,
      }}
    >
      {children}
    </MarketingUpsellContext.Provider>
  )
}
