import { Radio, Typography } from '@atlas-design-system/react'
import { AtpcoIcon } from 'common/components/icons/atpco-icon/atpco.component'
import { FamilyStatusIcon } from 'common/components/index'
import { useFamilyContext } from 'common/context/family-context/family-context'
import { PendingAction } from 'common/enums'
import { isATPCOFamily } from 'common/helpers'
import { useDirtyForm } from 'common/hooks'
import { Family } from 'common/types'
import React, { useCallback, useMemo } from 'react'

import styles from './family-select-row.module.css'

type FamilySelectRowProps = {
  family: Family
}

export const FamilySelectRow: React.FC<FamilySelectRowProps> = ({ family }) => {
  const {
    name,
    version,
    id,
    effectiveSearchDate,
    discontinueSearchDate,
    status,
    atpcoSequenceNumber,
  } = family

  const {
    selectedFamily,
    selectFamily,
    setPendingAction,
    selectPendingFamily,
    copyMode,
  } = useFamilyContext()
  const { dirtyForm } = useDirtyForm()

  const isChecked = useMemo(() => {
    if (copyMode) {
      return false
    } else {
      return selectedFamily?.id === family.id
    }
  }, [selectedFamily?.id, family.id, copyMode])

  const isATPCO = useMemo(() => isATPCOFamily(family), [family])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedId = event.target.value
      if (dirtyForm) {
        selectPendingFamily(selectedId)
        setPendingAction(PendingAction.SELECT_ANOTHER_FAMILY)
      } else {
        if (copyMode && selectedFamily && selectedFamily.id === selectedId) {
          selectFamily(selectedId, PendingAction.RESET_FORM)
        } else {
          selectFamily(selectedId)
        }
      }
    },
    [
      selectFamily,
      dirtyForm,
      selectPendingFamily,
      setPendingAction,
      copyMode,
      selectedFamily,
    ],
  )

  const familyUiName = useMemo(
    () =>
      `${name} ${atpcoSequenceNumber ? atpcoSequenceNumber : ''} v${version}`,
    [atpcoSequenceNumber, name, version],
  )

  return (
    <>
      <Radio
        name={'families'}
        label={familyUiName}
        onChange={handleChange}
        value={id}
        checked={isChecked}
        data-testid="family-name-label"
      />

      <div className={styles.list}>
        <div className="row middle-xs between-xs">
          <div className="col-xs">
            <Typography data-testid="family-dates" variant="contentText2">
              {effectiveSearchDate} - {discontinueSearchDate};
            </Typography>
          </div>
          <div className={`col-xs ${styles.statusIcon}`}>
            <FamilyStatusIcon status={status} />
          </div>
          <div className="col-xs" style={{ flex: '0 0 auto' }}>
            {isATPCO && <AtpcoIcon />}
          </div>
        </div>
      </div>
    </>
  )
}
