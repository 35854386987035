import {
  ActionIconGroup,
  Button,
  ButtonGroup,
  Dialog,
  FormControl,
  Input,
  TextArea,
  TrashIcon,
} from '@atlas-design-system/react'
import { isFieldRequired, MultiLanguageSchema, noop } from 'common/helpers'
import {
  AncillaryFormValue,
  BrandFormValue,
  MultiLanguageTypes,
  TextType,
} from 'common/types'
import { FormikProps, FormikProvider, useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'

import {
  MultiLanguageFormModel,
  MultiLanguageFormModelType,
} from './multi-language-form.model'

const StyledIcons = styled.div`
  position: absolute;
  right: 10px;
`

type MultiLanguageFormProps = {
  type: MultiLanguageTypes
  selectedLanguage?: string
  isDefault?: boolean
  formik: FormikProps<AncillaryFormValue & BrandFormValue>
  setLanguage: (language?: string) => void
  fieldsDisabled?: boolean
  validationSchema?: Yup.AnyObjectSchema
}

const labelNameMap = {
  ancillary: 'Ancillary',
  brand: 'Brand',
  segmentBranding: 'Ancillary',
}

const textareaProps = { rows: 7 }

export const MultiLanguageForm: FC<MultiLanguageFormProps> = ({
  type,
  selectedLanguage = '',
  isDefault = false,
  formik: parentFormik,
  setLanguage,
  fieldsDisabled,
  validationSchema = MultiLanguageSchema,
}) => {
  const labelName = labelNameMap[type]
  const disabled = fieldsDisabled || isDefault || !selectedLanguage
  const additionalDetailList =
    parentFormik.values.additionalDetailList ||
    parentFormik.values.brandDescription.additionalDetailList ||
    []

  const [isDialog, setDialog] = useState(false)

  const initialValues = MultiLanguageFormModel(
    isDefault,
    parentFormik.values,
    selectedLanguage,
  )

  const languageFormik = useFormik<MultiLanguageFormModelType>({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      // parse form values
      const textTypeList: TextType[] = []
      Object.entries(values).forEach(([key, value]) => {
        if (value) textTypeList.push({ type: key, value })
      })
      // set selected additionalDetail
      const selectedIndex = additionalDetailList.findIndex(
        (detail) => detail.langCode === selectedLanguage,
      )
      const detailListIndex =
        selectedIndex === -1 ? additionalDetailList.length : selectedIndex
      additionalDetailList[detailListIndex] = {
        langCode: selectedLanguage,
        textTypeList,
      }

      const fieldName =
        type === 'brand'
          ? 'brandDescription.additionalDetailList'
          : 'additionalDetailList'

      parentFormik.setFieldValue(fieldName, additionalDetailList)
      setLanguage(undefined)
    },
  })

  const { handleChange, handleSubmit, resetForm, values, errors, touched } =
    languageFormik

  useEffect(resetForm, [selectedLanguage, resetForm])

  const isDeleteIcon =
    !fieldsDisabled &&
    additionalDetailList.some((detail) => detail.langCode === selectedLanguage)

  const handleDelete = () => {
    const filteredAdditionalDetailList = additionalDetailList.filter(
      (detail) => detail.langCode !== selectedLanguage,
    )

    const fieldName =
      type === 'brand'
        ? 'brandDescription.additionalDetailList'
        : 'additionalDetailList'

    parentFormik.setFieldValue(fieldName, filteredAdditionalDetailList)
    setLanguage(undefined)
    setDialog(false)
  }

  return (
    <FormikProvider value={languageFormik}>
      {isDeleteIcon && (
        <StyledIcons>
          <ActionIconGroup
            actions={[
              {
                key: 'key',
                action: () => setDialog(true),
                icon: <TrashIcon data-testid="multi-language-delete-button" />,
                tooltip: 'Delete',
              },
            ]}
          />
        </StyledIcons>
      )}
      <div className="row">
        <div className="col-xs-8">
          <FormControl
            className="mmp-ui-form-control-required"
            label={`External Commercial ${labelName} Name`}
            disabled={disabled}
            error={touched.EXTERNAL_COMMERCIAL && errors.EXTERNAL_COMMERCIAL}
          >
            <Input
              name="EXTERNAL_COMMERCIAL"
              data-testid="multi-language-external-name"
              value={values.EXTERNAL_COMMERCIAL}
              onChange={handleChange}
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-8">
          <FormControl
            className="mmp-ui-form-control-required"
            label={`External Short ${labelName} Name`}
            disabled={disabled}
            error={touched.EXTERNAL_SHORT_NAME && errors.EXTERNAL_SHORT_NAME}
          >
            <Input
              name="EXTERNAL_SHORT_NAME"
              data-testid="multi-language-short-name"
              value={values.EXTERNAL_SHORT_NAME}
              onChange={handleChange}
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-8">
          <FormControl
            className={
              isFieldRequired(validationSchema, 'STRAPLINE')
                ? 'mmp-ui-form-control-required'
                : undefined
            }
            label="Strap Line"
            disabled={disabled}
            error={touched.STRAPLINE && errors.STRAPLINE}
          >
            <Input
              name="STRAPLINE"
              data-testid="multi-language-strapline"
              value={values.STRAPLINE}
              onChange={handleChange}
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </div>
      </div>
      {type === 'brand' && (
        <div className="row">
          <div className="col-xs-12">
            <FormControl label={'Upsell Marketing Text'} disabled={disabled}>
              <TextArea
                textareaProps={textareaProps}
                data-testid="multi-language-upsell-text"
                disabled={disabled}
                name="UPSELL"
                value={values.UPSELL}
                onChange={handleChange}
              />
            </FormControl>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-xs-12">
          <FormControl
            className="mmp-ui-form-control-required"
            label="Agent Marketing Text"
            disabled={disabled}
            error={touched.MARKETING_AGENT && errors.MARKETING_AGENT}
          >
            <TextArea
              textareaProps={textareaProps}
              data-testid="multi-language-agent-text"
              name="MARKETING_AGENT"
              value={values.MARKETING_AGENT}
              onChange={handleChange}
            />
          </FormControl>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <FormControl label="Consumer Marketing Text" disabled={disabled}>
            <TextArea
              textareaProps={textareaProps}
              data-testid="multi-language-consumer-text"
              name="MARKETING_CONSUMER"
              value={values.MARKETING_CONSUMER}
              onChange={handleChange}
            />
          </FormControl>
        </div>
      </div>
      {type !== 'segmentBranding' && (
        <div className="row">
          <div className="col-xs-12">
            <FormControl label={`${labelName} Rules`} disabled={disabled}>
              <TextArea
                textareaProps={textareaProps}
                data-testid="multi-language-rules-text"
                disabled={disabled}
                name="RULE"
                value={values.RULE}
                onChange={handleChange}
              />
            </FormControl>
          </div>
        </div>
      )}
      {!isDefault && (
        <ButtonGroup align="right">
          <Button
            type="submit"
            data-testid="multi-language-submit"
            onClick={handleSubmit}
            disabled={fieldsDisabled || !selectedLanguage}
            appearance="primary"
          >
            Confirm
          </Button>
          <Button
            onClick={() => setLanguage(undefined)}
            disabled={fieldsDisabled || !selectedLanguage}
            appearance="primary"
          >
            Cancel
          </Button>
        </ButtonGroup>
      )}
      {isDialog && (
        <Dialog
          id="multi-language-delete-dialog"
          title="Delete Language"
          onDismiss={() => setDialog(false)}
          hidden={!isDialog}
          dismissText="Cancel"
          confirmText="Ok"
          onConfirm={handleDelete}
        >
          Do you want to delete the language?
        </Dialog>
      )}
    </FormikProvider>
  )
}
