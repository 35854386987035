import {
  Button,
  ButtonGroup,
  FormControl,
  Modal,
  Select,
} from '@atlas-design-system/react'
import { FAMILY_STATUS_SELECT_OPTIONS } from 'common/constants'
import { useFamilyContext } from 'common/context/family-context/family-context'
import { FamilyStatus } from 'common/enums'
import { useAuthUser } from 'common/hooks/use-auth-user'
import { SelectOption, UpdateFamilyStatusRequest } from 'common/types'
import React, { useCallback, useMemo, useState } from 'react'

import { usePromoteFamily } from './use-promote-family'

const pickAvailableStatuses = (
  status: FamilyStatus,
): SelectOption<FamilyStatus>[] =>
  FAMILY_STATUS_SELECT_OPTIONS.filter(({ value }) => {
    if (status === FamilyStatus.DRAFT) {
      return value === FamilyStatus.TEST || value === FamilyStatus.LIVE
    }
    if (status === FamilyStatus.TEST) {
      return value === FamilyStatus.LIVE
    }
    if (status === FamilyStatus.SUPPRESSED) {
      return value === FamilyStatus.LIVE
    }

    return false
  }).map(({ value, label }) => ({
    label:
      status === FamilyStatus.SUPPRESSED && value === FamilyStatus.LIVE
        ? 'Reactivate'
        : label,
    value,
  }))

export const PromoteFamilyModal: React.FC = () => {
  const { isPromoteModalHidden, selectedFamily, closePromoteModal } =
    useFamilyContext()
  const user = useAuthUser()

  const promoteFamilyMutation = usePromoteFamily()

  const [selectedStatus, setSelectedStatus] = useState<FamilyStatus>(() =>
    selectedFamily
      ? pickAvailableStatuses(selectedFamily.status)[0]?.value
      : FamilyStatus.DRAFT,
  )

  const selectOptions = useMemo(() => {
    if (!selectedFamily) {
      return []
    }
    return pickAvailableStatuses(selectedFamily.status)
  }, [selectedFamily])

  const handleConfirmClick = useCallback(() => {
    if (!selectedFamily || !user) {
      return
    }

    const data: UpdateFamilyStatusRequest = {
      status: selectedStatus,
      id: selectedFamily.id,
    }

    promoteFamilyMutation.mutate(data)
  }, [selectedStatus, selectedFamily, user, promoteFamilyMutation])

  const actionButtons = (
    <ButtonGroup align="right">
      <Button
        data-testid="promote-confirm-button"
        appearance="primary"
        onClick={handleConfirmClick}
      >
        Confirm
      </Button>
      <Button data-testid="promote-cancel-button" onClick={closePromoteModal}>
        Cancel
      </Button>
    </ButtonGroup>
  )

  const handleStatusSelect = useCallback(
    (selectedOption: SelectOption<FamilyStatus>) => {
      setSelectedStatus(selectedOption.value)
    },
    [],
  )

  return (
    <>
      <Modal
        id="promote-family-modal"
        title="Promote Family"
        onDismiss={closePromoteModal}
        hidden={isPromoteModalHidden}
        actionButtons={actionButtons}
      >
        <FormControl label="Promote Environment">
          <Select
            data-testid="promote-environment-select"
            next
            options={selectOptions}
            defaultValue={selectOptions[0]}
            placeholder="Select status"
            onChange={handleStatusSelect}
          />
        </FormControl>
      </Modal>
    </>
  )
}
