import {
  GEOGRAPHY_POINT_INITIAL_VALUE,
  POINT_OF_SALE_INITIAL_VALUE,
} from 'common/constants'
import { PermissionLabel, ServiceSource } from 'common/enums'
import { GeographyListFormModel } from 'common/models'
import {
  Family,
  FamilyFormValue,
  PointOfSale,
  PointOfSaleValue,
} from 'common/types'

const EMPTY_FAMILY_FORM_VALUES = {
  name: '',
  effectiveTravelDate: '',
  discontinueTravelDate: '',
  effectiveSearchDate: '',
  discontinueSearchDate: '',
  geographyList: [GEOGRAPHY_POINT_INITIAL_VALUE],
  passengerTypeCode: '',
  accountTypeCodeList: [''],
  pointOfSalesList: [POINT_OF_SALE_INITIAL_VALUE],
  source: ServiceSource.TRAVELPORT,
  meta: {
    copyMode: false,
  },
}
const PointOfSalesListFormModel = (
  pointOfSalesList: PointOfSale[],
): PointOfSaleValue[] =>
  pointOfSalesList.map((pointOfSale) => {
    const { gds = '' } = pointOfSale

    return {
      iataNumber: pointOfSale.iataNumber ? pointOfSale.iataNumber.trim() : '',
      gds: { label: gds, value: gds },
      pcc: pointOfSale.pcc ? pointOfSale.pcc.trim() : '',
      locationType: {
        label: pointOfSale.locationType,
        value: pointOfSale.locationType,
      },
      locationCode: {
        label: pointOfSale.locationCode,
        value: pointOfSale.locationCode ? pointOfSale.locationCode.trim() : '',
      },
      permitted: {
        value: pointOfSale.permitted === 'Y',
        label:
          pointOfSale.permitted === 'Y'
            ? PermissionLabel.PERMITTED
            : PermissionLabel.NOT_PERMITTED,
      },
    }
  })

type FamilyFormModelConfig = {
  copyMode?: boolean
  hybridFamilyCount?: number
}

type FamilyFormModelProps = {
  family?: Family
  config?: FamilyFormModelConfig
}

export const FamilyFormModel = ({
  family,
  config = {},
}: FamilyFormModelProps = {}): FamilyFormValue => {
  if (!family) {
    return EMPTY_FAMILY_FORM_VALUES
  }

  const { copyMode = false, hybridFamilyCount } = config

  const {
    id,
    name,
    effectiveTravelDate,
    discontinueTravelDate,
    effectiveSearchDate,
    discontinueSearchDate,
    geographyList = [],
    passengerTypeCode,
    accountTypeCodeList = [],
    pointOfSaleList: pointOfSalesList = [],
    atpcoSequenceNumber,
  } = family

  const familyFormValue: FamilyFormValue = {
    id,
    name: name ? name.trim() : '',
    effectiveTravelDate,
    discontinueTravelDate,
    effectiveSearchDate,
    discontinueSearchDate,
    geographyList: GeographyListFormModel(geographyList),
    passengerTypeCode: passengerTypeCode ? passengerTypeCode.trim() : '',
    accountTypeCodeList:
      accountTypeCodeList.length === 0
        ? ['']
        : accountTypeCodeList.map((accountTypeCode) => accountTypeCode.trim()),
    pointOfSalesList:
      pointOfSalesList.length > 0
        ? PointOfSalesListFormModel(pointOfSalesList)
        : [POINT_OF_SALE_INITIAL_VALUE],
    meta: {
      copyMode,
    },
  }

  if (copyMode) {
    const atpcoSeqNumber = atpcoSequenceNumber ? ` ${atpcoSequenceNumber}` : ''
    const hybridName = `${name}${atpcoSeqNumber}-H${
      !!hybridFamilyCount ? hybridFamilyCount + 1 : 1
    }`

    if (family.source === ServiceSource.ATPCO) {
      return {
        ...familyFormValue,
        name: hybridName,
      }
    }
    return {
      ...familyFormValue,
      name: '',
    }
  }

  return familyFormValue
}
