import { Button, ButtonGroup } from '@atlas-design-system/react'
import { useAuth } from 'common/context'
import { useMarketingUpsellContext } from 'common/context/marketing-upsell'
import { Permission, ServiceSource } from 'common/enums'
import { useReorderBrands } from 'common/hooks'
import { BrandDetails } from 'common/types'
import update from 'immutability-helper'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDrop } from 'react-dnd'

import {
  BrandCollapsibleCard,
  DRAGGABLE_ITEM_TYPE,
} from './brand-collapsible-card/brand-collapsible-card.component'
import styles from './brands-list.module.css'

type DropItem = {
  item: BrandDetails
  index: number
}

export const BrandsList: React.FC = () => {
  const { selectedFamily, selectFamily } = useMarketingUpsellContext()
  const { saveReorderedBrands } = useReorderBrands()
  const { hasPermissionTo } = useAuth()

  const hasWritePermission = useMemo(
    () => hasPermissionTo(Permission.WRITE_MARKETING_UPSELL),
    [hasPermissionTo],
  )

  const brands = useMemo(() => {
    if (!selectedFamily) {
      return []
    }
    return selectedFamily.brandDetailsList || []
  }, [selectedFamily])

  const [localBrandsList, setLocalBrandsList] = useState<BrandDetails[]>([])

  useEffect(() => {
    setLocalBrandsList(brands)
  }, [brands])

  const isATPCOFamily = useMemo(
    () => selectedFamily?.source === ServiceSource.ATPCO,
    [selectedFamily],
  )

  const findBrand = useCallback(
    (id: string): DropItem => {
      const item = localBrandsList.filter(
        (brand) => `${brand.brandId}` === id,
      )[0]
      return {
        item,
        index: localBrandsList.indexOf(item),
      }
    },
    [localBrandsList],
  )

  const moveBrand = useCallback(
    (id: string, atIndex: number) => {
      if (!hasWritePermission) {
        return
      }

      if (isATPCOFamily) {
        return
      }

      const { item, index } = findBrand(id)
      setLocalBrandsList(
        update(localBrandsList, {
          $splice: [
            [index, 1],
            [atIndex, 0, item],
          ],
        }),
      )
    },
    [hasWritePermission, isATPCOFamily, findBrand, localBrandsList],
  )

  const [, drop] = useDrop(() => ({ accept: DRAGGABLE_ITEM_TYPE }))

  const handleSaveOrder = useCallback(() => {
    if (!selectedFamily) {
      return
    }
    saveReorderedBrands(localBrandsList, selectedFamily.id, true, selectFamily)
  }, [localBrandsList, saveReorderedBrands, selectedFamily, selectFamily])

  return (
    <div className={styles.list}>
      <div ref={drop}>
        {localBrandsList.map((brand) => (
          <BrandCollapsibleCard
            key={brand.brandId}
            brand={brand}
            moveBrand={moveBrand}
            findBrand={findBrand}
          />
        ))}
      </div>
      {localBrandsList.length > 0 && hasWritePermission && (
        <ButtonGroup align="right">
          <Button
            appearance="primary"
            type="button"
            disabled={isATPCOFamily}
            onClick={handleSaveOrder}
          >
            Save
          </Button>
        </ButtonGroup>
      )}
    </div>
  )
}
