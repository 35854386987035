import { toast } from '@atlas-design-system/react'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { QUERY_KEYS, SERVICES } from 'common/api'
import { deleteMandatoryAncillary } from 'common/services/mandatory-ancillaries.service'
import {
  DeleteMandatoryAncillariesRequest,
  ErrorMessage,
  MandatoryAncillariesResponse,
} from 'common/types'

export const useDeleteMandatoryAncillary = (): UseMutationResult<
  MandatoryAncillariesResponse,
  AxiosError<ErrorMessage>,
  DeleteMandatoryAncillariesRequest
> => {
  const queryClient = useQueryClient()

  return useMutation<
    MandatoryAncillariesResponse,
    AxiosError<ErrorMessage>,
    DeleteMandatoryAncillariesRequest
  >((data) => deleteMandatoryAncillary(data), {
    onSuccess: ({ confirmationId }) => {
      toast.success(
        `Success: Ancillary Deleted Successfully. Transaction ID is ${confirmationId}`,
      )
      queryClient.invalidateQueries([
        SERVICES.MANDATORY_ANCILLARY_SERVICE,
        QUERY_KEYS.ANCILLARY,
        QUERY_KEYS.MANDATORY,
      ])
      queryClient.invalidateQueries([QUERY_KEYS.AGENT_STORE_FRONT])
    },
    onError: ({ response, message: baseMassage }) => {
      toast.error(`Error: ${response?.data.message || baseMassage}`)
    },
  })
}
