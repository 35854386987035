import { Collapsible } from '@atlas-design-system/react'
import { useReferenceData } from 'common/hooks/use-reference-data.hook'
import {
  AncillaryFormValue,
  BrandFormValue,
  MultiLanguageTypes,
} from 'common/types'
import { FormikProps } from 'formik'
import { FC, useState } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'

import { MultiLanguageForm } from './multi-language-form.component'
import { MultiLanguageSidebar } from './multi-language-sidebar.component'

const StyledDiv = styled.div`
  position: relative;
`

type MultiLanguageModalContentProps = {
  type: MultiLanguageTypes
  formik: FormikProps<AncillaryFormValue & BrandFormValue>
  fieldsDisabled?: boolean
  validationSchema?: Yup.AnyObjectSchema
}

export const MultiLanguageModalContent: FC<MultiLanguageModalContentProps> = ({
  type,
  formik,
  fieldsDisabled = false,
  validationSchema,
}) => {
  const [selectedLanguage, setLanguage] = useState<string | undefined>(
    undefined,
  )
  const additionalDetailList =
    formik.values.additionalDetailList ||
    formik.values.brandDescription.additionalDetailList ||
    []

  const { languagesSelectOptions } = useReferenceData()

  const selectedLanguageName =
    languagesSelectOptions.find((detail) => detail.value === selectedLanguage)
      ?.label || 'Language'

  return (
    <div className="row">
      <StyledDiv className="col-xs-9">
        <Collapsible header="English (default)" defaultOpened={false}>
          <MultiLanguageForm
            type={type}
            isDefault={true}
            formik={formik}
            setLanguage={setLanguage}
            validationSchema={validationSchema}
          />
        </Collapsible>
        <Collapsible header={selectedLanguageName} defaultOpened={true}>
          <MultiLanguageForm
            type={type}
            selectedLanguage={selectedLanguage}
            formik={formik}
            setLanguage={setLanguage}
            fieldsDisabled={fieldsDisabled}
            validationSchema={validationSchema}
          />
        </Collapsible>
      </StyledDiv>
      <div className="col-xs-3">
        <MultiLanguageSidebar
          setLanguage={setLanguage}
          selectedLanguage={selectedLanguage}
          additionalDetailList={additionalDetailList}
          fieldsDisabled={fieldsDisabled}
        />
      </div>
    </div>
  )
}
