import { Footer, TravelportLogo } from '@atlas-design-system/react'
import { Dialog } from '@atlas-design-system/react'
import React, { useState } from 'react'

const year = new Date().getFullYear()
export const COPYRIGHT = `© ${year} Travelport`
export const AppFooter: React.FC = () => {
  const [showDialog, setShowDialog] = useState(true)

  const accurateVersionNumberPrefix =
    process.env.REACT_APP_VERSION?.slice(
      0,
      process.env.REACT_APP_VERSION.lastIndexOf('.') + 1 || 5,
    ) || '23.x.'
  const incrementedBuildVersionNumber: number =
    parseInt(
      process.env.REACT_APP_VERSION?.slice(
        process.env.REACT_APP_VERSION.lastIndexOf('.') + 1,
      ) || '0',
    ) + 1

  return (
    <Footer
      data-testid="app-footer"
      copyright={COPYRIGHT}
      logo={<TravelportLogo />}
      children={
        <>
          <Footer.Menu title="Links">
            <a
              href="https://www.travelport.com/privacy-websites"
              target="_blank"
            >
              Privacy Policy
            </a>
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowDialog(false)
              }}
            >
              About
            </a>
          </Footer.Menu>
          <Dialog
            id="footer-about-dialog"
            title="About"
            hidden={showDialog}
            appearance="information"
            onDismiss={() => {
              setShowDialog(true)
            }}
          >
            <div>Merchandising Management Portal</div>
            <span>{`version: ${
              accurateVersionNumberPrefix + incrementedBuildVersionNumber
            }`}</span>
          </Dialog>
        </>
      }
    />
  )
}
