import { Typography } from '@atlas-design-system/react'
import { ArchiveButton } from 'common/components/archive-button/archive-button'
import { useAuth } from 'common/context/auth-context'
import { useCarrierDetailsContext } from 'common/context/carrier-details/carrier-details.context'
import { useFamilyContext } from 'common/context/family-context'
import { Permission } from 'common/enums'
import React, { useMemo } from 'react'

import { FamilySidebarBlock, PromoteFamilyModal } from './components'
import styles from './families-list.module.css'

export const FamiliesList: React.FC = () => {
  const { hasPermissionTo } = useAuth()
  const { data, archiveMode } = useCarrierDetailsContext()
  const { isPromoteModalHidden } = useFamilyContext()

  const hasWritePermission = useMemo(
    () => hasPermissionTo(Permission.WRITE_FAREFAMILIES),
    [hasPermissionTo],
  )

  return (
    <>
      <div className={styles.header}>
        <div className="row middle-xs between-xs">
          <div className="col-xs">
            <Typography
              data-testid="families-list-title"
              variant="displayText6"
              bold
            >
              Families
            </Typography>
          </div>
          <ArchiveButton />
        </div>
        <Typography data-testid="families-list-subtitle" variant="contentText2">
          {archiveMode
            ? 'All archived families are displayed here'
            : 'All of the Families you create will be saved here'}
        </Typography>
      </div>

      <ul className={styles.list}>
        {data?.familyGroupList.map((familyGroup) => {
          const { familyGroupName, familyList } = familyGroup

          return (
            <li
              data-testid="families-list-group-block"
              key={familyGroupName}
              className={styles.listItem}
            >
              <FamilySidebarBlock
                title={`${familyGroupName} (${familyList.length})`}
                familyList={familyList}
                hasWritePermission={hasWritePermission}
              />
            </li>
          )
        })}
      </ul>
      {hasWritePermission && !isPromoteModalHidden && (
        <PromoteFamilyModal data-testid="families-promote-modal" />
      )}
    </>
  )
}
