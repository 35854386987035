import { API, QUERY_KEYS, SERVICES } from 'common/api'
import { MandatoryAncillaryModel } from 'common/models/ui/mandatory-ancillary.model'
import {
  ApiMandatoryAncillary,
  DeleteMandatoryAncillariesRequest,
  MandatoryAncillariesResponse,
  MandatoryAncillary,
  UpdateMandatoryAncillariesRequest,
} from 'common/types'

export const fetchMandatoryAncillaries = async (): Promise<
  MandatoryAncillary[]
> => {
  try {
    const { data = [] } = await API().get<ApiMandatoryAncillary[]>(
      `${SERVICES.MANDATORY_ANCILLARY_SERVICE}/${QUERY_KEYS.ANCILLARY}/${QUERY_KEYS.MANDATORY}`,
    )

    return data.map(MandatoryAncillaryModel)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateMandatoryAncillaries = async (
  request: UpdateMandatoryAncillariesRequest,
): Promise<MandatoryAncillariesResponse> => {
  try {
    const { data } = await API().post(
      `${SERVICES.MANDATORY_ANCILLARY_SERVICE}/${QUERY_KEYS.ANCILLARY}/${QUERY_KEYS.MANDATORY}`,
      request,
    )

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteMandatoryAncillary = async (
  request: DeleteMandatoryAncillariesRequest,
): Promise<MandatoryAncillariesResponse> => {
  try {
    const { data } = await API().delete(
      `${SERVICES.MANDATORY_ANCILLARY_SERVICE}/${QUERY_KEYS.ANCILLARY}/${QUERY_KEYS.MANDATORY}`,
      {
        data: request,
      },
    )

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
