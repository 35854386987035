import { useCarrierDetailsContext } from 'common/context'
import { FormikProps } from 'formik'
import { useEffect } from 'react'

export const useWatchForm = <T>(formik: FormikProps<T>): void => {
  const { setDirtyForm } = useCarrierDetailsContext()

  useEffect(() => {
    setDirtyForm(formik.dirty)
  }, [formik.dirty, setDirtyForm])
}
