import {
  Banner,
  Form,
  FormControl,
  Input,
  Radio,
  Select,
} from '@atlas-design-system/react'
import { CurrenciesSelect, FieldArrayActions } from 'common/components'
import {
  PRICE_RANGE_DIRECTION_SELECT_OPTIONS,
  PRICE_RANGE_INITIAL_VALUE,
} from 'common/constants'
import { PriceRangeDirection } from 'common/enums'
import { noop } from 'common/helpers'
import {
  PriceRangeFormValue,
  PriceRangeValue,
  SelectOption,
} from 'common/types'
import {
  FieldArray,
  FormikErrors,
  FormikProps,
  FormikProvider,
  FormikTouched,
  getIn,
} from 'formik'
import React, { useCallback, useMemo } from 'react'

type PriceRangeFormProps = {
  formik: FormikProps<PriceRangeFormValue>
}

export const PriceRangeForm: React.FC<PriceRangeFormProps> = ({ formik }) => {
  const { values, handleBlur, handleChange, errors, touched } = formik
  const { priceRangeList } = values
  const canDelete = useMemo(() => priceRangeList.length > 1, [priceRangeList])

  const handleDefaultIndicatorChange = useCallback(
    (index: number) => {
      formik.setFieldValue(
        'priceRangeList',
        formik.values.priceRangeList.map((row) => ({
          ...row,
          defaultIndicator: false,
        })),
      )
      formik.setFieldValue(`priceRangeList.${index}.defaultIndicator`, true)
    },
    [formik],
  )

  const handlePriceRangeDirectionChange = useCallback(
    (selectedOption: SelectOption<PriceRangeDirection>, index: number) => {
      if (selectedOption.value === PriceRangeDirection.FROM) {
        formik.setFieldValue(`priceRangeList.${index}.end`, '')
      }
      if (selectedOption.value === PriceRangeDirection.UP_TO) {
        formik.setFieldValue(`priceRangeList.${index}.start`, '')
      }

      formik.setFieldValue(
        `priceRangeList.${index}.priceRangeDirection`,
        selectedOption,
      )
    },
    [formik],
  )

  const formError = useMemo(() => {
    const { priceRangeList: priceRangeListErrors = [] } = errors as {
      priceRangeList: FormikErrors<PriceRangeValue>[]
    }

    const { priceRangeList: priceRangeListTouched = [] } = touched as {
      priceRangeList: FormikTouched<PriceRangeValue>[]
    }

    const { start = '', end = '' } =
      priceRangeListErrors.find((field, index) => {
        const someTouched =
          !!priceRangeListTouched[index]?.start ||
          !!priceRangeListTouched[index]?.end

        return !!field && someTouched
      }) || ({} as FormikErrors<PriceRangeValue>)

    return start || end
  }, [errors, touched])

  return (
    <FormikProvider value={formik}>
      <Form
        onSubmit={formik.handleSubmit}
        className="price-range-form"
        autoComplete="off"
      >
        {formError && (
          <Banner
            appearance="error"
            data-testid="price-range-error-banner"
            heading={formError}
            style={{
              marginBottom: 'var(--spaceL)',
            }}
          />
        )}

        <FieldArray name="priceRangeList">
          {({ push, remove }) => {
            return (
              <>
                {priceRangeList.map((item, index) => (
                  <div key={index} className="price-range-form-row">
                    <div key={index} className="row">
                      <div className="col-xs-2">
                        <FormControl label="" style={{ paddingTop: '8px' }}>
                          <Radio
                            label="Default"
                            data-testid="default-radio"
                            name="default-price-range"
                            checked={item.defaultIndicator}
                            value="defaultIndicator"
                            onChange={() => {
                              handleDefaultIndicatorChange(index)
                            }}
                          />
                        </FormControl>
                      </div>
                      <div className="col-xs-2">
                        <FormControl label="">
                          <Select
                            next
                            name={`priceRangeList.${index}.priceRangeDirection`}
                            value={item.priceRangeDirection}
                            options={PRICE_RANGE_DIRECTION_SELECT_OPTIONS}
                            onChange={(selectedOption) => {
                              handlePriceRangeDirectionChange(
                                selectedOption,
                                index,
                              )
                            }}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                      </div>
                      <div className="col-xs-2">
                        <FormControl
                          label=""
                          disabled={
                            item.priceRangeDirection.value ===
                            PriceRangeDirection.UP_TO
                          }
                          error={
                            getIn(touched, `priceRangeList.${index}.start`) &&
                            getIn(errors, `priceRangeList.${index}.start`)
                          }
                        >
                          <Input
                            name={`priceRangeList.${index}.start`}
                            data-testid="start-range-input"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={item.start}
                            disabled={
                              item.priceRangeDirection.value ===
                              PriceRangeDirection.UP_TO
                            }
                            maxLength="8"
                            wrapperProps={{
                              onMouseUp: noop,
                            }}
                          />
                        </FormControl>
                      </div>
                      <div className="col-xs-2">
                        <FormControl
                          label=""
                          disabled={
                            item.priceRangeDirection.value ===
                            PriceRangeDirection.FROM
                          }
                          error={
                            getIn(touched, `priceRangeList.${index}.end`) &&
                            getIn(errors, `priceRangeList.${index}.end`)
                          }
                        >
                          <Input
                            name={`priceRangeList.${index}.end`}
                            data-testid="end-range-input"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={item.end}
                            maxLength="8"
                            disabled={
                              item.priceRangeDirection.value ===
                              PriceRangeDirection.FROM
                            }
                            wrapperProps={{
                              onMouseUp: noop,
                            }}
                          />
                        </FormControl>
                      </div>
                      <div className="col-xs-2">
                        <CurrenciesSelect
                          name={`priceRangeList.${index}.currencyCode`}
                        />
                      </div>

                      <div className="col-xs-2">
                        <FieldArrayActions
                          addTooltip="Add price range"
                          canDelete={canDelete}
                          index={index}
                          push={push}
                          remove={remove}
                          removeTooltip="Remove price range"
                          initialValueShape={PRICE_RANGE_INITIAL_VALUE}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )
          }}
        </FieldArray>
      </Form>
    </FormikProvider>
  )
}
