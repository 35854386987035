import { generateId } from 'common/helpers'
import { Shelf, ShelfFormValue } from 'common/types'

export const ShelfFormModel = (data: Shelf[]): ShelfFormValue => {
  return {
    items: data.map(({ name, tags }) => ({
      id: generateId(),
      name: name + '',
      tags: tags.map((tag) => ({
        ...tag,
        id: generateId(),
      })),
      shouldDelete: false,
    })),
  }
}
