import { toast } from '@atlas-design-system/react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useCarrierDetailsContext } from 'common/context'
import { useFamilyContext } from 'common/context/family-context/family-context'
import { createFamily } from 'common/services'
import { CreateFamilyRequest, CreateFamilyResponse } from 'common/types'

export const useCreateFamily = (): UseMutationResult<
  CreateFamilyResponse,
  Error,
  CreateFamilyRequest
> => {
  const { reloadCarrierDetails } = useCarrierDetailsContext()
  const { onCreateFamilySuccess } = useFamilyContext()

  return useMutation<CreateFamilyResponse, Error, CreateFamilyRequest>(
    (data: CreateFamilyRequest) => createFamily(data),
    {
      onSuccess: ({ confirmationId, entityId: familyId }) => {
        toast.success(
          'Success! Family has been created. Confirmation id: ' +
            confirmationId,
        )
        reloadCarrierDetails()
        onCreateFamilySuccess(familyId)
      },
      onError: ({ message }) => {
        toast.error(`Error: Something went wrong. ${message}`)
      },
    },
  )
}
