import { useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEYS } from 'common/api'
import { PendingAction } from 'common/enums'
import { pickUniqueSipplierCodes } from 'common/helpers'
import { useCarriers } from 'common/hooks'
import { fetchCarrierDetails } from 'common/services'
import { FormResetConfirmConfig } from 'common/types'
import { Carrier, CarrierDetails } from 'common/types'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'

import { useAuth } from '../auth-context'
import {
  CarrierDetailsActionType,
  carrierDetailsReducer,
  INITIAL_STATE,
} from './carrier-details.reducer'

const findCarrier = (carrierList: Carrier[], code: string) =>
  carrierList.find((carrier) => carrier.code === code)

export type CarrierDetailsContextType = {
  selectedCarrier?: Carrier
  data?: CarrierDetails
  archiveMode: boolean
  isLoading: boolean
  isFetching: boolean
  pendingAction?: PendingAction
  pendingSelectedCarrierId?: string
  setPendingAction: (pendingAction?: PendingAction) => void
  selectPendingCarrier: (carrierId?: string) => void
  switchSelectedCarrier: (pendingAction?: PendingAction) => void
  setArchiveMode: (archiveMode: boolean) => void
  selectCarrier: (selectedCarrierCode: string) => void
  resetCarrier: () => void
  reloadCarrierDetails: () => void
  dirtyForm: boolean
  setDirtyForm: (isDirty: boolean) => void
  formResetConfirmConfig?: FormResetConfirmConfig
  setFormResetConfirmConfig: (config?: FormResetConfirmConfig) => void
}

export const CarrierDetailsContext = createContext<CarrierDetailsContextType>({
  selectedCarrier: undefined,
  data: undefined,
  archiveMode: false,
  isLoading: false,
  isFetching: false,
  setArchiveMode: () => ({}),
  selectCarrier: () => ({}),
  resetCarrier: () => ({}),
  reloadCarrierDetails: () => ({}),
  dirtyForm: false,
  setDirtyForm: () => ({}),
  formResetConfirmConfig: undefined,
  setFormResetConfirmConfig: () => ({}),
  pendingAction: undefined,
  setPendingAction: () => ({}),
  pendingSelectedCarrierId: undefined,
  selectPendingCarrier: () => ({}),
  switchSelectedCarrier: () => ({}),
})

export const useCarrierDetailsContext = (): CarrierDetailsContextType => {
  return useContext(CarrierDetailsContext)
}

export const CarrierDetailsContextProvider: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  const [state, dispatch] = useReducer(carrierDetailsReducer, INITIAL_STATE)
  const { user, isTravelportUser } = useAuth()

  const {
    archiveMode,
    dirtyForm,
    selectedCarrierCode,
    pendingAction,
    pendingSelectedCarrierId,
  } = state

  const queryClient = useQueryClient()
  const { carrierList, isLoading: isCarrierListLoading } = useCarriers()

  const [formResetConfirmConfig, setFormResetConfirmConfig] = useState<
    FormResetConfirmConfig | undefined
  >(undefined)

  useEffect(() => {
    if (user) {
      const cachedCode = localStorage.getItem('selectedCarrierCode')

      if (isTravelportUser) {
        if (cachedCode) {
          dispatch({
            type: CarrierDetailsActionType.SET_SELECTED_CARRIER_CODE,
            payload: {
              selectedCarrierCode: cachedCode,
            },
          })
        }
      } else {
        const supplierCodes = pickUniqueSipplierCodes(user.supplierCodes)
        if (cachedCode && supplierCodes.includes(cachedCode)) {
          dispatch({
            type: CarrierDetailsActionType.SET_SELECTED_CARRIER_CODE,
            payload: {
              selectedCarrierCode: cachedCode,
            },
          })
        } else if (supplierCodes.length === 1) {
          dispatch({
            type: CarrierDetailsActionType.SET_SELECTED_CARRIER_CODE,
            payload: {
              selectedCarrierCode: supplierCodes[0],
            },
          })
        }
      }
    }
  }, [user, isTravelportUser])

  const setPendingAction = (pendingActionType?: PendingAction) => {
    dispatch({
      type: CarrierDetailsActionType.SET_PENDING_ACTION,
      payload: {
        pendingAction: pendingActionType,
      },
    })
  }

  const selectPendingCarrier = (carrierCode?: string) => {
    dispatch({
      type: CarrierDetailsActionType.SET_PENDING_SELECT,
      payload: {
        id: carrierCode,
      },
    })
  }

  const switchSelectedCarrier = (nextAction?: PendingAction) => {
    if (!pendingSelectedCarrierId) {
      return
    }

    dispatch({
      type: CarrierDetailsActionType.SWITCH_SELECTED_CARRIER,
      payload: {
        pendingAction: nextAction,
        id: pendingSelectedCarrierId,
      },
    })
  }

  const selectedCarrier = useMemo(() => {
    if (selectedCarrierCode) {
      return findCarrier(carrierList, selectedCarrierCode)
    } else {
      return undefined
    }
  }, [carrierList, selectedCarrierCode])

  const { data, isLoading, isFetching } = useQuery<CarrierDetails>(
    [QUERY_KEYS.CARRIER_DETAILS, selectedCarrier?.code, archiveMode],
    () => {
      return fetchCarrierDetails({
        carrierCode: selectedCarrier?.code,
        showAllDetails: !archiveMode,
      })
    },
    {
      enabled: !!selectedCarrier,
    },
  )

  const setDirtyForm = useCallback(
    (isDirty = false) => {
      dispatch({
        type: CarrierDetailsActionType.SET_DIRTY_FORM,
        payload: {
          isDirty,
        },
      })
    },
    [dispatch],
  )

  const reloadCarrierDetails = useCallback(() => {
    setDirtyForm()
    queryClient.invalidateQueries([
      QUERY_KEYS.CARRIER_DETAILS,
      selectedCarrier?.code,
    ])
  }, [queryClient, selectedCarrier?.code, setDirtyForm])

  const setArchiveMode = useCallback(
    (enabled: boolean) => {
      dispatch({
        type: CarrierDetailsActionType.SET_ARCHIVE_MODE,
        payload: {
          archiveMode: enabled,
        },
      })
    },
    [dispatch],
  )

  const selectCarrier = useCallback(
    (code: string) => {
      localStorage.setItem('selectedCarrierCode', code)

      dispatch({
        type: CarrierDetailsActionType.SET_SELECTED_CARRIER_CODE,
        payload: {
          selectedCarrierCode: code,
        },
      })
    },
    [dispatch],
  )

  const resetCarrier = useCallback(() => {
    localStorage.removeItem('selectedCarrierCode')
    dispatch({
      type: CarrierDetailsActionType.RESET_SELECTED_CARRIER,
      payload: {},
    })
  }, [dispatch])

  if (isCarrierListLoading) {
    return <></>
  }

  return (
    <CarrierDetailsContext.Provider
      value={{
        isLoading,
        isFetching,
        selectedCarrier,
        data,
        archiveMode,
        setArchiveMode,
        selectCarrier,
        resetCarrier,
        reloadCarrierDetails,
        dirtyForm,
        setDirtyForm,
        formResetConfirmConfig,
        setFormResetConfirmConfig,
        pendingAction,
        setPendingAction,
        pendingSelectedCarrierId,
        selectPendingCarrier,
        switchSelectedCarrier,
      }}
    >
      {children}
    </CarrierDetailsContext.Provider>
  )
}
