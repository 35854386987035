import { Button, EmptyState } from '@atlas-design-system/react'
import { SERVICES } from 'common/api'
import { envConfig } from 'config/config'
import { useCallback } from 'react'

export const BreakingErrorPage: React.FC<{
  message?: string
}> = (props: { message?: string }) => {
  const onLoginClick = useCallback(() => {
    window.location.assign(
      `${envConfig.config.API_BASE_URL}/${SERVICES.AUTH_SERVICE}/`,
    )
  }, [])

  if (props.message && props.message.includes('401')) {
    return (
      <EmptyState
        title="Your login session is not valid. Please click the button below to login again."
        style={{ padding: 'var(--spaceXL) 0' }}
        actionButtons={
          <Button onClick={onLoginClick} appearance="primary">
            Login
          </Button>
        }
      />
    )
  } else {
    return (
      <EmptyState
        title={`A critical application error has occured: ${
          props.message || 'check console logs for details'
        }`}
        style={{ padding: 'var(--spaceXL) 0' }}
        actionButtons={
          <Button onClick={onLoginClick} appearance="primary">
            Refresh Application
          </Button>
        }
      />
    )
  }
}
