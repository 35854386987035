import { PendingAction } from 'common/enums'
import { OptionalService } from 'common/types'
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react'

import { useCarrierDetailsContext } from '../carrier-details/carrier-details.context'
import {
  INITIAL_SEGMENT_BRAND_STATE,
  SegmentBrandActionType,
  segmentBrandReducer,
} from './segment-branding-reducer'

type SegmentBrandingContextType = {
  copyMode: boolean
  setCopyMode: (segmentBrandkey?: string) => void
  pendingAction?: PendingAction
  resetSelectedSegmentBrand: () => void
  selectSegmentBrand: (id: string, pendingAction?: PendingAction) => void
  pendingSelectedBrandKey?: string
  selectPendingSegmentBrand: (id?: string) => void
  selectedSegmentBrand?: OptionalService
  setPendingAction: (pendingAction?: PendingAction) => void
  switchSelectedSegmentBrand: (pendingAction?: PendingAction) => void
  onCreateSegmentBrandSuccess: (key: string) => void
  onDeleteSegmentBrandSuccess: (key: string) => void
}

type SegmentBrandingContextProviderProps = {
  children?: ReactNode
}

export const SegmentBrandingContext = createContext<SegmentBrandingContextType>(
  {
    copyMode: false,
    setCopyMode: () => {},
    pendingAction: undefined,
    resetSelectedSegmentBrand: () => {},
    selectSegmentBrand: () => {},
    selectPendingSegmentBrand: () => {},
    selectedSegmentBrand: undefined,
    pendingSelectedBrandKey: undefined,
    setPendingAction: () => {},
    switchSelectedSegmentBrand: () => {},
    onCreateSegmentBrandSuccess: () => {},
    onDeleteSegmentBrandSuccess: () => {},
  },
)

export const useSegmentBrandingContext = (): SegmentBrandingContextType =>
  useContext(SegmentBrandingContext)

export const SegmentBrandingContextProvider: FC<
  SegmentBrandingContextProviderProps
> = ({ children }) => {
  const [state, dispatch] = useReducer(
    segmentBrandReducer,
    INITIAL_SEGMENT_BRAND_STATE,
  )

  const {
    copyMode,
    pendingAction,
    pendingSelectedBrandKey,
    selectedSegmentBrandKey,
  } = state

  const { data } = useCarrierDetailsContext()

  const { optionalServiceList = [] } = data || {}

  const selectedSegmentBrand = useMemo(() => {
    if (!selectedSegmentBrandKey || optionalServiceList.length === 0) {
      return undefined
    }

    return optionalServiceList.find(({ key }) => {
      return key === selectedSegmentBrandKey
    })
  }, [selectedSegmentBrandKey, optionalServiceList])

  const selectSegmentBrand = (
    key: string,
    pendingActionType?: PendingAction,
  ) => {
    dispatch({
      type: SegmentBrandActionType.SET_SELECTED_SEGMENT_BRAND,
      payload: {
        key,
        pendingAction: pendingActionType,
      },
    })
  }

  const resetSelectedSegmentBrand = () => {
    dispatch({
      type: SegmentBrandActionType.SET_SELECTED_SEGMENT_BRAND,
      payload: {
        key: undefined,
      },
    })
  }

  const setPendingAction = (pendingActionType?: PendingAction) => {
    dispatch({
      type: SegmentBrandActionType.SET_PENDING_ACTION,
      payload: {
        pendingAction: pendingActionType,
      },
    })
  }

  const setCopyMode = (segmentBrandKey?: string) => {
    dispatch({
      type: SegmentBrandActionType.SET_COPY_MODE,
      payload: {
        segmentBrandKey,
      },
    })
  }

  const selectPendingSegmentBrand = (key?: string) => {
    dispatch({
      type: SegmentBrandActionType.SET_PENDING_SELECTED,
      payload: {
        key,
      },
    })
  }

  const switchSelectedSegmentBrand = (pendingActionType?: PendingAction) => {
    if (!pendingSelectedBrandKey) {
      return
    }

    dispatch({
      type: SegmentBrandActionType.SWITCH_SELECTED_SEGMENT_BRAND,
      payload: {
        key: pendingSelectedBrandKey,
        pendingAction: pendingActionType,
      },
    })
  }

  const onCreateSegmentBrandSuccess = (key: string) => {
    dispatch({
      type: SegmentBrandActionType.CREATE_SUCCESS,
      payload: {
        key,
      },
    })
  }

  const onDeleteSegmentBrandSuccess = (key: string) => {
    dispatch({
      type: SegmentBrandActionType.DELETE_SUCCESS,
      payload: {
        key,
      },
    })
  }

  return (
    <SegmentBrandingContext.Provider
      value={{
        selectedSegmentBrand,
        copyMode,
        selectSegmentBrand,
        setCopyMode,
        pendingAction,
        resetSelectedSegmentBrand,
        selectPendingSegmentBrand,
        setPendingAction,
        switchSelectedSegmentBrand,
        onCreateSegmentBrandSuccess,
        onDeleteSegmentBrandSuccess,
        pendingSelectedBrandKey,
      }}
    >
      {children}
    </SegmentBrandingContext.Provider>
  )
}
