import { ServiceSource } from 'common/enums'
import {
  BrandOptionalServiceRequestShape,
  Family,
  UncreatedMandatoryOptionalServiceRequestShape,
} from 'common/types'

type FamilyAgrument = Pick<Family, 'source'>

export const isATPCOFamily = ({ source }: FamilyAgrument): boolean =>
  source === ServiceSource.ATPCO

export const isValidBrandOptionalService = (
  service:
    | BrandOptionalServiceRequestShape
    | UncreatedMandatoryOptionalServiceRequestShape,
): service is BrandOptionalServiceRequestShape => {
  if ((service as BrandOptionalServiceRequestShape).serviceAvailability) {
    return true
  } else {
    return false
  }
}
