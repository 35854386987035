import { Dialog } from '@atlas-design-system/react'
import React from 'react'

type FormCancelDialogProps = {
  hidden: boolean
  onConfirm: () => void
  onDismiss: () => void
}
export const FormCancelDialog: React.FC<FormCancelDialogProps> = ({
  hidden,
  onConfirm,
  onDismiss,
}) => (
  <Dialog
    id="form-cancel-dialog"
    hidden={hidden}
    appearance="warning"
    title="Reset Data"
    onConfirm={onConfirm}
    onDismiss={onDismiss}
  >
    Warning! Canceling will reset all the data entered.
  </Dialog>
)
