import { AtlasProvider } from '@atlas-ui/react'
import {
  AncillaryContextProvider,
  AuthContextProvider,
  BrandContextProvider,
  CarrierDetailsContextProvider,
  FamilyContextProvider,
  MarketingUpsellContextProvider,
  SegmentBrandingContextProvider,
} from 'common/context'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ReactQueryProvider } from './react-query.provider'

type AppProvidersProps = { children: JSX.Element }

export const AppProviders: React.FC<AppProvidersProps> = ({ children }) => {
  return (
    <AtlasProvider>
      <ReactQueryProvider>
        <BrowserRouter>
          <AuthContextProvider>
            <CarrierDetailsContextProvider>
              <AncillaryContextProvider>
                <FamilyContextProvider>
                  <SegmentBrandingContextProvider>
                    <BrandContextProvider>
                      <MarketingUpsellContextProvider>
                        {children}
                      </MarketingUpsellContextProvider>
                    </BrandContextProvider>
                  </SegmentBrandingContextProvider>
                </FamilyContextProvider>
              </AncillaryContextProvider>
            </CarrierDetailsContextProvider>
          </AuthContextProvider>
        </BrowserRouter>
      </ReactQueryProvider>
    </AtlasProvider>
  )
}
