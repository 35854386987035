import { FormControl, Select } from '@atlas-design-system/react'
import { PERMISSION_SELECT_OPTIONS } from 'common/constants'
import { SelectOption } from 'common/types'
import { useField } from 'formik'
import React, { useCallback } from 'react'

type PermissionSelectProps = {
  label?: string
  name: string
  disabled: boolean
}
export const PermissionSelect: React.FC<PermissionSelectProps> = ({
  label = 'Permission',
  disabled,
  ...props
}) => {
  const [field, , helpers] = useField(props)

  const { setValue } = helpers

  const handleChange = useCallback(
    (selectedOption: SelectOption) => {
      setValue(selectedOption)
    },
    [setValue],
  )

  return (
    <>
      <FormControl label={label} disabled={disabled}>
        <Select
          next
          data-testid="permission-select"
          {...field}
          {...props}
          onChange={handleChange}
          isDisabled={disabled}
          options={PERMISSION_SELECT_OPTIONS}
          className="mmp-ui-permission-select"
        />
      </FormControl>
    </>
  )
}
