import { ActionIconGroup, AddIcon, CloseIcon } from '@atlas-design-system/react'
import { ArrayHelpers } from 'formik'
import React from 'react'

type FieldArrayActionsProps = {
  addTooltip: string
  canDelete: boolean
  index: number
  insert?: ArrayHelpers['insert']
  push?: ArrayHelpers['push']
  replace?: ArrayHelpers['replace']
  remove: ArrayHelpers['remove']
  removeTooltip: string
  initialValueShape?: object
  isSettings?: boolean
  size?: number
}

export const FieldArrayActions: React.FC<FieldArrayActionsProps> = ({
  insert = () => ({}),
  push,
  remove,
  replace = () => ({}),
  index,
  canDelete,
  addTooltip,
  removeTooltip,
  initialValueShape = '',
  isSettings = false,
  size,
}) => (
  <ActionIconGroup
    actions={[
      {
        icon: <AddIcon />,
        key: 'add',
        tooltip: addTooltip,
        action: () =>
          push ? push(initialValueShape) : insert(index + 1, initialValueShape),
      },
      {
        icon: ((canDelete && index > 0) || isSettings) && (
          <CloseIcon fill="var('--primaryA300')" />
        ),
        key: 'remove',
        tooltip: removeTooltip,
        action: () => {
          if (!canDelete) {
            return
          }
          if (!!size && size === 1) {
            replace(index, initialValueShape)
          } else {
            remove(index)
          }
        },
      },
    ]}
    style={{
      height: '100%',
      alignItems: 'center',
    }}
  />
)
