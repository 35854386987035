import { parse } from 'date-fns'
import * as Yup from 'yup'
import { MixedSchema } from 'yup/lib/mixed'

import { DATE_FORMAT, MESSAGE_VALID_CODE_ERROR } from './../constants'

export const selectSchema = Yup.object({
  label: Yup.string(),
  value: Yup.string(),
})

export const selectRequiredSchema = Yup.object({
  label: Yup.string().required('Required'),
  value: Yup.string().required('Required'),
})

const locationSchema = selectSchema.when('direction.value', {
  is: 'WITHIN',
  then: Yup.object({
    label: Yup.string(),
    value: Yup.string().notOneOf(
      ['Airport', 'City'],
      'Error! Airport or City is not a valid value for Within.',
    ),
  }),
})

const codeSchema = (name: string, locationNumber?: 1 | 2, message?: string) =>
  selectSchema.when(`${name}${locationNumber ? locationNumber : ''}.value`, {
    is: (location: string) => location,
    then: Yup.object({
      label: Yup.string(),
      value: Yup.string().required(message ? message : 'Required'),
    }),
  })

export const geographyPointSchema = Yup.object({
  direction: selectSchema,
  location1: locationSchema,
  code1: codeSchema('location', 1, MESSAGE_VALID_CODE_ERROR),
  location2: locationSchema,
  code2: codeSchema('location', 2, MESSAGE_VALID_CODE_ERROR),
  permission: selectSchema,
}).test(
  'is-same-values',
  'Error! Location 1 and Location 2 cannot have same values. Please enter different values.',
  (values) => {
    const { location1, location2, code1, code2 } = values

    if (!location1.value || !location2.value || !code1.value || !code2.value)
      return true

    return location1.value !== location2.value || code1.value !== code2.value
  },
)

export const pointOfSaleSchema = Yup.object().shape({
  iataNumber: Yup.string(),
  gds: selectSchema,
  pcc: Yup.string(),
  locationType: selectSchema,
  locationCode: codeSchema('locationType', undefined, MESSAGE_VALID_CODE_ERROR),
  permitted: selectSchema,
})

const today = new Date()
today.setHours(0, 0, 0, 0)

export const dateSchema = Yup.mixed()
  .transform((dateString) => parse(dateString, DATE_FORMAT, new Date()))
  .required('Required')

export const effectiveDateSchema = dateSchema.test(
  'today-date',
  'Error! selected date should not be before todays date.',
  (date: Date) => date >= today,
)

export const discontinueDateSchema = (name: string): MixedSchema =>
  dateSchema.when(name, (effectiveDate, yup) =>
    (effectiveDate
      ? yup.test(
          'compare-with-effective',
          'Error! The Discontinue Date cannot be before the Effective Date.',
          (date: Date) => date >= effectiveDate,
        )
      : yup
    ).test(
      'today-date',
      'Error! selected date should not be before todays date.',
      (date: Date) => date >= today,
    ),
  )

export const MultiLanguageSchema = Yup.object({
  EXTERNAL_COMMERCIAL: Yup.string().required('Required'),
  EXTERNAL_SHORT_NAME: Yup.string().required('Required'),
  MARKETING_AGENT: Yup.string().required('Required'),
  MARKETING_CONSUMER: Yup.string(),
  STRAPLINE: Yup.string().required('Required'),
  RULE: Yup.string(),
})

export const MultiLanguageBrandsSchema = MultiLanguageSchema.shape({
  STRAPLINE: Yup.string(),
})

export const MultiLanguageAncillariesSchema = MultiLanguageBrandsSchema

export const marketingPartnersSchema = Yup.string()
  .test('marketingPartners-validCode', 'Enter Valid Carrier Code', (values) => {
    const wrongLendth = values
      ?.split('•')
      .map((value) => value.trim())
      .some((value) => value.length !== 2)
    if (wrongLendth) {
      return false
    }
    return true
  })
  .test(
    'marketingPartners-validCode',
    'A maximum of 51 two-letter IATA/ATPCO Airline Codes allowed',
    (values) => {
      const codePairs = values?.split('•')

      if (codePairs && codePairs.length > 51) {
        return false
      }

      return true
    },
  )
