import {
  Button,
  Calendar,
  CalendarDate,
  CalendarGrid,
  CalendarGridCells,
  CalendarHeader,
  CalendarIcon,
  CalendarNextMonthButton,
  CalendarNextYearButton,
  CalendarPreviousMonthButton,
  CalendarPreviousYearButton,
  CalendarTitle,
  CalendarWeekDays,
  getLocalTimeZone,
  Input,
  InputButtonEnd,
  InputGroup,
  Popover,
  Stack,
  today,
  useDatePicker,
} from '@atlas-ui/react'
import { DATE_FORMAT } from 'common/constants'
import { isValid } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'

type FormInputProps = {
  label?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  error?: string | boolean
  value: string
  disabledDate: (value: Date) => boolean
  onDateSelected: (date?: Date) => void
}

export const DatePicker: React.FC<FormInputProps> = ({
  label = '',
  placeholder = DATE_FORMAT,
  disabled = false,
  required = false,
  error,
  value,
  disabledDate = () => true,
  onDateSelected,
}) => {
  const [inputValue, setInputValue] = useState(value)

  const selectedDate = useMemo(() => {
    const jsDate = !!value ? new Date(value) : undefined

    return !!jsDate && isValid(jsDate)
      ? new CalendarDate(
          jsDate.getFullYear(),
          jsDate.getMonth() + 1,
          jsDate.getDate(),
        )
      : undefined
  }, [value])

  const handleInputChange = (inputVal: string) => setInputValue(inputVal)

  const handleInputBlur = () => {
    const dateFromInput = new Date(inputValue)

    if (isValid(dateFromInput) && !disabledDate(dateFromInput)) {
      onDateSelected(dateFromInput)
    } else {
      onDateSelected(undefined)
      setInputValue('')
    }
  }

  const handleDateChange = (calendarDate: CalendarDate) => {
    onDateSelected(calendarDate.toDate(getLocalTimeZone()))
  }

  const { state, buttonProps, calendarProps, ref } = useDatePicker({
    'aria-label': label,
    isDisabled: disabled,
    isInvalid: Boolean(error),
    isRequired: required,
    value: selectedDate,
    isDateUnavailable: (dateValue) =>
      disabledDate(dateValue.toDate(getLocalTimeZone())),
    onChange: handleDateChange,
  })

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <>
      <InputGroup ref={ref}>
        <Input
          aria-label={label}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          value={inputValue}
          placeholder={placeholder}
          isDisabled={disabled}
          isInvalid={Boolean(error)}
          isRequired={required}
        />
        <InputButtonEnd {...buttonProps}>
          <CalendarIcon />
        </InputButtonEnd>
      </InputGroup>
      {state.isOpen && (
        <Popover state={state} triggerRef={ref}>
          <Calendar {...calendarProps}>
            <CalendarHeader>
              <CalendarPreviousYearButton />
              <CalendarPreviousMonthButton />
              <CalendarTitle />
              <CalendarNextMonthButton />
              <CalendarNextYearButton />
            </CalendarHeader>
            <Stack>
              <Button
                appearance="secondary"
                onPress={() => state.setDateValue(today(getLocalTimeZone()))}
              >
                Today
              </Button>
            </Stack>
            <CalendarGrid>
              <CalendarWeekDays />
              <CalendarGridCells />
            </CalendarGrid>
          </Calendar>
        </Popover>
      )}
    </>
  )
}
