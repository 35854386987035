import {
  MESSAGE_MANDATORY_FIELDS_ERROR,
  MESSAGE_NON_UNIQUE_SEGMENT_BRAND_ERROR,
  MESSAGE_OVERLAPPING_ANCILLARY_DATES_ERROR,
} from 'common/constants'
import { useCarrierDetailsContext } from 'common/context'
import { useSegmentBrandingContext } from 'common/context/segment-branding-context'
import { isDatesOverlapping } from 'common/helpers'
import { SegmentBrandingFormValue } from 'common/types'
import { FormikProps } from 'formik'
import { useCallback, useMemo, useState } from 'react'

type UseSegmenBrandProps = {
  formik: FormikProps<SegmentBrandingFormValue>
  hasWritePermission: boolean
}

export const useSegmenBrandingForm = ({
  formik,
  hasWritePermission,
}: UseSegmenBrandProps): {
  cancelDialogVisible: boolean
  hideCancelDialog: () => void
  showCancelDialog: () => void
  onFormReset: () => void
  isMarketingFieldDisabled: boolean
  isCabinDisabled: boolean
  isEquipmentFieldDisabled: boolean
  fieldsDisabled: boolean
  titleFieldDisabled: boolean
  isNonUniqueSegmentBrand?: boolean
  segmentBrandError?: string
  datesOverlapping: boolean
} => {
  const { resetSelectedSegmentBrand, selectedSegmentBrand, copyMode } =
    useSegmentBrandingContext()
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false)

  const { values, errors, isValid, initialValues } = formik
  const { data } = useCarrierDetailsContext()
  const { optionalServiceList = [] } = data || {}

  const isNonUniqueSegmentBrand = useMemo(() => {
    if (!hasWritePermission) {
      return false
    }

    if (copyMode || !selectedSegmentBrand) {
      const { serviceName } = values

      return optionalServiceList.some(
        (segmentBrand) => segmentBrand.serviceName === serviceName,
      )
    }

    return false
  }, [
    hasWritePermission,
    copyMode,
    selectedSegmentBrand,
    values,
    optionalServiceList,
  ])

  const datesOverlapping = useMemo(
    () =>
      isDatesOverlapping({
        initialValues,
        copyMode,
        selectedOptionalServiceKey: selectedSegmentBrand?.key,
        values,
      }),
    [copyMode, initialValues, selectedSegmentBrand?.key, values],
  )

  const showCancelDialog = useCallback(() => {
    setCancelDialogVisible(true)
  }, [])

  const hideCancelDialog = useCallback(() => {
    setCancelDialogVisible(false)
  }, [])

  const handleFormReset = useCallback(() => {
    if (selectedSegmentBrand) {
      resetSelectedSegmentBrand()
    } else {
      formik.resetForm()
    }

    if (cancelDialogVisible) {
      hideCancelDialog()
    }
  }, [
    cancelDialogVisible,
    formik,
    hideCancelDialog,
    resetSelectedSegmentBrand,
    selectedSegmentBrand,
  ])

  const isMarketingFieldDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }

    if (copyMode || !selectedSegmentBrand) {
      return false
    }

    if (selectedSegmentBrand.ancillaryDescription) {
      return false
    }

    return true
  }, [copyMode, selectedSegmentBrand, hasWritePermission])

  const isCabinDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }

    if (copyMode) {
      return false
    }

    if (!selectedSegmentBrand) {
      return false
    }

    return true
  }, [copyMode, selectedSegmentBrand, hasWritePermission])

  const isEquipmentFieldDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }

    if (copyMode) {
      return false
    }
    if (!selectedSegmentBrand?.ancillaryDescription) {
      return false
    }

    return true
  }, [hasWritePermission, copyMode, selectedSegmentBrand])

  const titleFieldDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }

    if (copyMode) {
      return false
    }

    if (selectedSegmentBrand) {
      return true
    }

    return false
  }, [copyMode, selectedSegmentBrand, hasWritePermission])

  const fieldsDisabled = useMemo(() => {
    if (titleFieldDisabled && !hasWritePermission) {
      return true
    }

    if (copyMode) {
      return isNonUniqueSegmentBrand
    }

    return false
  }, [
    copyMode,
    titleFieldDisabled,
    isNonUniqueSegmentBrand,
    hasWritePermission,
  ])

  const onlyMatchingDataError =
    !!errors.matchingData && Object.keys(errors).length === 1

  const segmentBrandError = useMemo(() => {
    if (isNonUniqueSegmentBrand) {
      return MESSAGE_NON_UNIQUE_SEGMENT_BRAND_ERROR
    }

    if (datesOverlapping) {
      return MESSAGE_OVERLAPPING_ANCILLARY_DATES_ERROR
    }

    if (!isValid && onlyMatchingDataError) {
      return MESSAGE_MANDATORY_FIELDS_ERROR
    }
  }, [
    datesOverlapping,
    isNonUniqueSegmentBrand,
    isValid,
    onlyMatchingDataError,
  ])

  return {
    cancelDialogVisible,
    hideCancelDialog,
    showCancelDialog,
    onFormReset: handleFormReset,
    isMarketingFieldDisabled,
    isCabinDisabled,
    isEquipmentFieldDisabled,
    fieldsDisabled,
    titleFieldDisabled,
    isNonUniqueSegmentBrand,
    segmentBrandError,
    datesOverlapping,
  }
}
