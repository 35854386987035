import { useFamilyContext } from 'common/context/family-context/family-context'
import { PendingAction } from 'common/enums'
import { FamilyFormValue } from 'common/types'
import { useSubmitFamily } from 'modules/families/hooks/use-submit-family.hook'
import React, { useCallback, useEffect, useState } from 'react'

import { FamilyForm } from './family-form/family-form.component'
import { FamilyFormModel } from './models'

export const FamilyFormWrapper: React.FC = () => {
  const {
    selectedFamily,
    copyMode,
    pendingAction,
    setPendingAction,
    hybridFamilyCount,
  } = useFamilyContext()
  const { submitFamily } = useSubmitFamily()
  const [initialValues, setInitialValues] = useState<FamilyFormValue>(() =>
    FamilyFormModel(),
  )

  const resetInitialValues = useCallback(() => {
    setInitialValues(
      FamilyFormModel({
        family: selectedFamily,
        config: { copyMode, hybridFamilyCount },
      }),
    )
  }, [selectedFamily, copyMode, hybridFamilyCount])

  useEffect(() => {
    if (selectedFamily?.id !== initialValues.id || copyMode) {
      resetInitialValues()
    }
  }, [selectedFamily, copyMode, resetInitialValues, initialValues.id])

  useEffect(() => {
    if (pendingAction && pendingAction === PendingAction.RESET_FORM) {
      resetInitialValues()
      setPendingAction()
    }
  }, [pendingAction, resetInitialValues, setPendingAction])

  const handleSubmit = useCallback(
    (values: FamilyFormValue) => {
      submitFamily(values)
    },
    [submitFamily],
  )

  return (
    <>
      <FamilyForm onSubmit={handleSubmit} initialValues={initialValues} />
    </>
  )
}
