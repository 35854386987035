import { PendingAction } from 'common/enums'
import { useFamily } from 'common/hooks'
import { Family } from 'common/types'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'

import { FamilyActionType, familyReducer } from './family.reducer'

export type FamilyContextType = {
  closePromoteModal: () => void
  copyMode: boolean
  isPromoteModalHidden: boolean
  onCreateFamilySuccess: (id: string) => void
  onDeleteFamilySuccess: () => void
  onUpdateFamilySuccess: (id: string) => void
  openPromoteModal: () => void
  pendingAction?: PendingAction
  pendingSelectedFamilyId?: string
  resetSelectedFamily: () => void
  selectFamily: (id: string, pendingAction?: PendingAction) => void
  selectPendingFamily: (id?: string) => void
  selectedFamily?: Family
  setCopyMode: (active: boolean) => void
  setPendingAction: (pendingAction?: PendingAction) => void
  switchSelectedFamily: (pendingAction?: PendingAction) => void
  hybridFamilyCount: number
}

export const FamilyContext = createContext<FamilyContextType>({
  closePromoteModal: () => ({}),
  copyMode: false,
  isPromoteModalHidden: true,
  onCreateFamilySuccess: () => ({}),
  onDeleteFamilySuccess: () => ({}),
  onUpdateFamilySuccess: () => ({}),
  openPromoteModal: () => ({}),
  pendingAction: undefined,
  pendingSelectedFamilyId: undefined,
  resetSelectedFamily: () => {},
  selectFamily: () => ({}),
  selectPendingFamily: () => ({}),
  selectedFamily: undefined,
  setCopyMode: () => ({}),
  setPendingAction: () => ({}),
  switchSelectedFamily: () => ({}),
  hybridFamilyCount: 0,
})

export const useFamilyContext = (): FamilyContextType =>
  useContext(FamilyContext)

export const FamilyContextProvider: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  const [state, dispatch] = useReducer(familyReducer, {
    selectedFamilyId: undefined,
    copyMode: false,
    isPromoteModalHidden: true,
    pendingAction: undefined,
    pendingSelectedFamilyId: undefined,
  })

  const { familyList, familyGroupList } = useFamily()

  const {
    selectedFamilyId,
    isPromoteModalHidden,
    pendingAction,
    copyMode,
    pendingSelectedFamilyId,
  } = state

  const selectedFamily = useMemo(() => {
    if (!selectedFamilyId || familyList.length === 0) {
      return undefined
    }

    return familyList.find(({ id }) => {
      return id === selectedFamilyId
    })
  }, [selectedFamilyId, familyList])

  const resetSelectedFamily = useCallback(() => {
    dispatch({
      type: FamilyActionType.SET_SELECTED,
      payload: {
        id: undefined,
      },
    })
  }, [dispatch])

  const hybridFamilyCount = useMemo(() => {
    if (!selectedFamily) {
      return 0
    }
    const currentFamilyGroupList = familyGroupList.filter((group) =>
      group.familyGroupName.includes(selectedFamily.name),
    )
    const currentFamilyList = currentFamilyGroupList
      .map((group) => group.familyList)
      .flat(1)

    const filteredFamilyList = currentFamilyList.filter(
      (family) => family.linkedFareFamilyId,
    )

    const hybridFamilyCountList = filteredFamilyList.map(
      (family) => +family.name.split('H').slice(-1)[0], // the last family number after H
    )

    const hybridMaxCount =
      hybridFamilyCountList.length > 0 ? Math.max(...hybridFamilyCountList) : 0

    return hybridMaxCount
  }, [selectedFamily, familyGroupList])

  const selectFamily = (id: string, pendingActionType?: PendingAction) => {
    dispatch({
      type: FamilyActionType.SET_SELECTED,
      payload: {
        id,
        pendingAction: pendingActionType,
      },
    })
  }

  const openPromoteModal = () => {
    dispatch({
      type: FamilyActionType.OPEN_PROMOTE_MODAL,
    })
  }

  const closePromoteModal = () => {
    dispatch({
      type: FamilyActionType.CLOSE_PROMOTE_MODAL,
    })
  }

  const onCreateFamilySuccess = (id: string) => {
    dispatch({
      type: FamilyActionType.CREATE_SUCCESS,
      payload: {
        id,
      },
    })
  }

  const onUpdateFamilySuccess = (id: string) => {
    dispatch({
      type: FamilyActionType.UPDATE_SUCCESS,
      payload: {
        id,
      },
    })
  }

  const onDeleteFamilySuccess = () => {
    dispatch({
      type: FamilyActionType.DELETE_SUCCESS,
    })
  }

  const setCopyMode = (active: boolean) => {
    dispatch({
      type: FamilyActionType.SET_COPY_MODE,
      payload: {
        active,
      },
    })
  }

  const setPendingAction = (pendingActionType?: PendingAction) => {
    dispatch({
      type: FamilyActionType.SET_PENDING_ACTION,
      payload: {
        pendingAction: pendingActionType,
      },
    })
  }

  const selectPendingFamily = (id?: string) => {
    dispatch({
      type: FamilyActionType.SET_PENDING_SELECTED,
      payload: {
        id,
      },
    })
  }

  const switchSelectedFamily = (pendingActionType?: PendingAction) => {
    if (!pendingSelectedFamilyId) {
      return
    }

    dispatch({
      type: FamilyActionType.SWITCH_SELECTED_FAMILY,
      payload: {
        id: pendingSelectedFamilyId,
        pendingAction: pendingActionType,
      },
    })
  }

  return (
    <FamilyContext.Provider
      value={{
        closePromoteModal,
        copyMode,
        isPromoteModalHidden,
        onCreateFamilySuccess,
        onDeleteFamilySuccess,
        onUpdateFamilySuccess,
        openPromoteModal,
        pendingAction,
        pendingSelectedFamilyId,
        resetSelectedFamily,
        selectFamily,
        selectPendingFamily,
        selectedFamily,
        setCopyMode,
        setPendingAction,
        switchSelectedFamily,
        hybridFamilyCount,
      }}
    >
      {children}
    </FamilyContext.Provider>
  )
}
