import { toast } from '@atlas-design-system/react'
import { RoutePath } from 'common/enums'
import {
  GalleryItem,
  ImageDetails,
  ImageRequestShape,
  ImageRestrictions,
} from 'common/types'

export const getImageAttribute = (currentRoutePath: string): string => {
  switch (currentRoutePath) {
    case RoutePath.BRANDS:
      return 'brand'
    case RoutePath.ANCILLARIES:
    case RoutePath.SEGMENT_BRANDING:
      return 'ancillary'
    case RoutePath.DASHBOARD:
      return 'carrier'
    default:
      return 'attr'
  }
}

export const getImageDetails = (
  file: File,
  carrierCode: string,
  currentRoutePath: string,
): Promise<ImageRequestShape> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const base64 = reader.result?.toString().split(',')[1] || ''

      const image = new Image()
      if (reader.result?.toString() && reader.result?.toString().length <= 5) {
        reject('File contents are empty')
      }
      image.src = reader.result?.toString() || ''

      image.onload = function () {
        const imageElement = this as HTMLImageElement
        const height = imageElement.height
        const width = imageElement.width

        resolve({
          description: 'description',
          typeCode: 'general',
          name: 'name',
          fileName: file.name,
          height: height,
          sizeType: 'small',
          attribute: getImageAttribute(currentRoutePath),
          width: width,
          unitOfMeasure: 'px',
          fileContents: base64,
          carrierCode,
        })
      }
      image.onerror = function () {
        reject('Failed to parse image file')
      }
    }
  })

export const validateImage = (
  imageDetails: ImageRequestShape,
  gallery: GalleryItem[],
  fileSize: number,
  restrictions: ImageRestrictions = {},
): boolean => {
  const { fileName, height, width } = imageDetails
  const isCopy = gallery.some(
    (image) => image.versions[0].fileName === fileName,
  )
  const isWidthLimit = !!restrictions.width && width > restrictions.width
  const isHeightLimit = !!restrictions.height && height > restrictions.height
  const isDimensionLimit = isWidthLimit && isHeightLimit
  const isSizeLimit = !!restrictions.size && fileSize > restrictions.size * 1024 // size (kb)

  if (isCopy) {
    toast.error('Duplicate file name. Image already exist in image gallery.')
    return false
  }

  if (isDimensionLimit) {
    toast.error(
      `Error! Image dimension exceeds the specified limit of ${restrictions.width} X ${restrictions.height} pixels.`,
    )
    return false
  }

  if (isSizeLimit) {
    toast.error(
      `Error! Image size exceeds the specified limit of ${restrictions.size}KB.`,
    )
    return false
  }

  return true
}

export const parseImageDetails = <Category>(
  imageDetails: GalleryItem,
  category: Category,
): ImageDetails<Category> => {
  const { url, height, width, imageId } = imageDetails.versions[0]

  return {
    cdnSourcePath: url,
    height,
    width,
    category,
    cdnId: imageId,
  }
}
