import { FormControl, TextArea } from '@atlas-design-system/react'
import { FieldProps } from 'formik'
import React from 'react'

import { CustomFormControl } from '../custom-form-control/custom-form-control.component'

const TEXTAREA_PROPS = { rows: 7 }

type TextareaInputProps = {
  label?: string
  disabled?: boolean
  required?: boolean
  textareaProps?: object
} & FieldProps &
  Pick<React.ComponentProps<typeof FormControl>, 'hint'>

export const TextareaInput: React.FC<TextareaInputProps> = ({
  label = '',
  field: { name, onBlur, onChange, value },
  form: { getFieldMeta },
  hint,
  disabled = false,
  required = false,
  textareaProps = TEXTAREA_PROPS,
  ...props
}) => {
  const { touched, error } = getFieldMeta(name)
  const fieldError = touched && error ? error : ''

  return (
    <CustomFormControl
      label={label}
      error={fieldError}
      hint={hint}
      disabled={disabled}
      required={required}
    >
      <TextArea
        name={name}
        data-testid={`text-area-${name}`}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        disabled={disabled}
        textareaProps={textareaProps}
        {...props}
      />
    </CustomFormControl>
  )
}
