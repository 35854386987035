import { Spinner } from '@atlas-design-system/react'
import { AppSidebar } from 'common/components'
import { useCarrierDetailsContext } from 'common/context'
import React from 'react'

import { FamilyFormWrapper } from './components'
import { FamiliesList } from './components/families-list/families-list.component'

export const Families: React.FC = () => {
  const { isFetching } = useCarrierDetailsContext()

  if (isFetching) {
    return <Spinner />
  }

  return (
    <div data-testid="families-page">
      <div className="row">
        <div className="col-xs-8">
          <FamilyFormWrapper />
        </div>
        <div className="col-xs-4">
          <AppSidebar>
            <FamiliesList />
          </AppSidebar>
        </div>
      </div>
    </div>
  )
}
