import { AsyncSelect, FormControl } from '@atlas-design-system/react'
import { EMPTY_SELECT_OPTION } from 'common/constants'
import { LocationType } from 'common/enums'
import { useReferenceData } from 'common/hooks/use-reference-data.hook'
import { SelectOption } from 'common/types'
import React, { ReactEventHandler, useMemo } from 'react'

import styles from './location-code-select.module.css'

type LocationCodeSelectProps = {
  value: SelectOption<string>
  name: string
  locationValue: string
  disabled: boolean
  onChange: (selectedOption: SelectOption<string>, name: string) => void
  onBlur: (event: ReactEventHandler) => void
  label?: string
  error?: string | boolean
}

export const LocationCodeSelect: React.FC<LocationCodeSelectProps> = ({
  disabled,
  name,
  locationValue,
  value,
  onChange,
  onBlur,
  label = 'Code',
  error,
}) => {
  const {
    airportCodesSelectOptions,
    cityCodesSelectOptions,
    countryCodesSelectOptions,
    zoneCodesSelectOptions,
    areaCodesSelectOptions,
  } = useReferenceData()

  const searchCountriesFormatted = (inputValue: string) => {
    let data: any[] = []
    if (locationValue === LocationType.AIRPORT) {
      data = airportCodesSelectOptions
    }
    if (locationValue === LocationType.CITY) {
      data = cityCodesSelectOptions
    }
    if (locationValue === LocationType.COUNTRY) {
      data = countryCodesSelectOptions
    }
    if (locationValue === LocationType.ZONE) {
      data = zoneCodesSelectOptions
    }
    if (locationValue === LocationType.AREA) {
      data = areaCodesSelectOptions
    }
    return data.filter((i) =>
      i.label.toLowerCase().startsWith(inputValue.toLowerCase()),
    )
  }

  const loadOptions = (
    inputValue: string,
    callback: (options: any[]) => void,
  ) => {
    setTimeout(() => {
      callback(searchCountriesFormatted(inputValue))
    }, 300)
  }

  const isClearable = useMemo(() => {
    if (value?.value) {
      return true
    }
    return false
  }, [value])

  return (
    <FormControl
      className={`location-code-label ${styles.asyncSelect}`}
      label={label}
      disabled={disabled}
      error={error}
    >
      <AsyncSelect
        isSearchable
        name={name}
        data-testid="location-code-select"
        loadOptions={loadOptions}
        value={value}
        onChange={(selectedOption) =>
          onChange(selectedOption ? selectedOption : EMPTY_SELECT_OPTION, name)
        }
        onBlur={onBlur}
        isClearable={isClearable}
      />
    </FormControl>
  )
}
