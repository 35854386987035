import {
  Button,
  ButtonGroup,
  Card,
  Modal,
  toast,
} from '@atlas-design-system/react'
import { FormHeader } from 'common/components'
import { useCarrierDetailsContext } from 'common/context'
import { RoutePath } from 'common/enums'
import { getImageDetails } from 'common/helpers/file-upload-helpers'
import { useLogoImage, useUploadLogo } from 'modules/dashboard/hooks'
import React, { MutableRefObject, useRef, useState } from 'react'

import { StyledInput, StyledLogo } from './dashboard-logo.styled'

type LogoAreaProps = {
  disabled: boolean
}

export const LogoArea: React.FC<LogoAreaProps> = ({ disabled }) => {
  const [isModal, setModal] = useState(false)

  const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>

  const { selectedCarrier } = useCarrierDetailsContext()
  const { logoUrl } = useLogoImage()
  const updateLogoMutation = useUploadLogo(
    selectedCarrier?.CDNTravelSupplierId || '',
  )

  const handleUpload = () => {
    const file = fileInputRef?.current?.files?.[0]

    if (!file) {
      toast.error('Error! Invalid file, select a valid one.')
      return
    }

    getImageDetails(file, selectedCarrier?.code || '', RoutePath.DASHBOARD)
      .then((imageDetails) => {
        updateLogoMutation.mutate(imageDetails)
        setModal(false)
      })
      .catch(() => {
        toast.error('Failed to read file, please check your file and try again')
      })
  }

  const isButtonDisabled = disabled || updateLogoMutation.isLoading

  return (
    <StyledLogo>
      <Card className="atls-card dashboard">
        <FormHeader title="Current Logo:" variant="contentText1" />
        <Card
          image={logoUrl}
          imageAlt="Logotype"
          className="image-loader-preview"
        >
          {' '}
        </Card>
        <Button
          className={`link-button ${isButtonDisabled && 'disabled'}`}
          onClick={() => !disabled && setModal(true)}
          data-testid="dashboard-logo-load-button"
          appearance="tertiary"
          size="small"
          disabled={isButtonDisabled}
        >
          load image
        </Button>
      </Card>
      {isModal && (
        <Modal
          id="upload-logo-modal"
          onDismiss={() => setModal(false)}
          hidden={!isModal}
          size="small"
          title="Carrier Image"
        >
          <div className="row">
            <div className="col-xs">
              <StyledInput type="file" accept="image/*" ref={fileInputRef} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs">
              <Button
                data-testid="dashboard-logo-modal-upload"
                size="small"
                appearance="primary"
                onClick={handleUpload}
              >
                Upload
              </Button>
            </div>
          </div>
          <ButtonGroup size="small" appearance="primary" align="right">
            <Button
              data-testid="dashboard-logo-modal-cancel"
              onClick={() => setModal(false)}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </Modal>
      )}
    </StyledLogo>
  )
}
