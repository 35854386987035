import { FormControl, Input } from '@atlas-design-system/react'
import { FieldArrayActions } from 'common/components'
import { noop } from 'common/helpers'
import { FamilyFormValue } from 'common/types'
import { FieldArray, FormikProps } from 'formik'
import React, { useMemo } from 'react'

type AccountTypeCodeFieldsListProps = {
  formik: FormikProps<FamilyFormValue>
  items: string[]
  disabled?: boolean
}

export const AccountTypeCodeFieldsList: React.FC<
  AccountTypeCodeFieldsListProps
> = ({ items, formik, disabled = false }) => {
  const { handleBlur, handleChange } = formik

  const canDelete = useMemo(() => items.length > 1, [items])

  return (
    <FieldArray name="accountTypeCodeList">
      {({ insert, remove }) => {
        return (
          <>
            {items.map((item, index) => (
              <FormControl
                key={index}
                label="Account Value"
                hint={
                  index + 1 === items.length
                    ? 'Applicable up to 20 letter ATC'
                    : undefined
                }
                disabled={disabled}
              >
                <div key={index} className="row">
                  <div className="col-xs-3">
                    <Input
                      name={`accountTypeCodeList.${index}`}
                      data-testid={`account-type-code-list.${index}`}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={item}
                      maxLength="20"
                      disabled={disabled}
                      wrapperProps={{
                        onMouseUp: noop,
                      }}
                    />
                  </div>

                  <div className="col-xs-2">
                    {!disabled && (
                      <FieldArrayActions
                        addTooltip="Add ATC"
                        canDelete={canDelete}
                        index={index}
                        insert={insert}
                        remove={remove}
                        removeTooltip="Remove ATC"
                        data-testid={`field-array-action-buttons.${index}`}
                      />
                    )}
                  </div>
                </div>
              </FormControl>
            ))}
          </>
        )
      }}
    </FieldArray>
  )
}
