import { Button, ButtonGroup } from '@atlas-design-system/react'
import { useAuth, useBrandContext } from 'common/context'
import { PendingAction, Permission, ServiceSource } from 'common/enums'
import { useDirtyForm, useReorderBrands } from 'common/hooks'
import { BrandDetails } from 'common/types'
import update from 'immutability-helper'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDrop } from 'react-dnd'

import {
  BrandRadioCard,
  DRAGGABLE_ITEM_TYPE,
} from './brand-radio-card/brand-radio-card.component'
import styles from './brands-list.module.css'

type DropItem = {
  item: BrandDetails
  index: number
}

export const BrandsList: React.FC = () => {
  const {
    selectedFamily,
    selectBrand,
    selectedBrand,
    selectFamily,
    setPendingAction,
    selectPendingBrand,
    copyMode,
  } = useBrandContext()
  const { dirtyForm } = useDirtyForm()
  const { saveReorderedBrands } = useReorderBrands()

  const { hasPermissionTo } = useAuth()

  const hasWritePermission = useMemo(
    () => hasPermissionTo(Permission.WRITE_BRANDS),
    [hasPermissionTo],
  )

  const brands = useMemo(() => {
    if (!selectedFamily) {
      return []
    }

    return selectedFamily.brandDetailsList || []
  }, [selectedFamily])

  const [localBrandsList, setLocalBrandsList] = useState<BrandDetails[]>([])

  useEffect(() => {
    setLocalBrandsList(brands)
  }, [brands])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event

      if (dirtyForm) {
        selectPendingBrand(value)
        setPendingAction(PendingAction.SELECT_ANOTHER_BRAND)
      } else {
        if (copyMode && selectedBrand && selectedBrand.brandId === value) {
          selectBrand(value, PendingAction.RESET_FORM)
        } else {
          selectBrand(value)
        }
      }
    },
    [
      selectBrand,
      dirtyForm,
      setPendingAction,
      selectPendingBrand,
      copyMode,
      selectedBrand,
    ],
  )

  const isATPCOFamily = useMemo(
    () => selectedFamily?.source === ServiceSource.ATPCO,
    [selectedFamily],
  )

  const findBrand = useCallback(
    (id: string): DropItem => {
      const item = localBrandsList.filter(
        (brand) => `${brand.brandId}` === id,
      )[0]
      return {
        item,
        index: localBrandsList.indexOf(item),
      }
    },
    [localBrandsList],
  )

  const moveBrand = useCallback(
    (id: string, atIndex: number) => {
      if (!hasWritePermission) {
        return
      }

      if (isATPCOFamily) {
        return
      }

      const { item, index } = findBrand(id)
      setLocalBrandsList(
        update(localBrandsList, {
          $splice: [
            [index, 1],
            [atIndex, 0, item],
          ],
        }),
      )
    },
    [findBrand, hasWritePermission, localBrandsList, isATPCOFamily],
  )

  const [, drop] = useDrop(() => ({ accept: DRAGGABLE_ITEM_TYPE }))

  const handleSaveOrder = useCallback(() => {
    if (!selectedFamily) {
      return
    }
    if (isATPCOFamily) {
      return
    }

    saveReorderedBrands(localBrandsList, selectedFamily.id, true, selectFamily)
  }, [
    selectedFamily,
    isATPCOFamily,
    saveReorderedBrands,
    localBrandsList,
    selectFamily,
  ])

  return (
    <div className={styles.brandsList} data-testid="brand-list">
      <div ref={drop}>
        {localBrandsList.map((brand) => (
          <BrandRadioCard
            key={brand.brandId}
            brand={brand}
            onChange={handleChange}
            selectedBrand={selectedBrand}
            moveBrand={moveBrand}
            findBrand={findBrand}
            copyMode={copyMode}
            canDrag={!isATPCOFamily}
          />
        ))}
      </div>
      {localBrandsList.length > 0 && hasWritePermission && (
        <ButtonGroup align="right">
          <Button
            disabled={isATPCOFamily}
            appearance="primary"
            type="button"
            onClick={handleSaveOrder}
          >
            Save
          </Button>
        </ButtonGroup>
      )}
    </div>
  )
}
