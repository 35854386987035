import { Direction } from 'common/enums'
import { GeographyPointRequestShape, GeographyPointValue } from 'common/types'

export const GeographyListRequestModel = (
  geographyList: GeographyPointValue[],
): GeographyPointRequestShape[] => {
  return geographyList
    .filter(
      ({ location1, location2 }) => !!location1.value || !!location2.value,
    )
    .map(({ direction, location1, location2, code1, code2, permitted }) => ({
      direction: direction.value === Direction.BETWEEN ? null : direction.value,
      location1: location1.value || null,
      location2: location2.value || null,
      code1: code1.value || null,
      code2: code2.value || null,
      permitted: permitted.value,
    }))
}
