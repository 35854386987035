import { useSegmentBrandingContext } from 'common/context/segment-branding-context'
import { SegmentBrandingFormValue } from 'common/types'
import { useSubmitSegmenBranding } from 'modules/segment-branding/hooks'
import { FC, useCallback, useMemo } from 'react'

import { SegmentBrandingFormModel } from './models/segment-branding-form.model'
import { SegmentBrandingRequestModel } from './models/segment-branding-request-model'
import { SegmentBrandingForm } from './segment-branding-form/segment-branding-form.component'

export const SegmentBrandingFormWrapper: FC = () => {
  const { selectedSegmentBrand, copyMode, resetSelectedSegmentBrand } =
    useSegmentBrandingContext()

  const { submitSegmentBrand } = useSubmitSegmenBranding()

  const handleSubmit = useCallback(
    (values: SegmentBrandingFormValue) => {
      const requestShape = SegmentBrandingRequestModel(values, copyMode)

      submitSegmentBrand(requestShape)
      resetSelectedSegmentBrand()
    },
    [copyMode, submitSegmentBrand, resetSelectedSegmentBrand],
  )

  const initialValues = useMemo(
    () => SegmentBrandingFormModel({ selectedSegmentBrand, copyMode }),
    [selectedSegmentBrand, copyMode],
  )

  return (
    <SegmentBrandingForm
      onSubmit={handleSubmit}
      initialValues={initialValues}
    />
  )
}
