import { toast } from '@atlas-design-system/react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAncillaryContext, useCarrierDetailsContext } from 'common/context'
import { createAncillary } from 'common/services'
import {
  CreateAncillaryRequest,
  CreateAncillaryResponse,
  ErrorMessage,
} from 'common/types'

export const useCreateAncillary = (): UseMutationResult<
  CreateAncillaryResponse,
  AxiosError<ErrorMessage>,
  CreateAncillaryRequest
> => {
  const { reloadCarrierDetails } = useCarrierDetailsContext()
  const { onCreateAncillarySuccess } = useAncillaryContext()

  return useMutation<
    CreateAncillaryResponse,
    AxiosError<ErrorMessage>,
    CreateAncillaryRequest
  >((data: CreateAncillaryRequest) => createAncillary(data), {
    onSuccess: ({ confirmationId, entityId: anillaryId }) => {
      toast.success(
        'Success! Ancillary has been created. Confirmation id: ' +
          confirmationId,
      )
      reloadCarrierDetails()
      onCreateAncillarySuccess(anillaryId)
    },
    onError: ({ response, message: baseMassage }) => {
      toast.error(`Error: ${response?.data.message || baseMassage}`)
    },
  })
}
