//@ts-nocheck

import '@atlas-ui/fonts'
import './assets/styles/index.css'

import { AppProviders } from 'common/app-providers'
import { ErrorBoundary } from 'common/boundaries/error.boundary'
import React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from './App'
import { envConfig, fetchEnvConfig } from './config/config'
import { EnvConfig } from './config/envConfig'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

fetchEnvConfig<EnvConfig>()
  .then((config) => {
    envConfig.config = config

    return (
      <ErrorBoundary>
        <React.StrictMode>
          <AppProviders>
            <App />
          </AppProviders>
        </React.StrictMode>
      </ErrorBoundary>
    )
  })
  .catch(() => (
    <p style={{ color: 'red', textAlign: 'center' }}>
      Error while fetching global config
    </p>
  ))
  .then((reactElement) => {
    root.render(reactElement)
  })
