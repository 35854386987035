import { MandatoryAncillary, OptionalService } from 'common/types'

const filterSegments = (services: OptionalService[]) =>
  services.filter((service) => !service.ancillaryDescription)

const createServiceMasterMap = (items: MandatoryAncillary[]) => {
  const itemsMap: Record<string, MandatoryAncillary> = {}

  for (const item of items) {
    const { group, subgroup, subcode } = item

    const id = `${group}${subgroup || ''}${subcode || ''}`

    if (!itemsMap[id]) {
      itemsMap[id] = item
    }
  }

  return itemsMap
}

const createServiceMasterList = (items: MandatoryAncillary[]): string[] => {
  const ids: string[] = []

  for (const item of items) {
    const { group, subgroup } = item

    const id = `${group}${subgroup || ''}`

    if (!ids.includes(id)) {
      ids.push(id)
    }
  }

  return ids
}

export const splitOptionalServices = (
  services: OptionalService[],
  mandatoryAncillaryList: MandatoryAncillary[],
): {
  distinctMandatoryServiceList: OptionalService[]
  distinctSegmentServiceList: OptionalService[]
  distinctServiceList: OptionalService[]
  all: OptionalService[]
} => {
  const serviceMasterList = createServiceMasterList(mandatoryAncillaryList)

  const serviceMasterMap = createServiceMasterMap(mandatoryAncillaryList)

  const distinctKeyList: string[] = []
  const distinctServiceList: OptionalService[] = []
  const distinctSegmentServiceList: OptionalService[] = []
  const distinctMandatoryServiceList: OptionalService[] = []

  for (const serviceDetails of services) {
    const { group, subgroup, serviceName, subcode } = serviceDetails

    const key = `${group}${subgroup || ''}${serviceName || ''}`.replace(
      /\s/g,
      '',
    )

    if (!distinctKeyList.includes(key)) {
      const s = group + subgroup

      const smKey = `${group}${subgroup || ''}${subcode || ''}`

      let excludeIsMatched = false

      if (Object.keys(serviceMasterMap).includes(smKey)) {
        const sm = serviceMasterMap[smKey]

        const serviceMaterSubcode = sm.subcode
        const serviceMaterExclude = sm.exclude

        if (
          !!serviceMaterSubcode &&
          !!subcode &&
          serviceMaterSubcode.toLowerCase() === subcode.toLowerCase()
        ) {
          excludeIsMatched = serviceMaterExclude
        }
      }

      if (serviceMasterList.includes(s) && !excludeIsMatched) {
        distinctMandatoryServiceList.push(serviceDetails)
      } else if (serviceDetails.group === 'EQ' && !excludeIsMatched) {
        distinctSegmentServiceList.push(serviceDetails)
      } else if (!excludeIsMatched) {
        distinctServiceList.push(serviceDetails)
      }
      distinctKeyList.push(key)
    }
  }

  const distinctSegmentServiceListFiltered = filterSegments(
    distinctSegmentServiceList,
  )

  return {
    distinctMandatoryServiceList,
    distinctSegmentServiceList: distinctSegmentServiceListFiltered,
    distinctServiceList,
    all: [
      ...distinctMandatoryServiceList,
      ...distinctSegmentServiceListFiltered,
      ...distinctServiceList,
    ],
  }
}
