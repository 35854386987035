import {
  DATE_FORMAT,
  MESSAGE_OVERLAPPING_ANCILLARY_DATES_ERROR,
} from 'common/constants'
import {
  discontinueDateSchema,
  effectiveDateSchema,
  geographyPointSchema,
  selectRequiredSchema,
  selectSchema,
} from 'common/helpers'
import { AncillaryFormValue, OptionalService, SelectOption } from 'common/types'
import { areIntervalsOverlapping, isEqual, parse } from 'date-fns'
import { FormikConfig } from 'formik'
import * as Yup from 'yup'

export const ANCILLARY_NAME_UNIQUE_ERROR =
  'Ancillary already exists. Please enter new codes or edit the current Ancillary for updating'

const titleByTypeSchema = Yup.object({
  EXTERNAL: Yup.string().required('Required'),
  SHORT: Yup.string(),
})

const textByCategorySchema = Yup.object({
  STRAPLINE: Yup.string(),
  MARKETING_AGENT: Yup.string(),
  MARKETING_CONSUMER: Yup.string(),
})

const matchingDataSchema = Yup.object({
  geographyList: Yup.array().of(geographyPointSchema),
  equipmentType: selectSchema,
  cobin: selectSchema,
})

const dimensionsSchema = selectSchema.when(['cabin', 'weightUnit'], {
  is: (cabin: SelectOption, weightUnit: SelectOption) =>
    cabin?.value && !weightUnit?.value,
  then: selectRequiredSchema,
  otherwise: selectSchema,
})

const baggageNumberSchema = (max: number) =>
  Yup.number()
    .nullable()
    .min(1, `Value should be 1 to ${max}`)
    .max(max, `Value should be 1 to ${max}`)

const dimensionsSubvalueSchema = baggageNumberSchema(99).when('dimensionUnit', {
  is: (dimensionUnit: SelectOption) => dimensionUnit?.value,
  then: (yup) => yup.required('Required'),
})

const kglbSchema = selectSchema.when(['cabin', 'dimensionUnit'], {
  is: (cabin: SelectOption, dimensionUnit: SelectOption) =>
    cabin?.value && !dimensionUnit?.value,
  then: selectRequiredSchema,
})

const kglbSubvalueSchema = baggageNumberSchema(999).when('weightUnit', {
  is: (weightUnit: SelectOption) => weightUnit?.value,
  then: (yup) => yup.required('Required'),
})

const baggageDetailsCallback = (isCabinRequired: boolean): Yup.AnySchema =>
  Yup.object().shape(
    {
      cabin: isCabinRequired ? selectRequiredSchema : selectSchema,
      weight: kglbSubvalueSchema,
      height: dimensionsSubvalueSchema,
      length: dimensionsSubvalueSchema,
      dimensionUnit: dimensionsSchema,
      piece: baggageNumberSchema(99),
      width: dimensionsSubvalueSchema,
      weightUnit: kglbSchema,
    },
    [
      ['dimensionUnit', 'cabin'],
      ['weightUnit', 'cabin'],
      ['dimensionUnit', 'weightUnit'],
    ],
  )

const baggageDetailsSchema = Yup.object().when(['group', 'subgroup'], {
  is: (groupOption: SelectOption, subgroupOption: SelectOption<string>) =>
    groupOption.value === 'BG' &&
    (!subgroupOption.value || subgroupOption.value === 'CY'),
  then: baggageDetailsCallback(true),
  otherwise: baggageDetailsCallback(false),
})

const serviceNameSchema = (allAncillaries: OptionalService[]) =>
  selectRequiredSchema.test(
    'is-unique-ancillary',
    ANCILLARY_NAME_UNIQUE_ERROR,
    function ({ value = '' }) {
      const {
        group,
        subgroup,
        key,
        meta: { copyMode, archiveMode },
      } = this.parent

      if ((!copyMode && !!key) || archiveMode) {
        return true
      }

      return !allAncillaries.some((ancillary) => {
        if (ancillary.group === group.value) {
          if (ancillary?.subgroup) {
            return (
              ancillary.subgroup === subgroup.value &&
              ancillary.serviceName === value
            )
          } else {
            return '' === subgroup.value && ancillary.serviceName === value
          }
        }
      })
    },
  )

const ancillaryEffectiveDateSchema = (
  initialEffectiveDate: string,
  initialDiscontinueDate: string,
) =>
  effectiveDateSchema.test(
    'is-ancillary-dates-overlapping',
    MESSAGE_OVERLAPPING_ANCILLARY_DATES_ERROR,
    function (currentEffectiveDate = '') {
      const {
        key,
        meta: { copyMode },
      } = this.parent

      const isEditMode = !copyMode && !!key

      if (isEditMode) {
        if (!initialEffectiveDate || !initialDiscontinueDate) {
          return true
        }

        const currentDiscontinueDate = this.parent.discontinueDate

        const effectiveDate = parse(
          initialEffectiveDate,
          DATE_FORMAT,
          new Date(),
        )
        const discontinueDate = parse(
          initialDiscontinueDate,
          DATE_FORMAT,
          new Date(),
        )

        if (
          !isEqual(currentEffectiveDate, effectiveDate) &&
          !isEqual(currentDiscontinueDate, discontinueDate)
        ) {
          return !areIntervalsOverlapping(
            {
              start: effectiveDate,
              end: discontinueDate,
            },
            {
              start: currentEffectiveDate,
              end: currentDiscontinueDate,
            },
          )
        }
        return true
      }

      return true
    },
  )

export const validationSchema: FormikConfig<AncillaryFormValue>['validationSchema'] =
  ({
    allAncillaries,
    effectiveDate,
    discontinueDate,
  }: {
    allAncillaries: OptionalService[]
    effectiveDate: string
    discontinueDate: string
  }) =>
    Yup.object({
      group: selectRequiredSchema,
      subgroup: selectSchema,
      subcode: Yup.string(),
      serviceTypeCode: Yup.string(),
      serviceName: serviceNameSchema(allAncillaries),
      titleByType: titleByTypeSchema,
      textByCategory: textByCategorySchema,
      effectiveDate: ancillaryEffectiveDateSchema(
        effectiveDate,
        discontinueDate,
      ),
      discontinueDate: discontinueDateSchema('effectiveDate'),
      matchingData: matchingDataSchema,
      baggageDetails: baggageDetailsSchema,
    })
