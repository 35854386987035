import { toast } from '@atlas-design-system/react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAncillaryContext, useCarrierDetailsContext } from 'common/context'
import { editAncillary } from 'common/services'
import {
  EditAncillaryRequest,
  EditAncillaryResponse,
  ErrorMessage,
} from 'common/types'

export const useEditAncillary = (): UseMutationResult<
  EditAncillaryResponse,
  AxiosError<ErrorMessage>,
  EditAncillaryRequest
> => {
  const { reloadCarrierDetails } = useCarrierDetailsContext()
  const { onUpdateAncillarySuccess } = useAncillaryContext()

  return useMutation<
    EditAncillaryResponse,
    AxiosError<ErrorMessage>,
    EditAncillaryRequest
  >((data: EditAncillaryRequest) => editAncillary(data), {
    onSuccess: ({ confirmationId, entityId }) => {
      toast.success(
        `Success! Ancillary has been updated. Confirmation id:(${confirmationId})`,
      )
      reloadCarrierDetails()
      onUpdateAncillarySuccess(entityId)
    },
    onError: ({ response, message: baseMassage }) => {
      toast.error(`Error: ${response?.data.message || baseMassage}`)
    },
  })
}
