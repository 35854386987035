import { ApiTextByCategory } from 'common/types'
import { TextByCategory } from 'common/types'

export const TextByCategoryModel = (
  textByCategory: ApiTextByCategory,
): TextByCategory => {
  const {
    ATPCO = '',
    STRAPLINE = '',
    MARKETING_AGENT = '',
    MARKETING_CONSUMER = '',
    UPSELL = '',
    EXTERNAL_COMMERCIAL = '',
    EXTERNAL_SHORT_NAME = '',
    RULE = '',
  } = textByCategory

  return {
    ATPCO,
    STRAPLINE,
    MARKETING_AGENT,
    MARKETING_CONSUMER,
    UPSELL,
    EXTERNAL_COMMERCIAL,
    EXTERNAL_SHORT_NAME,
    RULE,
  }
}
