import { toast } from '@atlas-design-system/react'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAncillaryContext, useCarrierDetailsContext } from 'common/context'
import { useAuthUser } from 'common/hooks'
import { deleteAncillary } from 'common/services'
import {
  DeleteAncillaryRequest,
  DeleteAncillaryResponse,
  ErrorMessage,
} from 'common/types'
import { useCallback } from 'react'

export const useDeleteAncillary = (): {
  handleDeleteAncillary: (discontinueDate: string) => void
} => {
  const { reloadCarrierDetails } = useCarrierDetailsContext()
  const { selectedAncillary, onDeleteAncillarySuccess, setPendingAction } =
    useAncillaryContext()
  const user = useAuthUser()

  const deleteMutation = useMutation<
    DeleteAncillaryResponse,
    AxiosError<ErrorMessage>,
    DeleteAncillaryRequest
  >((data: DeleteAncillaryRequest) => deleteAncillary(data), {
    onSuccess: ({ confirmationId, entityId: ancillaryKey }) => {
      toast.success(
        'Success! Ancillary has been Archived. Confirmation id: ' +
          confirmationId,
      )
      reloadCarrierDetails()
      onDeleteAncillarySuccess(ancillaryKey)
    },
    onError: ({ response, message: baseMassage }) => {
      toast.error(`Error: ${response?.data.message || baseMassage}`)
    },
  })

  const handleDeleteAncillary = useCallback(
    (discontinueDate: string) => {
      if (!user || !discontinueDate || !selectedAncillary?.key) {
        return
      }
      deleteMutation.mutate({
        discontinueDate,
        id: selectedAncillary.key,
      })
      setPendingAction()
    },
    [selectedAncillary, user, deleteMutation, setPendingAction],
  )

  return { handleDeleteAncillary }
}
