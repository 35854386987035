import { Spinner, Typography } from '@atlas-design-system/react'
import { useAuth, useCarrierDetailsContext } from 'common/context'
import React, { useMemo } from 'react'

import { CarrierSelect } from './components/carrier-select/carrier-select.component'
import { GridArea } from './components/dashboard-gridarea/dashboard-gridarea.component'
import { useLogoImage } from './hooks'

export const Dashboard: React.FC = () => {
  const { user } = useAuth()
  const { selectedCarrier, isFetching: isCarrierDetailsLoading } =
    useCarrierDetailsContext()
  const { isFetching: isImageLoading } = useLogoImage()

  const userName = `${user?.firstName} ${user?.lastName}`

  const isLoading = useMemo(() => {
    return isImageLoading || isCarrierDetailsLoading
  }, [isCarrierDetailsLoading, isImageLoading])

  return useMemo(() => {
    if (isLoading && !!selectedCarrier) {
      return <Spinner data-testid="dashboard-spinner" />
    } else {
      return (
        <div data-testid="dashboard-page">
          <div className="mmp-ui-page-header">
            <div className="row">
              <div className="col-xs-12">
                <Typography variant="displayText6">Dashboard</Typography>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <Typography variant="contentText1">
                  Welcome, {userName}
                </Typography>
              </div>
            </div>
          </div>

          {selectedCarrier ? <GridArea /> : <CarrierSelect />}
        </div>
      )
    }
  }, [isLoading, selectedCarrier, userName])
}
