export enum MarketingUpsellActionType {
  SET_SELECTED_FAMILY = 'SET_SELECTED_FAMILY',
}

export type SetSelectedFamilyAction = {
  type: MarketingUpsellActionType.SET_SELECTED_FAMILY
  payload: {
    id: string
  }
}

export type MarketingUpsellAction = SetSelectedFamilyAction

export type MarketingUpsellState = {
  selectedFamilyId?: string
}

export const INITIAL_STATE = {
  selectedFamilyId: undefined,
}

export const marketingUpsellReducer = (
  state: MarketingUpsellState,
  action: MarketingUpsellAction,
): MarketingUpsellState => {
  const { type, payload } = action

  switch (type) {
    case MarketingUpsellActionType.SET_SELECTED_FAMILY:
      return {
        ...state,
        selectedFamilyId: payload.id,
      }

    default:
      return state
  }
}
