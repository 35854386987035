import { API, QUERY_KEYS, SERVICES } from 'common/api'
import { MarketingUpsellDetails } from 'common/types'

export const fetchMarketingUpsell = async ({
  familyId,
  carrierCode,
}: {
  familyId: string
  carrierCode: string
}): Promise<MarketingUpsellDetails> => {
  try {
    const { data } = await API().get(
      `${SERVICES.BRANDED_FARES_SERVICE}/${QUERY_KEYS.MARKETING_UPSELL}`,
      { params: { carrierCode, familyId } },
    )

    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
