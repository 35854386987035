import { FormControl, Select } from '@atlas-design-system/react'
import {
  EMPTY_SELECT_OPTION,
  LOCATION_TYPE_SELECT_OPTIONS,
} from 'common/constants'
import { noop } from 'common/helpers'
import { SelectOption } from 'common/types'
import { useField } from 'formik'
import React, { useCallback } from 'react'

type LocationTypeSelectProps = {
  label: string
  name: string
  disabled: boolean
  onChange: (selectedOption: SelectOption) => void
}

export const LocationTypeSelect: React.FC<LocationTypeSelectProps> = ({
  label,
  onChange,
  disabled,
  ...props
}) => {
  const [field, , helpers] = useField(props)
  const { setValue } = helpers

  const handleChange = useCallback(
    (selectedOption: SelectOption) => {
      setValue(selectedOption)
      onChange(selectedOption)
    },
    [setValue, onChange],
  )

  return (
    <>
      <FormControl label={label} disabled={disabled}>
        <Select
          next
          data-testid="location-type-select"
          {...field}
          {...props}
          onChange={handleChange}
          isDisabled={disabled}
          onBlur={noop}
          options={[EMPTY_SELECT_OPTION, ...LOCATION_TYPE_SELECT_OPTIONS]}
        />
      </FormControl>
    </>
  )
}
