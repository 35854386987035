import {
  ActionIconGroup,
  AddIcon,
  CloseIcon,
  CloseV2Icon,
  CopyIcon,
} from '@atlas-design-system/react'
import { useCarrierDetailsContext } from 'common/context'
import { useAuth } from 'common/context'
import { useFamilyContext } from 'common/context/family-context/family-context'
import { FamilyStatus, PendingAction, Permission } from 'common/enums'
import { isATPCOFamily } from 'common/helpers'
import { useDirtyForm, useFamily } from 'common/hooks'
import { useAuthUser } from 'common/hooks/use-auth-user'
import { UpdateFamilyStatusRequest } from 'common/types'
import { useCallback, useMemo } from 'react'

import { usePromoteFamily } from '../families-list/components/promote-family-modal/use-promote-family'

const disabledColor = 'var(--contrastColor40)'

export const ActionButtons: React.FC = () => {
  const {
    setCopyMode,
    copyMode,
    selectedFamily,
    resetSelectedFamily,
    setPendingAction,
  } = useFamilyContext()

  const { familyList } = useFamily()
  const user = useAuthUser()
  const promoteFamilyMutation = usePromoteFamily()
  const { archiveMode } = useCarrierDetailsContext()
  const { dirtyForm } = useDirtyForm()
  const { hasPermissionTo } = useAuth()

  const hasWritePermission = useMemo(
    () => hasPermissionTo(Permission.WRITE_FAREFAMILIES),
    [hasPermissionTo],
  )

  const isFromATPCO = useMemo(
    () => !!selectedFamily && isATPCOFamily(selectedFamily),
    [selectedFamily],
  )

  const canDelete = useMemo(() => {
    if (!hasWritePermission) {
      return false
    }
    if (isFromATPCO || copyMode || archiveMode || !selectedFamily?.id) {
      return false
    }
    return true
  }, [
    copyMode,
    archiveMode,
    isFromATPCO,
    selectedFamily?.id,
    hasWritePermission,
  ])

  const canCopy = useMemo(() => {
    if (!hasWritePermission) {
      return false
    }
    if (copyMode) {
      return false
    }
    if (selectedFamily) {
      return true
    }
    return false
  }, [copyMode, selectedFamily, hasWritePermission])

  const canSuppress = useMemo(() => {
    if (!hasWritePermission) {
      return false
    }
    if (!isFromATPCO || archiveMode) {
      return false
    }
    if (selectedFamily?.status !== FamilyStatus.LIVE) {
      return false
    }
    return familyList.some(
      (family) => selectedFamily.id === String(family?.linkedFareFamilyId),
    )
  }, [familyList, isFromATPCO, archiveMode, selectedFamily, hasWritePermission])

  const canAdd = useMemo(() => {
    if (!hasWritePermission) {
      return false
    }
    return !archiveMode
  }, [archiveMode, hasWritePermission])

  const handleSuppress = useCallback(() => {
    if (!canSuppress || !selectedFamily || !user) {
      return
    }

    const data: UpdateFamilyStatusRequest = {
      status: FamilyStatus.SUPPRESSED,
      id: selectedFamily.id,
    }

    promoteFamilyMutation.mutate(data)
  }, [canSuppress, promoteFamilyMutation, selectedFamily, user])

  const onSuppress = () => canSuppress && handleSuppress()
  const onNew = () => {
    if (!canAdd) {
      return
    }
    if (dirtyForm) {
      setPendingAction(PendingAction.NEW_BUTTON_CLICK)
    } else {
      resetSelectedFamily()
    }
  }
  const onCopy = () => canCopy && setCopyMode(true)
  const onDelete = () => canDelete && setPendingAction(PendingAction.DELETE)

  const actionButtons = [
    {
      icon: (
        <CloseV2Icon
          data-testid="suppress-icon"
          fill={canSuppress ? '' : disabledColor}
        />
      ),
      key: 'suppress',
      tooltip: 'Suppress',
      action: onSuppress,
    },
    {
      icon: (
        <AddIcon data-testid="new-icon" fill={canAdd ? '' : disabledColor} />
      ),
      key: 'new',
      tooltip: 'New',
      action: onNew,
    },
    {
      icon: (
        <CopyIcon data-testid="copy-icon" fill={canCopy ? '' : disabledColor} />
      ),
      key: 'copy',
      tooltip: 'Copy',
      action: onCopy,
    },
    {
      icon: (
        <CloseIcon
          data-testid="delete-icon"
          fill={canDelete ? '' : disabledColor}
        />
      ),
      key: 'delete',
      tooltip: 'Delete',
      action: onDelete,
    },
  ]

  return (
    <ActionIconGroup data-testid="action-buttons" actions={actionButtons} />
  )
}
