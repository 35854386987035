import { BrandsCategory } from 'common/enums'
import { ApiBrandDescription, BrandDescription } from 'common/types'

import { AdditionalDetailModel } from './additional-detail.model'
import { ImageDetailsModel } from './image-details.model'
import { TextByCategoryModel } from './text-by-category.model'
import { TitleByTypeModel } from './title-by-type.model'

export const BrandDescriptionModel = (
  brandDescription: ApiBrandDescription,
): BrandDescription => {
  const {
    additionalDetailList = [],
    brandCode = '',
    brandCodeExcluding = '',
    brandRule = '',
    imageDetailsList = [],
    name = '',
    textByCategory = {},
    titleByType = {},
  } = brandDescription

  return {
    additionalDetailList: additionalDetailList.map(AdditionalDetailModel),
    brandCode,
    brandCodeExcluding,
    brandRule,
    imageDetailsList: imageDetailsList.map((imageDetails) =>
      ImageDetailsModel<BrandsCategory>(imageDetails),
    ),
    name,
    textByCategory: TextByCategoryModel(textByCategory),
    titleByType: TitleByTypeModel(titleByType),
  }
}
