import {
  ActionIconGroup,
  AddIcon,
  CloseIcon,
  CopyIcon,
  toast,
} from '@atlas-design-system/react'
import { useAncillaryContext, useAuth } from 'common/context'
import { useCarrierDetailsContext } from 'common/context/carrier-details/carrier-details.context'
import { PendingAction, Permission } from 'common/enums'
import { useMemo } from 'react'

const disabledColor = '--contrastColor40'

export const ActionButtons: React.FC = () => {
  const {
    selectedAncillary,
    resetSelectedAncillary,
    setPendingAction,
    copyMode,
    setCopyMode,
  } = useAncillaryContext()
  const { hasPermissionTo } = useAuth()

  const hasWritePermission = useMemo(
    () => hasPermissionTo(Permission.WRITE_ANCILLARIES),
    [hasPermissionTo],
  )

  const { data, dirtyForm, archiveMode } = useCarrierDetailsContext()

  const canCreateNew = useMemo(() => {
    if (!hasWritePermission) {
      return false
    }

    return !archiveMode
  }, [archiveMode, hasWritePermission])

  const canCopy = useMemo(() => {
    if (!hasWritePermission) {
      return false
    }

    return selectedAncillary && !copyMode
  }, [selectedAncillary, copyMode, hasWritePermission])

  const canDelete = useMemo(() => {
    if (!hasWritePermission) {
      return false
    }

    return canCopy && !!selectedAncillary && !archiveMode
  }, [selectedAncillary, canCopy, archiveMode, hasWritePermission])

  const isAncillaryAssociatedWithBrand = useMemo(
    () =>
      data?.serviceBrandMap && selectedAncillary?.key
        ? !!data.serviceBrandMap[selectedAncillary.key]
        : false,

    [data, selectedAncillary],
  )

  const onNew = () => {
    if (!canCreateNew) {
      return
    }

    if (dirtyForm) {
      setPendingAction(PendingAction.NEW_BUTTON_CLICK)
    } else {
      resetSelectedAncillary()
    }
  }

  const onCopy = () => canCopy && setCopyMode(true)
  const onDelete = () => {
    if (canDelete) {
      if (selectedAncillary?.key === selectedAncillary?.subcode) {
        toast.error('Read-only ancillary cannot be deleted')
        return
      }
      if (isAncillaryAssociatedWithBrand) {
        return setPendingAction(PendingAction.ARCHIVE)
      }
      return setPendingAction(PendingAction.DELETE)
    }
  }

  const actionButtons = [
    {
      icon: (
        <AddIcon
          data-testid="ancillary-new-icon"
          fill={`var(${!canCreateNew && disabledColor})`}
        />
      ),
      key: 'new',
      tooltip: 'New',
      action: () => canCreateNew && onNew(),
    },
    {
      icon: (
        <CopyIcon
          data-testid="ancillary-copy-icon"
          fill={`var(${!canCopy && disabledColor})`}
        />
      ),
      key: 'copy',
      tooltip: 'Copy',
      action: () => canCopy && onCopy(),
    },
    {
      icon: (
        <CloseIcon
          data-testid="ancillary-delete-icon"
          fill={`var(${!canDelete && disabledColor})`}
        />
      ),
      key: 'delete',
      tooltip: 'Delete',
      action: () => canDelete && onDelete(),
    },
  ]

  return (
    <ActionIconGroup
      actions={actionButtons}
      className="mmp-ancillary-collapsible-action-group"
    />
  )
}
