import { Button } from '@atlas-design-system/react'
import { CellContext } from '@tanstack/react-table'
import { ServiceAvailability } from 'common/enums'
import {
  OptionalService,
  OptionalServicesByCategoryValue,
  OptionalServiceValue,
} from 'common/types'
import { useField } from 'formik'
import React, { useCallback, useMemo } from 'react'

type ChargeableButtonProps = CellContext<OptionalService, any> & {
  onEditChargeableClick?: (
    serviceId: string,
    name: keyof OptionalServicesByCategoryValue,
  ) => void
  name: keyof OptionalServicesByCategoryValue
}

export const ChargeableButton: React.FC<ChargeableButtonProps> = ({
  row,
  onEditChargeableClick,
  name,
}) => {
  const [field] = useField<OptionalServiceValue[]>(
    `optionalServicesByCategory.${name}`,
  )

  const { value = [] } = field

  const disabled = useMemo(() => {
    const item = value.find(({ meta: { id } }) => id === row.id)
    return item?.serviceAvailability !== ServiceAvailability.CHARGEABLE
  }, [row, value])

  const handleClick = useCallback(() => {
    if (onEditChargeableClick) {
      onEditChargeableClick(row.id, name)
    }
  }, [row, onEditChargeableClick, name])

  if (!row.getIsSelected()) {
    return <></>
  }

  return (
    <Button
      disabled={disabled}
      appearance="tertiary"
      size="small"
      onClick={handleClick}
    >
      Edit chargeable
    </Button>
  )
}
