import { ApiGdsDetails } from 'common/types'
import { GdsDetails } from 'common/types'

export const GdsDetailsModel = (gdsDetails: ApiGdsDetails): GdsDetails => {
  const { code = '', pcc = '' } = gdsDetails

  return {
    code,
    pcc,
  }
}
