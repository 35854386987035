import { Banner, Button, ButtonGroup, Form } from '@atlas-design-system/react'
import {
  CollapsibleFormBlock,
  FormCancelDialog,
  FormHeader,
  MatchingData,
} from 'common/components'
import {
  useAuth,
  useCarrierDetailsContext,
  useSegmentBrandingContext,
} from 'common/context'
import { PendingAction, Permission } from 'common/enums'
import { useWatchForm } from 'common/hooks'
import { SegmentBrandingFormValue } from 'common/types'
import { FormikProvider, useFormik } from 'formik'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { ActionButtons } from '../../action-buttons/action-buttons.component'
import { SegmentBrandConfirmModal, SegmentBrandingBuilder } from './components'
import styles from './segment-branding-form.module.css'
import { validationSchema } from './segment-branding-form.schema'
import { useSegmenBrandingForm } from './use-segment-brand-form'

type SegmentBrandingFormProps = {
  onSubmit: (values: SegmentBrandingFormValue) => void
  initialValues: SegmentBrandingFormValue
}

export const SegmentBrandingForm: FC<SegmentBrandingFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const { dirtyForm, archiveMode } = useCarrierDetailsContext()
  const { setPendingAction, copyMode, selectedSegmentBrand } =
    useSegmentBrandingContext()
  const [isCustomError, setCustomError] = useState(false)
  const { hasPermissionTo } = useAuth()

  const hasWritePermission = useMemo(
    () => hasPermissionTo(Permission.WRITE_SEGMENT_BRANDING),
    [hasPermissionTo],
  )

  const handleSubmit = useCallback(
    (formValues: SegmentBrandingFormValue) => {
      if (isCustomError) {
        return
      }

      if (!hasWritePermission) {
        return
      }

      if (!copyMode && selectedSegmentBrand) {
        setPendingAction(PendingAction.EDIT)
        return
      }

      onSubmit(formValues)
    },
    [
      hasWritePermission,
      copyMode,
      selectedSegmentBrand,
      onSubmit,
      setPendingAction,
      isCustomError,
    ],
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  })

  const {
    cancelDialogVisible,
    hideCancelDialog,
    showCancelDialog,
    onFormReset,
    isMarketingFieldDisabled,
    isCabinDisabled,
    isEquipmentFieldDisabled,
    fieldsDisabled,
    titleFieldDisabled,
    isNonUniqueSegmentBrand,
    segmentBrandError,
    datesOverlapping,
  } = useSegmenBrandingForm({
    formik,
    hasWritePermission,
  })

  useEffect(() => {
    setCustomError(datesOverlapping || !!isNonUniqueSegmentBrand)
  }, [datesOverlapping, isNonUniqueSegmentBrand])

  useWatchForm<SegmentBrandingFormValue>(formik)

  const { values, touched } = formik

  const submitDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }

    if (archiveMode && copyMode && !isNonUniqueSegmentBrand) {
      return false
    }

    if (archiveMode) {
      return true
    }

    return false
  }, [archiveMode, copyMode, hasWritePermission, isNonUniqueSegmentBrand])

  const cancelDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }

    if (dirtyForm) {
      return false
    }

    if (archiveMode) {
      return true
    }
    return false
  }, [archiveMode, dirtyForm, hasWritePermission])

  const submitForm = useCallback(() => {
    if (segmentBrandError) {
      return
    }
    onSubmit(values)
  }, [onSubmit, segmentBrandError, values])

  const touchedFields = useMemo(() => {
    return Object.keys(touched).some(
      (fieldName) =>
        fieldName === 'serviceName' ||
        'titleByType.EXTERNAL' ||
        'titleByType.SHORT' ||
        'effectiveDate' ||
        'discontinueDate',
    )
  }, [touched])

  const actionButtonsDisabled = useMemo(
    () => !hasWritePermission,
    [hasWritePermission],
  )

  return (
    <>
      {touchedFields && !!segmentBrandError && (
        <Banner
          appearance="error"
          heading={segmentBrandError}
          style={{ marginBottom: 'var(--spaceL)' }}
        ></Banner>
      )}
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <CollapsibleFormBlock title="Segment Branding" defaultOpened={true}>
            <FormHeader
              title=""
              actionButtons={<ActionButtons disabled={actionButtonsDisabled} />}
            />
            <SegmentBrandingBuilder
              formik={formik}
              titleFieldDisabled={titleFieldDisabled}
              fieldsDisabled={fieldsDisabled}
            />
          </CollapsibleFormBlock>
          <CollapsibleFormBlock
            title="Matching Data"
            className={styles.collapsible}
          >
            <FormHeader title="Geography" className={styles.formHeader} />
            <MatchingData
              formik={formik}
              fieldsDisabled={fieldsDisabled}
              equipmentFieldDisabled={isEquipmentFieldDisabled}
              cabinFieldDisabled={isCabinDisabled}
              marketingFieldDisabled={isMarketingFieldDisabled}
              marketingPartnersfield
            />
          </CollapsibleFormBlock>
          <ButtonGroup align="right">
            <Button
              appearance="primary"
              type="submit"
              disabled={submitDisabled}
            >
              Save
            </Button>
            <Button onClick={showCancelDialog} disabled={cancelDisabled}>
              Cancel
            </Button>
          </ButtonGroup>
        </Form>
      </FormikProvider>
      <FormCancelDialog
        hidden={!cancelDialogVisible}
        onConfirm={onFormReset}
        onDismiss={hideCancelDialog}
      />
      <SegmentBrandConfirmModal
        submitForm={submitForm}
        resetForm={onFormReset}
      />
    </>
  )
}
