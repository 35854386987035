import axios, { AxiosInstance } from 'axios'
import { envConfig } from 'config/config'

export const SERVICES = {
  AUTH_SERVICE: 'mmp-auth-service',
  BRANDED_FARES_SERVICE: 'branded-fares-service',
  REFERENCE_DATA_SERVICE: 'reference-service',
  PROVISIONING_CARRIER_SERVICE: 'provisioning-carrier-service',
  CDN_TOKEN_SERVICE: 'cdn-token-service',
  MANDATORY_ANCILLARY_SERVICE: 'mandatory-ancillary-service',
  AGENT_STORE_FRONT_SERVICE: 'agent-store-front-service',
}

export const QUERY_KEYS = {
  CARRIER_DETAILS: 'carrier-details',
  TOKEN: 'token',
  ANCILLARY: 'ancillary',
  MANDATORY: 'mandatory',
  AGENT_STORE_FRONT: 'agent-store-front',
  MARKETING_UPSELL: 'marketingUpsell',
  TOKENS: 'tokens',
  SUPPLIERS: 'suppliers',
  CARRIERS: 'carriers',
  IMAGES: 'images',
  SHELF: 'shelf',
}

export const API = (): AxiosInstance =>
  axios.create({
    baseURL: envConfig.config.API_BASE_URL,
    withCredentials: true,
    xsrfHeaderName: 'X-XSRF-TOKEN',
    xsrfCookieName: 'XSRF-TOKEN',
    headers: {
      TVPAuthorization: sessionStorage.TVPAuthorization,
    },
  })
