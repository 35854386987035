import { Dialog } from '@atlas-design-system/react'
import {
  MESSAGE_CONFIRM_ARCHIVE_BRAND,
  MESSAGE_CONFIRM_DELETE_BRAND,
  MESSAGE_CONFIRM_EDIT_BRAND,
  MESSAGE_CONFIRM_FORM_RESET,
} from 'common/constants'
import { useBrandContext } from 'common/context'
import { FamilyStatus, PendingAction } from 'common/enums'
import { useDeleteBrand } from 'modules/brands/hooks'
import React, { useCallback, useMemo } from 'react'
type BrandConfirmModalProps = {
  submitForm: () => void
  resetForm: () => void
}

export const BrandConfirmModal: React.FC<BrandConfirmModalProps> = ({
  submitForm,
  resetForm,
}) => {
  const {
    pendingAction,
    setPendingAction,
    selectedBrand,
    selectedFamily,
    switchSelectedBrand,
  } = useBrandContext()
  const deleteBrand = useDeleteBrand()

  const modalHidden = useMemo(() => {
    const hasPendingAction = !!pendingAction

    if (pendingAction && pendingAction === PendingAction.RESET_FORM) {
      return true
    }

    return (
      !hasPendingAction && pendingAction !== PendingAction.SET_BRAND_COPY_MODE
    )
  }, [pendingAction])

  const isMoveToArchive = useMemo(
    () =>
      selectedFamily?.status === FamilyStatus.LIVE ||
      selectedFamily?.status === FamilyStatus.TEST,
    [selectedFamily?.status],
  )

  const title = useMemo(() => {
    switch (pendingAction) {
      case PendingAction.EDIT_BRAND:
        return 'Update Brand'
      case PendingAction.DELETE_BRAND:
        if (isMoveToArchive) {
          return 'Archive Brand'
        }
        return 'Delete Brand'
      default:
        return 'Warning'
    }
  }, [pendingAction, isMoveToArchive])

  const handleDismiss = useCallback(() => {
    setPendingAction()
  }, [setPendingAction])

  const confirmEdit = useCallback(() => {
    submitForm()
    setPendingAction()
  }, [submitForm, setPendingAction])

  const confirmDelete = useCallback(() => {
    if (!selectedBrand) {
      return
    }
    deleteBrand(selectedBrand.brandId)
    setPendingAction()
  }, [deleteBrand, selectedBrand, setPendingAction])

  const confirmBrandSwitch = useCallback(() => {
    switchSelectedBrand()
  }, [switchSelectedBrand])

  const confirmFormReset = useCallback(() => {
    resetForm()
    setPendingAction()
  }, [resetForm, setPendingAction])

  const handleConfirm = useCallback(() => {
    switch (pendingAction) {
      case PendingAction.EDIT_BRAND:
        return confirmEdit()
      case PendingAction.DELETE_BRAND:
        return confirmDelete()
      case PendingAction.SELECT_ANOTHER_BRAND:
        return confirmBrandSwitch()
      case PendingAction.NEW_BUTTON_CLICK:
        return confirmFormReset()
      default:
        return setPendingAction()
    }
  }, [
    pendingAction,
    confirmEdit,
    confirmDelete,
    confirmBrandSwitch,
    setPendingAction,
    confirmFormReset,
  ])

  const modalMessage = useMemo(() => {
    switch (pendingAction) {
      case PendingAction.EDIT_BRAND: {
        return MESSAGE_CONFIRM_EDIT_BRAND
      }
      case PendingAction.DELETE_BRAND: {
        if (isMoveToArchive) {
          return MESSAGE_CONFIRM_ARCHIVE_BRAND
        }
        return MESSAGE_CONFIRM_DELETE_BRAND
      }
      case PendingAction.NEW_BUTTON_CLICK:
      case PendingAction.SELECT_ANOTHER_BRAND: {
        return MESSAGE_CONFIRM_FORM_RESET
      }
      default: {
        return ''
      }
    }
  }, [isMoveToArchive, pendingAction])

  return (
    <Dialog
      title={title}
      hidden={modalHidden}
      onDismiss={handleDismiss}
      onConfirm={handleConfirm}
      appearance="warning"
      id="brand-confirm-dialog"
      dismissText="Cancel"
    >
      {modalMessage}
    </Dialog>
  )
}
