import { Button, ButtonGroup } from '@atlas-design-system/react'
import { FormCancelDialog } from 'common/components/form-components'
import { useAuth, useCarrierDetailsContext } from 'common/context'
import { PendingAction, Permission } from 'common/enums'
import { useCarriers, useWatchForm } from 'common/hooks'
import { CarrierFormValue } from 'common/types'
import { Form, FormikProvider, useFormik } from 'formik'
import { useCallback, useEffect, useMemo } from 'react'

import styles from './carrier-form.module.css'
import { validationSchema } from './carrier-form.schema'
import { CarrierBuilder } from './components/carrier-builder/carrier-builder.component'
import { CarrierFormModal } from './components/carrier-confirm-modal/carrier-confirm-modal.component'
import { CountryList } from './components/country-list/country-list.component'
import { DistributionChannelSelect } from './components/distribution-channel-select/distribution-channel-select.component'
import { EmailList } from './components/email-list/email-list.component'
import { PseudoCityList } from './components/pseudo-city-list/pseudo-city-list.component'
import { UpsellList } from './components/upsell-list/upsell-list.component'
import { useCarrierForm } from './use-carrier-form'

type CarrierFormProps = {
  initialValues: CarrierFormValue
  onSubmit: (values: CarrierFormValue) => void
}

export const CarrierForm: React.FC<CarrierFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const { carrierList, isFetching } = useCarriers()
  const { selectedCarrier, setPendingAction, pendingAction } =
    useCarrierDetailsContext()
  const { hasPermissionTo } = useAuth()

  const handleSubmit = useCallback(
    (formValues: CarrierFormValue) => {
      if (!!selectedCarrier) {
        setPendingAction(PendingAction.EDIT)
        return
      }

      onSubmit(formValues)
    },
    [onSubmit, selectedCarrier, setPendingAction],
  )

  const formik = useFormik<CarrierFormValue>({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: validationSchema(carrierList),
    enableReinitialize: true,
  })

  useWatchForm<CarrierFormValue>(formik)

  const {
    cancelDialogVisible,
    onFormReset,
    hideCancelDialog,
    showCancelDialog,
  } = useCarrierForm({
    formik,
    hasWritePermissions: true,
  })

  const { values, resetForm } = formik

  const submitForm = useCallback(() => {
    onSubmit(values)
  }, [onSubmit, values])

  const saveDisabled = useMemo(() => {
    if (!!initialValues.id) {
      return !hasPermissionTo(Permission.UPDATE_CARRIERS)
    } else {
      return !hasPermissionTo(Permission.CREATE_CARRIERS)
    }
  }, [hasPermissionTo, initialValues.id])

  useEffect(() => {
    if (
      pendingAction &&
      pendingAction === PendingAction.RESET_FORM &&
      !isFetching
    ) {
      resetForm({ values: initialValues })
      setPendingAction()
    }
  }, [initialValues, isFetching, pendingAction, resetForm, setPendingAction])

  return (
    <div>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} autoComplete="off">
          <CarrierBuilder formik={formik} />
          <DistributionChannelSelect
            formik={formik}
            disabled={!hasPermissionTo(Permission.CREATE_CARRIERS)}
          />
          <UpsellList formik={formik} />
          <EmailList formik={formik} />
          <PseudoCityList formik={formik} />
          <CountryList formik={formik} />
          <ButtonGroup align="right" className={styles.formSubmitButtonGroup}>
            <Button appearance="primary" type="submit" disabled={saveDisabled}>
              Save
            </Button>
            <Button onClick={showCancelDialog} disabled={false}>
              Cancel
            </Button>
          </ButtonGroup>
        </Form>
      </FormikProvider>
      <FormCancelDialog
        hidden={!cancelDialogVisible}
        onConfirm={onFormReset}
        onDismiss={hideCancelDialog}
      />
      <CarrierFormModal submitForm={submitForm} />
    </div>
  )
}
