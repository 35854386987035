import { CarrierFormValue } from 'common/types'
import { FormikProps } from 'formik'
import React from 'react'

import { UpsellListForm } from './upsell-list-form/upsell-list-form.component'

type EmailListProps = {
  formik: FormikProps<CarrierFormValue>
}

export const UpsellList: React.FC<EmailListProps> = ({ formik }) => {
  return (
    <div>
      <UpsellListForm formik={formik} />
    </div>
  )
}
