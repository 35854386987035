import { LocationType } from 'common/enums'
import { ApiPointOfSale } from 'common/types'
import { PointOfSale } from 'common/types'

export const PointOfSaleModel = (pointOfSale: ApiPointOfSale): PointOfSale => {
  const {
    gds = '',
    iataNumber = '',
    locationCode = '',
    locationType = LocationType.EMPTY,
    pcc = '',
    permitted = 'N',
  } = pointOfSale

  return {
    gds: gds.trim(),
    iataNumber: iataNumber.trim(),
    locationCode: locationCode.trim(),
    locationType,
    pcc: pcc.trim(),
    permitted,
  }
}
