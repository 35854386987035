import { useAncillaryContext, useCarrierDetailsContext } from 'common/context'
import { useReferenceData } from 'common/hooks/use-reference-data.hook'
import { AncillaryFormValue } from 'common/types'
import { useSubmitAncillary } from 'modules/ancillaries/hooks'
import { FC, useCallback, useEffect, useState } from 'react'

import { AncillaryForm } from './components/ancillary-form/ancillary-form.component'
import { AncillaryFormModel } from './models'

export const AncillaryFormWrapper: FC = () => {
  const { selectedAncillary, copyMode } = useAncillaryContext()
  const { archiveMode } = useCarrierDetailsContext()
  const [initialValues, setInitialValues] = useState<AncillaryFormValue>(() =>
    AncillaryFormModel({
      copyMode,
      archiveMode,
    }),
  )

  const { groupSelectOptions, subGroupSelectOptions } = useReferenceData()
  const { submitAncillary } = useSubmitAncillary()

  const resetInitialValues = useCallback(() => {
    setInitialValues(
      AncillaryFormModel({
        selectedAncillary,
        groupSelectOptions,
        subGroupSelectOptions,
        copyMode,
        archiveMode,
      }),
    )
  }, [
    selectedAncillary,
    groupSelectOptions,
    subGroupSelectOptions,
    copyMode,
    archiveMode,
  ])

  useEffect(() => {
    if (selectedAncillary?.key !== initialValues.key || copyMode) {
      resetInitialValues()
    }
  }, [selectedAncillary, copyMode, resetInitialValues, initialValues.key])

  const handleSubmit = useCallback(
    (values: AncillaryFormValue) => {
      submitAncillary(values)
    },
    [submitAncillary],
  )

  return <AncillaryForm onSubmit={handleSubmit} initialValues={initialValues} />
}
