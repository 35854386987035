import { FamilyStatus, ServiceSource } from 'common/enums'
import { ApiFamily } from 'common/types'
import { Family } from 'common/types'

import { BrandDetailsModel } from './brand-details.model'
import { GeographyPointModel } from './geography-point.model'
import { PointOfSaleModel } from './point-of-sale.model'

export const FamilyModel = (family: ApiFamily): Family => {
  const {
    accountTypeCodeList = [],
    atpcoSequenceNumber,
    brandDetailsList = [],
    discontinueSearchDate = '',
    discontinueTravelDate = '',
    effectiveSearchDate = '',
    effectiveTravelDate = '',
    geographyList = [],
    id = '',
    linkedFareFamilyId,
    name = '',
    passengerTypeCode = '',
    pointOfSaleList = [],
    source = ServiceSource.TRAVELPORT,
    status = FamilyStatus.DRAFT,
    version = '',
  } = family

  return {
    accountTypeCodeList,
    atpcoSequenceNumber,
    brandDetailsList: brandDetailsList
      .map(BrandDetailsModel)
      .sort((a, b) => a.sequenceNumber - b.sequenceNumber),
    discontinueSearchDate,
    discontinueTravelDate,
    effectiveSearchDate,
    effectiveTravelDate,
    geographyList: geographyList.map(GeographyPointModel),
    id,
    linkedFareFamilyId,
    name,
    passengerTypeCode,
    pointOfSaleList: pointOfSaleList.map(PointOfSaleModel),
    source,
    status,
    version,
  }
}
