import { Button } from '@atlas-design-system/react'
import {
  EmptyState,
  EmptyStateHeader,
  EmptyStateSubMessage,
  EmptyStateWithImage,
  Group,
  Link,
  Stack,
  TravelportLogotype,
} from '@atlas-ui/react'
import { SERVICES } from 'common/api'
import { envConfig } from 'config/config'
import React from 'react'

import styles from './okta-error-page.module.scss'

type OktaErrorPageProps = {
  title: string
  subTitle: string
  imageSrc: string
  imageAlt: string
}

const handleGoBackHomeClick = () => {
  window.location.assign(
    `${envConfig.config.API_BASE_URL}/${SERVICES.AUTH_SERVICE}/`,
  )
}

const handleProblemsLoggingInClick = () => {
  window.location.assign(envConfig.config.PROBLEMS_LOGGING_IN)
}

const OktaErrorPage: React.FC<OktaErrorPageProps> = ({
  title,
  subTitle,
  imageSrc,
  imageAlt,
}) => {
  return (
    <EmptyStateWithImage className={styles.mainSection}>
      <div className={styles.imageContainer}>
        <img src={imageSrc} alt={imageAlt} />
      </div>
      <EmptyState className={styles.emptyState}>
        <Stack className={styles.mainStack}>
          <Stack alignItems="left">
            <TravelportLogotype className={styles.logo} appearance="black" />
            <Group>
              <Stack alignItems="left">
                <EmptyStateHeader className={styles.EmptyStateHeader}>
                  {title}
                </EmptyStateHeader>
                <EmptyStateSubMessage className={styles.emptyStateSubHeader}>
                  {subTitle}
                </EmptyStateSubMessage>
              </Stack>
            </Group>
          </Stack>
          {title.includes('Login') && (
            <Stack spacing="md" className={styles.linkStack}>
              <Link onPress={handleProblemsLoggingInClick}>
                Having problems logging in?
              </Link>
            </Stack>
          )}
          <Group alignItems="left">
            <Button
              appearance={title.includes('Login') ? 'secondary' : 'primary'}
              onClick={handleGoBackHomeClick}
            >
              {title.includes('Login') ? 'Go back to Login' : 'Go back to Home'}
            </Button>
            {!title.includes('Login') && (
              <Button
                appearance="secondary"
                onClick={handleProblemsLoggingInClick}
              >
                Go to Travelport Support
              </Button>
            )}
          </Group>
        </Stack>
      </EmptyState>
    </EmptyStateWithImage>
  )
}

export default OktaErrorPage
