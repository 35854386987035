import { PendingAction } from 'common/enums'
import { useFamily } from 'common/hooks'
import { BrandDetails, Family } from 'common/types'
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react'

import { BrandActionType, brandReducer, INITIAL_STATE } from './brand-reducer'

export type BrandContextType = {
  copyMode: boolean
  pendingAction?: PendingAction
  resetSelectedBrand: () => void
  selectBrand: (id: string, pendingAction?: PendingAction) => void
  selectFamily: (id: string) => void
  selectPendingBrand: (id?: string) => void
  selectedBrand?: BrandDetails
  selectedFamily?: Family
  setCopyMode: (familyId?: string) => void
  setPendingAction: (pendingAction?: PendingAction) => void
  switchSelectedBrand: () => void
}

export const BrandContext = createContext<BrandContextType>({
  copyMode: false,
  pendingAction: undefined,
  resetSelectedBrand: () => ({}),
  selectBrand: () => ({}),
  selectFamily: () => ({}),
  selectPendingBrand: () => ({}),
  selectedBrand: undefined,
  selectedFamily: undefined,
  setCopyMode: () => ({}),
  setPendingAction: () => ({}),
  switchSelectedBrand: () => ({}),
})

export const useBrandContext = (): BrandContextType => useContext(BrandContext)

export const BrandContextProvider: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  const [state, dispatch] = useReducer(brandReducer, INITIAL_STATE)

  const { familyList } = useFamily()

  const {
    selectedFamilyId,
    selectedBrandId,
    pendingAction,
    copyMode,
    pendingSelectedBrandId,
  } = state

  const selectedFamily = useMemo(() => {
    if (!selectedFamilyId || familyList.length === 0) {
      return undefined
    }

    return familyList.find(({ id }) => id === selectedFamilyId)
  }, [selectedFamilyId, familyList])

  const selectedBrand = useMemo(() => {
    if (!selectedFamily) {
      return undefined
    }

    if (copyMode) {
      const allBrands = familyList
        .map((item) => item.brandDetailsList || [])
        .flat()

      return allBrands.find(({ brandId }) => brandId === selectedBrandId)
    }

    const brands = selectedFamily.brandDetailsList || []

    return brands.find(({ brandId }) => brandId === selectedBrandId)
  }, [selectedFamily, selectedBrandId, copyMode, familyList])

  const selectFamily = (id: string) => {
    dispatch({
      type: BrandActionType.SET_SELECTED_FAMILY,
      payload: {
        id,
      },
    })
  }

  const selectBrand = (id: string, pendingActionType?: PendingAction) => {
    dispatch({
      type: BrandActionType.SET_SELECTED_BRAND,
      payload: {
        id,
        pendingAction: pendingActionType,
      },
    })
  }

  const resetSelectedBrand = () => {
    dispatch({
      type: BrandActionType.SET_SELECTED_BRAND,
      payload: {
        id: undefined,
      },
    })
  }

  const setPendingAction = (pendingActionType?: PendingAction) => {
    dispatch({
      type: BrandActionType.SET_PENDING_ACTION,
      payload: {
        pendingAction: pendingActionType,
      },
    })
  }

  const setCopyMode = (familyId?: string) => {
    dispatch({
      type: BrandActionType.SET_COPY_MODE,
      payload: {
        familyId,
      },
    })
  }

  const selectPendingBrand = (id?: string) => {
    dispatch({
      type: BrandActionType.SET_PENDING_SELECTED,
      payload: {
        id,
      },
    })
  }

  const switchSelectedBrand = () => {
    if (!pendingSelectedBrandId) {
      return
    }

    dispatch({
      type: BrandActionType.SWITCH_SELECTED_BRAND,
      payload: {
        id: pendingSelectedBrandId,
      },
    })
  }

  return (
    <BrandContext.Provider
      value={{
        copyMode,
        pendingAction,
        resetSelectedBrand,
        selectBrand,
        selectFamily,
        selectPendingBrand,
        selectedBrand,
        selectedFamily,
        setCopyMode,
        setPendingAction,
        switchSelectedBrand,
      }}
    >
      {children}
    </BrandContext.Provider>
  )
}
