import { FormControl, Select } from '@atlas-design-system/react'
import { CarrierFormValue } from 'common/types'
import { FormikProps } from 'formik'

import styles from '../../carrier-form.module.css'

type DistributionChannelSelectProps = {
  formik: FormikProps<CarrierFormValue>
  disabled: boolean
}

export const DIST_CHANNEL_SELECT_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'AGT', label: 'Agent-AGT' },
  { value: 'CXR', label: 'Website-CXR' },
]

export const DistributionChannelSelect: React.FC<
  DistributionChannelSelectProps
> = ({ formik, disabled }) => {
  const { values, setFieldValue } = formik

  return (
    <div>
      <div className="row">
        <div className="col-xs-2">
          <FormControl
            label="Carrier Distribution"
            className={styles.carrierFormControl}
            disabled={!!values.id || disabled}
          >
            <Select
              next
              data-testid="distribution-channel-select"
              className="atls-select-height-360"
              value={values.distChannel}
              onChange={(value) => setFieldValue('distChannel', value)}
              options={DIST_CHANNEL_SELECT_OPTIONS}
            />
          </FormControl>
        </div>
      </div>
    </div>
  )
}
