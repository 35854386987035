// @todo - Make such objects for all modules
export const BRAND_MESSAGE = {
  CONFIRM_DELETE: 'Warning! Are you sure you want to Delete the Brand?',
  CONFIRM_ARCHIVE: 'Warning! Are you sure you want to Archive the Brand?',
  CONFIRM_COPY: 'Warning! Do you want to copy Brand into the same Family?',
}

export const NAME_UNIQUE_ERROR = 'Title must be unique'
export const MESSAGE_DELETE_LIVE_FAMILY =
  'Are you sure you want to Archive the Family?'
export const MESSAGE_DELETE_DRAFT_FAMILY =
  'Are you sure you want to Delete the Family?'

export const MESSAGE_ARCHIVE_NOT_ASSOCIATED_ANCILLARY =
  'Are you sure you want to Archive the Segment?'
export const MESSAGE_UPDATE_ANCILLARY =
  'Warning! Are you sure you want to update the Ancillary?'
export const MESSAGE_DISCONTINUE_ASSOCIATED_ANCILLARY =
  'Ancillary already associated to one or more Brands. If you proceed the Ancillary content will be discontinued.'
export const MESSAGE_SAVE_FAMILY_TO_DRAFT =
  'Warning! The changes will be saved in Draft until promoted to Test or Live.'

export const MESSAGE_CONFIRM_FORM_RESET =
  'Warning! You will lose all the changes if you continue without saving.'

export const MESSAGE_NON_UNIQUE_ANCILLARY_ERROR =
  'Ancillary already exists. Please enter new codes or edit the current Ancillary for updating'

export const MESSAGE_MANDATORY_FIELDS_ERROR =
  'Error! Please fill all the mandatory fields'

export const MESSAGE_OVERLAPPING_ANCILLARY_DATES_ERROR =
  'Error! Dates are overlapping with the existing dates'

export const MESSAGE_CONFIRM_EDIT_BRAND =
  'Warning! Do you want to edit the Brand details?'

export const MESSAGE_CONFIRM_EDIT_SEGMENT_BRAND =
  'Warning! Are you sure you want to update the Segment?'

export const MESSAGE_CONFIRM_EDIT_CARRIER =
  'Warning! Are you sure you want to update the Supplier?'

export const MESSAGE_CONFIRM_DELETE_BRAND =
  'Warning! Are you sure you want to Delete the Brand?'

export const MESSAGE_CONFIRM_ARCHIVE_BRAND =
  'Warning! Are you sure you want to Archive the Brand?'

export const MESSAGE_BRAND_COPY_CONFIRM =
  'Warning! Do you want to copy Brand into the same Family?'

export const MESSAGE_VALID_CODE_ERROR = 'Error! Please enter a valid code'

export const MESSAGE_CONFIRM_ARCHIVE_SEGMENT_BRAND =
  'Warning! Are you sure you want to Archive the Segment?'

export const MESSAGE_NON_UNIQUE_SEGMENT_BRAND_ERROR =
  'Duplicate branding commercial name already exists. Please enter unique for updating'
