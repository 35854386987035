import { toast } from '@atlas-design-system/react'
import { TextareaField } from 'common/components'
import { MatchingDataValue } from 'common/types'
import { useField, useFormikContext } from 'formik'
import React, { ChangeEvent, KeyboardEvent, useCallback } from 'react'

import {
  FLIGHT_NUMBER_ERROR,
  flightNumberValidator,
} from '../../../../validators'

type FlightNumberFieldProps = {
  name: string
  label: string
  disabled: boolean
}

export const FlightNumberField: React.FC<FlightNumberFieldProps> = ({
  disabled,
  label,
  name,
}) => {
  const [field, meta, helpers] =
    useField<MatchingDataValue['flightNumbers']>(name)

  const { submitCount } = useFormikContext()

  const { value } = field
  const { error } = meta
  const { setValue } = helpers

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const fieldValue = event.target.value
      setValue(fieldValue, submitCount > 0)
    },
    [setValue, submitCount],
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        const items = value.split('•')

        if (items.length === 1 && items[0] === '') {
          toast.error(FLIGHT_NUMBER_ERROR)
        } else {
          const isValid = flightNumberValidator(value)

          if (isValid) {
            const newValue = items.join('•') + ' • '
            setValue(newValue, false)
          } else {
            toast.error(FLIGHT_NUMBER_ERROR)
          }
        }

        event.preventDefault()
      }
    },
    [value, setValue],
  )

  return (
    <TextareaField
      disabled={disabled}
      errors={error}
      label={label}
      name={name}
      onChange={handleChange}
      textareaProps={{ rows: 7, onKeyDown: handleKeyDown }}
      value={value}
    />
  )
}
