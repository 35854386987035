import { Tabs } from '@atlas-design-system/react'
import { NAVIGATION_TABS_LIST } from 'common/constants'
import { useCarrierDetailsContext } from 'common/context'
import { useAuth } from 'common/context/auth-context'
import { useDirtyForm } from 'common/hooks'
import { Carrier } from 'common/types'
import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const getIsTabDisabled = (
  carrierRequired: boolean,
  selectedCarrier?: Carrier,
): boolean => {
  if (carrierRequired) {
    return !selectedCarrier
  }
  return false
}
export const NavigationTabs: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { selectedCarrier } = useCarrierDetailsContext()
  const { dirtyForm, setFormResetConfirmConfig } = useDirtyForm()
  const { hasPermissionTo, isTravelportUser, isAdminUser } = useAuth()

  const handleTabClick = useCallback(
    ({ path, carrierRequired }: { path: string; carrierRequired: boolean }) =>
      () => {
        if (carrierRequired && !selectedCarrier) {
          return
        }
        if (dirtyForm) {
          setFormResetConfirmConfig({
            hidden: false,
            path,
          })
        } else {
          navigate(path)
        }
      },
    [navigate, selectedCarrier, dirtyForm, setFormResetConfirmConfig],
  )

  const availableTabs = useMemo(
    () =>
      NAVIGATION_TABS_LIST.filter(
        ({ requiredPermission, travelportOnly = false }) => {
          if (travelportOnly) {
            return isTravelportUser && isAdminUser
          }
          return hasPermissionTo(requiredPermission)
        },
      ),
    [hasPermissionTo, isTravelportUser, isAdminUser],
  )

  return (
    <Tabs
      data-testid="navigation-tabs"
      activeKey={location.pathname.split('/')[1]}
      className="mmp-ui-navigation-tabs"
      children={
        <>
          {availableTabs.map(({ title, path, carrierRequired }) => {
            const tabDisabled = getIsTabDisabled(
              carrierRequired,
              selectedCarrier,
            )
            return (
              <Tabs.Tab
                title={title}
                data-disabled={tabDisabled}
                onClick={handleTabClick({ path, carrierRequired })}
                key={path}
              >
                {''}
              </Tabs.Tab>
            )
          })}
        </>
      }
    />
  )
}
