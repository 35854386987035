import { SquareIcon, Tooltip } from '@atlas-design-system/react'
import { FamilyStatus } from 'common/enums/index'
import { useMemo } from 'react'

enum StatusColors {
  live = 'var(--statusSuccessBase)',
  test = 'var(--statusWarningBase)',
  draft = 'var(--primaryA300)',
  suppressed = 'var(--decorativeE90)',
  default = 'var(--statusWarningBase)',
}

type FamilyStatusProps = {
  status: FamilyStatus
}

export const FamilyStatusIcon: React.FC<FamilyStatusProps> = ({ status }) => {
  const statusColor = useMemo(() => {
    switch (status) {
      case FamilyStatus.DRAFT:
        return StatusColors.draft
      case FamilyStatus.TEST:
        return StatusColors.test
      case FamilyStatus.LIVE:
        return StatusColors.live
      case FamilyStatus.SUPPRESSED:
        return StatusColors.suppressed
      default:
        return StatusColors.default
    }
  }, [status])

  return (
    <>
      <Tooltip content={`Status: ${status}`}>
        <SquareIcon
          data-testid="family-status-icon"
          width="16"
          style={{ fill: statusColor, outline: 'none' }}
        />
      </Tooltip>
    </>
  )
}
