import { Radio, Typography } from '@atlas-design-system/react'
import { useAncillaryContext, useCarrierDetailsContext } from 'common/context'
import { PendingAction } from 'common/enums'
import { isTravelportSource } from 'common/helpers'
import { useDirtyForm } from 'common/hooks/form-reset'
import { OptionalService } from 'common/types'
import React, { useCallback, useMemo } from 'react'

import styles from './ancillary-select-row.module.css'

type AncillarySelectRowProps = {
  service: OptionalService
}

export const AncillarySelectRow: React.FC<AncillarySelectRowProps> = ({
  service,
}) => {
  const {
    discontinueDate,
    effectiveDate,
    key,
    serviceName,
    serviceTypeCode,
    subcode = 'no code',
  } = service

  const {
    selectAncillary,
    selectedAncillary,
    selectPendingAncillary,
    setPendingAction,
    copyMode,
  } = useAncillaryContext()
  const { dirtyForm } = useDirtyForm()
  const { archiveMode } = useCarrierDetailsContext()
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (dirtyForm) {
        selectPendingAncillary(event.target.value)
        setPendingAction(PendingAction.SELECT_ANOTHER_ANCILLARY)
      } else {
        selectAncillary(event.target.value)
      }
    },
    [selectAncillary, dirtyForm, selectPendingAncillary, setPendingAction],
  )

  const isChecked = useMemo(() => {
    if (copyMode) {
      return false
    }
    return selectedAncillary?.key === key
  }, [key, selectedAncillary, copyMode])

  return (
    <>
      <Radio
        name="ancillaries-list"
        label={serviceName}
        value={key}
        onChange={handleChange}
        data-testid={`ancillary-select-name-${key}`}
        checked={isChecked}
      />
      <div className={styles.list}>
        <div>
          {!isTravelportSource(service) && !archiveMode && (
            <Typography
              data-testid={`ancillary-select-codes-${key}`}
              variant="contentText2"
            >
              Sub Code = {subcode} | Type = {serviceTypeCode}
            </Typography>
          )}
        </div>
        <div>
          {effectiveDate && (
            <Typography
              data-testid={`ancillary-select-dates-${key}`}
              variant="contentText2"
            >
              {effectiveDate} - {discontinueDate}
            </Typography>
          )}
        </div>
      </div>
    </>
  )
}
