import { toast } from '@atlas-design-system/react'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { QUERY_KEYS, SERVICES } from 'common/api'
import { useCarrierDetailsContext } from 'common/context'
import { CarrierRequestModel } from 'common/models'
import { updateCarrier } from 'common/services'
import {
  deleteSupplierImage,
  postSupplierImage,
} from 'common/services/cdn.service'
import { CarrierResponse, ImageRequestShape } from 'common/types'

export const useUploadLogo = (
  supplierId: string,
): UseMutationResult<CarrierResponse, unknown, ImageRequestShape> => {
  const queryClient = useQueryClient()
  const { selectedCarrier } = useCarrierDetailsContext()

  return useMutation<CarrierResponse, unknown, ImageRequestShape>(
    (imageDetails) =>
      postSupplierImage(imageDetails).then(async (image) => {
        const imageId = image.versions[0]?.imageId.toString()
        if (!selectedCarrier) {
          return Promise.reject('No supplier selected to update')
        }
        const carrierDetails = CarrierRequestModel({
          ...selectedCarrier,
          logoId: imageId,
          logoUrl: image.versions[0]?.url,
        })
        deleteSupplierImage(supplierId, imageId)
        return updateCarrier(carrierDetails)
      }),
    {
      onSuccess: () => {
        toast.success('Image uploaded')
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          SERVICES.PROVISIONING_CARRIER_SERVICE,
          QUERY_KEYS.CARRIERS,
        ])
      },
    },
  )
}
