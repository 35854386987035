import { FormControl } from '@atlas-design-system/react'
import { DATE_FORMAT } from 'common/constants'
import { disabledDateBefore } from 'common/helpers'
import { format, isValid } from 'date-fns'
import { Dispatch, FC, SetStateAction, useCallback } from 'react'

import { DatePicker } from '../date-picker/date-picker.component'

type DiscontinueDatePickerInputProps = {
  onChange: Dispatch<SetStateAction<string | undefined>>
}

export const DiscontinueDatePickerInput: FC<
  DiscontinueDatePickerInputProps
> = ({ onChange }) => {
  const label = 'Select Discontinue Date'

  const handleDateSelected = useCallback(
    (selectedDate?: Date) => {
      if (isValid(selectedDate)) {
        onChange(format(selectedDate!, DATE_FORMAT))
      }
    },
    [onChange],
  )

  return (
    <FormControl label={label}>
      <DatePicker
        label={label}
        value={format(new Date(), DATE_FORMAT)}
        disabledDate={disabledDateBefore}
        onDateSelected={handleDateSelected}
      />
    </FormControl>
  )
}
