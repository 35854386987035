import { Toaster } from '@atlas-design-system/react'
import React from 'react'
import { Outlet } from 'react-router-dom'

import { FormResetConfirm } from '../../form-reset-confirm/form-reset-confirm.component'
import { AppFooter } from '../footer/footer.component'
import { AppHeader } from '../header/header.component'
import { NavigationTabs } from '../navigation-tabs/navigation-tabs.component'

export const PageLayout: React.FC = () => {
  return (
    <div className="atls">
      <AppHeader />
      <NavigationTabs />
      <div className="container mmp-ui-page-container">
        <Outlet />
      </div>
      <Toaster />
      <AppFooter />
      <FormResetConfirm />
    </div>
  )
}
