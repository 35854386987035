import { Dialog } from '@atlas-design-system/react'
import { useDirtyForm } from 'common/hooks/form-reset/use-dirty-form.hook'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const FormResetConfirm: React.FC = () => {
  const navigate = useNavigate()
  const { formResetConfirmConfig, setFormResetConfirmConfig } = useDirtyForm()

  const { hidden = true, path = '' } = formResetConfirmConfig || {}

  const handleDismiss = useCallback(() => {
    setFormResetConfirmConfig()
  }, [setFormResetConfirmConfig])

  const handleConfirm = useCallback(() => {
    navigate(path)
    setFormResetConfirmConfig({
      hidden: true,
      path: '',
    })
  }, [setFormResetConfirmConfig, navigate, path])

  return (
    <Dialog
      title="Warning"
      hidden={hidden}
      onDismiss={handleDismiss}
      onConfirm={handleConfirm}
      appearance="warning"
      id="family-confirm-dialog"
      dismissText="Cancel"
    >
      Warning! You will lose all the changes if you continue without saving.
    </Dialog>
  )
}
