import { useQuery } from '@tanstack/react-query'
import { SERVICES } from 'common/api'
import { TRAVELPORT_SUPPLIER_CODE } from 'common/constants'
import { Permission, UserRole } from 'common/enums'
import { pickUniqueSipplierCodes } from 'common/helpers'
import { AppRoutes } from 'common/routes/routes'
import { fetchUserDetails } from 'common/services/auth.service'
import { fetchCdnToken } from 'common/services/cdn.service'
import { AuthUser } from 'common/types'
import { envConfig } from 'config/config'
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

export type AuthContextType = {
  user: AuthUser | null
  TVPAuthorization: string | null
  logOut: () => void
  hasPermissionTo: (permission: Permission) => boolean
  isAdminUser: boolean
  isTravelportUser: boolean
  userSupplierCodes: string[]
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  TVPAuthorization: null,
  logOut: () => ({}),
  hasPermissionTo: () => false,
  isAdminUser: false,
  isTravelportUser: false,
  userSupplierCodes: [],
})

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext)
}

export const AuthContextProvider: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  const [TVPAuthorization, setTVPAuthorization] = useState<string | null>(null)

  const { data: user = null } = useQuery({
    queryKey: ['getUser'],
    queryFn: () =>
      fetchUserDetails().catch(() => {
        console.log(
          'Failed to retrieve user-details. Falling back to session invalid page...',
        )
        throw new Error('Invalid Auth Details: 401')
      }),
  })

  const logOut = () => {
    localStorage.setItem('selectedCarrierCode', '')
    window.location.assign(
      `${envConfig.config.API_BASE_URL}/${SERVICES.AUTH_SERVICE}/logout`,
    )
  }

  const hasPermissionTo = useCallback(
    (permission: Permission): boolean => {
      if (!user) {
        return false
      }

      return user.permissions.includes(permission)
    },
    [user],
  )

  const hasImageWritePermissions = useMemo(() => {
    if (!user) {
      return false
    }

    return (
      hasPermissionTo(Permission.WRITE_BRANDS) ||
      hasPermissionTo(Permission.WRITE_ANCILLARIES) ||
      hasPermissionTo(Permission.WRITE_DASHBOARD) ||
      hasPermissionTo(Permission.WRITE_SEGMENT_BRANDING)
    )
  }, [hasPermissionTo, user])

  useEffect(() => {
    if (user && !TVPAuthorization && hasImageWritePermissions) {
      fetchCdnToken()
        .then((token) => {
          sessionStorage.setItem('TVPAuthorization', token)
          setTVPAuthorization(token)
        })
        .catch(() => {
          console.log(
            'Failed to retrieve CDN token. Falling back to invalid session page...',
          )
          throw new Error('Invalid Auth Details: 401')
        })
      setTimeout(
        () => setTVPAuthorization(null),
        30 * 1000 * 60, // 30 min
      )
    }
  }, [TVPAuthorization, user, hasImageWritePermissions])

  const isAdminUser = useMemo(() => {
    if (!user) {
      return false
    }

    return user.roles.includes(UserRole.MMP_ADMIN)
  }, [user])

  const userSupplierCodes = useMemo(() => {
    if (!user) {
      return []
    }

    const { supplierCodes } = user
    return pickUniqueSipplierCodes(supplierCodes)
  }, [user])

  const isTravelportUser = useMemo(() => {
    if (!user) {
      return false
    }

    return userSupplierCodes.includes(TRAVELPORT_SUPPLIER_CODE)
  }, [user, userSupplierCodes])

  // Allow the auth service error page to be displayed without auth and other app contexts
  // Added for Okta authentication migration requested by SSO Team  (2024-10-01)
  if (window.location.pathname.includes('/error')) {
    return <AppRoutes />
  } else {
    return (
      <AuthContext.Provider
        value={{
          user,
          TVPAuthorization,
          logOut,
          hasPermissionTo,
          isAdminUser,
          isTravelportUser,
          userSupplierCodes,
        }}
      >
        {user && children}
      </AuthContext.Provider>
    )
  }
}
