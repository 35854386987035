import { Button, EmptyState } from '@atlas-design-system/react'
import { useAuth } from 'common/context'
import { Permission } from 'common/enums'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const RestrictedAccessPage: React.FC = () => {
  const { hasPermissionTo } = useAuth()
  const navigate = useNavigate()

  const canViewDashboard = hasPermissionTo(Permission.READ_DASHBOARD)

  const handleClick = useCallback(() => {
    navigate('/dashboard')
  }, [navigate])

  return (
    <EmptyState
      title="You don't have permissions to view this page"
      style={{ padding: 'var(--spaceXL) 0' }}
      actionButtons={
        canViewDashboard && (
          <div style={{ paddingTop: 'var(--spaceM)' }}>
            <Button appearance="primary" onClick={handleClick}>
              Go to Dashboard
            </Button>
          </div>
        )
      }
    />
  )
}
