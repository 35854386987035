import { FieldArrayActions, FormInput } from 'common/components'
import { CarrierFormValue } from 'common/types'
import { Field, FieldArray, FormikProps } from 'formik'
import React from 'react'

import styles from '../../../carrier-form.module.css'

type EmailListFormProps = {
  formik: FormikProps<CarrierFormValue>
}
export const EmailListForm: React.FC<EmailListFormProps> = ({ formik }) => {
  const { values } = formik

  return (
    <FieldArray name="emailList">
      {(fieldArrayProps) => (
        <>
          {values.emailList.map((item, index) => (
            <div className="row" key={index}>
              <div className="col-xs-6">
                <Field
                  component={FormInput}
                  data-testid="email-list-row"
                  label="Email"
                  minLength="1"
                  value={item}
                  name={`emailList[${index}]`}
                />
              </div>
              <div className={`col-xs-2 ${styles.emailActionButtons}`}>
                <FieldArrayActions
                  addTooltip="Add email"
                  canDelete={true}
                  index={index}
                  insert={fieldArrayProps.insert}
                  remove={fieldArrayProps.remove}
                  replace={fieldArrayProps.replace}
                  removeTooltip="Remove email"
                  isSettings
                  size={values.emailList.length}
                />
              </div>
            </div>
          ))}
        </>
      )}
    </FieldArray>
  )
}
