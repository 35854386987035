import styled from 'styled-components'

export const StyledInput = styled.input`
  margin-bottom: var(--spaceXS);
`

export const StyledLogo = styled.div`
  .image-loader-preview {
    display: inline-block;
    height: 120px;
    width: 60%;
    padding: 1px;
    margin-right: var(--spaceM);

    .hasIMG {
      background-size: contain;
      background-repeat: no-repeat;
      max-height: 100%;
      max-width: 100%;
    }
  }

  #upload-logo-modal {
    width: 500px;
    height: 160px;
  }

  .link-button {
    vertical-align: top;
  }
`
