import { Dialog } from '@atlas-design-system/react'
import {
  MESSAGE_CONFIRM_EDIT_CARRIER,
  MESSAGE_CONFIRM_FORM_RESET,
} from 'common/constants'
import { useCarrierDetailsContext } from 'common/context'
import { PendingAction } from 'common/enums'
import React, { useCallback, useMemo } from 'react'

type CarrierFormModalProps = {
  submitForm: () => void
}

export const CarrierFormModal: React.FC<CarrierFormModalProps> = ({
  submitForm,
}) => {
  const {
    selectedCarrier,
    pendingSelectedCarrierId,
    pendingAction,
    setPendingAction,
    switchSelectedCarrier,
    resetCarrier,
  } = useCarrierDetailsContext()

  const modalHidden = useMemo(() => {
    if (pendingAction) {
      return pendingAction === PendingAction.RESET_FORM
    }
    return true
  }, [pendingAction])

  const title = 'Warning'

  const handleDismiss = useCallback(() => {
    setPendingAction()
  }, [setPendingAction])

  const confirmEdit = useCallback(() => {
    submitForm()
    setPendingAction()
  }, [submitForm, setPendingAction])

  const confirmCarrierSwitch = useCallback(() => {
    const pendingActionType =
      selectedCarrier?.id === pendingSelectedCarrierId
        ? PendingAction.RESET_FORM
        : undefined

    switchSelectedCarrier(pendingActionType)
  }, [selectedCarrier?.id, pendingSelectedCarrierId, switchSelectedCarrier])

  const confirmFormReset = useCallback(() => {
    resetCarrier()
    setPendingAction(PendingAction.RESET_FORM)
  }, [resetCarrier, setPendingAction])

  const handleConfirm = useCallback(() => {
    switch (pendingAction) {
      case PendingAction.NEW_BUTTON_CLICK:
        return confirmFormReset()
      case PendingAction.EDIT:
        return confirmEdit()
      case PendingAction.SELECT_ANOTHER_CARRIER:
        return confirmCarrierSwitch()

      default:
        return setPendingAction()
    }
  }, [
    pendingAction,
    confirmFormReset,
    confirmEdit,
    confirmCarrierSwitch,
    setPendingAction,
  ])

  const modalMessage = useMemo(() => {
    switch (pendingAction) {
      case PendingAction.EDIT: {
        return MESSAGE_CONFIRM_EDIT_CARRIER
      }
      case PendingAction.NEW_BUTTON_CLICK:
      case PendingAction.SELECT_ANOTHER_CARRIER: {
        return MESSAGE_CONFIRM_FORM_RESET
      }
      default: {
        return ''
      }
    }
  }, [pendingAction])

  return (
    <Dialog
      title={title}
      hidden={modalHidden}
      onDismiss={handleDismiss}
      onConfirm={handleConfirm}
      appearance="warning"
      id="carrier-confirm-dialog"
      dismissText="Cancel"
    >
      {modalMessage}
    </Dialog>
  )
}
