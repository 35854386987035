import {
  MandatoryAncillariesFormValue,
  MandatoryAncillaryFormValue,
} from 'common/types'

export const EMPTY_MANDATORY_ANCILLARY: MandatoryAncillaryFormValue = {
  group: '',
  subgroup: '',
  displayOrder: undefined,
  exclude: false,
  mandatory: true,
  subcode: '',
  tagName: '',
}

export const EMPTY_MANDATORY_ANCILLARIES_FORM_VALUE: MandatoryAncillariesFormValue =
  {
    mandatoryAncillaryList: [EMPTY_MANDATORY_ANCILLARY],
  }
