import {
  BrandRuleFieldInputType,
  CabinType,
  Direction,
  FamilyStatus,
  FareRuleInputType,
  LocationType,
  PermissionLabel,
  PriceRangeDirection,
  UpsellIndicator,
} from 'common/enums'
import {
  PriceRangeValue,
  SelectOption,
  TextByCategory,
  TitleByType,
} from 'common/types'

export const EMPTY_SELECT_OPTION: SelectOption<string> = {
  label: '',
  value: '',
}

export const DIRECTION_SELECT_OPTIONS: SelectOption<Direction>[] = [
  {
    label: 'Between',
    value: Direction.BETWEEN,
  },
  {
    label: 'From',
    value: Direction.FROM,
  },
  {
    label: 'To',
    value: Direction.TO,
  },
  {
    label: 'Within',
    value: Direction.WITHIN,
  },
]

export const FARE_RULE_INPUT_TYPE_SELECT_OPTIONS = [
  {
    label: 'Select',
    value: FareRuleInputType.EMPTY,
  },
  {
    label: 'Multiple values for a field',
    value: FareRuleInputType.MULTIPLE,
  },
  {
    label: 'Single value for 2 or 3 fields',
    value: FareRuleInputType.SINGLE,
  },
]

export const BRAND_RULE_FIELD_INPUT_TYPE_SELECT_OPTIONS = [
  {
    label: 'Select One',
    value: BrandRuleFieldInputType.EMPTY,
  },
  {
    label: 'Reservation Booking Designator',
    value: BrandRuleFieldInputType.RBD,
  },
  {
    label: 'Fare Basis Code',
    value: BrandRuleFieldInputType.FBC,
  },
  {
    label: 'Fare Type Code',
    value: BrandRuleFieldInputType.FTC,
  },
]

export const PRICE_RANGE_DIRECTION_SELECT_OPTIONS: SelectOption<PriceRangeDirection>[] =
  [
    {
      label: 'Between',
      value: PriceRangeDirection.BETWEEN,
    },
    {
      label: 'Up to',
      value: PriceRangeDirection.UP_TO,
    },
    {
      label: 'From',
      value: PriceRangeDirection.FROM,
    },
  ]

export const CABIN_TYPE_SELECT_OPTIONS: SelectOption<CabinType>[] = [
  {
    label: '',
    value: CabinType.EMPTY,
  },
  {
    label: 'Premium First',
    value: CabinType.PREMIUM_FIRST,
  },
  {
    label: 'First',
    value: CabinType.FIRST,
  },
  {
    label: 'Premium Business',
    value: CabinType.PREMIUM_BUSINESS,
  },
  {
    label: 'Business',
    value: CabinType.BUSINESS,
  },
  {
    label: 'Premium Economy',
    value: CabinType.PREMIUM_ECONOMY,
  },
  {
    label: 'Economy',
    value: CabinType.ECONOMY,
  },
]

export const GDS_SELECT_OPTIONS: SelectOption<string>[] = [
  {
    label: '1G',
    value: '1G',
  },
  {
    label: '1V',
    value: '1V',
  },
  {
    label: '1P',
    value: '1P',
  },
]

export const PERMISSION_SELECT_OPTIONS: SelectOption<boolean>[] = [
  {
    label: PermissionLabel.PERMITTED,
    value: true,
  },
  {
    label: PermissionLabel.NOT_PERMITTED,
    value: false,
  },
]

export const LOCATION_TYPE_SELECT_OPTIONS: SelectOption<LocationType>[] = [
  {
    label: 'Airport',
    value: LocationType.AIRPORT,
  },
  {
    label: 'City',
    value: LocationType.CITY,
  },
  {
    label: 'Country',
    value: LocationType.COUNTRY,
  },
  {
    label: 'Zone',
    value: LocationType.ZONE,
  },
  {
    label: 'Area',
    value: LocationType.AREA,
  },
]

export const FAMILY_STATUS_SELECT_OPTIONS = [
  {
    label: 'Test',
    value: FamilyStatus.TEST,
  },
  {
    label: 'Production',
    value: FamilyStatus.LIVE,
  },
  {
    label: 'Draft',
    value: FamilyStatus.DRAFT,
  },
  {
    label: 'Supressed',
    value: FamilyStatus.SUPPRESSED,
  },
]

export const GEOGRAPHY_POINT_INITIAL_VALUE = {
  direction: {
    label: 'Between',
    value: Direction.BETWEEN,
  },
  code1: EMPTY_SELECT_OPTION,
  code2: EMPTY_SELECT_OPTION,
  location1: {
    label: '',
    value: LocationType.EMPTY,
  },
  location2: {
    label: '',
    value: LocationType.EMPTY,
  },
  permitted: {
    label: 'Permitted',
    value: true,
  },
}

export const PRICE_RANGE_INITIAL_VALUE: PriceRangeValue = {
  defaultIndicator: false,
  start: '',
  end: '',
  currencyCode: {
    label: 'ABC',
    value: 'ABC',
  },
  priceRangeDirection: {
    label: 'Between',
    value: PriceRangeDirection.BETWEEN,
  },
}

export const FARE_RULE_INITIAL_VALUE = {
  fareBasisCode: '',
  fareTypeCode: '',
  rbd: '',
  rule: '',
  tariff: '',
}

export const META_FARE_RULE_INITIAL_VALUE = {
  dynamicField: '',
  rule: '',
  tariff: '',
}

export const POINT_OF_SALE_INITIAL_VALUE = {
  iataNumber: '',
  gds: {
    label: '',
    value: '',
  },
  pcc: '',
  locationType: {
    label: '',
    value: LocationType.EMPTY,
  },
  locationCode: EMPTY_SELECT_OPTION,
  permitted: {
    label: 'Permitted',
    value: true,
  },
}

export const INITIAL_TEXT_BY_CATEGORY: TextByCategory = {
  STRAPLINE: '',
  MARKETING_AGENT: '',
  MARKETING_CONSUMER: '',
}

export const INITIAL_TITLE_BY_TYPE: TitleByType = {
  EXTERNAL: '',
  SHORT: '',
}

export const INITIAL_BAGGAGE_DETAILS = {
  cabin: EMPTY_SELECT_OPTION,
  weight: null,
  height: null,
  length: null,
  dimensionUnit: EMPTY_SELECT_OPTION,
  piece: null,
  width: null,
  weightUnit: EMPTY_SELECT_OPTION,
}

export const UPSELL_FORM_INITIAL_VALUE = {
  effectiveDate: '',
  groupCode: { value: 'TVP', label: 'Travelport-TVP' },
  indicator: { value: UpsellIndicator.O, label: 'Ancillary Only' },
}

export const PSEUDO_CITY_FORM_INITIAL_VALUE = {
  cityCode: '',
  effectiveDate: '',
  discontinueDate: '',
  gdsCode: EMPTY_SELECT_OPTION,
}

export const COUNTRY_FORM_INITIAL_VALUE = {
  code: EMPTY_SELECT_OPTION,
  name: '',
  effectiveDate: '',
  discontinueDate: '',
}
