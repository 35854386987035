import { Button, ButtonGroup, Modal } from '@atlas-design-system/react'
import { MandatoryMap } from 'common/types'
import React from 'react'

type MandatoryModalProps = {
  onDismiss: () => void
  hidden: boolean
  mandatoryMap: MandatoryMap
  onConfirm: () => void
}

export const MandatoryModal: React.FC<MandatoryModalProps> = ({
  onDismiss,
  hidden,
  mandatoryMap,
  onConfirm,
}) => {
  const actionButtons = (
    <ButtonGroup align="right">
      <Button appearance="primary" onClick={onConfirm}>
        Continue
      </Button>
      <Button onClick={onDismiss}>Go Back</Button>
    </ButtonGroup>
  )

  return (
    <>
      <Modal
        id="mandatory-modal"
        title="Mandatory Ancillary Check!"
        onDismiss={onDismiss}
        hidden={hidden}
        actionButtons={actionButtons}
      >
        Warning! Mandatory ancillary types have not been assigned to this brand.
        If you choose to continue, we will create and assign the following
        ancillary types to this brand to indicate they are 'Not Included'
        <ul data-testid="unincluded-mandatory-list">
          {Object.keys(mandatoryMap).map((mandatoryKey) => {
            const groupSubgroup = mandatoryMap[mandatoryKey][0]
            const group = groupSubgroup.substring(0, 2)
            const subGroup = groupSubgroup.substring(2)
            return (
              <li key={mandatoryKey} data-testid="unincluded-mandatory-item">
                {mandatoryKey} Group:'{group}'/Sub Group:'{subGroup}'
              </li>
            )
          })}
        </ul>
        Alternatively, Go Back and assign these mandatory ancillary types to the
        brand before continuing.
      </Modal>
    </>
  )
}
