import { ApiBrandDetails } from 'common/types'
import { BrandDetails } from 'common/types'

import { BrandDescriptionModel } from './brand-description.model'
import { MatchingDataModel } from './matching-data.model'
import { OptionalServiceModel } from './optional-service-model'

export const BrandDetailsModel = (
  brandDetails: ApiBrandDetails,
): BrandDetails => {
  const {
    brandDescription,
    brandId = '',
    excludingData = {},
    matchingData = {},
    optionalServiceList = [],
    sequenceNumber = 0,
  } = brandDetails

  return {
    brandDescription: BrandDescriptionModel(brandDescription),
    brandId,
    excludingData: MatchingDataModel(excludingData),
    matchingData: MatchingDataModel(matchingData),
    optionalServiceList: optionalServiceList.map(OptionalServiceModel),
    sequenceNumber,
  }
}
