import { toast } from '@atlas-design-system/react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useCarrierDetailsContext } from 'common/context'
import { useFamilyContext } from 'common/context/family-context/family-context'
import { deleteFamily } from 'common/services'
import { DeleteFamilyRequest, DeleteFamilyResponse } from 'common/types'

export const useDeleteFamily = (): UseMutationResult<
  DeleteFamilyResponse,
  Error,
  DeleteFamilyRequest
> => {
  const { reloadCarrierDetails } = useCarrierDetailsContext()
  const { onDeleteFamilySuccess } = useFamilyContext()

  return useMutation<DeleteFamilyResponse, Error, DeleteFamilyRequest>(
    (data: DeleteFamilyRequest) => deleteFamily(data),
    {
      onSuccess: ({ confirmationId }) => {
        toast.success(
          'Success! Family has been deleted. Confirmation id: ' +
            confirmationId,
        )
        reloadCarrierDetails()
        onDeleteFamilySuccess()
      },
      onError: ({ message }) => {
        toast.error(`Error: Failed to delete family, ${message}`)
      },
    },
  )
}
