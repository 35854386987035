import SOMETHING_WENT_WRONG_IMAGE from 'assets/images/generic-something-went-wrong.webp'
import UNAUTHORIZED_IMAGE from 'assets/images/unauthorized.webp'
import { useSearchParams } from 'react-router-dom'

import OktaErrorPage from '../okta-error-page/okta-error-page'

const SomethingWentWrongPage: React.FC = () => {
  const [searchParams] = useSearchParams()

  const UNAUTHORIZED = 'unauthorized'

  const isUnauthorized = searchParams.get('errorCode') === UNAUTHORIZED

  if (isUnauthorized) {
    return (
      <OktaErrorPage
        title={'Login failed'}
        subTitle={
          'Contact your admin or use the “Having problems logging in?” link to chat with support.'
        }
        imageSrc={UNAUTHORIZED_IMAGE}
        imageAlt="British Bulldog poking out of doggy door, it looks stuck"
      />
    )
  }

  return (
    <OktaErrorPage
      title={'Sorry, something went wrong.'}
      subTitle={'Please try again or go to Travelport Support.'}
      imageSrc={SOMETHING_WENT_WRONG_IMAGE}
      imageAlt="Four sheep standing on a mountainous road, with a lake in the background, staring at the camera"
    />
  )
}

export default SomethingWentWrongPage
