import { toast } from '@atlas-design-system/react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useCarrierDetailsContext } from 'common/context'
import { useSegmentBrandingContext } from 'common/context/segment-branding-context'
import { editSegmentBrand } from 'common/services'
import {
  EditSegmentBrandRequest,
  EditSegmentBrandResponse,
  ErrorMessage,
} from 'common/types'

export const useEditSegmentBranding = (): UseMutationResult<
  EditSegmentBrandResponse,
  AxiosError<ErrorMessage>,
  EditSegmentBrandRequest
> => {
  const { reloadCarrierDetails } = useCarrierDetailsContext()
  const { setPendingAction } = useSegmentBrandingContext()

  return useMutation<
    EditSegmentBrandResponse,
    AxiosError<ErrorMessage>,
    EditSegmentBrandRequest
  >((data: EditSegmentBrandRequest) => editSegmentBrand(data), {
    onSuccess: ({ confirmationId }) => {
      toast.success(
        `Well done! you have successfully completed the segment update. Confirmation id:(${confirmationId})`,
      )
      reloadCarrierDetails()
      setPendingAction(undefined)
    },
    onError: ({ response, message: baseMassage }) => {
      toast.error(`Error: ${response?.data.message || baseMassage}`)
    },
  })
}
