import { ApiTextType } from 'common/types'
import { TextType } from 'common/types'

export const TextTypeModel = (textType: ApiTextType): TextType => {
  const { type = '', value = '' } = textType

  return {
    type,
    value,
  }
}
