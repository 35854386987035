import { useBrandContext, useCarrierDetailsContext } from 'common/context'
import { ServiceSource } from 'common/enums'
import { useAncillaries, useMandatoryAncillaries } from 'common/hooks'
import { BrandFormValue } from 'common/types'
import { useCreateBrand, useEditBrand } from 'modules/brands/hooks'
import { useCallback } from 'react'

import { BrandRequestModel } from '../components/brand-form-wrapper/models'
import { getMissingMandatoryMap } from '../components/brand-form-wrapper/models/brand-request-model'

export const useSubmitBrand = (): {
  submitBrand: (values: BrandFormValue) => void
} => {
  const { selectedCarrier } = useCarrierDetailsContext()
  const { selectedFamily, copyMode } = useBrandContext()
  const createMutation = useCreateBrand()
  const editMutation = useEditBrand()
  const { defaultMandatoryMap } = useMandatoryAncillaries()

  const {
    brandsData: { all: allServices, distinctMandatoryServiceList },
  } = useAncillaries()

  const submitBrand = useCallback(
    (values: BrandFormValue) => {
      const missingMandatoryMap = getMissingMandatoryMap(
        values,
        defaultMandatoryMap,
        distinctMandatoryServiceList,
      )

      const isATPCO = selectedFamily?.source === ServiceSource.ATPCO

      const requestShape = BrandRequestModel(
        values,
        allServices,
        copyMode,
        isATPCO || (!!values.brandId && values.brandId !== 'NEW' && !copyMode)
          ? {}
          : missingMandatoryMap,
        distinctMandatoryServiceList,
      )

      if (!selectedFamily || !selectedCarrier) {
        return
      }

      const { brandId } = requestShape

      if (!brandId && brandId !== 'NEW') {
        createMutation.mutate({
          parentFareFamilyRef: selectedFamily.id,
          brandDetails: requestShape,
          carrierCode: selectedCarrier.code,
        })
      } else {
        editMutation.mutate({
          brandDetails: requestShape,
          carrierCode: selectedCarrier.code,
        })
      }
    },
    [
      selectedFamily,
      createMutation,
      editMutation,
      allServices,
      copyMode,
      defaultMandatoryMap,
      distinctMandatoryServiceList,
      selectedCarrier,
    ],
  )

  return { submitBrand }
}
