import { useCarrierDetailsContext } from 'common/context'
import { FormResetConfirmConfig } from 'common/types'

export const useDirtyForm = (): {
  dirtyForm: boolean
  formResetConfirmConfig?: FormResetConfirmConfig
  setFormResetConfirmConfig: (config?: FormResetConfirmConfig) => void
} => {
  const { dirtyForm, formResetConfirmConfig, setFormResetConfirmConfig } =
    useCarrierDetailsContext()

  return {
    dirtyForm,
    formResetConfirmConfig,
    setFormResetConfirmConfig,
  }
}
