import { Radio, Typography } from '@atlas-design-system/react'
import { useSegmentBrandingContext } from 'common/context/segment-branding-context'
import { PendingAction } from 'common/enums'
import { isTravelportSource } from 'common/helpers'
import { useDirtyForm } from 'common/hooks'
import { OptionalService } from 'common/types'
import React, { useCallback, useMemo } from 'react'

import styles from './segment-branding-select-row.module.css'

type SegmentBrandingSelectRowProps = {
  service: OptionalService
}

export const SegmentBrandingSelectRow: React.FC<
  SegmentBrandingSelectRowProps
> = ({ service }) => {
  const {
    discontinueDate,
    effectiveDate,
    key,
    serviceName,
    serviceTypeCode,
    subcode = 'no code',
  } = service

  const {
    selectedSegmentBrand,
    selectSegmentBrand,
    copyMode,
    setPendingAction,
    selectPendingSegmentBrand,
  } = useSegmentBrandingContext()

  const { dirtyForm } = useDirtyForm()

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedKey = event.target.value

      if (dirtyForm) {
        selectPendingSegmentBrand(selectedKey)
        setPendingAction(PendingAction.SELECT_ANOTHER_SEGMENT_BRAND)
      } else {
        if (
          copyMode &&
          selectedSegmentBrand &&
          selectedSegmentBrand.key === selectedKey
        ) {
          selectSegmentBrand(selectedKey, PendingAction.RESET_FORM)
        } else {
          selectSegmentBrand(selectedKey)
        }
      }
    },
    [
      dirtyForm,
      selectPendingSegmentBrand,
      setPendingAction,
      copyMode,
      selectedSegmentBrand,
      selectSegmentBrand,
    ],
  )

  const isChecked = useMemo(() => {
    if (copyMode) {
      return false
    }
    return selectedSegmentBrand?.key === key
  }, [key, selectedSegmentBrand, copyMode])

  return (
    <>
      <Radio
        name="ancillaries-list"
        label={serviceName}
        value={key}
        onChange={handleChange}
        checked={isChecked}
      />
      <div className={styles.list}>
        <div>
          {!isTravelportSource(service) && (
            <Typography variant="contentText2">
              Sub Code = {subcode} | Type = {serviceTypeCode}
            </Typography>
          )}
        </div>
        <div>
          {effectiveDate && (
            <Typography variant="contentText2">
              {effectiveDate} - {discontinueDate}
            </Typography>
          )}
        </div>
      </div>
    </>
  )
}
