import { CabinType } from 'common/enums'
import { ApiMatchingData } from 'common/types'
import { MatchingData } from 'common/types'

import { FareRuleModel } from './fare-rule.model'
import { FlightRangeModel } from './flight-range.model'

export const MatchingDataModel = (
  matchingData: ApiMatchingData,
): MatchingData => {
  const {
    cabin = CabinType.EMPTY,
    equipment = '',
    fareRules = [],
    flightRanges = [],
  } = matchingData

  return {
    cabin,
    equipment,
    fareRules: fareRules.map(FareRuleModel),
    flightRanges: flightRanges.map(FlightRangeModel),
  }
}
