import {
  BRAND_RULE_FIELD_INPUT_TYPE_SELECT_OPTIONS,
  CABIN_TYPE_SELECT_OPTIONS,
  EMPTY_SELECT_OPTION,
  FARE_RULE_INITIAL_VALUE,
  FARE_RULE_INPUT_TYPE_SELECT_OPTIONS,
  META_FARE_RULE_INITIAL_VALUE,
} from 'common/constants'
import {
  CabinType,
  PriceRangeDirection,
  ServiceAvailability,
} from 'common/enums'
import { removeSpaces } from 'common/helpers'
import {
  BrandDetails,
  BrandFormValue,
  BrandServicesFiltersValue,
  FlightRange,
  MatchingData,
  MatchingDataMetaValue,
  MatchingDataValue,
  OptionalService,
  OptionalServicesByCategoryValue,
  OptionalServiceValue,
  PriceRange,
  PriceRangeValue,
  SelectOption,
} from 'common/types'

export const BRAND_SERVICES_FILTERS: BrandServicesFiltersValue = {
  show: {
    label: 'All',
    value: 'all',
  },
  sort: {
    label: 'Group',
    value: 'group',
  },
}

export const EMPTY_BRAND_FORM_VALUES: BrandFormValue = {
  brandDescription: {
    name: '',
    imageDetailsList: [],
    brandCode: '',
    brandRule: '',
    brandCodeExcluding: '',
    additionalDetailList: [],
    textByCategory: {
      STRAPLINE: '',
      UPSELL: '',
      MARKETING_AGENT: '',
      MARKETING_CONSUMER: '',
    },
    titleByType: {
      EXTERNAL: '',
      SHORT: '',
    },
  },
  matchingData: {
    cabin: {
      label: '',
      value: CabinType.EMPTY,
    },
    equipment: EMPTY_SELECT_OPTION,
    fareRules: [FARE_RULE_INITIAL_VALUE],
    flightRanges: '',
    flightNumbers: '',
  },
  excludingData: {
    cabin: {
      label: '',
      value: CabinType.EMPTY,
    },
    equipment: EMPTY_SELECT_OPTION,
    fareRules: [FARE_RULE_INITIAL_VALUE],
    flightRanges: '',
    flightNumbers: '',
  },
  optionalServicesByCategory: {
    mandatory: [],
    standard: [],
    segment: [],
  },
  meta: {
    matchingData: {
      fareRuleInputType: FARE_RULE_INPUT_TYPE_SELECT_OPTIONS[0],
      brandRuleFieldInputType: BRAND_RULE_FIELD_INPUT_TYPE_SELECT_OPTIONS[0],
      fareRule: META_FARE_RULE_INITIAL_VALUE,
    },
    excludingData: {
      fareRuleInputType: FARE_RULE_INPUT_TYPE_SELECT_OPTIONS[0],
      brandRuleFieldInputType: BRAND_RULE_FIELD_INPUT_TYPE_SELECT_OPTIONS[0],
      fareRule: META_FARE_RULE_INITIAL_VALUE,
    },
    filters: BRAND_SERVICES_FILTERS,
  },
}

const MatchingDataMetaFormModel = (
  matchingData?: MatchingData,
): MatchingDataMetaValue => {
  const metaForm = {
    fareRuleInputType: FARE_RULE_INPUT_TYPE_SELECT_OPTIONS[0],
    brandRuleFieldInputType: BRAND_RULE_FIELD_INPUT_TYPE_SELECT_OPTIONS[0],
    fareRule: META_FARE_RULE_INITIAL_VALUE,
  }

  if (!matchingData?.fareRules?.length) {
    return metaForm
  } else {
    return {
      ...metaForm,
      fareRuleInputType: FARE_RULE_INPUT_TYPE_SELECT_OPTIONS[2],
    }
  }
}

const FlightRangesFormModel = (
  key: 'flightRanges' | 'flightNumbers',
  flightData?: FlightRange[],
): string => {
  if (!flightData) {
    return ''
  }

  return flightData
    .reduce<string[]>((rangeGroup, range) => {
      const { flightNumberStart, flightNumberEnd } = range
      const isEqual = flightNumberStart === flightNumberEnd

      if (key === 'flightNumbers' && isEqual) {
        rangeGroup.push(flightNumberStart)
      } else if (key === 'flightRanges' && !isEqual) {
        rangeGroup.push(`${flightNumberStart}-${flightNumberEnd}`)
      }
      return rangeGroup
    }, [])
    .join(' • ')
}

const MatchingDataFormModel = (
  matchingData: MatchingData,
): MatchingDataValue => {
  const {
    cabin = CabinType.EMPTY,
    equipment = '',
    fareRules = [FARE_RULE_INITIAL_VALUE],
    flightRanges: flightRangesData,
  } = matchingData

  const fareRulesValue =
    fareRules.length === 0 ? [FARE_RULE_INITIAL_VALUE] : fareRules

  return {
    cabin: {
      label:
        CABIN_TYPE_SELECT_OPTIONS.find(({ value }) => value === cabin)?.label ||
        CABIN_TYPE_SELECT_OPTIONS[0].label,
      value: cabin,
    },
    equipment: {
      label: equipment,
      value: equipment,
    },
    fareRules: fareRulesValue,
    flightRanges: FlightRangesFormModel('flightRanges', flightRangesData),
    flightNumbers: FlightRangesFormModel('flightNumbers', flightRangesData),
  }
}

const pickPriceRangeDirection = ({
  start,
  end,
}: {
  start?: string
  end?: string
}): SelectOption<PriceRangeDirection> => {
  if (!start) {
    return {
      label: 'Up to',
      value: PriceRangeDirection.UP_TO,
    }
  } else if (!end) {
    return {
      label: 'From',
      value: PriceRangeDirection.FROM,
    }
  } else {
    return {
      label: 'Between',
      value: PriceRangeDirection.BETWEEN,
    }
  }
}

export const PriceRangeFormModel = ({
  defaultIndicator,
  start,
  end,
  currencyCode,
}: PriceRange): PriceRangeValue => {
  const priceRangeDirection = pickPriceRangeDirection({ start, end })

  return {
    defaultIndicator,
    start: start || '',
    end: end || '',
    currencyCode: {
      label: currencyCode,
      value: currencyCode,
    },
    priceRangeDirection,
  }
}

export const OptionalServiceFormModel = ({
  key: id,
  serviceAvailability = ServiceAvailability.FREE,
  priceRangeList = [],
  subcode,
  discontinueDate,
  effectiveDate,
  group,
  subgroup,
  serviceName,
}: OptionalService): OptionalServiceValue => ({
  id,
  serviceAvailability,
  priceRangeList: priceRangeList.map(PriceRangeFormModel),
  subcode,
  discontinueDate,
  effectiveDate,
  group,
  subgroup,
  meta: {
    id: removeSpaces(`${group}${subgroup}${serviceName}`),
  },
})

const optionalServicesByCategoryFormModel = ({
  distinctMandatoryServiceList,
  distinctSegmentServiceList,
  distinctServiceList,
  optionalServiceList,
}: {
  distinctMandatoryServiceList: OptionalService[]
  distinctSegmentServiceList: OptionalService[]
  distinctServiceList: OptionalService[]
  optionalServiceList: OptionalService[]
}): OptionalServicesByCategoryValue => {
  const mandatory = []
  const standard = []
  const segment = []

  for (const service of optionalServiceList) {
    const {
      meta: { id },
    } = service

    if (distinctMandatoryServiceList.find(({ meta }) => meta.id === id)) {
      mandatory.push(OptionalServiceFormModel(service))
    }

    if (distinctServiceList.find(({ meta }) => meta.id === id)) {
      standard.push(OptionalServiceFormModel(service))
    }

    if (distinctSegmentServiceList.find(({ meta }) => meta.id === id)) {
      segment.push(OptionalServiceFormModel(service))
    }
  }

  return {
    mandatory,
    standard,
    segment,
  }
}

type BrandFormModelConfig = {
  selectedBrand?: BrandDetails
  copyMode?: boolean
  distinctMandatoryServiceList?: OptionalService[]
  distinctSegmentServiceList?: OptionalService[]
  distinctServiceList?: OptionalService[]
}

export const BrandFormModel = ({
  selectedBrand,
  copyMode = false,
  distinctMandatoryServiceList = [],
  distinctSegmentServiceList = [],
  distinctServiceList = [],
}: BrandFormModelConfig): BrandFormValue => {
  if (!selectedBrand) {
    return EMPTY_BRAND_FORM_VALUES
  }

  const {
    brandId,
    brandDescription,
    matchingData,
    excludingData,
    optionalServiceList,
  } = selectedBrand

  const {
    name,
    titleByType,
    textByCategory,
    imageDetailsList,
    brandCode,
    brandRule = '',
    brandCodeExcluding,
    additionalDetailList,
  } = brandDescription

  const { EXTERNAL = '', SHORT = '' } = titleByType

  const {
    ATPCO = '',
    STRAPLINE = '',
    MARKETING_AGENT = '',
    MARKETING_CONSUMER = '',
    UPSELL = '',
  } = textByCategory

  const formFields = {
    brandId,
    brandDescription: {
      name,
      brandRule,
      imageDetailsList,
      brandCode: brandCode.trim(),
      brandCodeExcluding: brandCodeExcluding.trim(),
      additionalDetailList,
      textByCategory: {
        ATPCO,
        STRAPLINE,
        UPSELL,
        MARKETING_AGENT,
        MARKETING_CONSUMER,
      },
      titleByType: {
        EXTERNAL,
        SHORT,
      },
    },
    matchingData: MatchingDataFormModel(matchingData),
    excludingData: MatchingDataFormModel(excludingData),
    meta: {
      matchingData: MatchingDataMetaFormModel(matchingData),
      excludingData: MatchingDataMetaFormModel(excludingData),
      filters: BRAND_SERVICES_FILTERS,
    },
    optionalServicesByCategory: optionalServicesByCategoryFormModel({
      distinctMandatoryServiceList,
      distinctSegmentServiceList,
      distinctServiceList,
      optionalServiceList,
    }),
  }

  if (copyMode) {
    return {
      ...formFields,
      brandDescription: {
        ...formFields.brandDescription,
        name: '',
      },
    }
  }

  return formFields
}
