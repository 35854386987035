import { GeographyListRequestModel } from 'common/models'
import { AncillaryFormRequestShape, AncillaryFormValue } from 'common/types'

type AncillaryRequestModelConfig = {
  copyMode?: boolean
}

export const AncillaryRequestModel = (
  values: AncillaryFormValue,
  config?: AncillaryRequestModelConfig,
): AncillaryFormRequestShape => {
  const {
    group,
    subgroup,
    subcode,
    serviceTypeCode,
    serviceName,
    ancillaryDescription,
    textByCategory,
    titleByType,
    effectiveDate,
    discontinueDate,
    serviceRule,
    matchingData,
    key,
    serviceAvailability,
    source,
    imageDetailsList,
    priceRangeList,
    baggageDetails,
    additionalDetailList,
  } = values
  const { equipmentType, cabinType, geographyList } = matchingData
  const {
    cabin,
    weight,
    height,
    length,
    dimensionUnit,
    piece,
    width,
    weightUnit,
  } = baggageDetails

  const keyValue = key !== subcode ? key : undefined

  const ancillaryFormRequestShape = {
    key: keyValue,
    group: group.value,
    subgroup: subgroup.value,
    serviceName: serviceName.value,
    ancillaryDescription,
    serviceTypeCode,
    subcode, //-
    titleByType,
    textByCategory,
    effectiveDate,
    discontinueDate,
    serviceRule,
    serviceAvailability,
    source,
    imageDetailsList,
    priceRangeList,
    matchingData: {
      equipmentType: equipmentType.value || null,
      cabinType: cabinType.label || null,
      geographyList: GeographyListRequestModel(geographyList),
    },
    baggageDetails: {
      cabin: cabin.value || null,
      weight,
      height,
      length,
      dimensionUnit: dimensionUnit.value || null,
      piece,
      width,
      weightUnit: weightUnit.value || null,
    },
    additionalDetailList,
  }

  if (config?.copyMode) {
    return {
      ...ancillaryFormRequestShape,
      key: undefined,
    }
  }

  return ancillaryFormRequestShape
}
