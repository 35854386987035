import { UpsellIndicator } from 'common/enums'
import { ApiCarrier } from 'common/types'
import { Carrier } from 'common/types'

export const CarrierModel = (carrier: ApiCarrier): Carrier => {
  const {
    cdnSupplierId = '',
    code = '',
    countries = [],
    description = '',
    distChannel = '',
    fareFamilyPrecedence,
    id,
    logoId = '',
    logoUrl = '',
    name = '',
    notificationMails = [],
    pseudoCities,
    typeCode = '',
    upsells,
    url = '',
  } = carrier

  const convertedUpsells = upsells.map((value) => {
    switch (value.indicator) {
      case UpsellIndicator.B:
      case 'B':
        return {
          ...value,
          indicator: UpsellIndicator.B,
        }
      case UpsellIndicator.O:
      case 'O':
        return {
          ...value,
          indicator: UpsellIndicator.O,
        }

      case UpsellIndicator.U:
      case 'U':
        return {
          ...value,
          indicator: UpsellIndicator.U,
        }
      default:
        return {
          ...value,
          indicator: UpsellIndicator.A,
        }
    }
  })

  return {
    CDNTravelSupplierId: cdnSupplierId,
    code,
    countries,
    description,
    distChannel,
    fareFamilyPrecedence,
    id,
    logoId,
    logoUrl,
    name,
    notificationMails,
    pseudoCities,
    typeCode,
    upsells: convertedUpsells,
    url,
  }
}
