import { BrandRuleFieldInputType } from 'common/enums'

import {
  FBC_REGEX,
  FBC_REGEX_ERROR,
  FLIGHT_NUMBER_REGEX,
  FLIGHT_RANGE_REGEX,
  FTC_REGEX,
  FTC_REGEX_ERROR,
  RBD_REGEX,
  RBD_REGEX_ERROR,
} from './brand-validation.constants'

export const validateCodes = (value: string, testRegEx: RegExp): boolean => {
  const items = value.split('•')
  if (items.length === 1 && items[0] === '') {
    return true
  }
  return items.every((item) => {
    if (item.trim().length > 0) {
      return testRegEx.test(item.trim())
    }
    return false
  })
}

export const validateCodesOrder = (value: string): boolean => {
  const items = value.split('•')
  if (items.length === 1 && items[0] === '') {
    return true
  }
  return items.every((item) => {
    if (item.trim().length > 0) {
      const range = item.split('-')
      return Number(range[1]) > Number(range[0])
    }
    return false
  })
}

export const flightRangeValidator = (value?: string): boolean => {
  if (!value) {
    return true
  }

  const allValid = validateCodes(value, FLIGHT_RANGE_REGEX)
  const allValidOrder = validateCodesOrder(value)

  if (!allValid || !allValidOrder) {
    return false
  }

  return true
}

export const flightNumberValidator = (value?: string): boolean => {
  if (!value) {
    return true
  }

  const allValid = validateCodes(value, FLIGHT_NUMBER_REGEX)

  if (!allValid) {
    return false
  }

  return true
}

export const pickBrandRuleRegEx = (value: BrandRuleFieldInputType): RegExp => {
  if (value === BrandRuleFieldInputType.RBD) {
    return RBD_REGEX
  }
  if (value === BrandRuleFieldInputType.FBC) {
    return FBC_REGEX
  }
  if (value === BrandRuleFieldInputType.FTC) {
    return FTC_REGEX
  }

  return FTC_REGEX
}

export const pickBrandRuleError = (value: BrandRuleFieldInputType): string => {
  if (value === BrandRuleFieldInputType.RBD) {
    return RBD_REGEX_ERROR
  }
  if (value === BrandRuleFieldInputType.FBC) {
    return FBC_REGEX_ERROR
  }
  if (value === BrandRuleFieldInputType.FTC) {
    return FTC_REGEX_ERROR
  }

  return FTC_REGEX_ERROR
}
