import { FormControl, Select } from '@atlas-design-system/react'
import { useReferenceData } from 'common/hooks/use-reference-data.hook'
import {
  BrandServicesFiltersValue,
  OptionalService,
  SelectOption,
} from 'common/types'
import { useField } from 'formik'
import React, { useCallback, useMemo } from 'react'

type ServicesFiltersProps = {
  services: OptionalService[]
}
export const ServicesFilters: React.FC<ServicesFiltersProps> = ({
  services,
}) => {
  const [field, , helpers] = useField<BrandServicesFiltersValue>('meta.filters')

  const { setValue } = helpers
  const { value } = field

  const { groupSelectOptions } = useReferenceData()
  const filteredGroups = useMemo(() => {
    return groupSelectOptions.filter((option) =>
      services.some(
        (service) => service.group.toLowerCase() === option.value.toLowerCase(),
      ),
    )
  }, [services, groupSelectOptions])

  const handleShowChange = useCallback(
    (selectedOption: SelectOption<string>) => {
      setValue({
        ...value,
        show: selectedOption,
      })
    },
    [setValue, value],
  )
  const handleSortChange = useCallback(
    (selectedOption: SelectOption<'group' | 'name' | 'associated'>) => {
      setValue({
        ...value,
        sort: selectedOption,
      })
    },
    [setValue, value],
  )

  return (
    <>
      <div className="row" style={{ paddingTop: 'var(--spaceM)' }}>
        <div className="col-xs-3">
          <FormControl label="Show">
            <Select
              next
              name="filters.show"
              options={[{ label: 'All', value: 'all' }, ...filteredGroups]}
              onChange={handleShowChange}
              onBlur={field.onBlur}
              value={field.value.show}
              className="atls-select__menu-400"
            />
          </FormControl>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-3">
          <FormControl label="Sort by">
            <Select
              next
              name="filters.sort"
              options={[
                { label: 'Group', value: 'group' },
                { label: 'Name', value: 'name' },
                { label: 'Associated', value: 'associated' },
              ]}
              onChange={handleSortChange}
              onBlur={field.onBlur}
              value={field.value.sort}
              className="atls-select__menu-400"
            />
          </FormControl>
        </div>
      </div>
    </>
  )
}
