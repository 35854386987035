import { API, SERVICES } from 'common/api'
import {
  CreateBrandRequest,
  CreateBrandResponse,
  DeleteBrandRequest,
  DeleteBrandResponse,
  EditBrandRequest,
  EditBrandResponse,
  ReorderBrandsRequest,
  ReorderBrandsResponse,
} from 'common/types'

export const createBrand = async (
  data: CreateBrandRequest,
): Promise<CreateBrandResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().post<CreateBrandResponse>(
      `${SERVICES.BRANDED_FARES_SERVICE}/brand`,
      data,
    )

    return { confirmationId, entityId }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const editBrand = async (
  data: EditBrandRequest,
): Promise<EditBrandResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().put<EditBrandResponse>(
      `${SERVICES.BRANDED_FARES_SERVICE}/brand`,
      data,
    )

    return {
      confirmationId,
      entityId,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteBrand = async (
  data: DeleteBrandRequest,
): Promise<DeleteBrandResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().delete<DeleteBrandResponse>(
      `${SERVICES.BRANDED_FARES_SERVICE}/brand`,
      { data },
    )

    return {
      confirmationId,
      entityId,
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export const reorderBrands = async (
  data: ReorderBrandsRequest,
): Promise<ReorderBrandsResponse> => {
  try {
    const {
      data: { confirmationId, entityId },
    } = await API().post<ReorderBrandsResponse>(
      `${SERVICES.BRANDED_FARES_SERVICE}/reorderBrands`,
      data,
    )

    return { confirmationId, entityId }
  } catch (error) {
    return Promise.reject(error)
  }
}
