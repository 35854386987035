import { toast } from '@atlas-design-system/react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useCarrierDetailsContext } from 'common/context'
import { useFamilyContext } from 'common/context/family-context/family-context'
import { updateFamilyStatus } from 'common/services'
import {
  UpdateFamilyStatusRequest,
  UpdateFamilyStatusResponse,
} from 'common/types'

export const usePromoteFamily = (): UseMutationResult<
  UpdateFamilyStatusResponse,
  Error,
  UpdateFamilyStatusRequest
> => {
  const { reloadCarrierDetails } = useCarrierDetailsContext()
  const { closePromoteModal } = useFamilyContext()

  return useMutation<
    UpdateFamilyStatusResponse,
    Error,
    UpdateFamilyStatusRequest
  >((data: UpdateFamilyStatusRequest) => updateFamilyStatus(data), {
    onSuccess: ({ confirmationId }) => {
      toast.success(
        'Success! Family has been promoted. Confirmation id: ' + confirmationId,
      )
      reloadCarrierDetails()
      closePromoteModal()
    },
    onError: ({ message }) => {
      toast.error(`Error: Something went wrong. ${message}`)
    },
  })
}
