import {
  CabinTypeSelect,
  EquipmentTypeSelect,
  FormHeader,
} from 'common/components'
import { useBrandContext } from 'common/context'
import { MatchingExcludingDataNamePath } from 'common/types'
import React, { useMemo } from 'react'

import { FlightNumberField } from './flight-number-field.component'
import { FlightRangeField } from './flight-range-field.component'

type AdditionalInfoFieldsProps = {
  namePath: MatchingExcludingDataNamePath
  disabled: boolean
}
export const AdditionalInfoFields: React.FC<AdditionalInfoFieldsProps> = ({
  namePath,
  disabled,
}) => {
  const { selectedFamily } = useBrandContext()

  const fieldsDisabled = useMemo(() => {
    if (disabled) {
      return disabled
    }
    return !selectedFamily
  }, [selectedFamily, disabled])

  return (
    <>
      <FormHeader title="Additional Information" />
      <div className="row">
        <div className="col-xs-6">
          <FlightRangeField
            disabled={fieldsDisabled}
            label="Flight Range"
            name={`${namePath}.flightRanges`}
          />

          <FlightNumberField
            disabled={fieldsDisabled}
            label="Flight Number"
            name={`${namePath}.flightNumbers`}
          />
        </div>
        <div className="col-xs-6">
          <EquipmentTypeSelect
            name={`${namePath}.equipment`}
            disabled={fieldsDisabled}
          />
          <CabinTypeSelect
            name={`${namePath}.cabin`}
            disabled={fieldsDisabled}
          />
        </div>
      </div>
    </>
  )
}
