import { CollapsibleFormBlock } from 'common/components'
import React from 'react'

import { MatchingExcludingFields } from './components'

type MatchingExcludingDataProps = {
  hasWritePermission: boolean
}
export const MatchingExcludingData: React.FC<MatchingExcludingDataProps> = ({
  hasWritePermission = false,
}) => {
  return (
    <>
      <CollapsibleFormBlock title="Matching Data/Excluding Data">
        <div style={{ paddingTop: 'var(--spaceM)' }}>
          <MatchingExcludingFields
            namePath={'matchingData'}
            hasWritePermission={hasWritePermission}
          />
          <MatchingExcludingFields
            namePath={'excludingData'}
            hasWritePermission={hasWritePermission}
          />
        </div>
      </CollapsibleFormBlock>
    </>
  )
}
