import { Button, ButtonGroup, Form } from '@atlas-design-system/react'
import { CollapsibleFormBlock, FormCancelDialog } from 'common/components'
import {
  useAuth,
  useBrandContext,
  useCarrierDetailsContext,
} from 'common/context'
import { PendingAction, Permission, ServiceSource } from 'common/enums'
import {
  useAncillaries,
  useMandatoryAncillaries,
  useWatchForm,
} from 'common/hooks'
import {
  BrandFormValue,
  MandatoryMap,
  OptionalServicesByCategoryValue,
  OptionalServiceValue,
  PriceRangeFormValue,
} from 'common/types'
import { FormikProvider, useFormik } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { getMissingMandatoryMap } from '../models/brand-request-model'
import styles from './brand-form.module.css'
import {
  AssociatedServices,
  BrandConfirmModal,
  BrandCopyModal,
  BrandDescription,
  BrandViewFilingModal,
  MandatoryModal,
  MatchingExcludingData,
} from './components'
import {
  ChargeableModal,
  useChargeableModal,
} from './components/chargeable-modal'
import { useBrandForm } from './use-brand-form'
import { validationSchema } from './validators/brand-validation.schema'

type BrandFormProps = {
  initialValues: BrandFormValue
  onSubmit: (values: BrandFormValue) => void
}

export const BrandForm: React.FC<BrandFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const [hiddenViewFilingModal, setHiddenViewFilingModal] = useState(true)
  const [mandatoryModalHidden, setMandatoryModalHidden] = useState(true)
  const [missingMandatoryMap, setMissingMandatoryMap] = useState<MandatoryMap>(
    {},
  )
  const { hasPermissionTo, isTravelportUser, isAdminUser } = useAuth()

  const {
    hidden,
    closeModal,
    openModal,
    initialValues: priceRangeFormInitialValues,
    selectedServiceId,
    namePath,
  } = useChargeableModal()

  const { defaultMandatoryMap } = useMandatoryAncillaries()

  const hasWritePermission = useMemo(
    () => hasPermissionTo(Permission.WRITE_BRANDS),
    [hasPermissionTo],
  )

  const { selectedFamily, setPendingAction, copyMode, selectedBrand } =
    useBrandContext()
  const { brandsData } = useAncillaries()

  const { archiveMode } = useCarrierDetailsContext()

  const handleSubmit = useCallback(
    (formValues: BrandFormValue) => {
      if (!hasWritePermission) {
        return
      }

      const { brandId } = formValues

      if (brandId && brandId !== 'NEW' && !copyMode) {
        setPendingAction(PendingAction.EDIT_BRAND)
        return
      }

      const mandatoryMap = getMissingMandatoryMap(
        formValues,
        defaultMandatoryMap,
        brandsData.distinctMandatoryServiceList,
      )

      if (Object.keys(mandatoryMap).length > 0) {
        setMandatoryModalHidden(false)
        setMissingMandatoryMap(mandatoryMap)
        return
      }

      onSubmit(formValues)
    },
    [
      hasWritePermission,
      onSubmit,
      setPendingAction,
      copyMode,
      defaultMandatoryMap,
      brandsData.distinctMandatoryServiceList,
    ],
  )

  const isATPCO = useMemo(
    () => selectedFamily?.source === ServiceSource.ATPCO,
    [selectedFamily],
  )

  const formik = useFormik<BrandFormValue>({
    initialValues,
    validationSchema: validationSchema(isATPCO),
    enableReinitialize: true,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    if (formik.values.brandId !== initialValues.brandId) {
      formik.resetForm({ values: initialValues, errors: {}, touched: {} })
    }
  }, [initialValues, formik])

  const {
    cancelDialogVisible,
    hideCancelDialog,
    showCancelDialog,
    onFormReset,
  } = useBrandForm({
    formik,
  })

  useWatchForm<BrandFormValue>(formik)

  const { values } = formik

  const viewFilingButtonDisabled = useMemo(() => {
    if (!selectedBrand) {
      return true
    }
    return false
  }, [selectedBrand])

  const viewFilingButtonShow = useMemo(() => {
    if (isTravelportUser || isAdminUser) {
      return true
    }
    return false
  }, [isTravelportUser, isAdminUser])

  const submitDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }
    if (!selectedFamily) {
      return true
    }

    if (
      selectedFamily.source === ServiceSource.ATPCO &&
      (!values.brandId || values.brandId === 'New')
    ) {
      return true
    }

    return archiveMode
  }, [hasWritePermission, selectedFamily, values.brandId, archiveMode])

  const cancelDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }
    return !selectedFamily || archiveMode
  }, [hasWritePermission, selectedFamily, archiveMode])

  const tableDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }
    if (!selectedFamily) {
      return true
    }
    if (selectedFamily.source === ServiceSource.ATPCO) {
      return true
    }

    return false
  }, [selectedFamily, hasWritePermission])

  const submitForm = useCallback(() => {
    onSubmit(values)
    setMandatoryModalHidden(true)
  }, [onSubmit, values])

  const handleEditChargeableClick = useCallback(
    (serviceId: string, name: keyof OptionalServicesByCategoryValue) => {
      const services = formik.values.optionalServicesByCategory[
        name
      ] as OptionalServiceValue[]
      const item = services.find(({ meta: { id } }) => id === serviceId)

      if (!item) {
        return
      }

      const { priceRangeList } = item

      openModal(serviceId, priceRangeList, name)
    },
    [openModal, formik],
  )

  const handleChargeableDetailsSave = useCallback(
    (priceRangeFormValue: PriceRangeFormValue) => {
      if (!namePath) {
        return
      }
      const currentValues = formik.values.optionalServicesByCategory[
        namePath
      ] as OptionalServiceValue[]
      formik.setFieldValue(
        `optionalServicesByCategory.${namePath}`,
        currentValues.map((service: OptionalServiceValue) => {
          if (service.meta.id === selectedServiceId) {
            return {
              ...service,
              priceRangeList: priceRangeFormValue.priceRangeList,
            }
          }
          return service
        }),
      )
      closeModal()
    },
    [formik, selectedServiceId, closeModal, namePath],
  )

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <div className={styles.collapsibleBlock}>
            <BrandDescription
              formik={formik}
              hasWritePermission={hasWritePermission}
            />
          </div>

          <div className={styles.collapsibleBlock}>
            <MatchingExcludingData hasWritePermission={hasWritePermission} />
          </div>

          <CollapsibleFormBlock
            title={
              initialValues.brandId
                ? `Ancillaries and Segment Branding - Associated: ${
                    Object.values(
                      formik.values.optionalServicesByCategory,
                    ).flat().length
                  }`
                : 'Ancillaries and Segment Branding'
            }
          >
            <AssociatedServices
              brandId={values.brandId}
              filters={values.meta.filters}
              optionalServicesByCategory={values.optionalServicesByCategory}
              tableDisabled={tableDisabled}
              onEditChargeableClick={handleEditChargeableClick}
            />
          </CollapsibleFormBlock>

          <ButtonGroup align="right">
            {viewFilingButtonShow && (
              <Button
                appearance="primaryLink"
                onClick={() => setHiddenViewFilingModal(false)}
                disabled={viewFilingButtonDisabled}
              >
                View Filing
              </Button>
            )}
            <Button
              appearance="primary"
              type="submit"
              disabled={submitDisabled}
            >
              Save
            </Button>
            <Button onClick={showCancelDialog} disabled={cancelDisabled}>
              Cancel
            </Button>
          </ButtonGroup>
        </Form>
      </FormikProvider>

      <FormCancelDialog
        hidden={!cancelDialogVisible}
        onConfirm={onFormReset}
        onDismiss={hideCancelDialog}
      />
      <BrandConfirmModal submitForm={submitForm} resetForm={onFormReset} />
      <BrandCopyModal />
      <BrandViewFilingModal
        onDismiss={setHiddenViewFilingModal}
        hidden={hiddenViewFilingModal}
      />

      {selectedServiceId && (
        <ChargeableModal
          hidden={hidden}
          onDismiss={closeModal}
          initialValues={priceRangeFormInitialValues}
          onChargeableDetailsSave={handleChargeableDetailsSave}
        />
      )}

      <MandatoryModal
        hidden={mandatoryModalHidden}
        onConfirm={submitForm}
        onDismiss={() => {
          setMandatoryModalHidden(true)
        }}
        mandatoryMap={missingMandatoryMap}
      />
    </>
  )
}
