import { useCarrierDetailsContext } from 'common/context'
import { Family, FamilyGroup, GdsDetails } from 'common/types'
import { useMemo } from 'react'

export type UseFamily = {
  familyGroupList: FamilyGroup[]
  familyList: Family[]
  gdsDetailsList: GdsDetails[]
  nationList: string[]
  isLoading: boolean
}

export const useFamily = (): UseFamily => {
  const { data, isLoading } = useCarrierDetailsContext()

  const familyGroupList = useMemo(() => data?.familyGroupList || [], [data])
  const gdsDetailsList = useMemo(() => data?.gdsDetailsList || [], [data])
  const nationList = useMemo(() => data?.nationList || [], [data])

  const familyList = useMemo(
    () =>
      familyGroupList.reduce<Family[]>((acc, familyGroup) => {
        const families = familyGroup.familyList
        acc.push(...families)
        return acc
      }, []),
    [familyGroupList],
  )

  return {
    isLoading,
    gdsDetailsList,
    familyGroupList,
    familyList,
    nationList,
  }
}
