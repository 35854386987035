import { Spinner, Typography } from '@atlas-design-system/react'
import { ArchiveButton } from 'common/components/archive-button/archive-button'
import { useCarrierDetailsContext } from 'common/context'
import { groupServices } from 'common/helpers'
import { useAncillaries } from 'common/hooks'
import React from 'react'

import styles from './ancillaries-list.module.css'
import { AncillarySidebarBlock } from './components'

const SUBTITLE = {
  LIVE_MODE: 'All of the Ancillaries you create will be saved here',
  ARCHIVE_MODE: 'All archived ancillaries are displayed here',
}

export const AncillariesList: React.FC = () => {
  const { isLoading, archiveMode } = useCarrierDetailsContext()
  const { allAncillaries } = useAncillaries()

  const serviceGroups = groupServices(allAncillaries, 'group')

  return (
    <>
      <div className={styles.header}>
        <div className="row middle-xs between-xs">
          <div className="col-xs">
            <Typography
              data-testid="ancillaries-list-title"
              variant="displayText6"
              bold
            >
              Ancillaries
            </Typography>
          </div>
          <ArchiveButton />
        </div>
        <Typography
          data-testid="ancillaries-list-subtitle"
          variant="contentText2"
        >
          {archiveMode ? SUBTITLE.ARCHIVE_MODE : SUBTITLE.LIVE_MODE}
        </Typography>
      </div>

      {isLoading && <Spinner className="mmp-ui-spinner" />}

      <ul className={styles.list}>
        {serviceGroups.map((serviceGroup, index) => (
          <li key={index} className={styles.listItem}>
            <AncillarySidebarBlock serviceGroup={serviceGroup} />
          </li>
        ))}
      </ul>
    </>
  )
}
