import { Pagination } from '@atlas-design-system/react'
import { GeographyPointValue } from 'common/types'
import { FieldArray, FormikProps } from 'formik'
import { useEffect, useMemo, useState } from 'react'

import { GeographyRow } from './components'
import styles from './geography-fields-list.module.css'

type GeographyFieldsListProps<T> = {
  namePath: string
  formik: FormikProps<T>
  items: GeographyPointValue[]
  disabled?: boolean
  onGetErrosMessage?: (fieldName: string, index: number) => string | undefined
}

export const GeographyFieldsList = <T,>({
  formik,
  namePath,
  items,
  disabled = false,
  onGetErrosMessage,
}: GeographyFieldsListProps<T>): JSX.Element => {
  const canDelete = useMemo(() => items.length > 1, [items])
  const [pageSize, setPageSize] = useState(20)
  const [pageIndex, setPageIndex] = useState(0)

  const canNext = pageIndex + 1 !== Math.ceil(items.length / pageSize)
  const canPrev = pageIndex !== 0

  useEffect(() => {
    if (pageIndex >= Math.ceil(items.length / pageSize)) {
      setPageIndex(Math.ceil(items.length / pageSize) - 1)
    } else {
      setPageIndex(pageIndex)
    }
  }, [setPageIndex, pageIndex, pageSize, items.length])

  const onShowRowsChange = (size: number) => {
    const startingNumber = pageIndex * pageSize
    setPageSize(size)
    setPageIndex(startingNumber / size)
  }

  const nextPage = () => {
    setPageIndex(pageIndex + 1)
  }

  const previousPage = () => {
    setPageIndex(pageIndex - 1)
  }

  const displayList = useMemo(() => {
    const start = pageIndex * pageSize
    return items.slice(start, start + pageSize)
  }, [items, pageIndex, pageSize])

  return (
    <FieldArray name={namePath}>
      {(fieldArrayProps) => (
        <>
          {displayList.map((item, index) => (
            <GeographyRow<T>
              formik={formik}
              key={index}
              index={index + pageIndex * pageSize}
              item={item}
              canDelete={canDelete}
              fieldArrayProps={fieldArrayProps}
              namePath={namePath}
              disabled={disabled}
              onGetErrosMessage={onGetErrosMessage}
            />
          ))}
          <div className="row">
            <div className={`col-xs-12 ${styles.geoPagination}`}>
              {items.length > 20 && (
                <Pagination
                  canNextPage={canNext}
                  canPreviousPage={canPrev}
                  onShowRowsChange={onShowRowsChange}
                  pageSizeOptions={[
                    {
                      label: '5',
                      value: 5,
                    },
                    {
                      label: '10',
                      value: 10,
                    },
                    {
                      label: '20',
                      value: 20,
                    },
                    {
                      label: '50',
                      value: 50,
                    },
                  ]}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  dataSize={items.length}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  defaultPageSize={20}
                />
              )}
            </div>
          </div>
        </>
      )}
    </FieldArray>
  )
}
