import { Checkbox } from '@atlas-design-system/react'
import { CellContext } from '@tanstack/react-table'
import { OptionalService } from 'common/types'
import React from 'react'

type RowSelectionCellProps = CellContext<OptionalService, any> & {
  disabled: boolean
}

export const RowSelectionCell: React.FC<RowSelectionCellProps> = ({
  row,
  disabled,
}) => (
  <Checkbox
    disabled={disabled}
    checked={row.getIsSelected()}
    onChange={row.getToggleSelectedHandler()}
    id={`check-${row.original.meta.id}`}
    label=""
  />
)
