import {
  ArrowDownIcon,
  ArrowUpIcon,
  Collapsible,
  Typography,
} from '@atlas-design-system/react'
import { ReactNode } from 'react'

import styles from './collapsible-form-block.module.css'

type CollapsibleFormBlockProps = {
  children?: ReactNode
  title?: string | ReactNode
  defaultOpened?: boolean
  className?: string
}

type CollapsibleFormBlockHeaderProps = {
  tabIndex: number
  isExpanded: boolean
  onClick: () => void
  onKeyDown: () => void
}

export const CollapsibleFormBlock: React.FC<CollapsibleFormBlockProps> = ({
  children,
  title = 'Ancillary Builder',
  defaultOpened = false,
  className,
}) => {
  return (
    <>
      <Collapsible
        data-testid={`collapsible-block-${title?.toString().replace(' ', '')}`}
        header={({
          onClick,
          tabIndex,
          onKeyDown,
          isExpanded,
        }: CollapsibleFormBlockHeaderProps) => {
          return (
            <div
              onClick={onClick}
              onKeyDown={onKeyDown}
              role="button"
              tabIndex={tabIndex}
              className={`${styles.header} ${className}`}
            >
              <Typography variant="displayText6">{title}</Typography>
              {isExpanded ? (
                <ArrowUpIcon className={styles.arrowIcon} width="20" />
              ) : (
                <ArrowDownIcon className={styles.arrowIcon} width="20" />
              )}
            </div>
          )
        }}
        defaultOpened={defaultOpened}
        className={styles.wrapper}
      >
        {children}
      </Collapsible>
    </>
  )
}
