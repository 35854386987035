import { DATE_FORMAT } from 'common/constants'
import { UpsellIndicator } from 'common/enums'
import { CarrierRequestModel } from 'common/models'
import {
  Carrier,
  CarrierFormValue,
  CarrierRequest,
  Country,
  CountryFormValue,
  PseudoCity,
  PseudoCityFormValue,
  Upsell,
  UpsellFormValue,
} from 'common/types'
import { format } from 'date-fns'

const today = new Date()
today.setHours(0, 0, 0, 0)

const CountriesRequestModel = (values: CountryFormValue[]): Country[] => {
  return values
    .map((countryForm) => {
      return {
        ...countryForm,
        effectiveDate:
          !!countryForm.effectiveDate && countryForm.effectiveDate.length > 0
            ? countryForm.effectiveDate
            : format(today, DATE_FORMAT),
        code: countryForm.code.value,
        discontinueDate:
          !countryForm.discontinueDate ||
          countryForm.discontinueDate.length === 0
            ? undefined
            : countryForm.discontinueDate,
      }
    })
    .filter((value) => value.code.length > 0)
}

const PseudoCityRequestModel = (
  values: PseudoCityFormValue[],
): PseudoCity[] => {
  return values
    .map((pccForm) => {
      return {
        ...pccForm,
        effectiveDate:
          !!pccForm.effectiveDate && pccForm.effectiveDate.length > 0
            ? pccForm.effectiveDate
            : format(today, DATE_FORMAT),
        gdsCode: pccForm.gdsCode.value,
        cityCode: pccForm.cityCode.toUpperCase(),
        discontinueDate:
          !pccForm.discontinueDate || pccForm.discontinueDate.length === 0
            ? undefined
            : pccForm.discontinueDate,
      }
    })
    .filter((value) => value.cityCode.length > 0 && value.gdsCode.length > 0)
}

const UpsellIndicatorModel = (indicator: string): UpsellIndicator => {
  switch (indicator) {
    case UpsellIndicator.A:
      return UpsellIndicator.A
    case UpsellIndicator.B:
      return UpsellIndicator.B
    case UpsellIndicator.O:
      return UpsellIndicator.O
    case UpsellIndicator.U:
      return UpsellIndicator.U
    default:
      return UpsellIndicator.O
  }
}

const UpsellsRequestModel = (values: UpsellFormValue[]): Upsell[] => {
  return values.map((upsellForm) => {
    return {
      ...upsellForm,
      effectiveDate:
        !!upsellForm.effectiveDate && upsellForm.effectiveDate.length > 0
          ? upsellForm.effectiveDate
          : format(today, DATE_FORMAT),
      indicator: UpsellIndicatorModel(upsellForm.indicator.value),
      groupCode: 'TVP',
    }
  })
}
export const CarrierFormRequestModel = (
  values: CarrierFormValue,
): CarrierRequest => {
  const {
    code,
    countries,
    description,
    distChannel,
    fareFamilyPrecedence,
    typeCode,
    id,
    name,
    emailList,
    pseudoCities,
    upsells,
    url,
  } = values

  const emails = emailList.filter((value) => !!value && value.length > 0)

  const carrierRequest: Carrier = {
    code: code,
    countries: CountriesRequestModel(countries),
    description: description.trim(),
    distChannel: distChannel.value.length > 0 ? distChannel.value : undefined,
    id: id,
    name: name.trim(),
    fareFamilyPrecedence: !!fareFamilyPrecedence ? fareFamilyPrecedence : 'A',
    notificationMails: emails,
    pseudoCities: PseudoCityRequestModel(pseudoCities),
    typeCode: !!typeCode ? typeCode : 'A',
    upsells: UpsellsRequestModel(upsells),
    url: url.length > 0 ? url : undefined,
  }

  return CarrierRequestModel(carrierRequest)
}
