import { FormControl, Select } from '@atlas-design-system/react'
import { FormInput } from 'common/components'
import {
  BRAND_RULE_FIELD_INPUT_TYPE_SELECT_OPTIONS,
  FARE_RULE_INPUT_TYPE_SELECT_OPTIONS,
} from 'common/constants'
import { BrandRuleFieldInputType, FareRuleInputType } from 'common/enums'
import { MatchingDataMetaValue, SelectOption } from 'common/types'
import { Field, useField } from 'formik'
import React, { useCallback, useMemo } from 'react'

import { FareRulesFieldsList } from '../fare-rules-fields-list/fare-rules-fields-list.component'
import { FareRuleDynamicField } from './fare-rule-dynamic-field.component'

type FareRulesFieldsProps = {
  namePath: 'matchingData' | 'excludingData'
  disabled: boolean
}

export const FareRulesFields: React.FC<FareRulesFieldsProps> = ({
  disabled,
  namePath,
}) => {
  const [field, , helpers] = useField<MatchingDataMetaValue>(`meta.${namePath}`)
  const { value } = field
  const { brandRuleFieldInputType, fareRuleInputType } = value
  const { setValue } = helpers

  const brandCodeNamePath = useMemo(
    () => (namePath === 'matchingData' ? 'brandCode' : 'brandCodeExcluding'),
    [namePath],
  )

  const handleFareRuleInputTypeChange = useCallback(
    (selectedOption: SelectOption<FareRuleInputType>) => {
      setValue({
        ...value,
        fareRuleInputType: selectedOption,
      })
    },
    [setValue, value],
  )

  const handleBrandRuleFieldInputTypeChange = useCallback(
    (selectedOption: SelectOption<BrandRuleFieldInputType>) => {
      const { value: selectedType } = selectedOption

      setValue(
        {
          ...value,
          brandRuleFieldInputType: selectedOption,
          fareRule: {
            ...value.fareRule,
            dynamicField:
              selectedType === BrandRuleFieldInputType.EMPTY
                ? ''
                : value.fareRule.dynamicField,
          },
        },
        true,
      )
    },
    [setValue, value],
  )

  return (
    <div>
      <div className="row">
        <div className="col-xs-6">
          <FormControl
            label="Select one or two ways to enter: RBD, FBC and FTC"
            disabled={disabled}
          >
            <Select
              next
              name={`meta.${namePath}.fareRuleInputType`}
              data-testid="fare-rule-input-type-select"
              options={FARE_RULE_INPUT_TYPE_SELECT_OPTIONS}
              onChange={handleFareRuleInputTypeChange}
              value={value.fareRuleInputType}
            />
          </FormControl>
        </div>
      </div>

      {fareRuleInputType.value === FareRuleInputType.MULTIPLE && (
        <div className="row">
          <div className="col-xs-6">
            <FormControl label="Select a" disabled={disabled}>
              <Select
                next
                name={`meta.${namePath}.brandRuleFieldInputType`}
                data-testid="brand-rule-field-input-type-select"
                options={BRAND_RULE_FIELD_INPUT_TYPE_SELECT_OPTIONS}
                onChange={handleBrandRuleFieldInputTypeChange}
                value={brandRuleFieldInputType}
              />
            </FormControl>
          </div>
          <div className="col-xs-6">
            <FareRuleDynamicField
              name={`meta.${namePath}.fareRule.dynamicField`}
              brandRuleFieldInputType={brandRuleFieldInputType.value}
            />
          </div>
        </div>
      )}

      {fareRuleInputType.value === FareRuleInputType.SINGLE && (
        <FareRulesFieldsList
          name={`${namePath}.fareRules`}
          disabled={disabled}
        />
      )}

      <div className="row">
        <div className="col-xs-4">
          <div className="row">
            <div className="col-xs-6">
              <Field
                component={FormInput}
                data-testid="brand-code-input"
                label="Brand Code"
                maxLength="10"
                minLength="1"
                name={`brandDescription.${brandCodeNamePath}`}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
        {fareRuleInputType.value === FareRuleInputType.MULTIPLE && (
          <>
            <div className="col-xs-4">
              <div className="row">
                <div className="col-xs-6">
                  <Field
                    component={FormInput}
                    data-testid="tariff-code-input"
                    label="Tariff"
                    maxLength="3"
                    minLength="1"
                    name={`meta.${namePath}.fareRule.tariff`}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-4">
              <div className="row">
                <div className="col-xs-6">
                  <Field
                    component={FormInput}
                    data-testid="fare-rule-input"
                    label="Rule"
                    maxLength="4"
                    minLength="1"
                    name={`meta.${namePath}.fareRule.rule`}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
