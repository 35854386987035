import { ApiImageDetails } from 'common/types'
import { ImageDetails } from 'common/types'

export const ImageDetailsModel = <ImageCategory>(
  imageDetails: ApiImageDetails<ImageCategory>,
): ImageDetails<ImageCategory> => {
  const { cdnSourcePath, height, width, category, cdnId } = imageDetails

  return {
    cdnSourcePath,
    height,
    width,
    category,
    cdnId,
  }
}
