import { Banner, Button, ButtonGroup, Form } from '@atlas-design-system/react'
import {
  CollapsibleFormBlock,
  FormCancelDialog,
  FormHeader,
  MatchingData,
} from 'common/components'
import { MESSAGE_OVERLAPPING_ANCILLARY_DATES_ERROR } from 'common/constants'
import {
  useAncillaryContext,
  useAuth,
  useCarrierDetailsContext,
} from 'common/context'
import { PendingAction, Permission } from 'common/enums'
import { errorsContainRequiredField } from 'common/helpers'
import { useAncillaries } from 'common/hooks'
import { useWatchForm } from 'common/hooks/form-reset'
import { AncillaryFormValue } from 'common/types'
import { FormikProvider, useFormik } from 'formik'
import React, { useCallback, useMemo } from 'react'

import { ActionButtons } from '../../../action-buttons/action-buttons.component'
import { AncillaryBuilder } from '../../../index'
import styles from './ancillary-form.module.css'
import {
  ANCILLARY_NAME_UNIQUE_ERROR,
  validationSchema,
} from './ancillary-form.schema'
import { AncillaryConfirmModal } from './components'
import { useAncillaryForm } from './use-ancillary-form'

type AncillaryFormProps = {
  onSubmit: (values: AncillaryFormValue) => void
  initialValues: AncillaryFormValue
}

export const AncillaryForm: React.FC<AncillaryFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const { copyMode, selectedAncillary, setPendingAction } =
    useAncillaryContext()
  const { archiveMode } = useCarrierDetailsContext()
  const { hasPermissionTo } = useAuth()
  const { allAncillaries } = useAncillaries()

  const hasWritePermission = useMemo(
    () => hasPermissionTo(Permission.WRITE_ANCILLARIES),
    [hasPermissionTo],
  )

  const handleSubmit = useCallback(
    (formValues: AncillaryFormValue) => {
      if (!copyMode && selectedAncillary) {
        setPendingAction(PendingAction.EDIT)
        return
      }

      onSubmit(formValues)
    },
    [onSubmit, setPendingAction, copyMode, selectedAncillary],
  )

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema({
      allAncillaries,
      effectiveDate: initialValues.effectiveDate,
      discontinueDate: initialValues.discontinueDate,
    }),
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: handleSubmit,
  })

  const { values, submitCount, errors } = formik

  const {
    onFormReset,
    cancelDialogVisible,
    hideCancelDialog,
    showCancelDialog,
  } = useAncillaryForm(formik)

  useWatchForm<AncillaryFormValue>(formik)

  const editMode = useMemo(() => {
    if (hasWritePermission) {
      if (copyMode) {
        return true
      }

      if (selectedAncillary) {
        return false
      }

      return true
    }

    return false
  }, [copyMode, selectedAncillary, hasWritePermission])

  const nameUniqueError = useMemo(
    () =>
      !!errors.serviceName &&
      typeof errors.serviceName === 'string' &&
      errors.serviceName === ANCILLARY_NAME_UNIQUE_ERROR,
    [errors],
  )

  const datesOverlappingError = useMemo(
    () =>
      !!errors.effectiveDate &&
      typeof errors.effectiveDate === 'string' &&
      errors.effectiveDate === MESSAGE_OVERLAPPING_ANCILLARY_DATES_ERROR,
    [errors],
  )

  const fieldsDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }

    return editMode && nameUniqueError
  }, [editMode, nameUniqueError, hasWritePermission])

  const submitForm = useCallback(() => {
    if (!hasWritePermission) {
      return
    }

    onSubmit(values)
  }, [onSubmit, values, hasWritePermission])

  const saveButtonDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }
    return archiveMode && !copyMode
  }, [hasWritePermission, archiveMode, copyMode])

  const cancelButtonDisabled = useMemo(() => {
    if (!hasWritePermission) {
      return true
    }
    return archiveMode && !copyMode
  }, [hasWritePermission, archiveMode, copyMode])

  const errorBannerMessage = useMemo(() => {
    const errorsKeys = Object.keys(errors)

    if (errorsKeys.length === 0) {
      return ''
    }

    if (nameUniqueError) {
      return ANCILLARY_NAME_UNIQUE_ERROR
    }

    const requiredError = errorsContainRequiredField<AncillaryFormValue>(errors)

    if (requiredError) {
      return 'Error! Please fill all the mandatory fields'
    }

    if (datesOverlappingError) {
      return MESSAGE_OVERLAPPING_ANCILLARY_DATES_ERROR
    }
  }, [errors, nameUniqueError, datesOverlappingError])

  return (
    <>
      {!!submitCount && !!errorBannerMessage && (
        <Banner
          appearance="error"
          data-testid="ancillaries-form-error-banner"
          heading={errorBannerMessage}
          style={{ marginBottom: 'var(--spaceL)' }}
        />
      )}

      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <>
            <CollapsibleFormBlock title="Ancillary" defaultOpened={true}>
              <FormHeader title="" actionButtons={<ActionButtons />} />
              <AncillaryBuilder
                formik={formik}
                editMode={editMode}
                fieldsDisabled={fieldsDisabled}
              />
            </CollapsibleFormBlock>
            <CollapsibleFormBlock
              title="Matching Data"
              className={styles.collapsible}
            >
              <FormHeader title="Geography" className={styles.formHeader} />
              <MatchingData formik={formik} fieldsDisabled={fieldsDisabled} />
            </CollapsibleFormBlock>
            <ButtonGroup align="right">
              <Button
                data-testid="ancillary-form-submit"
                appearance="primary"
                disabled={saveButtonDisabled}
                type="submit"
              >
                Save
              </Button>
              <Button
                data-testid="ancillary-form-cancel"
                disabled={cancelButtonDisabled}
                onClick={showCancelDialog}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </>
        </Form>
      </FormikProvider>
      <FormCancelDialog
        hidden={!cancelDialogVisible}
        onConfirm={onFormReset}
        onDismiss={hideCancelDialog}
      />
      <AncillaryConfirmModal submitForm={submitForm} resetForm={onFormReset} />
    </>
  )
}
