import { useCarrierDetailsContext } from 'common/context'
import { useCarriers } from 'common/hooks'

export const useLogoImage = (): {
  logoUrl?: string
  isFetching: boolean
} => {
  const { selectedCarrier } = useCarrierDetailsContext()
  const { carrierList, isFetching } = useCarriers()

  const logoUrl = carrierList?.find(
    (carrier) => carrier.code === selectedCarrier?.code,
  )?.logoUrl

  return { logoUrl, isFetching }
}
