import { BrandRuleFieldInputType } from 'common/enums'
import { selectSchema } from 'common/helpers'
import { SelectOption } from 'common/types'
import * as Yup from 'yup'

import {
  FBC_REGEX,
  FLIGHT_NUMBER_ERROR,
  FLIGHT_RANGE_ERROR,
  FTC_REGEX,
  RBD_REGEX,
  RULE_REGEX,
  SBN_REGEX,
  SBN_REGEX_ERROR,
  TARIFF_REGEX,
} from './brand-validation.constants'
import {
  flightNumberValidator,
  flightRangeValidator,
  pickBrandRuleError,
  pickBrandRuleRegEx,
  validateCodes,
} from './brand-validation.utils'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const titleByTypeSchema = (isATPCO: boolean) => {
  if (isATPCO) {
    return Yup.object({
      EXTERNAL: Yup.string().required('Required'),
      SHORT: Yup.string(),
    })
  } else {
    return Yup.object({
      EXTERNAL: Yup.string().required('Required'),
      SHORT: Yup.string()
        .matches(SBN_REGEX, SBN_REGEX_ERROR)
        .required('Required'),
    })
  }
}

const brandDescriptionSchema = (isATPCO: boolean) =>
  Yup.object({
    name: Yup.string().required('Required'),
    titleByType: titleByTypeSchema(isATPCO),
  })

export const fareRuleSchema = Yup.object().shape({
  fareBasisCode: Yup.string().matches(FBC_REGEX),
  fareTypeCode: Yup.string().matches(FTC_REGEX),
  rbd: Yup.string().matches(RBD_REGEX),
  rule: Yup.string().matches(RULE_REGEX),
  tariff: Yup.string().matches(TARIFF_REGEX),
})

const flightRangeSchema = Yup.string().test(
  'flightRangeValidator',
  FLIGHT_RANGE_ERROR,
  flightRangeValidator,
)

const flightNumberSchema = Yup.string().test(
  'flightNumberValidator',
  FLIGHT_NUMBER_ERROR,
  flightNumberValidator,
)

const matchingDataSchema = Yup.object({
  cabinType: selectSchema,
  equipmentType: selectSchema,
  fareRules: Yup.array().of(fareRuleSchema),
  flightRanges: flightRangeSchema,
  flightNumbers: flightNumberSchema,
})

const metaMatchingDataSchema = Yup.object({
  fareRuleInputType: selectSchema,
  brandRuleFieldInputType: selectSchema,

  fareRule: Yup.object().when(
    'brandRuleFieldInputType',
    (
      brandRuleFieldInputType: SelectOption<BrandRuleFieldInputType>,
      schema,
    ) => {
      if (brandRuleFieldInputType.value === BrandRuleFieldInputType.EMPTY)
        return schema

      const testRegEx = pickBrandRuleRegEx(brandRuleFieldInputType.value)
      const testRegExError = pickBrandRuleError(brandRuleFieldInputType.value)

      return schema.shape({
        dynamicField: Yup.string().test(
          'fare-rule-field-format',
          testRegExError,
          (value = '') => validateCodes(value, testRegEx),
        ),
        rule: Yup.string().matches(RULE_REGEX),
        tariff: Yup.string().matches(TARIFF_REGEX),
      })
    },
  ),
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (isATPCO: boolean) =>
  Yup.object({
    brandDescription: brandDescriptionSchema(isATPCO),
    matchingData: matchingDataSchema,
    excludingData: matchingDataSchema,
    meta: Yup.object({
      matchingData: metaMatchingDataSchema,
      excludingData: metaMatchingDataSchema,
    }),
  })
