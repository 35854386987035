import { useAncillaryContext, useCarrierDetailsContext } from 'common/context'
import { AncillaryFormValue } from 'common/types'
import { useCallback } from 'react'

import { AncillaryRequestModel } from '../components/ancillary-form-wrapper/models'
import { useCreateAncillary } from './use-create-ancillary.hook'
import { useEditAncillary } from './use-edit-ancillary.hook'

type UseSubmitAncillaryReturn = {
  submitAncillary: (values: AncillaryFormValue) => void
}

export const useSubmitAncillary = (): UseSubmitAncillaryReturn => {
  const { selectedCarrier, setArchiveMode } = useCarrierDetailsContext()
  const { selectedAncillary, copyMode } = useAncillaryContext()

  const editMutation = useEditAncillary()
  const createMutation = useCreateAncillary()

  const submitAncillary = useCallback(
    (values: AncillaryFormValue) => {
      const optionalService = AncillaryRequestModel(values, {
        copyMode,
      })

      if (!selectedCarrier) {
        return
      }

      if (copyMode) {
        createMutation.mutate({
          carrierCode: selectedCarrier.code,
          carrierDistributionChannel: selectedCarrier?.distChannel,
          optionalService,
        })
      } else {
        // If the optional service is read only, we have to create one in the Branded Fares database
        if (!selectedAncillary || optionalService.key === undefined) {
          createMutation.mutate({
            carrierCode: selectedCarrier.code,
            carrierDistributionChannel: selectedCarrier?.distChannel,
            optionalService,
          })
        } else {
          editMutation.mutate(optionalService)
        }
      }
      setArchiveMode(false)
    },
    [
      copyMode,
      selectedCarrier,
      setArchiveMode,
      createMutation,
      selectedAncillary,
      editMutation,
    ],
  )

  return { submitAncillary }
}
