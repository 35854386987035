import {
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import React from 'react'

const useFallback = (error: unknown) => {
  if ((error as Error).message.includes('401')) {
    return true
  }
  return false
}

const queryClient = new QueryClient({
  mutationCache: new MutationCache(),
  defaultOptions: {
    mutations: {
      useErrorBoundary: useFallback,
    },
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: useFallback,
      retry: false,
      staleTime: Infinity,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

type ReactQueryProviderProps = { children: JSX.Element }

export const ReactQueryProvider: React.FC<ReactQueryProviderProps> = ({
  children,
}) => <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
