import { Button, ButtonGroup, Modal } from '@atlas-design-system/react'
import { FC } from 'react'

import { StyledButtonGroup, StyledImageView } from '../../image-loader.styled'

interface ImageLoaderViewModalProps {
  image?: string
  showImageModal: boolean
  setImageModal: (state: boolean) => void
  setGalleryModal: (state: boolean) => void
}

export const ImageLoaderViewModal: FC<ImageLoaderViewModalProps> = (props) => {
  const { image, showImageModal, setImageModal, setGalleryModal } = props

  return (
    <Modal
      title="Image Upload"
      hidden={!showImageModal}
      size="small"
      onDismiss={() => setImageModal(false)}
      id="image-loader-view-modal"
      actionButtons={
        <StyledButtonGroup>
          <ButtonGroup size="small">
            <Button
              className="image-loader-button"
              appearance="tertiary"
              onClick={() => {
                setImageModal(false)
                setGalleryModal(true)
              }}
            >
              view image gallery
            </Button>
            <Button appearance="primary" onClick={() => setImageModal(false)}>
              Close
            </Button>
          </ButtonGroup>
        </StyledButtonGroup>
      }
    >
      <StyledImageView>
        {image && <img src={image} alt="Image" height={305} width={400} />}
      </StyledImageView>
    </Modal>
  )
}
