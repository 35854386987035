import { AncillaryFormValue, BrandFormValue, Map } from 'common/types'

export type MultiLanguageFormModelType = {
  EXTERNAL_COMMERCIAL: string
  EXTERNAL_SHORT_NAME: string
  MARKETING_AGENT: string
  MARKETING_CONSUMER: string
  STRAPLINE: string
  RULE: string
  UPSELL: string
}

const INITIAL_VALUES = {
  EXTERNAL_COMMERCIAL: '',
  EXTERNAL_SHORT_NAME: '',
  MARKETING_AGENT: '',
  MARKETING_CONSUMER: '',
  STRAPLINE: '',
  RULE: '',
  UPSELL: '',
}

export const MultiLanguageFormModel = (
  isDefault: boolean,
  values: AncillaryFormValue & BrandFormValue,
  selectedLanguage: string,
): MultiLanguageFormModelType => {
  let initialValues = INITIAL_VALUES
  const additionalDetailList =
    values.additionalDetailList ||
    values.brandDescription.additionalDetailList ||
    []

  if (isDefault) {
    initialValues = {
      EXTERNAL_COMMERCIAL:
        values.titleByType?.EXTERNAL || values.brandDescription?.name,
      EXTERNAL_SHORT_NAME:
        values.titleByType?.SHORT ||
        values.brandDescription?.titleByType?.SHORT,
      MARKETING_AGENT:
        values.textByCategory?.MARKETING_AGENT ||
        values.brandDescription?.textByCategory.MARKETING_AGENT ||
        '',
      MARKETING_CONSUMER:
        values.textByCategory?.MARKETING_CONSUMER ||
        values.brandDescription?.textByCategory.MARKETING_CONSUMER ||
        '',
      STRAPLINE:
        values.textByCategory?.STRAPLINE ||
        values.brandDescription?.titleByType?.EXTERNAL ||
        '',
      RULE: values.serviceRule || values.brandDescription?.brandRule || '',
      UPSELL: values.brandDescription?.textByCategory.UPSELL,
    }
  } else {
    const textTypeList =
      additionalDetailList.find(
        (additionalDetail) => additionalDetail.langCode === selectedLanguage,
      )?.textTypeList || []

    const textTypeListMap = textTypeList.reduce<Map>((map, typeList) => {
      map[typeList.type] = typeList.value
      return map
    }, {})

    initialValues = { ...INITIAL_VALUES, ...textTypeListMap }
  }

  return initialValues
}
