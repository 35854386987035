import { GalleryItem, ImageRestrictions } from 'common/types'
import { FC, useState } from 'react'

import { ImageLoaderGalleryModal } from './components/image-loader-gallery-modal/image-loader-gallery-modal.component'
import { ImageLoaderView } from './components/image-loader-view/image-loader-view.component'
import { ImageLoaderViewModal } from './components/image-loader-view-modal/image-loader-view-modal.component'
import { StyledImageLoader } from './image-loader.styled'

interface ImageLoaderProps {
  image?: string
  label: string
  onRemoveImage: () => void
  onSelectImage: (selectedImageDetails: GalleryItem) => void
  disabled?: boolean
  restrictions?: ImageRestrictions
}

export const ImageLoader: FC<ImageLoaderProps> = ({
  image,
  label,
  onRemoveImage,
  onSelectImage,
  restrictions,
  disabled = false,
}) => {
  const [showImageModal, setImageModal] = useState(false)
  const [showGalleryModal, setGalleryModal] = useState(false)

  return (
    <StyledImageLoader>
      <ImageLoaderView
        image={image}
        label={label}
        disabled={disabled}
        setImageModal={setImageModal}
        onRemoveImage={onRemoveImage}
      />
      <ImageLoaderViewModal
        image={image}
        showImageModal={showImageModal}
        setImageModal={setImageModal}
        setGalleryModal={setGalleryModal}
      />
      {showGalleryModal && (
        <ImageLoaderGalleryModal
          showGalleryModal={showGalleryModal}
          setImageModal={setImageModal}
          onSelectImage={onSelectImage}
          setGalleryModal={setGalleryModal}
          restrictions={restrictions}
        />
      )}
    </StyledImageLoader>
  )
}
