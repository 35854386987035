import { UpsellIndicator } from 'common/enums'
import { ApiCarrier, Carrier } from 'common/types'

export const CarrierRequestModel = (carrierDetails: Carrier): ApiCarrier => {
  const {
    CDNTravelSupplierId,
    code,
    countries,
    description,
    distChannel,
    fareFamilyPrecedence,
    id,
    logoId,
    logoUrl,
    name,
    notificationMails,
    pseudoCities,
    typeCode,
    upsells,
    url,
  } = carrierDetails

  const convertedUpsells = upsells.map((value) => {
    switch (value.indicator) {
      case UpsellIndicator.B:
        return {
          ...value,
          indicator: 'B',
        }
      case UpsellIndicator.O:
        return {
          ...value,
          indicator: 'O',
        }

      case UpsellIndicator.U:
        return {
          ...value,
          indicator: 'U',
        }
      default:
        return {
          ...value,
          indicator: 'A',
        }
    }
  })

  return {
    cdnSupplierId: CDNTravelSupplierId,
    code,
    countries,
    description,
    distChannel,
    fareFamilyPrecedence,
    id,
    logoId,
    logoUrl,
    name,
    notificationMails,
    pseudoCities,
    typeCode,
    upsells: convertedUpsells,
    url,
  }
}
