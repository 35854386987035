import { useCarrierDetailsContext } from 'common/context'
import { useSegmentBrandingContext } from 'common/context/segment-branding-context'
import { useAuthUser } from 'common/hooks'
import { SegmentBrandRequestShape } from 'common/types'
import {
  useCreateSegmentBranding,
  useEditSegmentBranding,
} from 'modules/segment-branding/hooks'
import { useCallback } from 'react'

// @todo - Create request fails, need to adjust payload
// @todo - Edit request fails, need to adjust payload

export const useSubmitSegmenBranding = (): {
  submitSegmentBrand: (requestShape: SegmentBrandRequestShape) => void
} => {
  const { selectedCarrier } = useCarrierDetailsContext()
  const { copyMode } = useSegmentBrandingContext()
  const user = useAuthUser()
  const createMutation = useCreateSegmentBranding()
  const editMutation = useEditSegmentBranding()

  const submitSegmentBrand = useCallback(
    (requestShape: SegmentBrandRequestShape) => {
      if (!user || !selectedCarrier) {
        return
      }

      const { key } = requestShape

      if (!key || copyMode) {
        createMutation.mutate({
          carrierCode: selectedCarrier?.code,
          carrierDistributionChannel: selectedCarrier?.distChannel,
          optionalService: requestShape,
        })
      } else {
        editMutation.mutate(requestShape)
      }
    },
    [user, selectedCarrier, copyMode, createMutation, editMutation],
  )

  return { submitSegmentBrand }
}
