import { useBrandContext } from 'common/context'
import { PendingAction } from 'common/enums'
import { useAncillaries } from 'common/hooks'
import { BrandFormValue } from 'common/types'
import { useSubmitBrand } from 'modules/brands/hooks'
import React, { useCallback, useEffect, useState } from 'react'

import { BrandForm } from './brand-form/brand-form.component'
import { BrandFormModel } from './models'

export const BrandFormWrapper: React.FC = () => {
  const { selectedBrand, copyMode, pendingAction, setPendingAction } =
    useBrandContext()
  const { brandsData } = useAncillaries()
  const { submitBrand } = useSubmitBrand()

  const {
    distinctMandatoryServiceList,
    distinctSegmentServiceList,
    distinctServiceList,
  } = brandsData

  const [initialValues, setInitialValues] = useState<BrandFormValue>(() =>
    BrandFormModel({}),
  )

  const resetInitialValues = useCallback(() => {
    setInitialValues(
      BrandFormModel({
        selectedBrand,
        copyMode,
        distinctMandatoryServiceList,
        distinctSegmentServiceList,
        distinctServiceList,
      }),
    )
  }, [
    selectedBrand,
    copyMode,
    distinctMandatoryServiceList,
    distinctSegmentServiceList,
    distinctServiceList,
  ])

  useEffect(() => {
    if (selectedBrand?.brandId !== initialValues.brandId || copyMode) {
      resetInitialValues()
    }
  }, [selectedBrand, copyMode, resetInitialValues, initialValues.brandId])

  useEffect(() => {
    if (pendingAction && pendingAction === PendingAction.RESET_FORM) {
      resetInitialValues()
      initialValues.brandId = undefined
      setPendingAction()
    }
  }, [initialValues, pendingAction, resetInitialValues, setPendingAction])

  const handleSubmit = useCallback(
    (values: BrandFormValue) => {
      if (!values.brandId) {
        initialValues.brandId = 'NEW'
      }
      submitBrand(values)
    },
    [initialValues, submitBrand],
  )

  return (
    <div>
      <BrandForm initialValues={initialValues} onSubmit={handleSubmit} />
    </div>
  )
}
