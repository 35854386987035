import { FormControl, Select } from '@atlas-design-system/react'
import { useFamily } from 'common/hooks'
import { FamilySelectOption } from 'common/types'
import React, { useCallback, useMemo } from 'react'

import { CustomOptionLabel } from './custom-option-label/custom-option-label.component'

type FamilySelectProps = {
  onFamilySelect: (id: string) => void
  selectedFamilyId?: string
  hideLabel?: boolean
  label?: string
  className?: string
  placeholder?: string
  hideAtpcoNumber?: boolean
  excludeId?: string
}

export const FamilySelect: React.FC<FamilySelectProps> = ({
  onFamilySelect,
  selectedFamilyId,
  hideLabel = false,
  label,
  className,
  placeholder = 'Select one',
  hideAtpcoNumber = false,
  excludeId = '',
}) => {
  const { familyList } = useFamily()
  const selectOptions: FamilySelectOption[] = useMemo(
    () =>
      familyList
        .filter(({ id }) => id !== excludeId)
        .map((family) => ({
          value: family.id,
          source: family.source,
          name: family.name,
          version: family.version,
          status: family.status,
          brandDetailsList: family.brandDetailsList,
          atpcoSequenceNumber: family.atpcoSequenceNumber,
        })),
    [familyList, excludeId],
  )

  const handleFamilySelect = useCallback(
    (selectedOption: any) => {
      onFamilySelect(selectedOption.value)
    },
    [onFamilySelect],
  )

  const value = useMemo(
    () => selectOptions.find(({ value: id }) => id === selectedFamilyId),
    [selectedFamilyId, selectOptions],
  )

  const selectNode = (
    <Select
      name="family-select-input"
      data-testid="family-selector"
      next
      options={selectOptions}
      placeholder={placeholder}
      className="atls-select-height-360"
      onChange={handleFamilySelect}
      formatOptionLabel={(family: FamilySelectOption) => (
        <CustomOptionLabel family={family} hideAtpcoNumber={hideAtpcoNumber} />
      )}
      value={value}
    />
  )

  return (
    <>
      {hideLabel ? (
        selectNode
      ) : (
        <FormControl label={label} className={className}>
          {selectNode}
        </FormControl>
      )}
    </>
  )
}
