import { Spinner } from '@atlas-design-system/react'
import { useCarrierDetailsContext } from 'common/context'
import React from 'react'

import { BrandFormWrapper, BrandsSidebar } from './components'

export const Brands: React.FC = () => {
  const { isFetching } = useCarrierDetailsContext()

  if (isFetching) {
    return <Spinner />
  }

  return (
    <div data-testid="brands-page">
      <div className="row">
        <div className="col-xs-12"></div>
      </div>
      <div className="row">
        <div className="col-xs-8">
          <BrandFormWrapper />
        </div>
        <div className="col-xs-4">
          <BrandsSidebar />
        </div>
      </div>
    </div>
  )
}
