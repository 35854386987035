import { Typography } from '@atlas-design-system/react'
import React, { ReactNode } from 'react'

import styles from './form-header.module.css'

interface FormHeaderProps {
  title: string
  actionButtons?: ReactNode
  className?: string
  variant?:
    | 'heroText'
    | 'displayText1'
    | 'displayText2'
    | 'displayText3'
    | 'displayText4'
    | 'displayText5'
    | 'displayText6'
    | 'displayText7'
    | 'contentText1'
    | 'contentText2'
}

export const FormHeader: React.FC<FormHeaderProps> = (props) => {
  const { actionButtons, title, className, variant = 'displayText7' } = props

  return (
    <div className={`row between-xs ${styles.formHeader} ${className}`}>
      <div className="col-xs">
        <Typography
          data-testid={`form-header-${title.replace(' ', '')}`}
          variant={variant}
        >
          {title}
        </Typography>
      </div>
      <div className={`col-xs ${styles.actionButtons}`}>{actionButtons}</div>
    </div>
  )
}
