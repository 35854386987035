import { Button, ButtonGroup, Modal } from '@atlas-design-system/react'
import { PriceRangeFormValue } from 'common/types'
import { useFormik } from 'formik'
import React, { useCallback } from 'react'

import { priceRangeFormSchema } from './chargeable-form.schema'
import { PriceRangeForm } from './price-range-form.component'

type ChargeableModalProps = {
  hidden: boolean
  onDismiss: () => void
  initialValues: PriceRangeFormValue
  onChargeableDetailsSave: (formValues: PriceRangeFormValue) => void
}

export const ChargeableModal: React.FC<ChargeableModalProps> = ({
  hidden,
  onDismiss,
  initialValues,
  onChargeableDetailsSave,
}) => {
  const handleSubmit = useCallback(
    (formValues: PriceRangeFormValue) => {
      onChargeableDetailsSave(formValues)
    },
    [onChargeableDetailsSave],
  )

  const formik = useFormik<PriceRangeFormValue>({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: priceRangeFormSchema,
  })

  const actionButtons = (
    <ButtonGroup align="right">
      <Button appearance="primary" onClick={formik.submitForm}>
        Confirm
      </Button>
      <Button onClick={onDismiss}>Cancel</Button>
    </ButtonGroup>
  )

  return (
    <>
      <Modal
        id="chargeable-modal"
        title="Chargeable Values"
        onDismiss={onDismiss}
        hidden={hidden}
        actionButtons={actionButtons}
      >
        <PriceRangeForm formik={formik} />
      </Modal>
    </>
  )
}
