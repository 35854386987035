import { useCarrierDetailsContext, useFamilyContext } from 'common/context'
import {
  useAuthUser,
  useCreateFamily,
  useEditFamily,
  useFamily,
} from 'common/hooks'
import { BrandDetails, FamilyFormValue } from 'common/types'
import { useCallback } from 'react'

import { FamilyRequestModel } from '../components/family-form-wrapper/models'

export const useSubmitFamily = (): {
  submitFamily: (values: FamilyFormValue) => void
} => {
  const user = useAuthUser()
  const { familyList } = useFamily()
  const { selectedFamily, copyMode } = useFamilyContext()
  const { selectedCarrier, setArchiveMode } = useCarrierDetailsContext()
  const createMutation = useCreateFamily()
  const editMutation = useEditFamily()

  const submitFamily = useCallback(
    (values: FamilyFormValue) => {
      let brandList: BrandDetails[] = []
      if (copyMode) {
        brandList =
          familyList.find((i) => i.id === values.id)?.brandDetailsList || []
      }
      const familyDetails = FamilyRequestModel(values, {
        copyMode,
        source: selectedFamily?.source,
        brandList,
      })

      if (!user || !selectedCarrier) {
        return
      }

      if (copyMode) {
        createMutation.mutate({
          carrierCode: selectedCarrier.code,
          carrierDistributionChannel: selectedCarrier?.distChannel,
          familyDetails,
        })
      } else {
        if (selectedFamily?.id) {
          editMutation.mutate(familyDetails)
        } else {
          createMutation.mutate({
            carrierCode: selectedCarrier.code,
            carrierDistributionChannel: selectedCarrier?.distChannel,
            familyDetails,
          })
        }
      }
      setArchiveMode(false)
    },
    [
      copyMode,
      selectedFamily?.source,
      selectedFamily?.id,
      user,
      selectedCarrier,
      setArchiveMode,
      familyList,
      createMutation,
      editMutation,
    ],
  )

  return { submitFamily }
}
