import { toast } from '@atlas-design-system/react'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { useCarrierDetailsContext } from 'common/context'
import { useFamilyContext } from 'common/context/family-context/family-context'
import { editFamily } from 'common/services'
import { EditFamilyRequest, EditFamilyResponse } from 'common/types'

export const useEditFamily = (): UseMutationResult<
  EditFamilyResponse,
  Error,
  EditFamilyRequest
> => {
  const { reloadCarrierDetails, setDirtyForm } = useCarrierDetailsContext()
  const { onUpdateFamilySuccess } = useFamilyContext()

  return useMutation<EditFamilyResponse, Error, EditFamilyRequest>(
    (data: EditFamilyRequest) => editFamily(data),
    {
      onSuccess: ({ confirmationId, entityId: familyId }) => {
        toast.success(
          'Well done! you have successfully updated the fare family. Confirmation id: ' +
            confirmationId,
        )
        reloadCarrierDetails()

        onUpdateFamilySuccess(familyId)
      },
      onError: ({ message }) => {
        toast.error(`Error: Something went wrong. ${message}`)
      },
    },
  )
}
