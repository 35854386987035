import { Shelf, ShelfValue } from 'common/types'

export const ShelfRequestModel = (
  items: ShelfValue[],
  toDelete: ShelfValue[],
): {
  itemsToUpdate: Shelf[]
  itemsToDelete: string[]
} => {
  const itemsToUpdate = items.map((shelf) => ({
    name: shelf.name,
    tags: shelf.tags.map(({ name, isActive }) => ({ name, isActive })),
  }))

  const itemsToDelete = toDelete.map(({ name }) => name)

  return {
    itemsToUpdate,
    itemsToDelete,
  }
}
