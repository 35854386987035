import { Permission, RoutePath, RouteTitle } from 'common/enums'
import { RoutePathItem } from 'common/types'

export const DATE_FORMAT = 'dd-MMM-yyyy'

export const DIRECTION_TITLE_MAP = {
  BETWEEN: 'Between',
  FROM: 'From',
  TO: 'To',
  WITHIN: 'Within',
}

export const ancillariesGroupCodes: any = {
  BD: 'Bundled Service',
  BF: 'Branded Fares',
  BG: 'Baggage',
  CO: 'Carbon Offset',
  FF: 'Frequent Flyer',
  GT: 'Ground Transportation and Non Air Services',
  IE: 'In flight Entertainment',
  LG: 'Lounge',
  MD: 'Medical',
  ML: 'Meal/Beverage',
  PT: 'Pets',
  RO: 'Rule Override',
  SA: 'Pre-reserved Seat Assignment',
  SB: 'Standby',
  ST: 'Store',
  TS: 'Travel Services',
  UN: 'Unaccompanied Travel(Escort)',
  UP: 'Upgrades',
  UU: 'Unaccompanied Travel(Unescorted)',
  EQ: 'Vistara codeshares',
}

export const TRAVELPORT_SUPPLIER_CODE = 'Travelport'

export const NAVIGATION_TABS_LIST: RoutePathItem[] = [
  {
    title: RouteTitle.DASHBOARD,
    path: RoutePath.DASHBOARD,
    carrierRequired: false,
    requiredPermission: Permission.READ_DASHBOARD,
  },
  {
    title: RouteTitle.ANCILLARIES,
    path: RoutePath.ANCILLARIES,
    carrierRequired: true,
    requiredPermission: Permission.READ_ANCILLARIES,
  },
  {
    title: RouteTitle.FAMILIES,
    path: RoutePath.FAMILIES,
    carrierRequired: true,
    requiredPermission: Permission.READ_FAREFAMILIES,
  },
  {
    title: RouteTitle.BRANDS,
    path: RoutePath.BRANDS,
    carrierRequired: true,
    requiredPermission: Permission.READ_BRANDS,
  },
  {
    title: RouteTitle.MARKETING_UPSELL,
    path: RoutePath.MARKETING_UPSELL,
    carrierRequired: true,
    requiredPermission: Permission.READ_MARKETING_UPSELL,
  },
  {
    title: RouteTitle.SEGMENT_BRANDING,
    path: RoutePath.SEGMENT_BRANDING,
    carrierRequired: true,
    requiredPermission: Permission.READ_SEGMENT_BRANDING,
  },
  {
    title: RouteTitle.MANDATORY_ANCILLARIES,
    path: RoutePath.MANDATORY_ANCILLARIES,
    carrierRequired: false,
    requiredPermission: Permission.READ_MANDATORY_ANCILLARY,
    travelportOnly: true,
  },
  {
    title: RouteTitle.AGENT_STORE_FRONT,
    path: RoutePath.AGENT_STORE_FRONT,
    carrierRequired: false,
    requiredPermission: Permission.READ_AGENT_STOREFRONT,
    travelportOnly: true,
  },
]
