import { ServiceAvailability, ServiceSource } from 'common/enums'
import { removeSpaces } from 'common/helpers'
import { ApiOptionalService } from 'common/types'
import { OptionalService } from 'common/types'

import { PriceRangeModel } from './price-range.model'
import { TextByCategoryModel } from './text-by-category.model'
import { TitleByTypeModel } from './title-by-type.model'

export const OptionalServiceModel = (
  optionalService: ApiOptionalService,
): OptionalService => {
  const {
    additionalDetailList = [],
    ancillaryDescription = '',
    baggageDetails,
    discontinueDate = '',
    effectiveDate = '',
    group = '',
    imageDetailsList = [],
    key = '',
    matchingData,
    priceRangeList = [],
    serviceAvailability = ServiceAvailability.FREE,
    serviceName = '',
    serviceRule = '',
    serviceTypeCode = '',
    source = ServiceSource.TRAVELPORT,
    subcode = '',
    subgroup = '',
    textByCategory = {},
    titleByType = {},
  } = optionalService

  return {
    additionalDetailList,
    ancillaryDescription,
    baggageDetails,
    discontinueDate,
    effectiveDate,
    group,
    imageDetailsList,
    key,
    matchingData,
    priceRangeList: priceRangeList.map(PriceRangeModel),
    serviceAvailability,
    serviceName: serviceName.trim(),
    serviceRule,
    serviceTypeCode,
    source,
    subcode: subcode.trim(),
    subgroup: subgroup.trim(),
    textByCategory: TextByCategoryModel(textByCategory),
    titleByType: TitleByTypeModel(titleByType),
    meta: {
      id: removeSpaces(`${group}${subgroup}${serviceName}`),
    },
  }
}
