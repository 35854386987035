import {
  EMPTY_SELECT_OPTION,
  GEOGRAPHY_POINT_INITIAL_VALUE,
  INITIAL_BAGGAGE_DETAILS,
  INITIAL_TEXT_BY_CATEGORY,
  INITIAL_TITLE_BY_TYPE,
} from 'common/constants'
import { ServiceSource } from 'common/enums'
import { toOption } from 'common/helpers'
import { GeographyListFormModel } from 'common/models'
import { AncillaryFormValue, OptionalService, SelectOption } from 'common/types'

const EMPTY_ANCILLARY_FORM_VALUES: AncillaryFormValue = {
  group: EMPTY_SELECT_OPTION,
  subgroup: EMPTY_SELECT_OPTION,
  subcode: '',
  serviceTypeCode: 'F',
  serviceName: EMPTY_SELECT_OPTION,
  ancillaryDescription: '',
  textByCategory: INITIAL_TEXT_BY_CATEGORY,
  titleByType: INITIAL_TITLE_BY_TYPE,
  effectiveDate: '',
  discontinueDate: '',
  serviceRule: '',
  matchingData: {
    geographyList: [GEOGRAPHY_POINT_INITIAL_VALUE],
    equipmentType: EMPTY_SELECT_OPTION,
    cabinType: EMPTY_SELECT_OPTION,
  },
  imageDetailsList: [],
  priceRangeList: [],
  source: ServiceSource.TRAVELPORT,
  baggageDetails: INITIAL_BAGGAGE_DETAILS,
  additionalDetailList: [],
  meta: {
    copyMode: false,
  },
}

export const AncillaryFormModel = (config: {
  selectedAncillary?: OptionalService
  groupSelectOptions?: SelectOption<string>[]
  subGroupSelectOptions?: SelectOption<string>[]
  copyMode: boolean
  archiveMode?: boolean
}): AncillaryFormValue => {
  const {
    selectedAncillary,
    groupSelectOptions,
    subGroupSelectOptions,
    copyMode,
    archiveMode,
  } = config
  if (!selectedAncillary || !groupSelectOptions || !subGroupSelectOptions) {
    return EMPTY_ANCILLARY_FORM_VALUES
  }

  const {
    key,
    group = '',
    subgroup = '',
    subcode = '',
    serviceTypeCode = 'F',
    serviceName = '',
    ancillaryDescription,
    textByCategory,
    titleByType = INITIAL_TITLE_BY_TYPE,
    effectiveDate = '',
    discontinueDate = '',
    serviceRule = '',
    matchingData,
    serviceAvailability,
    source = ServiceSource.TRAVELPORT,
    imageDetailsList = [],
    priceRangeList = [],
    baggageDetails = {
      cabin: '',
      weight: null,
      height: null,
      length: null,
      dimensionUnit: '',
      piece: null,
      width: null,
      weightUnit: '',
    },
    additionalDetailList = [],
  } = selectedAncillary

  const { SHORT = '', EXTERNAL = '' } = titleByType
  const {
    ATPCO = '',
    STRAPLINE = '',
    MARKETING_AGENT = '',
    MARKETING_CONSUMER = '',
  } = textByCategory
  const {
    cabin,
    weight,
    height,
    length,
    dimensionUnit,
    piece,
    width,
    weightUnit,
  } = baggageDetails

  const checkedBaggage = group === 'BG' &&
    subgroup === '' && { value: '', label: 'Checked Baggage' }

  return {
    key,
    group:
      groupSelectOptions.find(({ value }) => value === group) ||
      EMPTY_SELECT_OPTION,
    subgroup:
      checkedBaggage ||
      subGroupSelectOptions.find(({ value }) => value === subgroup) ||
      EMPTY_SELECT_OPTION,
    subcode,
    serviceTypeCode,
    ancillaryDescription,
    serviceName: toOption(serviceName),
    textByCategory: {
      ATPCO,
      STRAPLINE,
      MARKETING_AGENT,
      MARKETING_CONSUMER,
    },
    titleByType: {
      SHORT,
      EXTERNAL,
    },
    effectiveDate,
    discontinueDate,
    serviceRule,
    matchingData: {
      geographyList: matchingData?.geographyList
        ? GeographyListFormModel(matchingData.geographyList)
        : [GEOGRAPHY_POINT_INITIAL_VALUE],
      equipmentType: toOption(matchingData?.equipmentType),
      cabinType: toOption(matchingData?.cabinType),
    },
    serviceAvailability,
    source,
    imageDetailsList,
    priceRangeList,
    baggageDetails: {
      cabin: toOption(cabin),
      weight,
      height,
      length,
      dimensionUnit: toOption(dimensionUnit),
      piece,
      width,
      weightUnit: toOption(weightUnit),
    },
    additionalDetailList,
    meta: {
      copyMode,
      archiveMode,
    },
  }
}
