import {
  Button,
  ButtonGroup,
  Dialog,
  Form,
  Spinner,
  toast,
} from '@atlas-design-system/react'
import { FormCancelDialog } from 'common/components'
import { useAuth } from 'common/context/auth-context'
import { Permission } from 'common/enums'
import { useMandatoryAncillaries } from 'common/hooks'
import { useReferenceData } from 'common/hooks/use-reference-data.hook'
import { MandatoryAncillariesFormValue } from 'common/types'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { useUpdateMandatoryAncillary } from 'modules/mandatory-ancillaries/hooks'
import { FC, useMemo, useState } from 'react'

import { MandatoryAncillary } from '../mandatory-ancillary/mandatory-ancillary.component'
import { validationSchema } from './mandatory-ancillaries-form.schema'
import {
  EMPTY_MANDATORY_ANCILLARY,
  MandatoryAncillaryRequestModel,
} from './models'

export const MandatoryAncillariesForm: FC = () => {
  const { hasPermissionTo } = useAuth()
  const [cancelDialog, setCancelDialog] = useState(false)
  const [saveDialog, setSaveDialog] = useState(false)

  const updateMutation = useUpdateMandatoryAncillary()
  const { mandatoryAncillaryList, isLoading } = useMandatoryAncillaries()
  const { groupSubGroupMap } = useReferenceData()
  const initialValues = { mandatoryAncillaryList }

  const hasWritePermission = useMemo(
    () => hasPermissionTo(Permission.WRITE_MANDATORY_ANCILLARY),
    [hasPermissionTo],
  )

  const submitDisabled = useMemo(
    () => !hasWritePermission,
    [hasWritePermission],
  )

  const formik = useFormik<MandatoryAncillariesFormValue>({
    initialValues,
    onSubmit: (values) => {
      const request = MandatoryAncillaryRequestModel(values)

      updateMutation.mutate(request)
      setSaveDialog(false)
    },
    validationSchema: validationSchema(groupSubGroupMap),
    enableReinitialize: true,
  })

  const { values, isValid, handleSubmit, resetForm, submitForm } = formik

  const handleReset = () => {
    resetForm()
    setCancelDialog(false)
  }

  const verifyDuplicates = (): boolean => {
    const mandatoryAncillaries = values.mandatoryAncillaryList.map(
      ({ group, subcode, subgroup }) =>
        JSON.stringify({ group, subgroup, subcode }),
    )
    const unique = Array.from(new Set(mandatoryAncillaries))
    return mandatoryAncillaries.length !== unique.length
  }

  const handleSave = () => {
    if (!hasWritePermission) {
      return
    }

    if (isValid) {
      const hasDuplicates = verifyDuplicates()

      if (hasDuplicates) {
        toast.error(
          'Error: Duplicate mandatory ancillary found with same Group, SubGroup and Subcode',
        )
      } else {
        setSaveDialog(true)
      }
    } else {
      submitForm()
    }
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          {isLoading ? (
            <Spinner data-testid="mandatory-ancillary-spinner" />
          ) : (
            <>
              <FieldArray name="mandatoryAncillaryList">
                {(fieldArrayHelpers) => (
                  <>
                    {values.mandatoryAncillaryList.length ? (
                      values.mandatoryAncillaryList.map((item, index) => (
                        <MandatoryAncillary
                          formik={formik}
                          key={index}
                          index={index}
                          item={item}
                          fieldArrayProps={fieldArrayHelpers}
                          disabled={!hasWritePermission}
                        />
                      ))
                    ) : (
                      <Button
                        disabled={!hasWritePermission}
                        onClick={() =>
                          fieldArrayHelpers.insert(0, EMPTY_MANDATORY_ANCILLARY)
                        }
                      >
                        Add Mandatory Ancillary
                      </Button>
                    )}
                  </>
                )}
              </FieldArray>
              <ButtonGroup>
                <Button
                  disabled={submitDisabled}
                  onClick={() => setCancelDialog(true)}
                >
                  Cancel
                </Button>
                <Button disabled={submitDisabled} onClick={handleSave}>
                  Save
                </Button>
              </ButtonGroup>
            </>
          )}
        </Form>
      </FormikProvider>
      <FormCancelDialog
        hidden={!cancelDialog}
        onDismiss={() => setCancelDialog(false)}
        onConfirm={handleReset}
      />
      <Dialog
        id="mandatory-ancillaries-save-dialog"
        appearance="warning"
        hidden={!saveDialog}
        onDismiss={() => setSaveDialog(false)}
        onConfirm={submitForm}
      >
        Warning! Changes may result in impacted Shelves getting deleted. Deleted
        Shelves have to be manually redefined. Confirm Changes?
      </Dialog>
    </>
  )
}
