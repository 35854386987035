import { CustomCollapsible } from 'common/components'
import { useAncillaryContext } from 'common/context'
import { groupServices } from 'common/helpers'
import { ServiceGroup } from 'common/types'
import React, { useMemo } from 'react'

import { SegmentBrandingSelectRow } from '../segment-branding-select-row/segment-branding-select-row.component'
import styles from './segment-branding-sidebar-block.module.css'

type SegmentBrandingSidebarBlockProps = {
  serviceGroup: ServiceGroup
}

export const SegmentBrandingSidebarBlock: React.FC<
  SegmentBrandingSidebarBlockProps
> = ({ serviceGroup }) => {
  const { selectedAncillary } = useAncillaryContext()
  const { title, services } = serviceGroup

  const serviceGroups = groupServices(services, 'source')

  const selectedAncillaryInGroup = useMemo(() => {
    for (const ancillaryGroup of serviceGroups) {
      const findedAncillary = ancillaryGroup.services.find(
        (ancillary) => ancillary.key === selectedAncillary?.key,
      )
      if (findedAncillary) {
        return findedAncillary
      }
    }
  }, [selectedAncillary, serviceGroups])

  return (
    <>
      <CustomCollapsible
        title={title}
        defaultOpened={!!selectedAncillaryInGroup}
        data-testid="segment-branding-sidebar-collapsible-element"
      >
        <ul className={styles.list}>
          {serviceGroup.services.map((service) => (
            <li
              key={service.key + service.serviceName}
              className={styles.listItem}
              data-testid="segment-branding-sidebar-list-element"
            >
              <SegmentBrandingSelectRow service={service} />
            </li>
          ))}
        </ul>
      </CustomCollapsible>
    </>
  )
}
