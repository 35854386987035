import { Button } from '@atlas-design-system/react'
import { useCarrierDetailsContext } from 'common/context'
import { FC } from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  flex: 0 0 auto;
`

export const ArchiveButton: FC = () => {
  const { archiveMode, setArchiveMode } = useCarrierDetailsContext()

  const handleArchiveMode = () => {
    setArchiveMode(!archiveMode)
  }

  return (
    <StyledWrapper className="col-xs">
      <Button
        appearance="tertiary"
        size="small"
        className="link-button"
        onClick={handleArchiveMode}
      >
        {archiveMode ? 'view current' : 'view archive'}
      </Button>
    </StyledWrapper>
  )
}
