import { toast } from '@atlas-design-system/react'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useCarrierDetailsContext } from 'common/context'
import { useSegmentBrandingContext } from 'common/context/segment-branding-context'
import { useAuthUser } from 'common/hooks'
import { deleteAncillary } from 'common/services'
import {
  DeleteSegmentBrandRequest,
  DeleteSegmentBrandResponse,
  ErrorMessage,
} from 'common/types'
import { useCallback } from 'react'

type DeleteBrandFn = (discontinueDate: string) => void
export const useDeleteSegmentBranding = (): DeleteBrandFn => {
  const { reloadCarrierDetails } = useCarrierDetailsContext()
  const { selectedSegmentBrand, onDeleteSegmentBrandSuccess } =
    useSegmentBrandingContext()
  const user = useAuthUser()

  const deleteMutation = useMutation<
    DeleteSegmentBrandResponse,
    AxiosError<ErrorMessage>,
    DeleteSegmentBrandRequest
  >((data: DeleteSegmentBrandRequest) => deleteAncillary(data), {
    onSuccess: ({ confirmationId, entityId }) => {
      toast.success(
        'Success! Segment has been Archived. Confirmation id: ' +
          confirmationId,
      )
      reloadCarrierDetails()
      onDeleteSegmentBrandSuccess(entityId)
    },
    onError: ({ response, message: baseMassage }) => {
      toast.error(`Error: ${response?.data.message || baseMassage}`)
    },
  })

  // on success delete segment drand updates with new discontinue date, it will arhive at the end of discontinue date

  const handleDeleteSegmentBrand = useCallback(
    (discontinueDate: string) => {
      if (!user || !selectedSegmentBrand?.key) {
        return
      }

      deleteMutation.mutate({
        id: selectedSegmentBrand?.key,
        discontinueDate,
      })
    },
    [user, selectedSegmentBrand?.key, deleteMutation],
  )

  return handleDeleteSegmentBrand
}
