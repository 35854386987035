import { Typography } from '@atlas-design-system/react'
import { AppSidebar } from 'common/components'
import { ArchiveButton } from 'common/components/archive-button/archive-button'
import { FamilySelect } from 'common/components/family-select/family-select.component'
import { useBrandContext } from 'common/context'
import React, { useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import styles from './brands-sidebar.module.css'
import { BrandsList } from './components'

export const BrandsSidebar: React.FC = () => {
  const { selectFamily, selectedFamily } = useBrandContext()

  const handleFamilySelect = useCallback(
    (id: string) => {
      selectFamily(id)
    },
    [selectFamily],
  )

  return (
    <>
      <AppSidebar>
        <div className={styles.header}>
          <div className="row middle-xs between-xs">
            <div className="col-xs">
              <Typography variant="displayText6" bold>
                Brands
              </Typography>
            </div>
            <ArchiveButton />
          </div>
        </div>
        <div className={styles.familySelect}>
          <FamilySelect
            label={'Family'}
            placeholder={'Select one'}
            selectedFamilyId={selectedFamily?.id}
            onFamilySelect={handleFamilySelect}
            className={'mmp-ui-form-control-required'}
            hideAtpcoNumber={false}
          />
        </div>
        <div>
          {selectedFamily && (
            <DndProvider backend={HTML5Backend}>
              <BrandsList />
            </DndProvider>
          )}
        </div>
      </AppSidebar>
    </>
  )
}
