export enum Permission {
  READ_AGENT_STOREFRONT = 'READ_AGENT_STOREFRONT',
  READ_ANCILLARIES = 'READ_ANCILLARIES',
  READ_BRANDS = 'READ_BRANDS',
  READ_DASHBOARD = 'READ_DASHBOARD',
  READ_FAREFAMILIES = 'READ_FAREFAMILIES',
  READ_MANDATORY_ANCILLARY = 'READ_MANDATORY_ANCILLARY',
  READ_MARKETING_UPSELL = 'READ_MARKETING_UPSELL',
  READ_SEGMENT_BRANDING = 'READ_SEGMENT_BRANDING',
  WRITE_AGENT_STOREFRONT = 'WRITE_AGENT_STOREFRONT',
  WRITE_ANCILLARIES = 'WRITE_ANCILLARIES',
  WRITE_BRANDS = 'WRITE_BRANDS',
  WRITE_DASHBOARD = 'WRITE_DASHBOARD',
  WRITE_FAREFAMILIES = 'WRITE_FAREFAMILIES',
  WRITE_MANDATORY_ANCILLARY = 'WRITE_MANDATORY_ANCILLARY',
  WRITE_MARKETING_UPSELL = 'WRITE_MARKETING_UPSELL',
  WRITE_SEGMENT_BRANDING = 'WRITE_SEGMENT_BRANDING',
  UPDATE_CARRIERS = 'UPDATE_CARRIERS',
  CREATE_CARRIERS = 'CREATE_CARRIERS',
}
