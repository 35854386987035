import { Typography } from '@atlas-design-system/react'
import { FamilyStatusIcon } from 'common/components'
import { Family } from 'common/types'
import React from 'react'
import styled from 'styled-components'

const StyledSubtitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .date-text {
    margin-right: var(--spaceS);
  }
`

type MarketingUpsellHeaderProps = {
  selectedFamily: Family
}

export const MarketingUpsellHeader: React.FC<MarketingUpsellHeaderProps> = ({
  selectedFamily,
}) => {
  const { name, effectiveSearchDate, discontinueSearchDate, status } =
    selectedFamily
  return (
    <div data-testid="marketing-upsell-header">
      <div>
        <Typography variant="displayText7">{name}</Typography>
      </div>
      <StyledSubtitleRow>
        <Typography variant="contentText1" className="date-text">
          {effectiveSearchDate} - {discontinueSearchDate}
        </Typography>
        <FamilyStatusIcon status={status} />
      </StyledSubtitleRow>
    </div>
  )
}
