import { RestrictedAccessPage } from 'common/components'
import { NAVIGATION_TABS_LIST } from 'common/constants'
import { useCarrierDetailsContext } from 'common/context'
import { useAuth } from 'common/context/auth-context'
import { Permission } from 'common/enums'
import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

type RequireAccessProps = {
  permission?: Permission
}

export const RequireAccess: React.FC<RequireAccessProps> = ({ permission }) => {
  const { selectedCarrier } = useCarrierDetailsContext()
  const { hasPermissionTo, isTravelportUser, isAdminUser } = useAuth()
  const location = useLocation()

  const page = NAVIGATION_TABS_LIST.find(
    (item) => `/${item.path}` === location.pathname,
  )

  if (!!page?.travelportOnly && !isTravelportUser && !isAdminUser) {
    return <RestrictedAccessPage />
  }

  if (permission) {
    if (!hasPermissionTo(permission)) {
      return <RestrictedAccessPage />
    }
  }

  if (page && page.carrierRequired && !selectedCarrier) {
    return <Navigate to="/dashboard" />
  }

  return <Outlet />
}
