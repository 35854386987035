import {
  AuthorisedIcon,
  CurrencyIcon,
  ErrorIcon,
  Lozenge,
} from '@atlas-design-system/react'
import { ServiceAvailability } from 'common/enums'
import { FC } from 'react'

type AvailabilityStatusProps = {
  status: ServiceAvailability
}

type Appearance = 'success' | 'warning' | 'error'
type Text = 'Included' | 'Chargeable' | 'Not Offered'
type Icon = typeof AuthorisedIcon

export const AvailabilityStatus: FC<AvailabilityStatusProps> = ({ status }) => {
  const appearanceMap: { [key: string]: Appearance } = {
    [ServiceAvailability.FREE]: 'success',
    [ServiceAvailability.CHARGEABLE]: 'warning',
    [ServiceAvailability.NOT_OFFERED]: 'error',
  }

  const textMap: { [key: string]: Text } = {
    [ServiceAvailability.FREE]: 'Included',
    [ServiceAvailability.CHARGEABLE]: 'Chargeable',
    [ServiceAvailability.NOT_OFFERED]: 'Not Offered',
  }

  const iconMap: { [key: string]: Icon } = {
    [ServiceAvailability.FREE]: <AuthorisedIcon />,
    [ServiceAvailability.CHARGEABLE]: <CurrencyIcon />,
    [ServiceAvailability.NOT_OFFERED]: <ErrorIcon />,
  }

  return (
    <Lozenge
      appearance={appearanceMap[status]}
      text={textMap[status]}
      icon={iconMap[status]}
    />
  )
}
