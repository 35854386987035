import { ActionIconGroup, AddIcon, CloseIcon } from '@atlas-design-system/react'
import { FC } from 'react'

const disabledColor = 'var(--contrastColor40)'

type ActionButtonsProps = {
  onAdd: () => void
  onDelete: () => void
  disabled: boolean
}

export const ActionButtons: FC<ActionButtonsProps> = ({
  onAdd,
  onDelete,
  disabled,
}) => {
  const canDelete = true
  const canAdd = true

  const actionButtons = !disabled
    ? [
        {
          icon: <AddIcon fill={canAdd ? '' : disabledColor} />,
          key: 'add',
          tooltip: 'Add Ancillary',
          action: onAdd,
        },
        {
          icon: <CloseIcon fill={canDelete ? '' : disabledColor} />,
          key: 'delete',
          tooltip: 'Delete Ancillary',
          action: onDelete,
        },
      ]
    : []

  return (
    <ActionIconGroup data-testid="action-buttons" actions={actionButtons} />
  )
}
