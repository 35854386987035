import {
  discontinueDateSchema,
  effectiveDateSchema,
  geographyPointSchema,
  marketingPartnersSchema,
  selectSchema,
} from 'common/helpers'
import * as Yup from 'yup'

const titleByTypeSchema = Yup.object({
  EXTERNAL: Yup.string().required('Required'),
  SHORT: Yup.string().required('Required'),
})

const textByCategorySchema = Yup.object({
  STRAPLINE: Yup.string().required('Required'),
  MARKETING_AGENT: Yup.string(),
  MARKETING_CONSUMER: Yup.string(),
})

const matchingDataSchema = Yup.object({
  geographyList: Yup.array().of(geographyPointSchema),
  cabinType: selectSchema,
  equipmentType: selectSchema,
  marketingPartners: marketingPartnersSchema,
})

export const validationSchema = Yup.object({
  titleByType: titleByTypeSchema,

  subcode: Yup.string(),
  serviceTypeCode: Yup.string().required('Required'),
  serviceName: Yup.string().required('Required'),
  textByCategory: textByCategorySchema,
  effectiveDate: effectiveDateSchema,
  discontinueDate: discontinueDateSchema('effectiveDate'),
  matchingData: matchingDataSchema,
})
