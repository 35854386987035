import {
  FormControl,
  Radio,
  RadioGroup,
  Select,
} from '@atlas-design-system/react'
import styles from 'common/components/layout/sidebar/sidebar.module.css'
import { useReferenceData } from 'common/hooks/use-reference-data.hook'
import { AdditionalDetail } from 'common/types'
import { FC } from 'react'

type MultiLanguageSidebarProps = {
  setLanguage: (language?: string) => void
  additionalDetailList: AdditionalDetail[]
  selectedLanguage?: string
  fieldsDisabled: boolean
}

const emptyOption = { value: undefined, label: 'Select one' }

export const MultiLanguageSidebar: FC<MultiLanguageSidebarProps> = (props) => {
  const {
    setLanguage,
    selectedLanguage,
    additionalDetailList,
    fieldsDisabled,
  } = props
  const { languagesSelectOptions } = useReferenceData()

  const radioOptions = additionalDetailList
    .map((detail) =>
      languagesSelectOptions.find((option) => option.value === detail.langCode),
    )
    .filter(Boolean)

  const selectOptions = [
    ...languagesSelectOptions.filter(
      (option) => !radioOptions.some((radio) => radio?.value === option.value),
    ),
    emptyOption,
  ]

  const selectValue =
    selectOptions.find((option) => option.value === selectedLanguage) ||
    emptyOption

  return (
    <div className={styles.sidebar}>
      <FormControl label="" disabled={fieldsDisabled}>
        <Select
          next
          options={selectOptions}
          onChange={(option) => setLanguage(option.value)}
          value={selectValue}
          className="atls-select-height-360"
          data-testid="multi-language-language-select"
          isSearchable
        />
      </FormControl>
      <RadioGroup
        value={selectedLanguage}
        onChange={(event) => setLanguage(event.target.value)}
      >
        {radioOptions.map((radio) => (
          <Radio
            key={radio?.value}
            label={radio?.label}
            data-testid={`multi-language-radio-${radio?.label}`}
            name="selectedLanguage"
            value={radio?.value}
          />
        ))}
      </RadioGroup>
    </div>
  )
}
