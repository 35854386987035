import { FormControl, Select } from '@atlas-design-system/react'
import { EMPTY_SELECT_OPTION } from 'common/constants'
import { matchFromStart } from 'common/helpers'
import { useReferenceData } from 'common/hooks/use-reference-data.hook'
import { SelectOption } from 'common/types'
import { useField } from 'formik'
import React, { useCallback } from 'react'

type CurrenciesSelectProps = {
  name: string
  disabled?: boolean
}
export const CurrenciesSelect: React.FC<CurrenciesSelectProps> = ({
  disabled = false,
  ...props
}) => {
  const [field, , helpers] = useField(props)
  const { setValue } = helpers

  const { currenciesSelectOptions } = useReferenceData()

  const handleChange = useCallback(
    (selectedOption: SelectOption) => {
      setValue(selectedOption)
    },
    [setValue],
  )

  return (
    <>
      <FormControl label="" disabled={disabled}>
        <Select
          next
          {...field}
          {...props}
          isSearchable
          data-testid="currency-code-select"
          className="atls-select-height-200"
          onChange={handleChange}
          options={[EMPTY_SELECT_OPTION, ...currenciesSelectOptions]}
          isDisabled={disabled}
          filterOption={matchFromStart}
        />
      </FormControl>
    </>
  )
}
