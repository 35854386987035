import { AddIcon, Button, FormControl, Input } from '@atlas-design-system/react'
import { CommonCarrierSelect } from 'common/components/carrier-select/common-carrier-select.component'
import { useAuth, useCarrierDetailsContext } from 'common/context'
import { PendingAction, Permission } from 'common/enums'
import { noop } from 'common/helpers'
import { CarrierFormValue, SelectOption } from 'common/types'
import { FormikProps } from 'formik'
import { useCallback, useMemo } from 'react'

import styles from '../../carrier-form.module.css'

type CarrierBuilderProps = {
  formik: FormikProps<CarrierFormValue>
}

export const CarrierBuilder: React.FC<CarrierBuilderProps> = ({ formik }) => {
  const { values, handleChange, handleBlur, errors, touched } = formik

  const {
    resetCarrier,
    dirtyForm,
    setPendingAction,
    selectCarrier,
    selectPendingCarrier,
  } = useCarrierDetailsContext()

  const { hasPermissionTo } = useAuth()

  const onNewButtonClick = () => {
    if (dirtyForm) {
      setPendingAction(PendingAction.NEW_BUTTON_CLICK)
    } else {
      resetCarrier()
    }
  }

  const canCreate = useMemo(() => {
    return hasPermissionTo(Permission.CREATE_CARRIERS)
  }, [hasPermissionTo])

  const onCarrierSelect = useCallback(
    ({ value }: SelectOption<string>) => {
      if (dirtyForm) {
        setPendingAction(PendingAction.SELECT_ANOTHER_CARRIER)
        selectPendingCarrier(value)
      } else {
        selectCarrier(value)
      }
    },
    [dirtyForm, selectCarrier, selectPendingCarrier, setPendingAction],
  )

  return (
    <div>
      <div className="row">
        <div className="col-xs">
          <FormControl
            label="Supplier"
            className={styles.carrierFormControl}
            hint="Select a Supplier. You can begin typing to search, or create a new supplier"
          >
            <CommonCarrierSelect
              handleSelect={onCarrierSelect}
              formatLabel={(carrier) => `${carrier.name} - ${carrier.code}`}
            />
          </FormControl>
        </div>
        <div className={`col-xs-2 ${styles.addButton}`}>
          <Button
            icon={<AddIcon />}
            onClick={onNewButtonClick}
            disabled={!canCreate}
          >
            New Carrier
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-2">
          <FormControl
            label="Carrier Code"
            className={`mmp-ui-form-control-required ${styles.carrierFormControl}`}
            error={touched.code && errors.code}
            disabled={!!values.id || !canCreate}
          >
            <Input
              name="code"
              data-testid="carrier-code-input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.code}
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </div>
        <div className="col-xs-4">
          <FormControl
            label="Carrier Name"
            className={`mmp-ui-form-control-required ${styles.carrierFormControl}`}
            error={touched.name && errors.name}
            disabled={!canCreate}
          >
            <Input
              name="name"
              data-testid="carrier-name-input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </div>
        <div className="col-xs-6">
          <FormControl
            label="Carrier Web URL"
            className={styles.carrierFormControl}
            error={touched.url && errors.url}
            disabled={!canCreate}
          >
            <Input
              name="url"
              data-testid="carrier-url-input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.url}
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </div>
      </div>
      <div className="row">
        <div className="col-xs">
          <FormControl
            label="Carrier Description"
            className={`mmp-ui-form-control-required ${styles.carrierFormControl}`}
            error={touched.description && errors.description}
            disabled={!canCreate}
          >
            <Input
              name="description"
              data-testid="carrier-description-input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </div>
      </div>
    </div>
  )
}
