import { Card, Typography } from '@atlas-design-system/react'
import { CommonCarrierSelect } from 'common/components/carrier-select/common-carrier-select.component'
import { useCarrierDetailsContext } from 'common/context'
import { SelectOption } from 'common/types'
import React, { useCallback } from 'react'

import styles from './carrier-select.module.css'

export const CarrierSelect: React.FC = () => {
  const { selectCarrier } = useCarrierDetailsContext()

  const onCarrierSelect = useCallback(
    ({ value }: SelectOption<string>) => {
      selectCarrier(value)
    },
    [selectCarrier],
  )

  return (
    <div>
      <div className="row">
        <div className="col-xs-3"></div>
        <div className="col-xs-6">
          <Card className={styles.card}>
            <div className={styles.cardContent}>
              <div className={styles.cardTitle}>
                <Typography variant="displayText6">
                  Select the carrier you want to sign in as:
                </Typography>
              </div>
              <CommonCarrierSelect
                handleSelect={onCarrierSelect}
                formatLabel={(carrier) => carrier.name}
              />
            </div>
          </Card>
        </div>
        <div className="col-xs-3"></div>
      </div>
    </div>
  )
}
