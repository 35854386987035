import { Radio } from '@atlas-design-system/react'
import { CellContext } from '@tanstack/react-table'
import { ServiceAvailability } from 'common/enums'
import {
  OptionalService,
  OptionalServicesByCategoryValue,
  OptionalServiceValue,
} from 'common/types'
import { useField } from 'formik'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

type AvailabilityRadioProps = CellContext<
  OptionalService,
  ServiceAvailability
> & {
  serviceAvailabilityType: ServiceAvailability
  disabled: boolean
  name: keyof OptionalServicesByCategoryValue
}

const StyledRadio = styled.div`
  .atls-radio {
    justify-content: center;
    .radioBtn {
      margin: 0;
    }
  }
`

export const AvailabilityRadio: React.FC<AvailabilityRadioProps> = ({
  row,
  serviceAvailabilityType,
  disabled: tableDisabled,
  name,
}) => {
  const [field, , helpers] = useField<OptionalServiceValue[]>(
    `optionalServicesByCategory.${name}`,
  )

  const { value = [] } = field
  const { setValue } = helpers

  const checked = useMemo(() => {
    if (!row.getIsSelected()) {
      return false
    }

    const item = value.find(({ meta: { id } }) => id === row.id)

    if (!item) {
      return false
    }

    return item.serviceAvailability === serviceAvailabilityType
  }, [value, row, serviceAvailabilityType])

  const disabled = useMemo(() => {
    if (tableDisabled) {
      return true
    }
    return !row.getIsSelected()
  }, [row, tableDisabled])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const serviceAvailability = event.target.value as ServiceAvailability

      const newValue = value.map((service) =>
        service.meta.id === row.id
          ? {
              ...service,
              serviceAvailability,
            }
          : service,
      )

      setValue(newValue)
    },
    [setValue, row, value],
  )

  return (
    <StyledRadio>
      <Radio
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
        name={`serviceAvailability-${row.id}`}
        value={serviceAvailabilityType}
        id={`serviceAvailability-${serviceAvailabilityType}-${row.id}`}
        key={row.id}
        label=""
      />
    </StyledRadio>
  )
}
