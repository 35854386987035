import { Select } from '@atlas-design-system/react'
import { useAuth, useCarrierDetailsContext } from 'common/context'
import { matchFromStart } from 'common/helpers'
import { useCarriers } from 'common/hooks'
import { Carrier, SelectOption } from 'common/types'
import { useMemo } from 'react'

type CarrierSelectProps = {
  formatLabel: (carrier: Carrier) => string
  handleSelect: (value: SelectOption<string>) => void
}

export const CommonCarrierSelect: React.FC<CarrierSelectProps> = ({
  formatLabel,
  handleSelect,
}) => {
  const { selectedCarrier } = useCarrierDetailsContext()
  const { isTravelportUser, userSupplierCodes } = useAuth()
  const { carrierList } = useCarriers()

  const filteredCarrierList = useMemo(() => {
    if (isTravelportUser) {
      return carrierList
    }

    return carrierList.filter((carrier) =>
      userSupplierCodes.includes(carrier.code),
    )
  }, [carrierList, isTravelportUser, userSupplierCodes])

  const carrierSelectOptions = useMemo(
    () =>
      filteredCarrierList.map((carrier) => ({
        value: carrier.code,
        label: formatLabel(carrier),
      })),
    [filteredCarrierList, formatLabel],
  )

  const displayValue = useMemo(() => {
    const display = carrierSelectOptions.find(
      ({ value }) => value === selectedCarrier?.code,
    )
    return !!display ? display : null
  }, [carrierSelectOptions, selectedCarrier])

  return (
    <div>
      <Select
        next
        isSearchable
        data-testid="carrier-select"
        options={carrierSelectOptions}
        placeholder="Select one"
        onChange={handleSelect}
        className="atls-select-height-360"
        filterOption={matchFromStart}
        value={displayValue}
      />
    </div>
  )
}
